export const pointsSinguliersDictionnary: {
  [key: string]: {
    title: string;
    amount: number;
    typedeTravaux: number;
  };
} = {
  isGoutiere: {
    title: "goutière(s)",
    amount: 110,
    typedeTravaux: 629950013,
  },
  isGroupeExterieur: {
    title: "groupe(s) extérieur(s)",
    amount: 0, // Frais sup à estimer post VT - mettre 0€ (cf. spec https://dev.azure.com/TucoEnergie/Oree/_wiki/wikis/PRODUCT/59/Specs-VT-ITE-pour-Web)
    typedeTravaux: 629950014,
  },
  isEvacuationEaux: {
    title: "évacuation(s) des eaux",
    amount: 110,
    typedeTravaux: 629950015,
  },
  isMiseEnGoulotteCableExterieur: {
    title: "mise(s) en goulotte cable extérieur",
    amount: 20,
    typedeTravaux: 629950016,
  },
  isPriseElectrique: {
    title: "prise(s) électrique(s)",
    amount: 10,
    typedeTravaux: 629950017,
  },
  isRobinetExterieur: {
    title: "robinet(s) extérieur(s)",
    amount: 30,
    typedeTravaux: 629950018,
  },
  isAppliqueMurale: {
    title: "applique(s) murale's)",
    amount: 30,
    typedeTravaux: 629950019,
  },
  isStoreBannes: {
    title: "store(s) bannes",
    amount: 300,
    typedeTravaux: 629950020,
  },
  isParabole: {
    title: "parabole(s)",
    amount: 250,
    typedeTravaux: 629950033,
  },
  isGondDeVolet: {
    title: "gond(s) de volet",
    amount: 65,
    typedeTravaux: 629950022,
  },
  isGardeCorpFenetreScelllement: {
    title: "garde(s) corps de fenêtre en scellement",
    amount: 250,
    typedeTravaux: 629950023,
  },
  isGardeCorpFenetrePlatine: {
    title: "garde(s) corps de fenêtre en platine",
    amount: 250,
    typedeTravaux: 629950024,
  },
  isAérationGrille: {
    title: "aération(s) grille",
    amount: 15,
    typedeTravaux: 629950025,
  },
  isReagreage: {
    title: "réagreage(s)",
    amount: 55,
    typedeTravaux: 629950026,
  },
  isMarquiseDePorte: {
    title: "marquise(s) de porte",
    amount: 250,
    typedeTravaux: 629950027,
  },
  isMarquiseDeFenetre: {
    title: "marquise(s) de fenêtre",
    amount: 250,
    typedeTravaux: 629950028,
  },
  appuiFenetreExistant: {
    title: "appui fenêtre normaux",
    amount: 55,
    typedeTravaux: 629950030,
  },
  appuiFenetreProfond: {
    title: "appui fenêtre profond (> 60 ou 90cm)",
    amount: 55,
    typedeTravaux: 629950031,
  },
};
