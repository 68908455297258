import "./BonDeCommandeAModifierComponent.scss";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { SecondaryButton } from "../../../components/SecondaryButton/SecondaryButton";

export const BonDeCommandeAModifierComponent = ({
  onClickYes,
  onClickNo,
}: {
  onClickYes: () => void;
  onClickNo: () => void;
}) => {
  return (
    <div className="BonDeCommandeAModifierComponent">
      <div className="BonDeCommandeAModifierComponent__TextBlock">
        <h3>Le bon de commande est-il à modifier ?</h3>
        <p>
          Exemple : dimensionnement à revoir, produit à ajouter, frais
          supplémentaire à ajouter, ...
        </p>
      </div>

      <div className="BonDeCommandeAModifierComponent__Buttons">
        <>
          <SecondaryButton name="Non" onClick={onClickNo} />
          <MainButton name="Oui" onClick={onClickYes} size="big" />
        </>
      </div>
    </div>
  );
};
