// Libraries import
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { FormWrapper } from "../../../containers/Forms/FormWrapper/FormWrapper";
import { SubHeader } from "../../../components/SubHeader/SubHeader";
import * as PacAirEauPages from "./Pages";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetVTCommonInfoFromCRM } from "../../../services/Forms/useGetVTCommonInfoFromCRM";
import { useGetFormAnswerFromLocalStorage } from "../../../services/Forms/useGetFormAnswerFromLocalStorage";

// Data import
import { PacAirEauFormData } from "../../../data/forms/PacAirEauFormData";
import { useUpdateFormCETAccordingToUserAnswer } from "../../../services/Forms/CETForm/useUpdateFormCETAccordingToUserAnswer";
import { Ranaglyphe } from "../../../containers/Ranaglyphe/Ranaglyphe";

export const PacAirEau = () => {
  const singleVisitId = useParams().visitId;
  const formName = PacAirEauFormData.formName;
  const formsMutableData = useAppSelector(
    (state) => state.formsMutableData[formName]
  );
  const currentScreenIndex = formsMutableData.currentScreenIndex;
  const currentScreenName =
    PacAirEauFormData.formScreensName[currentScreenIndex];

  const {
    getVTCommonInfoFromCRM,
    isLoading: isGetVTCommonInfoLoading,
    errorMessage: getVTCommonInfoErrorMessage,
  } = useGetVTCommonInfoFromCRM();

  const { visiteTechniqueInfo, userAuthInfo } = useAppSelector(
    (state) => state
  );

  const [isRanaglypheClicked, setIsRanaglypheClicked] =
    useState<boolean>(false);

  // Get saved form answer from local storage if there are any
  useGetFormAnswerFromLocalStorage({ formName, singleVisitId });

  useEffect(() => {
    // At the opening of the form we download data from CRM
    if (singleVisitId) {
      getVTCommonInfoFromCRM(userAuthInfo.userToken, formName, singleVisitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVisitId]);

  useUpdateFormCETAccordingToUserAnswer({ formName, formsMutableData });

  const PageToDisplay =
    PacAirEauPages[
      PacAirEauFormData.formScreens[currentScreenName].pages[
        formsMutableData.currentPageIndex[currentScreenName]
      ]
    ];

  return (
    <div>
      <div>
        <SubHeader
          clientName={
            visiteTechniqueInfo.customerFirstName +
            " " +
            visiteTechniqueInfo.customerLastName
          }
          productType={visiteTechniqueInfo.products}
          commercialFullName={visiteTechniqueInfo.commercialFullName}
          commercialMobilePhone={visiteTechniqueInfo.commercialMobilePhone}
          urlButtonBack={`/visite-technique/${singleVisitId}`}
          commercialEmail={visiteTechniqueInfo.commercialEmail}
          formName="PAC A/E"
        />
      </div>
      {isRanaglypheClicked ? (
        <Ranaglyphe
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
          visiteTechniqueInfo={visiteTechniqueInfo}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
        />
      ) : (
        <FormWrapper
          visiteTechniqueInfo={visiteTechniqueInfo}
          formName={formName}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
          formPagesLength={
            PacAirEauFormData.formScreens[currentScreenName].pages.length
          }
          visiteTechniqueId={singleVisitId}
          formScreenSteps={
            PacAirEauFormData.formScreens[currentScreenName].steps
          }
          currentScreenName={currentScreenName}
          numberOfScreens={PacAirEauFormData.formScreensName.length}
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
        >
          <Layout>
            <div>
              <PageToDisplay
                PacAirEauFormMutableData={formsMutableData}
                visitId={singleVisitId}
              />
            </div>
          </Layout>
        </FormWrapper>
      )}
    </div>
  );
};
