// Interfaces import

interface IITEFormData {
  formName: "ITE";
  formScreensName: ["visiteTechniqueFormPages"];
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "Installation électrique",
        "Logistique",
        "Extérieur",
        "Résultats"
      ];
      pages: [
        "InstallationElectrique",
        "LogistiquePage",
        "ExterieurPage",
        "ResultatsPage"
      ];
    };
    extraChargesFormPages: {
      steps: [];
      pages: [];
    };
  };
}

export const ITEFormData: IITEFormData = {
  formName: "ITE",
  formScreensName: ["visiteTechniqueFormPages"],
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "Installation électrique",
        "Logistique",
        "Extérieur",
        "Résultats",
      ],
      pages: [
        "InstallationElectrique",
        "LogistiquePage",
        "ExterieurPage",
        "ResultatsPage",
      ],
    },
    extraChargesFormPages: {
      steps: [],
      pages: [],
    },
  },
};
