// Libraries import
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Components import
import { PageLayout } from "../../../components/PageLayout/PageLayout";
import { FormWrapper } from "../../../containers/Forms/FormWrapper/FormWrapper";
import { SubHeader } from "../../../components/SubHeader/SubHeader";
import * as ITEPages from "./Pages";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetVTCommonInfoFromCRM } from "../../../services/Forms/useGetVTCommonInfoFromCRM";
import { useGetITEWallsFromCRM } from "../../../services/Forms/ITE/useGetITEWallsFromCRM";
import { useGetITEExtraChargesFromCRM } from "../../../services/Forms/ITE/useGetITEExtraChargesFromCRM";
import { useGetFormAnswerFromLocalStorage } from "../../../services/Forms/useGetFormAnswerFromLocalStorage";

// Data import
import { ITEFormData } from "../../../data/forms/ITEFormData";
import { Ranaglyphe } from "../../../containers/Ranaglyphe/Ranaglyphe";

export const ITE = () => {
  const singleVisitId = useParams().visitId;
  const formName = ITEFormData.formName;
  const formsMutableData = useAppSelector(
    (state) => state.formsMutableData[formName]
  );
  const currentScreenName = "visiteTechniqueFormPages"; // In this form there is no extraCharge screen

  const {
    getVTCommonInfoFromCRM,
    isLoading: isGetVTCommonInfoLoading,
    errorMessage: getVTCommonInfoErrorMessage,
  } = useGetVTCommonInfoFromCRM();
  const { getITEWallsFromCRM } = useGetITEWallsFromCRM();
  const { getITEExtraChargesFromCRM } = useGetITEExtraChargesFromCRM();

  const { visiteTechniqueInfo, userAuthInfo } = useAppSelector(
    (state) => state
  );

  const [isRanaglypheClicked, setIsRanaglypheClicked] =
    useState<boolean>(false);

  // Get saved form answer from local storage if there are any
  const isVisiteTechniqueInLocalStorage = useGetFormAnswerFromLocalStorage({
    formName,
    singleVisitId,
  });

  const getVTInformationFromCRM = async () => {
    if (singleVisitId) {
      getVTCommonInfoFromCRM(userAuthInfo.userToken, formName, singleVisitId);
      await getITEWallsFromCRM({
        userToken: userAuthInfo.userToken,
        visiteTechniqueId: singleVisitId,
      });

      // We need ITE walls information before getting ITE extra charges
      getITEExtraChargesFromCRM({
        userToken: userAuthInfo.userToken,
        visiteTechniqueId: singleVisitId,
      });
    }
  };

  useEffect(() => {
    // At the opening of the form we download data from CRM only if there is nothing saved in the local storage
    if (singleVisitId && isVisiteTechniqueInLocalStorage === false) {
      getVTInformationFromCRM();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisiteTechniqueInLocalStorage]);

  const PageToDisplay =
    ITEPages[
      ITEFormData.formScreens[currentScreenName].pages[
        formsMutableData.currentPageIndex[currentScreenName]
      ]
    ];

  return (
    <div>
      <div>
        <SubHeader
          clientName={
            visiteTechniqueInfo.customerFirstName +
            " " +
            visiteTechniqueInfo.customerLastName
          }
          productType={visiteTechniqueInfo.products}
          commercialFullName={visiteTechniqueInfo.commercialFullName}
          commercialMobilePhone={visiteTechniqueInfo.commercialMobilePhone}
          urlButtonBack={`/visite-technique/${singleVisitId}`}
          commercialEmail={visiteTechniqueInfo.commercialEmail}
          formName="ITE"
        />
      </div>
      {isRanaglypheClicked ? (
        <Ranaglyphe
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
          visiteTechniqueInfo={visiteTechniqueInfo}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
        />
      ) : (
        <FormWrapper
          visiteTechniqueInfo={visiteTechniqueInfo}
          formName={formName}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
          formPagesLength={
            ITEFormData.formScreens[currentScreenName].pages.length
          }
          visiteTechniqueId={singleVisitId}
          formScreenSteps={ITEFormData.formScreens[currentScreenName].steps}
          currentScreenName={currentScreenName}
          numberOfScreens={ITEFormData.formScreensName.length}
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
        >
          <PageLayout>
            <div>
              <PageToDisplay
                ITEFormMutableData={formsMutableData}
                visitId={singleVisitId}
              />
            </div>
          </PageLayout>
        </FormWrapper>
      )}
    </div>
  );
};
