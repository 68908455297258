import { ICRMExtraCharge } from "../../../interfaces/ExtraChargesInterfaces";
import { valueToLabelDictionnary } from "../valueToLabelDictionnary";

interface ITypeDeTravauxDictionnary {
  [key: number]: string;
}
interface ITypeDeTerrain {
  [key: number]: string;
}

export const formatGlobalDescriptionExtraCharges = (
  singleExtraCharges: ICRMExtraCharge
): string | undefined => {
  const typeDeTravauxDictionnary: ITypeDeTravauxDictionnary = {
    629950000: "Tranchée",
    629950001: "Options tranchée",
    629950002: "Bacs à lester",
    629950003: "Carrotage",
    629950004: "Renforcement charpente",
    629950005: "Autres",
    629950006: "Accès",
    629950007: "Déport du ballon",
    629950008: "Pompe de relevage",
    629950009: "Dalle de béton",
    629950010: "Désembouage",
    629950011: "Liaison hydraulique",
    629950012: "Liaison frigorifique",
  };

  const typeDeTerrainDictonnary: ITypeDeTerrain = {
    629950002: "Terre",
    629950001: "Remblais",
    629950000: "Béton/Goudron",
  };

  let extraChargeStringDescription = "";

  if (singleExtraCharges?.typedeTravaux === 629950000) {
    extraChargeStringDescription = `${
      singleExtraCharges?.longueurTranche
    } mètres de ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } dans ${
      singleExtraCharges.typedeTerrain &&
      typeDeTerrainDictonnary[singleExtraCharges.typedeTerrain]
    } pour ${singleExtraCharges?.amount}; `;
  }

  if (
    singleExtraCharges?.typedeTravaux === 629950001 &&
    singleExtraCharges.typeOutil
  ) {
    extraChargeStringDescription = `Une ${valueToLabelDictionnary(
      "typeDOutilPourTranchee",
      singleExtraCharges.typeOutil
    )} pour ${singleExtraCharges?.amount}; `;
  }

  if (singleExtraCharges?.typedeTravaux === 629950004) {
    extraChargeStringDescription = `un renforcement de charpente peut être necessaire; `;
  }

  if (
    singleExtraCharges?.typedeTravaux === 629950002 ||
    singleExtraCharges?.typedeTravaux === 629950003
  ) {
    extraChargeStringDescription = `${singleExtraCharges?.nombre} ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } pour ${singleExtraCharges?.amount}; `;
  }

  if (singleExtraCharges?.typedeTravaux === 629950005) {
    //vase d'expansion/Pompe de relevage
    extraChargeStringDescription = `Autres frais supplémentaires pour ${singleExtraCharges.amount} ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950006) {
    extraChargeStringDescription =
      singleExtraCharges.nacelleAPrevoir && singleExtraCharges.echelleaPrevoir
        ? `Une échelle et une nacelle pour un total de ${singleExtraCharges.amount}; `
        : singleExtraCharges.echelleaPrevoir
        ? `Une échelle à prévoir pour un total de ${singleExtraCharges.amount}; `
        : singleExtraCharges.nacelleAPrevoir
        ? `Une nacelle à prévoir pour un total de ${singleExtraCharges.amount}; `
        : "";
  }

  if (singleExtraCharges?.typedeTravaux === 629950007) {
    //Déport CET
    extraChargeStringDescription = `Un ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } est à prévoir sur ${singleExtraCharges.nombre} mètres pour ${
      singleExtraCharges.amount
    } ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950008) {
    //vase d'expansion/Pompe de relevage
    extraChargeStringDescription = `Une ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } pour ${singleExtraCharges.amount} ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950009) {
    //Dalle de béton
    extraChargeStringDescription = `Une ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } pour ${singleExtraCharges.amount} ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950010) {
    // Désembouage
    extraChargeStringDescription = `Un ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } pour ${singleExtraCharges.amount} ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950011) {
    // Isolant pour la liaison hydraulique
    extraChargeStringDescription = `Une ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } pour ${singleExtraCharges.amount} ;`;
  }

  if (singleExtraCharges?.typedeTravaux === 629950012) {
    // Ajout de gaz
    extraChargeStringDescription = `Une ${
      typeDeTravauxDictionnary[singleExtraCharges.typedeTravaux]
    } (ajout de gaz) pour ${singleExtraCharges.amount} ;`;
  }

  return extraChargeStringDescription;
};
