import "./CheckboxContainer.scss";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";
import { Checkbox } from "@web/shared/dist/components/DesignSystem/Checkboxes/Checkbox/Checkbox";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

// Local interface declaration
interface Props {
  title: string | JSX.Element;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onCheckboxClick?: (value: boolean) => void;
  onUploadPicture?: (pictureUrl: string) => void;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const CheckboxContainer = ({
  title,
  formName,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  onCheckboxClick,
  onUploadPicture,
  wallIndex,
  pointSingulierName,
}: Props) => {
  return (
    <div className={`CheckboxContainer`}>
      <section
        className={`CheckboxContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <Checkbox
          isChecked={userAnswer?.value === "true" ? true : false}
          onClick={() =>
            onCheckboxClick &&
            onCheckboxClick(userAnswer?.value === "true" ? false : true)
          }
        />
        <p className={"CheckboxContainer__Title"}>{title}</p>
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
