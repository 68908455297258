import "./mesVisites.scss";
import { useState } from "react";

// Components import
import { ArrayHeader } from "../../containers/MesVisites/ArrayHeader/ArrayHeader";
import { Layout } from "../../components/Layout/Layout";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import arrow from "../../assets/images/white_simple_arrow.svg";

// Services import
import { IVisiteTechniqueList } from "../../interfaces/generalInterfaces";
import { useAppSelector } from "../../redux/store/hook";
import { mesVisitesSortAndFilter } from "../../services/mesVisitesSortAndFilter";

// Local interfaces declaration
interface Props {
  isRequestVisitesTechniquesListLoading: boolean;
}

export const MesVisites = ({
  isRequestVisitesTechniquesListLoading,
}: Props) => {
  const visitesTechniquesList = useAppSelector(
    (state) => state.visitesTechniquesList
  );
  const [clickedColumnName, setClickedColumnName] =
    useState<keyof IVisiteTechniqueList>("dateVisite");
  const [sortType, setSortType] = useState<
    "ascendant" | "descendant" | "default"
  >("descendant");
  const [buttonsManager, setButtonsManager] = useState<boolean[]>([
    false,
    true,
    false,
    false,
  ]);

  const toggleButtonManager = (toggledButtonIndex: number) => {
    setButtonsManager((prevState) => {
      if (!buttonsManager[toggledButtonIndex]) {
        const newState = prevState.map(() => false);
        newState[toggledButtonIndex] = !prevState[toggledButtonIndex];
        return newState;
      }
      return prevState;
    });
  };

  return (
    <div className="mesVisites">
      <Layout>
        <div className="mesVisites__Header">
          <h3>Mes visites</h3>

          <div className="mesVisites__NavigationButtonsContainer">
            <div
              className={`mesVisites__ShowVisitsButton ${
                buttonsManager[0] && "mesVisites__ShowVisitsButton__Active"
              }`}
              onClick={() => toggleButtonManager(0)}
            >
              En cours
              <img src={arrow} alt="" />
            </div>
            <div
              className={`mesVisites__ShowVisitsButton ${
                buttonsManager[1] && "mesVisites__ShowVisitsButton__Active"
              }`}
              onClick={() => toggleButtonManager(1)}
            >
              A venir
              <img src={arrow} alt="" />
            </div>
            <div
              className={`mesVisites__ShowVisitsButton ${
                buttonsManager[2] && "mesVisites__ShowVisitsButton__Active"
              }`}
              onClick={() => toggleButtonManager(2)}
            >
              Réalisées
              <img src={arrow} alt="" />
            </div>
            <div
              className={`mesVisites__ShowVisitsButton ${
                buttonsManager[3] && "mesVisites__ShowVisitsButton__Active"
              }`}
              onClick={() => toggleButtonManager(3)}
            >
              Toutes
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>
        <ArrayHeader
          onClickOnColumnName={setClickedColumnName}
          setSortType={setSortType}
          sortType={sortType}
        />
        <div className="mesVisites__Container">
          {isRequestVisitesTechniquesListLoading ? (
            <LoadingComponent />
          ) : visitesTechniquesList.length > 0 ? (
            mesVisitesSortAndFilter(
              visitesTechniquesList,
              buttonsManager,
              clickedColumnName,
              sortType
            )
          ) : (
            <p className="mesVisites__NoVisit">
              Pas de visite technique planifiée.
            </p>
          )}
        </div>
      </Layout>
    </div>
  );
};
