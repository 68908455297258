// complexRawBase64 : create complex base64 string. Trasnform the base64 into a string that will be accepted by backend
// Parameters
// ----------
// file: the file to upload
//
// ----------
// Returns the string

export const complexRawBase64 = (
  file: string | ArrayBuffer,
  fileName: string
): string => {
  const complexBase64 = file.toString().concat(":", fileName);
  return complexBase64;
};
