import "./visitDetailsProductsList.scss";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { VisitDetailsProductCard } from "../VisitDetailsProductCard/VisitDetailsProductCard";

// Interfaces import
import { formNames } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  visiteTechniqueId: string;
  formName: formNames;
}

export const VisitDetailsProductsList = ({
  visiteTechniqueId,
  formName,
}: Props) => {
  return (
    <div className="visitDetailsProductsList">
      <Layout>
        <div className="visitDetailsProductsList__Container">
          <VisitDetailsProductCard
            visiteTechniqueId={visiteTechniqueId}
            formName={formName}
          />
        </div>
      </Layout>
    </div>
  );
};
