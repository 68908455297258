// Libraries import
import SVG from "react-inlinesvg";
import { useState } from "react";

// Components import
import { BooleanChoiceBtnContainer } from "../FormStepsWithSharedComponents/BooleanChoiceBtnContainer/BooleanChoiceBtnContainer";
import { InputContainer } from "../FormStepsWithSharedComponents/InputContainer/InputContainer";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import { TooltipInfoForm } from "../../../components/TooltipInfoForm/TooltipInfoForm";
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { CTACopyPaste } from "@web/shared/dist/components/DesignSystem/CTACopyPaste/CTACopyPaste";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
// Services import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setITEOuvrantQuestionAction,
  deleteITEOuvrantAction,
  duplicateITEOuvrantAction,
  createITEPointSingulierAction,
  deleteITEPointSingulierAction,
} from "../../../redux/appActions";

// Interfaces import
import { IITEFormData } from "../../../interfaces/generalInterfaces";
import { KeyOfIITEOuvrant } from "../../../interfaces/ITEFormInterface";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  murId: string | null;
  windowId: string | null;
  formName?: "ITE";
  visitId?: string;
  windowIndex: number;
  wallIndex: number;
}

export const ITEWindow = ({
  ITEFormMutableData,
  murId,
  windowId,
  formName = "ITE",
  visitId,
  windowIndex,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const window = ITEFormMutableData?.windows?.find(
    (window) =>
      window.murId?.value === murId && window.ouvrantId?.value === windowId
  );

  const appuiFenetreExistant = ITEFormMutableData?.pointsSinguliers?.find(
    (pointSingulier) =>
      pointSingulier.murId?.value === murId &&
      pointSingulier.ouvrantId?.value === windowId &&
      pointSingulier.pointSingulierName?.value === "appuiFenetreExistant"
  );

  const appuiFenetreProfond = ITEFormMutableData?.pointsSinguliers?.find(
    (pointSingulier) =>
      pointSingulier.murId?.value === murId &&
      pointSingulier.ouvrantId?.value === windowId &&
      pointSingulier.pointSingulierName?.value === "appuiFenetreProfond"
  );

  const onClickOnWindowQuestion = ({
    questionName,
    value,
    windowId,
    murId,
  }: {
    questionName: KeyOfIITEOuvrant;
    value: string;
    windowId: string | null;
    murId: string | null;
  }) => {
    dispatch(
      setITEOuvrantQuestionAction({
        value,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId ?? null,
        ouvrantId: windowId,
        murId,
        ouvrant: "windows",
      })
    );
  };

  const onClickOnDeleteWindow = ({
    murId,
    windowId,
  }: {
    murId: string | null;
    windowId: string | null;
  }) => {
    setIsDeleteModaleOpen(false);
    dispatch(
      deleteITEOuvrantAction({
        visitId: visitId ?? null,
        ouvrantId: windowId,
        murId,
        ouvrant: "windows",
      })
    );
  };

  const onClickDuplicateWindow = ({
    murId,
    windowId,
  }: {
    murId: string | null;
    windowId: string | null;
  }) => {
    dispatch(
      duplicateITEOuvrantAction({
        visitId: visitId ?? null,
        murId,
        ouvrantId: windowId,
        ouvrant: "windows",
      })
    );
    if (appuiFenetreExistant) {
      dispatch(
        createITEPointSingulierAction({
          visitId: visitId ?? null,
          wallId: murId,
          windowId:
            ITEFormMutableData?.windows?.[
              ITEFormMutableData?.windows?.length - 1
            ].ouvrantId?.value,
          pointSingulierName: "appuiFenetreExistant",
        })
      );
    }
    if (appuiFenetreProfond) {
      dispatch(
        createITEPointSingulierAction({
          visitId: visitId ?? null,
          wallId: murId,
          windowId:
            ITEFormMutableData?.windows?.[
              ITEFormMutableData?.windows?.length - 1
            ].ouvrantId?.value,
          pointSingulierName: "appuiFenetreProfond",
        })
      );
    }
  };

  const onClickOnCreateAppuiFenetre = ({
    wallId,
    windowId,
    pointSingulierName,
    visitId,
    isQuestionAnswered,
  }: {
    wallId: string | null;
    windowId: string | null;
    pointSingulierName: string;
    visitId?: string;
    isQuestionAnswered?: boolean;
  }) => {
    !isQuestionAnswered &&
      dispatch(
        createITEPointSingulierAction({
          visitId: visitId ?? null,
          wallId,
          windowId,
          pointSingulierName,
        })
      );
  };

  const onClickOnDeleteAppuiFenetre = ({
    visitId,
    pointSingulierId,
    wallId,
    windowId,
  }: {
    visitId?: string;
    pointSingulierId?: string | null;
    wallId: string | null;
    windowId?: string | null;
  }) => {
    dispatch(
      deleteITEPointSingulierAction({
        visitId: visitId ?? null,
        pointSingulierId,
        wallId,
        windowId,
      })
    );
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer la fenêtre ${
          windowIndex + 1
        } ?`}
      >
        <div className="Modale_Buttons">
          <CTAButton
            name="Non"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          />
          <CTAButton
            name="Oui"
            family="vitee"
            onClick={() =>
              onClickOnDeleteWindow({ murId: murId || null, windowId })
            }
          />
        </div>
      </Modale>
      <div className="formPagesITE__WindowContainer">
        <div className="formPagesITE__SubTitle">
          <div className="formPagesITE__DuplicateContainer">
            <H2 title={`Fenêtre n°${windowIndex + 1}`} />
            <CTACopyPaste
              onClick={() =>
                onClickDuplicateWindow({
                  murId: window?.murId?.value || null,
                  windowId: window?.ouvrantId?.value || null,
                })
              }
              name="Dupliquer"
            />
          </div>
          <div
            className="formPagesITE__Trash"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          >
            <SVG src={Trash2} />
          </div>
        </div>

        <div className="formPagesITE__Question">
          <InputContainer
            title={`Hauteur fenêtre ${windowIndex + 1} :`}
            questionName="windowHeigh"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            iconType="meters"
            placeholder="XXXX"
            type="number"
            userAnswer={window?.windowHeigh}
            onChange={(value) => {
              onClickOnWindowQuestion({
                questionName: "windowHeigh",
                murId: window?.murId?.value || null,
                windowId: window?.ouvrantId?.value || null,
                value: value,
              });
            }}
          />
        </div>
        <div className="formPagesITE__Question">
          <InputContainer
            title={`Largeur fenêtre ${windowIndex + 1} :`}
            questionName="windowWidth"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            iconType="meters"
            placeholder="XXXX"
            type="number"
            userAnswer={window?.windowWidth}
            onChange={(value) => {
              onClickOnWindowQuestion({
                questionName: "windowWidth",
                murId: window?.murId?.value || null,
                windowId: window?.ouvrantId?.value || null,
                value: value,
              });
            }}
          />
        </div>
        <div className="formPagesITE__Question">
          <BooleanChoiceBtnContainer
            title={<>Appui&nbsp;fenêtre&nbsp;existant&nbsp;:</>}
            size={"small"}
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            userAnswer={
              appuiFenetreExistant ? { value: "true" } : { value: "false" }
            }
            onCardClick={(value) => {
              value
                ? onClickOnCreateAppuiFenetre({
                    pointSingulierName: "appuiFenetreExistant",
                    wallId: murId,
                    windowId,
                    visitId,
                    isQuestionAnswered: !!appuiFenetreExistant,
                  })
                : onClickOnDeleteAppuiFenetre({
                    visitId,
                    wallId: murId,
                    windowId: windowId,
                    pointSingulierId:
                      appuiFenetreExistant?.pointSingulierId?.value,
                  });
            }}
          />
        </div>

        {appuiFenetreExistant && (
          <div className="formPagesITE__Question">
            <BooleanChoiceBtnContainer
              title={
                <>
                  Appui&nbsp;fenêtre&nbsp;profond&nbsp;(&gt;&nbsp;60cm)&nbsp;?
                </>
              }
              formName={formName}
              visitId={visitId}
              size={"small"}
              isPictureNeeded={false}
              userAnswer={
                appuiFenetreProfond ? { value: "true" } : { value: "false" }
              }
              onCardClick={(value) => {
                value
                  ? onClickOnCreateAppuiFenetre({
                      pointSingulierName: "appuiFenetreProfond",
                      wallId: murId,
                      windowId,
                      visitId,
                      isQuestionAnswered:
                        !!appuiFenetreProfond?.pointSingulierName?.value,
                    })
                  : onClickOnDeleteAppuiFenetre({
                      visitId,
                      wallId: murId,
                      windowId: windowId,
                      pointSingulierId:
                        appuiFenetreProfond?.pointSingulierId?.value,
                    });
              }}
            />
          </div>
        )}
        <div className="formPagesITE__ToolTip">
          <TooltipInfoForm
            textContent={
              <>
                {`N’oubliez pas d’indiquer sur les photos, quelle
        fenêtre correspond à la “Fenêtre n°${windowIndex + 1}"`}
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
