import React from "react";
import "./typeOfProductIcon.scss";
import { determineFormNameFromProductType } from "../../../services/Forms/determineFormNameFromProductType";

interface IProps {
  typeOfProduct: number;
}

export const TypeOfProductIcon = ({ typeOfProduct }: IProps) => {
  const determinedName = determineFormNameFromProductType(typeOfProduct);
  const typeOfProductPastille = () => {
    switch (determinedName) {
      case "photovoltaique":
        return "PV";
      case "CET":
        return "CET";
      case "chauffage":
        return "CHA";
      case "ITE":
        return "ISO";
      case "PacAirEau":
        return "PAC";
      case "pompe a chaleur Air/Air":
        return "PAC";
      case "batterie de stockage":
        return "STO";
      default:
        return "PV";
    }
  };

  return (
    <div className={`typeOfProductIcon`}>
      <div
        className={`typeOfProductIcon__IconStyle ${typeOfProductPastille()}`}
      >
        {typeOfProductPastille()}
      </div>
    </div>
  );
};
