import { AnyAction } from "redux";

// Services import
import { formatCRMDoorsBeforeSavingInRedux } from "../../services/Forms/ITE/formatCRMDoorsBeforeSavingInRedux";
import { formatCRMWallBeforeSavingInRedux } from "../../services/Forms/ITE/formatCRMWallBeforeSavingInRedux";
import { formatCRMWindowsBeforeSavingInRedux } from "../../services/Forms/ITE/formatCRMWindowsBeforeSavingInRedux";
import { pointsSinguliersDictionnary } from "../../services/Forms/ITE/pointsSinguliersDictionnary";

// Other imports
import * as actions from "../appActions";

// Interfaces import
import { IAppStates } from "../../interfaces/generalInterfaces";
import {
  IITEWallAll,
  IITEOuvrant,
  ICommentairePointsSinguliersItem,
} from "../../interfaces/ITEFormInterface";

// REDUCER FUNCTIONS USED IN THE ITE FORM
export const setITEWall = (state: IAppStates, action: AnyAction) => {
  if (actions.setITEWallAction.match(action)) {
    //local variable declaration
    const { CRMWalls, visitId } = action.payload;
    const newState = { ...state };
    newState.formsMutableData.ITE.walls = [];
    newState.formsMutableData.ITE.windows = [];
    newState.formsMutableData.ITE.doors = [];
    newState.formsMutableData.ITE.pointsSinguliers = [];
    newState.formsMutableData.ITE.otherPointsSinguliers = [];

    const findKeyByValue = ({
      obj,
      value,
    }: {
      obj: typeof pointsSinguliersDictionnary;
      value: {
        title: string;
        amount: number;
        typedeTravaux: number;
      };
    }) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === value) {
          return key;
        }
      }
      return null;
    };

    for (let wallIndex = 0; wallIndex < CRMWalls.length; wallIndex++) {
      //add formated wall, windows and doors from CRM to redux
      newState.formsMutableData.ITE.walls.push(
        formatCRMWallBeforeSavingInRedux(CRMWalls[wallIndex])
      );
      newState.formsMutableData.ITE.windows.push(
        ...formatCRMWindowsBeforeSavingInRedux(CRMWalls[wallIndex])
      );
      newState.formsMutableData.ITE.doors.push(
        ...formatCRMDoorsBeforeSavingInRedux(CRMWalls[wallIndex])
      );

      // Add pointsSingulier from the comment commentairesPointssinguliers
      const commentairePointssinguliersFormatted = CRMWalls[
        wallIndex
      ].commentairePointssinguliers.replace(/\\/g, "");
      const pointsSinguliersFromCRM: ICommentairePointsSinguliersItem[] =
        JSON.parse(
          commentairePointssinguliersFormatted.substring(
            1,
            commentairePointssinguliersFormatted.length - 1
          )
        );

      // For each extra charges received from CRM we add the ither in pointsSinguliers, otherPointsSinglier or we update appuiFenetre or appuiFenetreProfond
      Array.isArray(pointsSinguliersFromCRM) &&
        pointsSinguliersFromCRM.forEach((extraCharge) => {
          const extraChargeFromDictionnary = Object.values(
            pointsSinguliersDictionnary
          ).find((value) => value.title === extraCharge.nom);

          // If the name of the extra charge is known from the dictionnary it means it is a point singulier
          extraCharge.nom && extraChargeFromDictionnary
            ? newState.formsMutableData.ITE.pointsSinguliers?.push(
                // Case extraCharge is a pointSingulier
                {
                  murId: {
                    value: CRMWalls[wallIndex].murId,
                  },
                  ouvrantId: {
                    value: extraCharge.windowName
                      ? newState.formsMutableData.ITE.windows?.find(
                          (window) =>
                            window.ouvrantName?.value === extraCharge.windowName
                        )?.ouvrantId?.value
                      : null,
                  },
                  pointSingulierName: {
                    value: findKeyByValue({
                      obj: pointsSinguliersDictionnary,
                      value: extraChargeFromDictionnary,
                    }),
                  },
                  pointSingulierQuantity: {
                    value: extraCharge.quantité?.toString() || "0",
                  },
                  isPointSingulierAReposer: {
                    value: extraCharge.reposer === "non" ? "false" : "true",
                  },
                }
              )
            : newState.formsMutableData.ITE.otherPointsSinguliers?.push(
                // Case extraCharge is an otherPointSingulier
                {
                  murId: {
                    value: CRMWalls[wallIndex].murId,
                  },
                  pointSingulierName: {
                    value: extraCharge.nom,
                  },
                  pointSingulierQuantity: {
                    value: extraCharge.quantité?.toString() || "0",
                  },
                  isPointSingulierAReposer: {
                    value: extraCharge.reposer === "non" ? "false" : "true",
                  },
                  pointSingulierAmount: {
                    value: extraCharge.montant?.toString(),
                  },
                }
              );
        });
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const setITEExtraChargesCRMId = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setITEExtraChargesCRMIdAction.match(action)) {
    //local variable declaration
    const { CRMExtraCharges, visitId } = action.payload;
    const newState = { ...state };

    // For each point singulier (= extra charge) we add its extra charge id
    (newState.formsMutableData.ITE?.pointsSinguliers || []).forEach(
      (pointSingulier, index) => {
        pointSingulier.pointSingulierId = {
          value:
            CRMExtraCharges.find(
              (CRMExtraCharge) =>
                CRMExtraCharge.typedeTravaux ===
                pointsSinguliersDictionnary[
                  pointSingulier.pointSingulierName?.value || ""
                ]?.typedeTravaux
            )?.id || `VITEE-pointSingulier${index}`,
        }; // Autres
      }
    );

    (newState.formsMutableData.ITE?.otherPointsSinguliers || []).forEach(
      (pointSingulier, index) => {
        pointSingulier.pointSingulierId = {
          value:
            CRMExtraCharges.find(
              (CRMExtraCharge) =>
                CRMExtraCharge.name === pointSingulier.pointSingulierName?.value
            )?.id || `VITEE-otherPointsSinguliers${index}`,
        }; // Autres
      }
    );

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const setITEWallQuestion = (state: IAppStates, action: AnyAction) => {
  if (actions.setITEWallQuestionAction.match(action)) {
    const { value, formQuestionName, visitId, wallId, pictureUrl } =
      action.payload;
    const newState = { ...state };

    const walls = newState.formsMutableData.ITE.walls || [];
    const currentWallIndex =
      walls.findIndex((wall) => wall.murId?.value === wallId) || 0;
    const currentAnswer =
      state.formsMutableData.ITE.walls?.[currentWallIndex]?.[formQuestionName];
    const currentPicture =
      state.formsMutableData.ITE.walls?.[currentWallIndex]?.[formQuestionName]
        ?.pictures;
    // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
    if (currentAnswer?.value === value) {
      walls[currentWallIndex] = {
        ...walls[currentWallIndex],
        [formQuestionName]: {
          value: null,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    } else {
      walls[currentWallIndex] = {
        ...walls[currentWallIndex],
        [formQuestionName]: {
          ...currentAnswer, // We keep the other potentials keys that already exist
          value: value !== undefined ? value : currentAnswer?.value,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const createITEWall = (state: IAppStates, action: AnyAction) => {
  if (actions.createITEWallAction.match(action)) {
    const { visitId, setIsBlocksOpen } = action.payload;
    const newState = { ...state };
    const newWalls: IITEWallAll[] = [
      ...(state.formsMutableData.ITE.walls || []),
      {
        murId: {
          value:
            "VITEE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now(),
        },
      },
    ];

    newState.formsMutableData.ITE.walls = newWalls;

    // When adding a block (last index) we open it
    setIsBlocksOpen((state) => {
      const newState = [...state];
      newState[newWalls.length - 1] = true;
      return newState;
    });

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const deleteITEWall = (state: IAppStates, action: AnyAction) => {
  if (actions.deleteITEWallAction.match(action)) {
    const { visitId, murId } = action.payload;
    const newState = { ...state };

    const newWalls = state.formsMutableData.ITE.walls?.filter(
      (walls) => walls.murId?.value !== murId
    );
    const newWindows = state.formsMutableData.ITE.windows?.filter(
      (window) => window.murId?.value !== murId
    );
    const newDoors = state.formsMutableData.ITE.doors?.filter(
      (door) => door.murId?.value !== murId
    );

    const newPointsSinguliers =
      state.formsMutableData.ITE.pointsSinguliers?.filter(
        (pointSingulier) => pointSingulier.murId?.value !== murId
      );

    const newOtherPointsSinguliers =
      state.formsMutableData.ITE.otherPointsSinguliers?.filter(
        (pointSingulier) => pointSingulier.murId?.value !== murId
      );
    newState.formsMutableData.ITE.walls = newWalls;
    newState.formsMutableData.ITE.windows = newWindows;
    newState.formsMutableData.ITE.doors = newDoors;
    newState.formsMutableData.ITE.pointsSinguliers = newPointsSinguliers;
    newState.formsMutableData.ITE.otherPointsSinguliers =
      newOtherPointsSinguliers;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const createITEOuvrant = (state: IAppStates, action: AnyAction) => {
  if (actions.createITEOuvrantAction.match(action)) {
    const { visitId, murId, ouvrant } = action.payload;
    const newState = { ...state };
    const ouvrantId =
      "VITEE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now();
    const ouvrantName =
      ouvrant === "windows"
        ? "FENETRE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now()
        : ouvrant === "doors"
        ? "PORTE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now()
        : "AUTRE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now();
    const newWindows: IITEOuvrant[] = [
      ...(state.formsMutableData.ITE[ouvrant] || []),
      {
        murId: {
          value: murId,
        },
        ouvrantId: {
          value: ouvrantId,
        },
        ouvrantName: {
          value: ouvrantName,
        },
      },
    ];
    newState.formsMutableData.ITE[ouvrant] = newWindows;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const createITEOtherPointSingulier = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.createITEOtherPointSingulierAction.match(action)) {
    const { visitId, murId } = action.payload;
    const newState = { ...state };
    const otherPointSingulierId =
      "VITEE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now();

    const newOtherPointsSingulier: IITEOuvrant[] = [
      ...(state.formsMutableData.ITE.otherPointsSinguliers || []),
      {
        murId: {
          value: murId,
        },
        pointSingulierId: {
          value: otherPointSingulierId,
        },
        pointSingulierQuantity: {
          value: "1",
        },
        isPointSingulierAReposer: {
          value: "true",
        },
      },
    ];
    newState.formsMutableData.ITE.otherPointsSinguliers =
      newOtherPointsSingulier;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const duplicateITEOuvrant = (state: IAppStates, action: AnyAction) => {
  if (actions.duplicateITEOuvrantAction.match(action)) {
    const { visitId, ouvrant, ouvrantId, murId } = action.payload;
    const newState = { ...state };
    const ouvrantToDuplicateIndex = state.formsMutableData.ITE?.[
      ouvrant
    ]?.findIndex(
      (ouvrant) =>
        ouvrant.murId?.value === murId && ouvrantId === ouvrant.ouvrantId?.value
    );
    const ouvrantToDuplicate =
      ouvrantToDuplicateIndex !== undefined
        ? state.formsMutableData.ITE?.[ouvrant]?.[ouvrantToDuplicateIndex]
        : null;

    const ouvrantName =
      ouvrant === "windows"
        ? "FENETRE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now()
        : ouvrant === "doors"
        ? "PORTE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now()
        : "AUTRE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now();

    if (ouvrantToDuplicate && ouvrantToDuplicateIndex !== undefined) {
      (newState.formsMutableData.ITE?.[ouvrant] || []).splice(
        ouvrantToDuplicateIndex + 1,
        0,
        {
          ...ouvrantToDuplicate,
          ouvrantId: {
            value:
              "VITEE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now(),
          },
          ouvrantName: {
            value: ouvrantName,
          },
        }
      );
      // When redux state is updated, we save the new form state in local storage
      visitId &&
        localStorage.setItem(
          visitId,
          JSON.stringify(newState.formsMutableData.ITE)
        );
      return newState;
    }

    return state;
  }
  return state;
};

export const deleteITEOuvrant = (state: IAppStates, action: AnyAction) => {
  if (actions.deleteITEOuvrantAction.match(action)) {
    const { visitId, ouvrantId, murId, ouvrant, isDeleteLastElement } =
      action.payload;
    const newState = { ...state };
    if (isDeleteLastElement) {
      newState.formsMutableData.ITE[ouvrant]?.pop();
    } else {
      const newWindows = state.formsMutableData.ITE[ouvrant]?.filter(
        (ouvrant) => {
          return (
            ouvrant.murId?.value !== murId ||
            ouvrant.ouvrantId?.value !== ouvrantId
          );
        }
      );

      newState.formsMutableData.ITE[ouvrant] = newWindows;
    }

    // If we delete a fenêtre we also delete its appuis fenêtre (which are points singuliers)
    const newPointsSinguliers =
      state.formsMutableData.ITE.pointsSinguliers?.filter((pointSingulier) => {
        return (
          pointSingulier.murId?.value !== murId ||
          pointSingulier.ouvrantId?.value !== ouvrantId
        );
      });

    newState.formsMutableData.ITE.pointsSinguliers = newPointsSinguliers;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const deleteITEOtherPointSingulier = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.deleteITEOtherPointSingulierAction.match(action)) {
    const { visitId, otherPointSingulierId, murId, isDeleteLastElement } =
      action.payload;
    const newState = { ...state };
    if (isDeleteLastElement) {
      newState.formsMutableData.ITE.otherPointsSinguliers?.pop();
    } else {
      const newOtherPointsSinguliers =
        state.formsMutableData.ITE.otherPointsSinguliers?.filter(
          (otherPointSingulier) => {
            return (
              otherPointSingulier.murId?.value !== murId ||
              otherPointSingulier.pointSingulierId?.value !==
                otherPointSingulierId
            );
          }
        );

      newState.formsMutableData.ITE.otherPointsSinguliers =
        newOtherPointsSinguliers;
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const setITEOuvrantQuestion = (state: IAppStates, action: AnyAction) => {
  if (actions.setITEOuvrantQuestionAction.match(action)) {
    const {
      value,
      formQuestionName,
      visitId,
      murId,
      ouvrantId,
      ouvrant,
      pictureUrl,
    } = action.payload;
    const newState = { ...state };
    const ouvrants = state.formsMutableData.ITE[ouvrant] || [];
    const currentouvrantIndex = state.formsMutableData.ITE[ouvrant]?.findIndex(
      (ouvrant) =>
        ouvrant.murId?.value === murId && ouvrant.ouvrantId?.value === ouvrantId
    );

    if (currentouvrantIndex === undefined) {
      return state;
    }
    const currentWindow = ouvrants[currentouvrantIndex];
    const currentAnswer = currentWindow?.[formQuestionName];
    const currentPicture = currentAnswer?.pictures;

    // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
    if (currentAnswer?.value === value) {
      ouvrants[currentouvrantIndex] = {
        ...ouvrants[currentouvrantIndex],
        [formQuestionName]: {
          value: null,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    } else {
      ouvrants[currentouvrantIndex] = {
        ...ouvrants[currentouvrantIndex],
        [formQuestionName]: {
          ...currentAnswer, // We keep the other potentials keys that already exist
          value: value !== undefined ? value : currentAnswer?.value,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const setITEOtherPointSingulier = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setITEOtherPointSingulierAction.match(action)) {
    const {
      value,
      formQuestionName,
      visitId,
      murId,
      otherPointSingulierId,
      pictureUrl,
    } = action.payload;
    const newState = { ...state };
    const otherPointsSinguliers =
      state.formsMutableData.ITE.otherPointsSinguliers || [];
    const currentOtherPointSingulierIndex =
      state.formsMutableData.ITE?.otherPointsSinguliers?.findIndex(
        (otherPOintSingulier) =>
          otherPOintSingulier.murId?.value === murId &&
          otherPOintSingulier.pointSingulierId?.value === otherPointSingulierId
      );

    if (currentOtherPointSingulierIndex === undefined) {
      return state;
    }
    const currentOtherPointSingulier =
      otherPointsSinguliers[currentOtherPointSingulierIndex];
    const currentAnswer = currentOtherPointSingulier?.[formQuestionName];
    const currentPicture = currentAnswer?.pictures;

    // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
    if (currentAnswer?.value === value) {
      otherPointsSinguliers[currentOtherPointSingulierIndex] = {
        ...otherPointsSinguliers[currentOtherPointSingulierIndex],
        [formQuestionName]: {
          value: null,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    } else {
      otherPointsSinguliers[currentOtherPointSingulierIndex] = {
        ...otherPointsSinguliers[currentOtherPointSingulierIndex],
        [formQuestionName]: {
          ...currentAnswer, // We keep the other potentials keys that already exist
          value: value !== undefined ? value : currentAnswer?.value,
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        },
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const setITEPointSingulier = (state: IAppStates, action: AnyAction) => {
  if (actions.setITEPointSingulierAction.match(action)) {
    const {
      value,
      formQuestionName,
      visitId,
      pictureUrl,
      wallId,
      pointSingulierName,
    } = action.payload;
    const newState = { ...state };
    const pointsSinguliers = state.formsMutableData.ITE.pointsSinguliers || [];
    const currentPointSingulierIndex =
      state.formsMutableData.ITE.pointsSinguliers?.findIndex(
        (pointSingulier) =>
          pointSingulier.murId?.value === wallId &&
          pointSingulier.pointSingulierName?.value === pointSingulierName
      );

    if (currentPointSingulierIndex !== undefined) {
      const currentAnswer =
        state.formsMutableData.ITE.pointsSinguliers?.[
          currentPointSingulierIndex
        ]?.[formQuestionName];
      const currentPicture =
        state.formsMutableData.ITE.pointsSinguliers?.[
          currentPointSingulierIndex
        ]?.[formQuestionName]?.pictures;
      // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
      if (currentAnswer?.value === value) {
        pointsSinguliers[currentPointSingulierIndex || 0] = {
          ...pointsSinguliers[currentPointSingulierIndex || 0],
          [formQuestionName]: {
            value: null,
            pictures:
              currentPicture && currentPicture?.length > 0
                ? [...currentPicture, pictureUrl]
                : pictureUrl
                ? [pictureUrl]
                : currentPicture,
          },
        };
      } else {
        pointsSinguliers[currentPointSingulierIndex || 0] = {
          ...pointsSinguliers[currentPointSingulierIndex || 0],
          [formQuestionName]: {
            ...currentAnswer, // We keep the other potentials keys that already exist
            value: value !== undefined ? value : currentAnswer?.value,
            pictures:
              currentPicture && currentPicture?.length > 0
                ? [...currentPicture, pictureUrl]
                : pictureUrl
                ? [pictureUrl]
                : currentPicture,
          },
        };
      }
      // When redux state is updated, we save the new form state in local storage
      visitId &&
        localStorage.setItem(
          visitId,
          JSON.stringify(newState.formsMutableData.ITE)
        );
      return newState;
    }
  }
  return state;
};

export const createITEPointSingulier = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.createITEPointSingulierAction.match(action)) {
    const { visitId, wallId, windowId, pointSingulierName } = action.payload;
    const newState = { ...state };
    const pointSingulierId =
      "VITEE-" + Math.floor(Math.random() * 1000000) + "-" + Date.now();
    const newOuvrants: IITEOuvrant[] = [
      ...(state.formsMutableData.ITE.pointsSinguliers || []),
      {
        murId: {
          value: wallId,
        },
        ouvrantId: {
          value: windowId || null,
        },
        pointSingulierName: {
          value: pointSingulierName || "",
        },
        pointSingulierQuantity: {
          value: "1",
        },
        isPointSingulierAReposer: {
          value: "true",
        },
        pointSingulierId: {
          value: pointSingulierId,
        },
      },
    ];
    newState.formsMutableData.ITE.pointsSinguliers = newOuvrants;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const deleteITEPointSingulier = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.deleteITEPointSingulierAction.match(action)) {
    const { visitId, wallId, windowId, pointSingulierId } = action.payload;
    const newState = { ...state };
    const newPointsSinguliers = windowId
      ? state.formsMutableData.ITE.pointsSinguliers?.filter(
          (pointSingulier) => {
            return (
              pointSingulier.murId?.value?.toString() !== wallId ||
              pointSingulier.ouvrantId?.value?.toString() !== windowId ||
              pointSingulier.pointSingulierId?.value?.toString() !==
                pointSingulierId
            );
          }
        )
      : state.formsMutableData.ITE.pointsSinguliers?.filter(
          (pointSingulier) => {
            return (
              pointSingulier.murId?.value?.toString() !== wallId ||
              pointSingulier.pointSingulierId?.value?.toString() !==
                pointSingulierId
            );
          }
        );

    newState.formsMutableData.ITE.pointsSinguliers = newPointsSinguliers;

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};

export const updateReduxAfterSaveMurWithOuvrantItems = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.updateReduxAfterSaveMurWithOuvrantItemsAction.match(action)) {
    const { visitId, saveMurWithOuvrantItemsResponse } = action.payload;
    const newState = { ...state };

    // Update Walls ids

    for (
      let wallIndex = 0;
      wallIndex < saveMurWithOuvrantItemsResponse.length;
      wallIndex++
    ) {
      if (newState.formsMutableData.ITE.walls?.[wallIndex]) {
        const currentWallFromResponse =
          saveMurWithOuvrantItemsResponse[wallIndex];
        const currentMurIdAnswer =
          state.formsMutableData.ITE.walls?.[wallIndex].murId || [];
        newState.formsMutableData.ITE.walls[wallIndex].murId = {
          ...currentMurIdAnswer,
          value: currentWallFromResponse.murId,
        };

        for (
          let ouvrantIndex = 0;
          ouvrantIndex < currentWallFromResponse.ouvrantItem.length;
          ouvrantIndex++
        ) {
          // Update windows ids
          const windowToUpdateIndex =
            newState.formsMutableData.ITE.windows?.findIndex(
              (window) =>
                window.ouvrantName?.value ===
                currentWallFromResponse.ouvrantItem[ouvrantIndex].name
            ) ?? -1;
          if (
            windowToUpdateIndex > -1 &&
            newState.formsMutableData.ITE.windows?.[windowToUpdateIndex]
          ) {
            const currentOuvrantIdAnswer =
              state.formsMutableData.ITE.windows?.[windowToUpdateIndex]
                .ouvrantId || [];
            newState.formsMutableData.ITE.windows[
              windowToUpdateIndex
            ].ouvrantId = {
              ...currentOuvrantIdAnswer,
              value:
                currentWallFromResponse.ouvrantItem[ouvrantIndex].ouvrantId,
            };
            newState.formsMutableData.ITE.windows[windowToUpdateIndex].murId = {
              value: currentWallFromResponse.murId,
            };
          }
          // Update doors ids
          const doorToUpdateIndex =
            newState.formsMutableData.ITE.doors?.findIndex(
              (door) =>
                door.ouvrantName?.value ===
                currentWallFromResponse.ouvrantItem[ouvrantIndex].name
            ) ?? -1;
          if (
            doorToUpdateIndex > -1 &&
            newState.formsMutableData.ITE.doors?.[doorToUpdateIndex]
          ) {
            const currentAnswer =
              state.formsMutableData.ITE.doors?.[doorToUpdateIndex].ouvrantId ||
              [];
            newState.formsMutableData.ITE.doors[doorToUpdateIndex].ouvrantId = {
              ...currentAnswer,
              value:
                currentWallFromResponse.ouvrantItem[ouvrantIndex].ouvrantId,
            };
            newState.formsMutableData.ITE.doors[doorToUpdateIndex].murId = {
              value: currentWallFromResponse.murId,
            };
          }
        }
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData.ITE)
      );
    return newState;
  }
  return state;
};
