import "./buttonDisconnect.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ButtonDisconnect: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      className="button-disconnect"
      data-testid="button-disconnect"
      onClick={onClick}
    >
      Déconnexion
    </button>
  );
};
