// Interfaces import
import { ICRMWall } from "../../../interfaces/ITEFormInterface";

export const formatCRMWallBeforeSavingInRedux = (CRMWall: ICRMWall) => {
  return {
    murId: {
      value: CRMWall.murId || null,
    },
    actionssurlemur: {
      value: String(CRMWall.actionssurlemur),
    },
    surfaceisoler: {
      value: String(CRMWall.surfaceisoler),
    },
    metreslineairescomptabiliserprofildepart: {
      value: String(CRMWall.metreslineairescomptabiliserprofildepart),
    },
    metreslineairesprofilcouronnement: {
      value: String(CRMWall.metreslineairesprofilcouronnement),
    },
    presenceduncableahautedensiteEDF: {
      value: String(CRMWall.presenceduncableahautedensiteEDF),
    },
    commentairesurlemur: {
      value: CRMWall.commentairesurlemur,
    },
  };
};
