import "./resetPasswordWindow.scss";

// Libraries import
import React from "react";

// Components import
import { ResetPasswordWelcome } from "../ResetPasswordWelcome/ResetPasswordWelcome";
import { ResetPasswordForm } from "../ResetPasswordForm/ResetPasswordForm";

export const ResetPasswordWindow: React.FC = () => {
  return (
    <div className="reset-password-window">
      <ResetPasswordWelcome />
      <div className="simple-vertical-trait-container">
        <div className="simple-vertical-trait"></div>
      </div>
      <ResetPasswordForm />
    </div>
  );
};
