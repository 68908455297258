// Libraries imports
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components imports
import { Header } from "../containers/Header/Header";
import { MesVisites } from "../pages/MesVisites/MesVisites";
import { Login } from "../pages/Login/Login";
import { Photovoltaique } from "../pages/forms/photovoltaique/photovoltaique";
import { CET } from "../pages/forms/CET/CET";
import { ITE } from "../pages/forms/ITE/ITE";
import { PacAirEau } from "../pages/forms/PacAirEau/PacAirEau";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { PickPositionMap } from "../pages/PickPositionMap/PickPositionMap";
import { LocalStorageViewer } from "../pages/LocalStorageViewer/LocalStorageViewer";

// Services imports
import { useGetUserAuthInfo } from "../services/Login/useGetUserAuthInfo";
import { useGetUserProfileInfo } from "../services/Login/useGetUserProfileInfo";
import { useRequestVisitesTechniquesList } from "../services/VisitesTechniquesList/useRequestVisitesTechniquesList";

//Other imports
import { useAppSelector } from "../redux/store/hook";
import { VisitDetails } from "../pages/VisitDetails/VisitDetails";

export const Navigation: React.FC = () => {
  const { getUserAuthInfo } = useGetUserAuthInfo();
  const { getUserProfileInfo, isLoading: isGetProfileInfoIsLoading } =
    useGetUserProfileInfo();
  const {
    requestVisitesTechniquesList,
    isLoading: isRequestVisitesTechniquesListLoading,
  } = useRequestVisitesTechniquesList();
  const { userAuthInfo, userProfileInfo } = useAppSelector((states) => states);

  // At the opening of the portail
  useEffect(() => {
    // Get user authentication information from local storage if they exist.
    getUserAuthInfo();
    // Adding getUserAuthInfo as a dependency creates an infinit iteration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If token exist and we don't already have user profile info in Redux store-> get user profile information from session storage or from backend.
    if (userAuthInfo.userToken && userAuthInfo.userId) {
      getUserProfileInfo(userAuthInfo.userToken, userAuthInfo.userId);
      requestVisitesTechniquesList(userAuthInfo.userToken, userAuthInfo.userId);
    }

    // Adding above functoins as a dependency creates an infinit iteration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthInfo.userToken]);

  return !userAuthInfo?.userToken || !userProfileInfo.id ? (
    <Router>
      <Routes>
        <Route path="/mot-de-passe-oublie" element={<ResetPassword />} />
        <Route
          path="/*"
          element={
            <Login isGetProfileInfoIsLoading={isGetProfileInfoIsLoading} />
          }
        />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Header />
      {/* You don't need to use an exact prop on <Route path="/"> anymore. 
      This is because all paths match exactly by default. 
      If you want to match more of the URL because you have child routes use a trailing * as in <Route path="users/*">. */}
      <Routes>
        <Route path="/visite-technique/:visitId" element={<VisitDetails />} />
        <Route
          path="/visite-technique/:visitId/photovoltaique"
          element={<Photovoltaique />}
        />
        <Route path="/visite-technique/:visitId/CET" element={<CET />} />
        <Route
          path="/visite-technique/:visitId/PacAirEau"
          element={<PacAirEau />}
        />
        <Route path="/visite-technique/:visitId/ITE" element={<ITE />} />
        <Route
          path="/visite-technique/:visitId/map"
          element={<PickPositionMap />}
        />
        <Route path="/localstorage" element={<LocalStorageViewer />} />
        <Route
          path="/*"
          element={
            <MesVisites
              isRequestVisitesTechniquesListLoading={
                isRequestVisitesTechniquesListLoading
              }
            />
          }
        />
      </Routes>
    </Router>
  );
};
