// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Service imports
import { complexRawBase64 } from "./complexRawBase64";
import { useAppSelector } from "../../redux/store/hook";

// Interface delcaration

interface IRequestResponse {
  photoFiles: {
    fileName: string;
    url: string;
  }[];
  clientId: string;
  folderName: string;
  message?: string;
}

export const useSaveVTFilesToSharePoint = () => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const { customerId } = useAppSelector((states) => states.visiteTechniqueInfo);
  const { userToken, eeroToken } = useAppSelector(
    (states) => states.userAuthInfo
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const saveVTFilesToSharePoint = async ({
    uploadedFile,
    fileName,
    VTId,
  }: {
    uploadedFile: string | ArrayBuffer;
    fileName: string;
    VTId?: string;
  }): Promise<IRequestResponse | void> => {
    setErrorMessage("");
    if (uploadedFile) {
      const filesInComplexRawBase64 = complexRawBase64(uploadedFile, fileName);
      const filesPayload = {
        photoFiles: [
          {
            complexRawBase64: filesInComplexRawBase64,
          },
        ],
        clientId: customerId,
        folderName: `visite_technique/vt-${VTId}`,
      };

      const response = await axiosPostRequest<IRequestResponse>(
        `${process.env.REACT_APP_EERO_URL}/vitee/saveFilesToSharepoint`,
        { filesPayload: filesPayload },
        {
          headers: {
            Authorization: `Bearer ${eeroToken}`,
            "crm-authorization": `Bearer ${userToken}`,
          },
        }
      );
      if (!response || response.status !== 200) {
        setErrorMessage(
          response?.data?.message || "Erreur lors de l'upload du fichier"
        );
      } else {
        return response.data;
      }
    } else {
      setErrorMessage("Merci d'insérer au moins un fichier avant de valider");
    }
  };

  return {
    saveVTFilesToSharePoint,
    isLoading,
    errorMessage: axiosErrorMessage ? axiosErrorMessage : errorMessage,
    setErrorMessage,
  };
};
