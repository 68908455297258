// Function to calculate the surface available on the roof, to put solar pannels
export const availableSurfaceCalculation = ({
  longueurRampant,
  longueurdelaFacade,
  velux,
  cheminee,
  chienAssis,
}: {
  longueurRampant?: number | null;
  longueurdelaFacade?: number | null;
  velux?: number | null;
  cheminee?: number | null;
  chienAssis?: number | null;
}): number => {
  if (longueurRampant && longueurdelaFacade) {
    // Handle cases where no data is filled
    const skylightNumberIfAny = velux || 0;
    const chimneyNumberIfAny = cheminee || 0;
    const dogSittingRoofIfAny = chienAssis || 0;

    const surface =
      longueurdelaFacade * longueurRampant -
      (skylightNumberIfAny * 1.092 +
        chimneyNumberIfAny * 0.5 +
        dogSittingRoofIfAny);
    return Math.floor(surface * 100) / 100; // Round less or equal with two digits after the integer
  }
  return 0;
};
