// Function to calculate the inclination of the roof according to the heigh and the lentgh
export const roofInclinationCalculation = ({
  hauteurdelaToitureFaitage,
  hauteurdelaToitureGoutiere,
  distanceduPignon,
}: {
  hauteurdelaToitureFaitage?: number | null;
  hauteurdelaToitureGoutiere?: number | null;
  distanceduPignon?: number | null;
}): number => {
  if (
    hauteurdelaToitureFaitage &&
    hauteurdelaToitureGoutiere &&
    distanceduPignon
  ) {
    const tanAlpha =
      (hauteurdelaToitureFaitage - hauteurdelaToitureGoutiere) /
      (distanceduPignon);

    const angleInRadian = Math.atan(tanAlpha);
    const angleInDegree = (angleInRadian * 180) / Math.PI;
    return Math.floor(angleInDegree);
  }
  return 0;
};
