import { MouseEventHandler, ReactElement, forwardRef } from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../styles/variables";

// Local interface declaration
export interface CTACopyPasteProps {
  name?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  form?: string;
  dataTestId?: string;
}

const Button = styled.button`
  background-color: ${stylesVariables.AssuranceBlueLight05};
  color: ${stylesVariables.AssuranceBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 2.125rem;
  padding: 10px 30px;
  width: 6.06rem;
  cursor: pointer;
  position: relative;
  border: 1px solid ${stylesVariables.AssuranceBlue};
  > p {
    font-size: "0.875rem";
    font-family: "DM Sans";
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: 0rem;
  }
  &:disabled {
    background-color: ${stylesVariables.StableGrayLight04};
    border: 1px solid ${stylesVariables.StableGrayLight02};
    color: ${stylesVariables.StableGrayLight02};
  }
  &:hover {
    background-color: ${stylesVariables.AssuranceBlueLight03};
    border: 1px solid ${stylesVariables.AssuranceBlueDark01};
    color: ${stylesVariables.AssuranceBlueDark01};
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
- None of the props are mandatory
- Default props are listed below
**/

export const CTACopyPaste = forwardRef<HTMLButtonElement, CTACopyPasteProps>(
  (props, ref): ReactElement => {
    const {
      name = "Button",
      onClick,
      disabled = false,
      isLoading = false,
      id,
      form,
      dataTestId,
    } = props;

    return (
      <Button
        id={id}
        disabled={disabled}
        onClick={onClick}
        form={form}
        ref={ref}
        data-testid={dataTestId}
      >
        {isLoading && (
          <Loader>
            <ReactLoading
              type={"spin"}
              color={"#e4e3e3"}
              height={25}
              width={25}
            />
          </Loader>
        )}
        <p style={isLoading ? { color: "transparent" } : undefined}>{name}</p>
      </Button>
    );
  }
);
