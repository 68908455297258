import "./visitDetails.scss";

// Libraries import
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store/hook";
import {
  resetFormAnswersStateAction,
  setVisiteTechniqueInfoAction,
} from "../../redux/appActions";
import { useParams } from "react-router-dom";

// Components import
import { VisitDetailsClientInfo } from "../../containers/MesVisites/VisitDetailsClientInfo/VisitDetailsClientInfo";
import { VisitDetailsProductsList } from "../../containers/MesVisites/VisitDetailsProductsList/VisitDetailsProductsList";
import { SubHeader } from "../../components/SubHeader/SubHeader";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Services import
import { useRequestVisiteTechniqueInfo } from "../../services/VisitesTechniquesInfo/useRequestVisitesTechniquesInfo";
import { determineFormNameFromProductType } from "../../services/Forms/determineFormNameFromProductType";

export const VisitDetails = () => {
  const visiteTechniqueId = useParams().visitId;
  const { requestVisiteTechniqueInfo, isLoading } =
    useRequestVisiteTechniqueInfo();
  const { userAuthInfo, visiteTechniqueInfo, visitesTechniquesList } =
    useAppSelector((state) => state);
  const visiteTechniqueListData = visitesTechniquesList.find(
    (visiteTechnique) => visiteTechnique.visiteTechniqueId === visiteTechniqueId
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    // When opening the "Visite details" pages -> reset redux form answers
    dispatch(resetFormAnswersStateAction());

    // And update visite technique info if we have opened a new visite technique (visiteTechnique id has changed)
    if (visiteTechniqueInfo.id !== visiteTechniqueId) {
      dispatch(setVisiteTechniqueInfoAction({ visiteTechniqueInfo: null }));
      userAuthInfo.userToken &&
        visiteTechniqueId &&
        requestVisiteTechniqueInfo(userAuthInfo.userToken, visiteTechniqueId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiteTechniqueId]);

  return (
    <div className="visiteDetails">
      <SubHeader
        clientName={
          visiteTechniqueListData?.customerFirstName +
          " " +
          visiteTechniqueListData?.customerLastName
        }
        productType={visiteTechniqueInfo.products}
        commercialFullName={visiteTechniqueInfo.commercialFullName}
        commercialMobilePhone={visiteTechniqueInfo.commercialMobilePhone}
        commercialEmail={visiteTechniqueInfo.commercialEmail}
      />
      <VisitDetailsClientInfo data={visiteTechniqueInfo} />
      <div className="visiteDetails__VisiteInfo">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <VisitDetailsProductsList
            visiteTechniqueId={visiteTechniqueId || ""}
            formName={determineFormNameFromProductType(
              visiteTechniqueInfo.typeDeProduit
            )}
          />
        )}
      </div>
    </div>
  );
};
