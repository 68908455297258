// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Service import
import { useSetVisitesTechniquesList } from "./useSetVisitesTechniquesList";

// Interfaces import
import { IVisiteTechniqueList } from "../../interfaces/generalInterfaces";

// Interface delcaration
interface IFunctionReturn {
  requestVisitesTechniquesList: (
    userToken: string,
    userId: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useRequestVisitesTechniquesList : return tools to get the list of the visites techniques
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestVisitesTechniquesList
//     Function to call to send get visites techniques request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useRequestVisitesTechniquesList = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setVisitesTechniquesList } = useSetVisitesTechniquesList();

  // requestVisitesTechniquesList : send Get visites techniques request. If successfull-> save visites techniques information info in local storage and in redux store
  // Parameters
  // ----------
  // userToken: String
  //      the JWT authentication token of the user
  // Returns
  // ----------
  // Void

  const requestVisitesTechniquesList = async (
    userToken: string,
    userId: string
  ): Promise<void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<IVisiteTechniqueList[]>(
      `${BACKEND_URL}/api/VT/${userId}/VisitesTechniquesList`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response && response[0]?.visiteTechniqueId) {
      setVisitesTechniquesList(response);
    } else {
      setErrorMessage("Erreur lors du chargement des données utilisateurs");
    }
  };

  return {
    requestVisitesTechniquesList,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
