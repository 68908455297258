// Libraries import
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { FormWrapper } from "../../../containers/Forms/FormWrapper/FormWrapper";
import { SubHeader } from "../../../components/SubHeader/SubHeader";
import { FormQuestionsFormatter } from "../../../containers/Forms/FormQuestionsFormatter/FormQuestionsFormatter";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetVTCommonInfoFromCRM } from "../../../services/Forms/useGetVTCommonInfoFromCRM";
import { useGetFormAnswerFromLocalStorage } from "../../../services/Forms/useGetFormAnswerFromLocalStorage";

// Data import
import { CETFormData } from "../../../data/forms/CETFormData";
import { useUpdateFormCETAccordingToUserAnswer } from "../../../services/Forms/CETForm/useUpdateFormCETAccordingToUserAnswer";
import { Ranaglyphe } from "../../../containers/Ranaglyphe/Ranaglyphe";

export const CET = () => {
  const singleVisitId = useParams().visitId;
  const formName = CETFormData.formName;
  const formsMutableData = useAppSelector(
    (state) => state.formsMutableData[formName]
  );
  const currentScreenIndex = formsMutableData.currentScreenIndex;
  const currentScreenName = CETFormData.formScreensName[currentScreenIndex];

  const {
    getVTCommonInfoFromCRM,
    isLoading: isGetVTCommonInfoLoading,
    errorMessage: getVTCommonInfoErrorMessage,
  } = useGetVTCommonInfoFromCRM();

  const { visiteTechniqueInfo, userAuthInfo } = useAppSelector(
    (state) => state
  );

  const [isRanaglypheClicked, setIsRanaglypheClicked] =
    useState<boolean>(false);

  // Get saved form answer from local storage if there are any
  useGetFormAnswerFromLocalStorage({ formName, singleVisitId });

  useEffect(() => {
    // At the opening of the form we download data from CRM
    if (singleVisitId) {
      getVTCommonInfoFromCRM(userAuthInfo.userToken, formName, singleVisitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVisitId]);

  useUpdateFormCETAccordingToUserAnswer({ formName, formsMutableData });

  return (
    <div className="photovoltaique">
      <div className="photovoltaique__SubHeader">
        <SubHeader
          clientName={
            visiteTechniqueInfo.customerFirstName +
            " " +
            visiteTechniqueInfo.customerLastName
          }
          productType={visiteTechniqueInfo.products}
          commercialFullName={visiteTechniqueInfo.commercialFullName}
          commercialMobilePhone={visiteTechniqueInfo.commercialMobilePhone}
          urlButtonBack={`/visite-technique/${singleVisitId}`}
          commercialEmail={visiteTechniqueInfo.commercialEmail}
          formName="CET"
        />
      </div>
      {isRanaglypheClicked ? (
        <Ranaglyphe
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
          visiteTechniqueInfo={visiteTechniqueInfo}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
        />
      ) : (
        <FormWrapper
          visiteTechniqueInfo={visiteTechniqueInfo}
          formName={formName}
          areGetDataFromCRMLoading={isGetVTCommonInfoLoading}
          getDataErrorMessage={getVTCommonInfoErrorMessage}
          formPagesLength={
            CETFormData.formScreens[currentScreenName].pages.length
          }
          visiteTechniqueId={singleVisitId}
          formScreenSteps={CETFormData.formScreens[currentScreenName].steps}
          currentScreenName={currentScreenName}
          numberOfScreens={CETFormData.formScreensName.length}
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
        >
          <Layout>
            <div className="photovoltaique__Content">
              <FormQuestionsFormatter
                formScreenData={
                  CETFormData.formScreens[currentScreenName].pages
                }
                formName={CETFormData.formName}
                visiteTechniqueId={singleVisitId}
                formCurrentPage={
                  formsMutableData.currentPageIndex[currentScreenName]
                }
                formCurrentBranchs={formsMutableData.formCurrentBranchs}
              />
            </div>
          </Layout>
        </FormWrapper>
      )}
    </div>
  );
};
