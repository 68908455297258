// Componsants import
import { BooleanChoiceBtnContainer } from "../../Forms/FormStepsWithSharedComponents/BooleanChoiceBtnContainer/BooleanChoiceBtnContainer";
import { CheckboxContainer } from "../../Forms/FormStepsWithSharedComponents/CheckboxContainer/CheckboxContainer";
import { UpDownButtonContainer } from "../../Forms/FormStepsWithSharedComponents/UpDownButtonContainer/UpDownButtonContainer";
import { UploadPicturesBtn } from "../../Forms/FormStepsWithSharedComponents/UploadPicturesBtn/UploadPicturesBtn";

// Services import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setITEPointSingulierAction,
  createITEPointSingulierAction,
  deleteITEPointSingulierAction,
} from "../../../redux/appActions";

// Interfaces import
import {
  IITEPointsSinguliers,
  KeyOfIITEPointsSinguliers,
} from "../../../interfaces/ITEFormInterface";

// Local interface declaration
interface IProps {
  pointsSinguliers?: IITEPointsSinguliers[];
  visitId?: string;
  wallId: string | null;
  title: string;
  pointSingulierName: KeyOfIITEPointsSinguliers;
  wallIndex: number;
}

export const ITEPointSingulier = ({
  pointsSinguliers,
  visitId,
  wallId,
  title,
  pointSingulierName,
  wallIndex,
}: IProps) => {
  const dispatch = useAppDispatch();

  const pointSingulier = pointsSinguliers?.find(
    (pointSingulier) =>
      pointSingulier.murId?.value === wallId &&
      pointSingulier.pointSingulierName?.value === pointSingulierName
  );

  const onClickOnCreatePointSingulier = ({
    wallId,
    pointSingulierName,
    visitId,
    isQuestionAnswered,
  }: {
    wallId: string | null;
    pointSingulierName: string;
    visitId?: string;
    isQuestionAnswered?: boolean;
  }) => {
    !isQuestionAnswered &&
      dispatch(
        createITEPointSingulierAction({
          visitId: visitId ?? null,
          wallId,
          pointSingulierName,
        })
      );
  };

  const onClickOnDeletePointSingulier = ({
    pointSingulierId,
    visitId,
    wallId,
    windowId,
  }: {
    pointSingulierId: string | null;
    wallId: string | null;
    windowId?: string | null;
    pointSingulierName: string;
    visitId?: string;
  }) => {
    dispatch(
      deleteITEPointSingulierAction({
        visitId: visitId ?? null,
        pointSingulierId,
        wallId,
        windowId,
      })
    );
  };

  const onClickOnPointSingulierQuestion = ({
    value,
    formName = "ITE",
    formQuestionName,
    visitId,
    pictureUrl,
    pointSingulierName,
  }: {
    value?: string | null;
    formName?: "ITE";
    formQuestionName: KeyOfIITEPointsSinguliers;
    visitId?: string | null;
    pictureUrl?: string;
    pointSingulierName: string;
  }) => {
    dispatch(
      setITEPointSingulierAction({
        value,
        formName: formName,
        formQuestionName,
        visitId: visitId ?? null,
        wallId,
        pictureUrl,
        pointSingulierName,
      })
    );
  };

  return (
    <div className="formPagesITE__PointSingulier">
      <div className="formPagesITE__QuestionLeftRight">
        <BooleanChoiceBtnContainer
          title={title}
          questionName={pointSingulierName}
          areAnswersAlign={false}
          formName="ITE"
          visitId={visitId}
          isPictureNeeded={false}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulier?.pointSingulierName}
          userAnswer={pointSingulier?.pointSingulierName}
          onCardClick={(value) => {
            value
              ? onClickOnCreatePointSingulier({
                  pointSingulierName: pointSingulierName,
                  wallId,
                  visitId,
                  isQuestionAnswered: !!pointSingulier,
                })
              : onClickOnDeletePointSingulier({
                  pointSingulierName: pointSingulierName,
                  wallId,
                  pointSingulierId:
                    pointSingulier?.pointSingulierId?.value || null,
                  visitId,
                });
          }}
        />
        {pointSingulier ? (
          <UpDownButtonContainer
            title="Quantité :"
            questionName="pointSingulierQuantity"
            formName="ITE"
            visitId={visitId}
            areAnswersAlign={false}
            isPictureNeeded={false}
            wallIndex={wallIndex}
            pointSingulierName={pointSingulier?.pointSingulierName}
            userAnswer={
              pointSingulier?.pointSingulierQuantity || { value: "0" }
            }
            onPlusMinusClick={(value) => {
              onClickOnPointSingulierQuestion({
                value: value.toString(),
                formQuestionName: "pointSingulierQuantity",
                visitId,
                pointSingulierName: pointSingulierName,
              });
            }}
          />
        ) : null}
      </div>
      {pointSingulier ? (
        <div className="formPagesITE__QuestionPhoto">
          <p className={"formPagesITE__QuestionPhoto__QuestionTitle"}>
            Veuillez prendre une ou des photo(s) :{" "}
          </p>
          <UploadPicturesBtn
            questionName={pointSingulierName}
            uploadedPictures={pointSingulier?.pointSingulierPictures?.pictures}
            buttonName={"Ajouter"}
            formName="ITE"
            visitId={visitId}
            wallIndex={wallIndex}
            pointSingulierName={pointSingulier?.pointSingulierName}
            onUpload={(pictureUrl) =>
              onClickOnPointSingulierQuestion({
                pictureUrl,
                formQuestionName: "pointSingulierPictures",
                visitId,
                pointSingulierName: pointSingulierName,
              })
            }
          />
        </div>
      ) : null}
      {pointSingulier ? (
        <div className="formPagesITE__Question">
          <CheckboxContainer
            title={
              <>
                Le client souhaite reposer ce(s) point(s) singulier(s) après ITE
                (donc à réaliser par <strong>TUCOENERGIE</strong>).
              </>
            }
            questionName="isPointSingulierAReposer"
            formName="ITE"
            visitId={visitId}
            isPictureNeeded={false}
            userAnswer={pointSingulier?.isPointSingulierAReposer}
            wallIndex={wallIndex}
            pointSingulierName={pointSingulier?.pointSingulierName}
            onCheckboxClick={(value) =>
              onClickOnPointSingulierQuestion({
                value: value === true ? "true" : "false",
                formQuestionName: "isPointSingulierAReposer",
                visitId,
                pointSingulierName: pointSingulierName,
              })
            }
          />
        </div>
      ) : null}
    </div>
  );
};
