//Style import
import "./pickPositionMapModal.scss";

//Component import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";

// Local interface declaration
interface Props {
  onClickButton: () => void;
  onClickButtonBack: () => void;
}

export const PickPositionMapModal = ({
  onClickButton,
  onClickButtonBack,
}: Props) => {
  return (
    <div className="pickPositionMapModal">
      <div className="pickPositionMapModal__TextBlock">
        <h3>Attention ! Vous n’avez pas cliqué sur la maison à équiper...</h3>
        <h4>
          Voulez-vous continuer sans avoir renseigné les coordonnées exactes ?
        </h4>
      </div>
      <div className="pickPositionMapModal__ButtonsContainer">
        <div className="pickPositionMapModal__Buttons">
          <MainButton
            name="Ne pas envoyer"
            onClick={onClickButtonBack}
            size="big"
            color="grey"
          />
        </div>
        <div className="pickPositionMapModal__Buttons">
          <MainButton
            name="Envoyer"
            onClick={onClickButton}
            size="big"
            color="orange"
          />
        </div>
      </div>
    </div>
  );
};
