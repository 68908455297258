import "./formPages.scss";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { PickMultipleCards } from "../../../../containers/Forms/FormStepsNew/PickMultipleCards/PickMultipleCards";
import { InputText } from "../../../../containers/Forms/FormStepsNew/InputText/InputText";
import { InputNumber } from "../../../../containers/Forms/FormStepsNew/InputNumber/InputNumber";
import { InfoBoxForm } from "../../../../components/InfoBoxForm/InfoBoxForm";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const InstallationExistantePage = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const formName = "PacAirEau";

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Installation existante</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Quel est le type de chauffage central existant ?"
        formName={formName}
        cards={[
          629950000, 629950001, 629950002, 629950003, 629950004, 629950005,
        ]}
        questionName="typeDeChauffageExistant"
        userAnswer={PacAirEauFormMutableData?.typeDeChauffageExistant}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputText
        title="Si autres :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.otherKindOfHeater}
        questionName="otherKindOfHeater"
        visitId={visitId}
      />
      <InputText
        title="Marque du chauffage existant"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.heaterExistingBrand}
        questionName="heaterExistingBrand"
        visitId={visitId}
      />
      <InputText
        title="Modèle du chauffage existant"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.heaterExistingModel}
        questionName="heaterExistingModel"
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Fonction Chauffage & ECS intégrée ? "
        cards={[0, 1]}
        formName={formName}
        questionName="fonctionChauffageECSintegree"
        userAnswer={PacAirEauFormMutableData?.fonctionChauffageECSintegree}
        visitId={visitId}
      />
      <InputNumber
        title="Puissance chauffage en kW"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.puissanceduchauffageenkW}
        questionName="puissanceduchauffageenkW"
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Quel est l’emplacement de ce système actuel ?"
        cards={[629950000, 629950001, 629950002, 629950003]}
        formName={formName}
        questionName="emplacementactueldusystemedechauffage"
        userAnswer={
          PacAirEauFormMutableData?.emplacementactueldusystemedechauffage
        }
        visitId={visitId}
      />
      <InputText
        title="Si autres :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.otherPACLocation}
        questionName="otherPACLocation"
        visitId={visitId}
      />
      <PickMultipleCards
        title="Quel est le type de réseau existant ?"
        cards={[629950000, 629950001, 629950002, 629950003]}
        formName={formName}
        questionName="typeDeReseauExistants"
        userAnswer={PacAirEauFormMutableData?.typeDeReseauExistants}
        visitId={visitId}
      />
      {PacAirEauFormMutableData?.typeDeReseauExistants?.value?.includes(
        629950000
      ) && (
        <div className="formPages__BlueWrapper">
          <h2 className="title">Type de réseau : Radiateurs </h2>
          <div className="formPages__BlueWrapper__WrapperComponents">
            <InputNumber
              title="Nombre d'unités :"
              formName={formName}
              questionName="nombredeRadiateurs"
              userAnswer={PacAirEauFormMutableData?.nombredeRadiateurs}
              visitId={visitId}
            />
            <PickUniqueCard
              title="Type d'émetteurs"
              cards={[629950000, 629950001, 629950002]}
              formName={formName}
              questionName="emmitterType"
              userAnswer={PacAirEauFormMutableData?.emmitterType}
              visitId={visitId}
            />
            <InputNumber
              title="Nombre de pièces chauffées par les radiateurs  :"
              formName={formName}
              userAnswer={
                PacAirEauFormMutableData?.nombredePiecesChauffeByRadiateurs
              }
              questionName="nombredePiecesChauffeByRadiateurs"
              visitId={visitId}
            />
            <PickUniqueCard
              title="État du réseau existant"
              cards={[629950000, 629950001, 629950002]}
              formName={formName}
              questionName="etatreseauradiateurs"
              userAnswer={PacAirEauFormMutableData?.etatreseauradiateurs}
              visitId={visitId}
            />
            <PickUniqueCard
              title="Si l’état est vétuste, est-ce que le réseau existant est fonctionnel :"
              cards={[0, 1]}
              formName={formName}
              questionName="radiateursVetustesFonctionnels"
              userAnswer={
                PacAirEauFormMutableData?.radiateursVetustesFonctionnels
              }
              visitId={visitId}
            />
            <InputText
              title="Commentaire sur le réseau de radiateurs"
              formName={formName}
              userAnswer={PacAirEauFormMutableData?.commentairesradiateurs}
              questionName="commentairesradiateurs"
              visitId={visitId}
              isPictureNeeded={true}
            />
          </div>
        </div>
      )}
      {PacAirEauFormMutableData?.typeDeReseauExistants?.value?.includes(
        629950001
      ) && (
        <div className="formPages__BlueWrapper">
          <h2 className="title">Type de réseau : Plancher chauffant </h2>
          <div className="formPages__BlueWrapper__WrapperComponents">
            <PickUniqueCard
              title="État du réseau existant"
              cards={[629950000, 629950001, 629950002]}
              formName={formName}
              questionName="etatreseauplancherchauffant"
              userAnswer={PacAirEauFormMutableData?.etatreseauplancherchauffant}
              visitId={visitId}
            />
            <PickUniqueCard
              title="Si l’état est vétuste, est-ce que le réseau existant est fonctionnel :"
              cards={[0, 1]}
              formName={formName}
              questionName="pcVetuste"
              userAnswer={PacAirEauFormMutableData?.pcVetuste}
              visitId={visitId}
            />
            <InputNumber
              title="Nombre de boucles chauffées par le plancher chauffant :"
              formName={formName}
              userAnswer={
                PacAirEauFormMutableData?.nombredeboucleschauffeesparleplancherchauff
              }
              questionName="nombredeboucleschauffeesparleplancherchauff"
              visitId={visitId}
            />
            <InputText
              title="Commentaires sur le réseau de plancher chauffant"
              formName={formName}
              userAnswer={
                PacAirEauFormMutableData?.commentairesplancherchauffant
              }
              questionName="commentairesplancherchauffant"
              visitId={visitId}
              isPictureNeeded={true}
            />
          </div>
        </div>
      )}
      {PacAirEauFormMutableData?.typeDeReseauExistants?.value?.includes(
        629950003
      ) && (
        <InfoBoxForm
          textContent={
            <>
              <strong>La VT peut s'arrêter là.</strong>
            </>
          }
        />
      )}
    </div>
  );
};
