import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet?.create({
  layoutPdf: {
    padding: "0px 40px",
  },
});

// Interface declaration
interface PdfLayoutProps {
  children: React.ReactNode;
}

export const PdfLayout: React.FC<PdfLayoutProps> = ({ children }) => {
  return <View style={styles.layoutPdf}>{children}</View>;
};
