import "./CalendarDayLine.scss";

interface IProps {
  day: string;
  dayDifference: number;
  isToday: boolean;
}

export const CalendarDayLine = ({ day, dayDifference, isToday }: IProps) => {
  return isToday ? (
    <div className="CalendarDayLine">
      <p>Aujourd'hui</p>
    </div>
  ) : dayDifference > -6 ? (
    <div className="CalendarDayLine">
      <p>{day}</p>
    </div>
  ) : (
    <div className="CalendarDayLineNextWeek">
      <p>La semaine prochaine</p>
    </div>
  );
};
