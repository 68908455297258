import "./uploadPictures.scss";

// Components import
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Interface import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  visitId?: string;
  formName: formNames;
  answerIndex?: number;
}

export const UploadPictures = ({
  questionData,
  visitId,
  formName,
  answerIndex,
}: Props) => {
  return (
    <div className="uploadPictures">
      <div className="uploadPictures__Title">
        <p>{questionData.title}</p>
      </div>
      <div className="uploadPictures__uploadPictureComponent">
        <UploadPicturesComponent
          questionData={questionData}
          visitId={visitId}
          formName={formName}
          answerIndex={answerIndex}
        />
      </div>
    </div>
  );
};
