import "./PageLayout.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return <div className="PageLayout">{children}</div>;
};
