import "./authenticationWindow.scss";

// Libraries import
import React, { useState } from "react";

// Components import
import { AuthenticationWelcome } from "../AuthenticationWelcome/AuthenticationWelcome";
import { AuthenticationCredentials } from "../AuthenticationCredentials/AuthenticationCredentials";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";

// Local interface declaration
interface Props {
  isGetProfileInfoIsLoading: boolean;
}

export const AuthenticationWindow = ({ isGetProfileInfoIsLoading }: Props) => {
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] =
    useState<boolean>(false);

  return (
    <div className="authentication-window">
      <AuthenticationWelcome />
      {!isForgotPasswordClicked ? (
        <>
          <AuthenticationCredentials
            isForgotPasswordClicked={isForgotPasswordClicked}
            setIsForgotPasswordClicked={setIsForgotPasswordClicked}
            isGetProfileInfoIsLoading={isGetProfileInfoIsLoading}
          />
          <div className="authentication-window__EspaceClient">
            <p>Vous êtes un particulier&nbsp;? Connectez vous&nbsp;:</p>
            <a
              className="espaceClientButton"
              href={process.env.REACT_APP_STARGATE_REDIRECTION_URL}
            >
              Espace Client
            </a>
          </div>
        </>
      ) : (
        <ForgotPassword
          isForgotPasswordClicked={isForgotPasswordClicked}
          setIsForgotPasswordClicked={setIsForgotPasswordClicked}
        />
      )}
    </div>
  );
};
