// Other imports
import { useAppDispatch } from "../../redux/store/hook";
import { setVisiteTechniqueInfoAction } from "../../redux/appActions";

// Data import
import { initialState } from "../../redux/initialState";

// Interfaces import
import { IVisiteTechniqueInfo } from "../../interfaces/generalInterfaces";

// Interfaces delcaration
interface IFunctionReturn {
  setVisiteTechniqueInfo: (
    visiteTechniqueInfo: IVisiteTechniqueInfo | null
  ) => void;
}

// useSetVisiteTechniqueInfo : return tools to save visite technique info in redux store and local storage
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function setVisiteTechniqueInfo
//     Function to create/delete user's profile info in redux store and sessions storage

export const useSetVisiteTechniqueInfo = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  // setVisiteTechniqueInfo : create/delete visite technique info in local storage and redux store
  // Parameters
  // ----------
  // visiteTechniqueInfo: see IVisiteTechniqueInfo interface
  //      object containing the visite information
  // Returns
  // ----------
  // Void

  const setVisiteTechniqueInfo = (
    visiteTechniqueInfo: IVisiteTechniqueInfo | null
  ): void => {
    if (visiteTechniqueInfo) {
      dispatch(setVisiteTechniqueInfoAction({ visiteTechniqueInfo }));
    } else {
      dispatch(
        setVisiteTechniqueInfoAction({
          visiteTechniqueInfo: initialState.visiteTechniqueInfo,
        })
      );
    }
  };
  return { setVisiteTechniqueInfo };
};
