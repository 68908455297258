import "./uploadPicturesBtn.scss";

// Libraries import
import Compressor from "compressorjs";
import { useRef, useState } from "react";

// Components import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Assets import
import pictoCamera from "../../../../assets/images/pictos/cameraWhite.svg";
import ValidatedBlue from "../../../../assets/images/pictos/validated_blue.svg";

// Services import
import { useSaveVTFilesToSharePoint } from "../../../../services/UploadDocuments/useSaveVTFilesToSharePoint";

// Interface import
import {
  formNames,
  IAllFormQuestionsKeys,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

// Local interface declaration
interface Props {
  formName: formNames;
  visitId: string | undefined;
  questionName?: IAllFormQuestionsKeys;
  answerIndex?: number;
  uploadedPictures?: string[];
  onUpload?: (pictureUrl: string) => void;
  buttonName?: string;
  showIcn?: boolean;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const UploadPicturesBtn = ({
  onUpload,
  uploadedPictures,
  buttonName,
  showIcn = true,
  questionName,
  wallIndex,
  pointSingulierName,
  visitId,
}: Props) => {
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);
  const uploadPictureInput = useRef<HTMLInputElement>(null);
  const { saveVTFilesToSharePoint, isLoading } = useSaveVTFilesToSharePoint();

  const fileName =
    (wallIndex !== undefined
      ? pointSingulierName
        ? `Mur_${wallIndex + 1}_${pointSingulierName.value}`
        : `Mur_${wallIndex + 1}_${questionName}`
      : `${questionName}`) +
    "-" +
    Date.now();

  const handleUploadDocument = (file: File) => {
    // we double check here that it is an image and not an other type of file
    if (file && file.type.substring(0, 5) === "image") {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (reader.result) {
          const response = await saveVTFilesToSharePoint({
            uploadedFile: reader.result,
            fileName: fileName + "." + file.type.split("/")[1] || "jpeg",
            VTId: visitId,
          });
          if (response && response.photoFiles.length > 0) {
            onUpload && onUpload(response.photoFiles[0].url); // For now we upload only one picture at a time so we only have one photoFile
          }
        }
      };
    }
  };

  const handleCompressedImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // https://github.com/fengyuanchen/compressorjs/blob/main/README.md
    const image = event.target.files?.[0];
    if (image) {
      new Compressor(image, {
        quality: image.size > 1000000 ? 0.1 : image.size > 500000 ? 0.5 : 0.8, //if image more than 1MB, we compress it to 90%, if image size more than 500kb we compress it to 50%, else, we comprese it to 80%
        success: (compressedResult) => {
          handleUploadDocument(compressedResult as File);
        },
        error() {
          // if error, we upload the non compressed image
          handleUploadDocument(image);
        },
      });
    }
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Ajouter une ou des photo(s)`}
      >
        <div className="UploadPicturesBtn__ImageList">
          {uploadedPictures?.map((url, index) => {
            return (
              <div
                className="UploadPicturesBtn__singleImageContainer"
                key={index}
                onClick={() => window.open(url)}
              >
                <div className="UploadPicturesBtn__validatedImage">
                  <img src={ValidatedBlue} alt="Picto validated blue" />
                </div>
                <img
                  src={url}
                  onError={(event) => {
                    event.currentTarget.onerror = null; // prevents looping
                    event.currentTarget.src =
                      "/images/produce-my-electricity.svg"; // Fallback image
                  }}
                  alt="preview"
                />
              </div>
            );
          })}
        </div>
        <div className="Modale_Buttons">
          <CTAButton
            name="Retour"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          />
          {isLoading ? (
            <LoadingComponent diameter={60} />
          ) : (
            <CTAButton
              name="Ajouter"
              family="vitee"
              onClick={(event) => {
                event.preventDefault();
                uploadPictureInput.current?.click();
              }}
            />
          )}
        </div>
      </Modale>
      <div className="UploadPicturesBtn">
        <div className="UploadPicturesBtn__Container">
          {uploadPictureInput && (
            <div className="UploadPicturesBtn__FormInput">
              <CTAButton
                family={"vitee"}
                category={
                  (uploadedPictures?.length || 0) > 0 ? "tertiary" : "primary"
                }
                iconSrc={showIcn ? pictoCamera : ""}
                name={
                  (uploadedPictures?.length || 0) > 0
                    ? `${uploadedPictures?.length} photo(s) prise(s)`
                    : buttonName || ""
                }
                onClick={() => setIsDeleteModaleOpen((state) => !state)}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={uploadPictureInput}
                // we only accept images. when file explorer opens, only images will be proposed
                accept="image/*"
                onChange={(event) => {
                  handleCompressedImageUpload(event);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
