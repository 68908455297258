// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";

// Services import
import { updateReduxAfterSaveMurWithOuvrantItemsAction } from "../../../redux/appActions";
import { useAppDispatch } from "../../../redux/store/hook";

// Interfaces import
import {
  ICRMWall,
  ISaveMurWithOuvrantItemsResponse,
} from "../../../interfaces/ITEFormInterface";

// Local interfaces delcaration

export const useSaveITEWallsInCRM = () => {
  const dispatch = useAppDispatch();
  const {
    axiosPostRequest,
    isLoading: axiosPostIsLoading,
    errorMessage: axiosPostErrorMessage,
    setErrorMessage: setAxiosPostErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const resetStates = () => {
    setIsSuccessfull(false);
    setErrorMessage("");
    setAxiosPostErrorMessage("");
  };

  const saveITEWallsInCRM = async ({
    ITEWallData,
    VTId,
    crmToken,
    eeroToken,
  }: {
    ITEWallData: ICRMWall[] | undefined;
    VTId: string;
    crmToken: string | null;
    eeroToken: string | null;
  }): Promise<void> => {
    setErrorMessage("");

    if (!ITEWallData || ITEWallData.length === 0) {
      setIsSuccessfull(true); // If ITEWallFormatted array is empty, we don't send the request but we consider the saveITEWallsInCRM request as successfull
    }

    const response = await axiosPostRequest<ISaveMurWithOuvrantItemsResponse[]>(
      `${process.env.REACT_APP_EERO_URL}/vitee/saveITEWallsInCRM/${VTId}`,

      { ITEWallData: ITEWallData },
      {
        headers: {
          Authorization: `Bearer ${eeroToken}`,
          "crm-authorization": `Bearer ${crmToken}`,
        },
      }
    );

    if (response) {
      setIsSuccessfull(true); // If we have data in the answer it means the request is successfull
      dispatch(
        updateReduxAfterSaveMurWithOuvrantItemsAction({
          // We update redux with the CRM ids in case one of the 3 "save calls" fails and we need to resend them -> allow to update and not to create a doublon in the CRM
          visitId: VTId,
          saveMurWithOuvrantItemsResponse: response.data,
        })
      );
      return;
    }
    return;
  };

  return {
    saveITEWallsInCRM,
    isLoading: axiosPostIsLoading,
    errorMessage: errorMessage || axiosPostErrorMessage,
    isSuccessfull,
    resetStates,
  };
};
