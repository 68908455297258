// Services import
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/store/hook";
import { setFormMutableDataStateAction } from "../../redux/appActions";

// Interfaces import
import {
  formNames,
  IFormsMutableDataObect,
} from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  formName: formNames;
  singleVisitId?: string;
}

export const useGetFormAnswerFromLocalStorage = ({
  singleVisitId,
  formName,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isVisiteTechniqueInLocalStorage, setIsVisiteTechniqueInLocalStorage] =
    useState<boolean | null>(null);
  // Get saved form answer from local storage if there are any
  useEffect(() => {
    const savedFormAnswer = singleVisitId
      ? localStorage.getItem(singleVisitId)
      : undefined;
    const savedAnswerFormatted: IFormsMutableDataObect = savedFormAnswer
      ? JSON.parse(savedFormAnswer)
      : undefined;

    // We save if there is a visit technique saved in the local storage
    setIsVisiteTechniqueInLocalStorage(!!savedFormAnswer);

    savedAnswerFormatted &&
      dispatch(
        setFormMutableDataStateAction({
          newFormsMutableDataState: savedAnswerFormatted,
          formName,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVisitId]);

  return isVisiteTechniqueInLocalStorage;
};
