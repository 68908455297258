// Service import
import { useRequestUserProfileInfo } from "./useRequestUserProfileInfo";
import { useSetUserProfileInfo } from "./useSetUserProfileInfo";

// Interface import
import { IUserProfileInfo } from "../../interfaces/generalInterfaces";

// Interface declaration
interface FunctionReturn {
  getUserProfileInfo: (userToken: string, userId: string) => void;
  isLoading: boolean;
  errorMessage: string;
}

// useGetUserProfileInfo : return function to get user profile information from sessions storage and save it in redux store
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function getUserProfileInfo
//     Function to get user profile information
// isLoading : string
//      Indicate if the async function is on going
// errorMessage : string
//      Request error message

export const useGetUserProfileInfo = (): FunctionReturn => {
  const { setUserProfileInfo } = useSetUserProfileInfo();
  const { requestUserProfileInfo, isLoading, errorMessage } =
    useRequestUserProfileInfo();

  // getUserAuthInfo : function to get user profile information from sessions storage and save it in redux store. If does't exist in session storage, request them from backend.
  // Parameters
  // ----------
  // userToken : string
  //       jwt authentication token
  //
  // Returns
  // ----------
  // Void
  const getUserProfileInfo = async (userToken: string, userId: string) => {
    const stored = localStorage.getItem("userProfileInfo");
    const userProfileInfo: IUserProfileInfo = stored
      ? JSON.parse(stored)
      : null;
    // If profile information exist we store them in the store. We request them otherwise.
    userProfileInfo?.id
      ? setUserProfileInfo(userProfileInfo)
      : await requestUserProfileInfo(userToken, userId);
  };
  return { getUserProfileInfo, isLoading, errorMessage };
};
