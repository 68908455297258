import "./formPages.scss";

// Librairie import
import React from "react";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { InputNumber } from "../../../../containers/Forms/FormStepsNew/InputNumber/InputNumber";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const InstallationCiblePage = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const formName = "PacAirEau";

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Habitudes de consommations</h4>
        <div className="formPages__Trait" />
      </div>
      <InputNumber
        title="Température de confort (en °C)"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.temperaturedeconfort}
        questionName="temperaturedeconfort"
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Analyse du logement</h4>
        <div className="formPages__Trait" />
      </div>
      <InputNumber
        title="Année de construction ou de rénovation"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.anneedeconstructionourenovation}
        questionName="anneedeconstructionourenovation"
        visitId={visitId}
      />
      <PickUniqueCard
        title="Nombre d’étages"
        cards={[0, 1, 2, 3, 4, 5]}
        formName={formName}
        questionName="nombredetages"
        userAnswer={PacAirEauFormMutableData?.nombredetages}
        visitId={visitId}
      />
      <InputNumber
        title="Surface chaufée en m2"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.surfaceenm2}
        questionName="surfaceenm2"
        visitId={visitId}
      />
      <InputNumber
        title="Hauteur moyenne sous plafond (en m)"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.hauteurmoyennesousplafond}
        questionName="hauteurmoyennesousplafond"
        visitId={visitId}
      />
      <PickUniqueCard
        title="Nombre de zone de chauffage ?"
        cards={[629950000, 629950001, 629950002, 629950003]}
        formName={formName}
        questionName="nombredezonedechauffage"
        userAnswer={PacAirEauFormMutableData?.nombredezonedechauffage}
        visitId={visitId}
      />
      <PickUniqueCard
        title="Y-a-t’il un réducteur de pression ?"
        cards={[0, 1]}
        formName={formName}
        questionName="reducteurdepression"
        userAnswer={PacAirEauFormMutableData?.reducteurdepression}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <div className="formPages__Category">
        <h4>Liaison frigorifique</h4>
        <div className="formPages__Trait" />
      </div>
      <InputNumber
        title="Longueur liaison frigorifique et électrique (en m)"
        formName={formName}
        userAnswer={
          PacAirEauFormMutableData?.longueurdeliaisonfrigorifiqueetelectrique
        }
        questionName="longueurdeliaisonfrigorifiqueetelectrique"
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputNumber
        title="Distance groupe extérieur / au TGBT (en m)"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.distancegroupeexterieurauTGBTm}
        questionName="distancegroupeexterieurauTGBTm"
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputNumber
        title="Distance TGBT / groupe hydraulique (en m)"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.distanceTGBTgroupehydrauliquem}
        questionName="distanceTGBTgroupehydrauliquem"
        visitId={visitId}
        isPictureNeeded={true}
      />
    </div>
  );
};
