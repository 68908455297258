import { useEffect } from "react";
import {
  formNames,
  ICETFormMutableData,
} from "../../../interfaces/generalInterfaces";
import { updateFormBranchStateAction } from "../../../redux/appActions";
import { useAppDispatch } from "../../../redux/store/hook";

//Local interface
interface IProps {
  formName: formNames;
  formsMutableData: ICETFormMutableData;
}

export const useUpdateFormCETAccordingToUserAnswer = ({
  formName,
  formsMutableData,
}: IProps) => {
  const dispatch = useAppDispatch();

  //Rule to updtate branch and show/hide question : autre d’ECS existant
  const typeECSExistant = formsMutableData.typeEcsExistant?.value;
  useEffect(() => {
    if (typeECSExistant === 629950003) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "typeECSExistant",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "typeECSExistant",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeECSExistant]);

  ////Rule to updtate branch and show/hide question : emplacement existant autre
  const emplacementECSExistant = formsMutableData.cetExistingLocation?.value;
  useEffect(() => {
    if (emplacementECSExistant === 629950003) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "emplacementECSExistant",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "emplacementECSExistant",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emplacementECSExistant]);

  ////Rule to updtate branch and show/hide question : emplacement a prévoire autre
  const emplacementAPrevoir = formsMutableData.cetFutureLocation?.value;
  useEffect(() => {
    if (emplacementAPrevoir === 629950001) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "emplacementAPrevoir",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "emplacementAPrevoir",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emplacementAPrevoir]);

  ////Rule to updtate branch and show/hide question : type d'installation
  const typeInstallationCET = formsMutableData.cetInstallationType?.value;
  useEffect(() => {
    if (typeInstallationCET === 629950000) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "typeInstallationCETAirExtrait",
        })
      );
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "typeInstallationCET",
        })
      );
    }

    if (typeInstallationCET === 629950001) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "typeInstallationCETAirExtrait",
        })
      );
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "typeInstallationCET",
        })
      );
    }

    if (typeInstallationCET === 629950002) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "typeInstallationCET",
          branchToRemove: "typeInstallationCETAirExtrait",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeInstallationCET]);

  const carrotageAPrevoir = formsMutableData.carrotageaPrevoir?.value;
  useEffect(() => {
    if (carrotageAPrevoir === 0) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "carrotageAPrevoir",
        })
      );
    }

    if (carrotageAPrevoir === 1) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "carrotageAPrevoir",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrotageAPrevoir]);
};
