// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

interface IProps {
  userToken: string | null;
  building_id: string | null;
  isCalepinageRelevant: boolean | null;
  commentCalepinage: string | number | null;
  numberOfPanels?: number | string | null;
}

export const usePostCalepinageRelevant = () => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const postCalepinageRelevant = async ({
    userToken,
    building_id,
    isCalepinageRelevant,
    commentCalepinage,
    numberOfPanels,
  }: IProps): Promise<void> => {
    setErrorMessage("");

    const body = {
      building_id,
      isCalepinageRelevant,
      commentCalepinage,
      numberOfPanels,
    };

    await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/vitee/postCalepinageRelevant`,
      body,
      // For now the headers conig is useless as we don't authenticate yet Vitee users with Eero
      { headers: { Authorization: `Bearer ${userToken}` } }
    );
  };

  return {
    postCalepinageRelevant,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
