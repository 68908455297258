// Interfaces import
import { IStandardFormPages } from "../../interfaces/generalInterfaces";
import { IPhotovoltaiqueFormDataInterface } from "../../interfaces/photovoltaiqueFormInterfaces";

// images import
import houseMeasuresSchema from "../../assets/images/houseMeasuresSchema.svg";

export const selectPanQuestions: IStandardFormPages[] = [
  {
    categoryName: "Informations générales",
    questions: [
      {
        type: "PickUniqueCard",
        name: "typedeSupport",
        branchs: ["main"],
        title: "Type de fixation :",
        cards: [629950000, 629950001, 629950002],
        pictureNeeded: true,
        isAnswerRequired: true,
      },
      {
        type: "PickUniqueCard",
        name: "implantationduPan",
        branchs: ["main"],
        title: "Implantation du pan :",
        cards: [
          629950000, 629950001, 629950002, 629950003, 629950004, 629950005,
        ],
        isAnswerRequired: true,
      },
      {
        type: "PickUniqueCard",
        name: "orientation",
        branchs: ["main"],
        title: "Orientation du pan :",
        cards: [
          629950000, 629950001, 629950002, 629950003, 629950004, 629950005,
          629950006, 629950007,
        ],
        isAnswerRequired: true,
      },
      {
        type: "PickUniqueCard",
        name: "natureDuSupport",
        branchs: ["main"],
        title: "Nature du support :",
        cards: [
          629950000, 629950001, 629950002, 629950003, 629950004, 629950005,
          629950006, 629950007, 629950008, 629950009, 629950010, 629950011,
        ],
        isAnswerRequired: true,
      },
      {
        type: "InputNumber",
        name: "surfaceDisponible",
        branchs: ["panAuSol"],
        title: "Surface disponible (m²) :",
        placeholder: "ex : 50",
      },
      {
        type: "PickMultipleCards",
        name: "masqueSolaires",
        branchs: ["main"],
        title: "Nature du masque solaire (choix multiple) :",
        cards: [629950000, 629950001, 629950002, 629950003],
      },
      {
        type: "PickUniqueCard",
        name: "elagageaPrevoir",
        branchs: ["masqueSolaireArbre"],
        title: "Un élagage est-il à prévoir ?",
        cards: [0, 1],
        isAnswerRequired: true,
      },

      {
        type: "InputText",
        name: "commentairePartieSolMasque",
        branchs: ["main"],
        title: "Commentaire installation du pan :",
      },
    ],
  },
  {
    categoryName: "Mesures",
    image: {
      imgSrc: houseMeasuresSchema,
      imgAlt: "",
      imgWidth: 328,
      imgHeight: 247,
    },
    questions: [
      {
        type: "InputNumber",
        name: "hauteurdelaToitureGoutiere",
        branchs: ["panSupportToiture"],
        preTitleIndication: { text: "A", backgroundColor: "#46d1ca" },
        title: "Hauteur de la toiture jusqu'à la gouttière (m) :",
        placeholder: "ex : 50",
        isAnswerRequired: true,
      },
      {
        type: "InputNumber",
        name: "hauteurdelaToitureFaitage",
        branchs: ["panSupportToiture"],
        preTitleIndication: { text: "B", backgroundColor: "#ed8c00" },
        title: "Hauteur de la toiture jusqu'au faîtage (m) :",
        placeholder: "ex : 50",
        isAnswerRequired: true,
      },
      {
        type: "InputNumber",
        name: "distanceduPignon",
        branchs: ["panSupportToiture"],
        preTitleIndication: { text: "C", backgroundColor: "#9860c3" },
        title: "Largeur du pignon (m) :",
        placeholder: "ex : 50",
        isAnswerRequired: true,
      },
      {
        type: "InputNumber",
        name: "longueurdelaFacade",
        branchs: ["panSupportToiture"],
        preTitleIndication: { text: "D", backgroundColor: "#05a358" },
        title: "Longueur de la façace (m) :",
        placeholder: "ex : 50",
        isAnswerRequired: true,
      },
    ],
  },
  {
    categoryName: "Accès toiture",
    questions: [
      {
        type: "PickUniqueCard",
        name: "accessibiliteDesComnbles",
        branchs: ["panSupportToiture"],
        title: "Combles accessibles depuis l'intérieur ?",
        cards: [0, 1],
      },
      {
        type: "PickUniqueCard",
        name: "typeDaccesDesCombles",
        branchs: ["comblesAccessibles"],
        title: "Type d'accès :",
        cards: [629950000, 629950001, 629950002],
      },
      {
        type: "PickUniqueCard",
        name: "etatdelaCharpente",
        branchs: ["panSupportToiture", "comblesAccessibles"],
        title: "Quel est l'état de la charpente ?",
        cards: [629950000, 629950001, 629950002],
        isAnswerRequired: true,
      },
    ],
  },
  {
    categoryName: "Obstacles toiture",
    questions: [
      {
        type: "PickUniqueCard",
        name: "presencedeCheminee",
        branchs: ["panSupportToiture"],
        title: "Présence de cheminées ?",
        cards: [0, 1],
      },
      {
        type: "PickUniqueCard",
        name: "cheminee",
        branchs: ["panSupportToiture", "presenceDeCheminee"],
        title: "Nombre de cheminées :",
        cards: [1, 2, 3, 4, 5, 6],
      },
      {
        type: "PickUniqueCard",
        name: "presencedeVelux",
        branchs: ["panSupportToiture"],
        title: "Présence de velux ?",
        cards: [0, 1],
      },
      {
        type: "PickUniqueCard",
        name: "velux",
        branchs: ["panSupportToiture", "presenceDeVelux"],
        title: "Nombre de velux :",
        cards: [1, 2, 3, 4, 5, 6],
      },
      {
        type: "PickUniqueCard",
        name: "presencedeChienAssis",
        branchs: ["panSupportToiture"],
        title: "Présence de chiens assis ?",
        cards: [0, 1],
      },
      {
        type: "InputNumber",
        name: "chienAssis",
        branchs: ["panSupportToiture", "presenceDeChiensAssis"],
        title: "Chien assis (m²) :",
        placeholder: "ex : 50",
      },
      {
        type: "InputText",
        name: "commentairePartieToiture",
        branchs: ["panSupportToiture"],
        title: "Commentaire toiture :",
      },
    ],
  },
];

export const potentialPanQuestions: IStandardFormPages[] = [
  {
    categoryName: "",
    questions: [
      {
        type: "InputNumber",
        name: "inclinaison",
        branchs: ["main"],
        title: "Inclinaison",
        placeholder: "en degrés °",
        isAnswerRequired: true,
      },
      {
        type: "InputNumber",
        name: "longueurduRampant",
        branchs: ["main"],
        title: "Longueur des rampants",
        placeholder: "en mètres",
      },
      {
        type: "InputText",
        name: "calepinage",
        branchs: ["main"],
        title: "Calepinage des panneaux",
        placeholder: "ex : 1x2 portraits, 2x2 portraits, 2x3 paysage",
        isAnswerRequired: true,
      },
    ],
  },
];

export const potentialTrancheeQuestions: IStandardFormPages[] = [
  {
    categoryName: "",
    questions: [
      {
        type: "PickUniqueCard",
        name: "typedeTerrain",
        branchs: ["trahcheeAPrevoir"],
        title: "Dans quel terrain ?",
        cards: [629950000, 629950001, 629950002],
      },
      {
        type: "InputNumber",
        name: "longueurTranchee",
        branchs: ["trahcheeAPrevoir"],
        title: "Longueur de la tranchée (m)",
        placeholder: "en mètres",
      },
      {
        type: "InputText",
        name: "commentaireTranchee",
        branchs: ["trahcheeAPrevoir"],
        title: "Commentaire sur la tranchée à prévoir :",
        placeholder:
          "Préciser la longueur et la matière dans laquelle sera creusée la tranchée (terre, pierre, asphalte)...",
      },
    ],
  },
];

export const photovoltaiqueData: IPhotovoltaiqueFormDataInterface = {
  formName: "photovoltaique",
  formScreensName: [
    "visiteTechniqueFormPages",
    "extraChargesFormPages",
    "validateCalepinagePages",
  ],
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "1. Caractéristiques pan(s)",
        "2. Potentiel Pan(s)",
        "3. Partie électrique",
        "4. Logistique",
      ],
      pages: [
        [
          {
            categoryName: "Nombre de pan(s)",
            questions: [
              {
                type: "SelectPans",
                name: "nombredePantoEquip",
                branchs: ["main"],
                title: "Nombre de pans à équiper :",
                cards: [1, 2, 3, 4],
                defaultValue: 1,
                subQuestions: selectPanQuestions, // This key is not used but it allows to underline the link with selectPanQuestions
              },
            ],
          },
        ],
        [
          {
            categoryName: "Potentiel(s) pan(s)",
            questions: [
              {
                type: "PotentialPans",
                name: "potentialPans",
                branchs: ["main"],
                title: "",
                subQuestions: potentialPanQuestions, // This key is not used but it allows to underline the link with potentialPanQuestions
              },
            ],
          },
        ],
        [
          {
            categoryName: "Partie électrique",
            infoBox: (
              <>
                Pensez à prévenir les clients si une augmentation de la
                puissance souscrite est à réaliser auprès de son fournisseur
                d’énergie. Précisez en commentaire la bonne prise en compte de
                cette information par le client.
              </>
            ),
            questions: [
              {
                type: "PickUniqueCard",
                name: "typeAlimentation",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Type d'alimentation électrique :",
                cards: [629950000, 629950001],
                isAnswerRequired: true,
              },
              {
                type: "PickUniqueCard",
                name: "puissanceSouscrite",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Puissance souscrite :",
                cards: [
                  629950000, 629950001, 629950002, 629950004, 629950003,
                  629950005, 629950006, 629950007,
                ],
                isAnswerRequired: true,
              },
              {
                type: "PickUniqueCard",
                name: "typeCompteur",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Type de compteur :",
                cards: [629950000, 629950001, 629950002],
              },
              {
                type: "PickUniqueCard",
                name: "emplacementCompteur",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Emplacement du compteur :",
                cards: [629950000, 629950001, 629950002],
              },
              {
                type: "PickUniqueCard",
                name: "emplacementTableauElec",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Emplacement du tableau électrique :",
                cards: [629950000, 629950001],
              },
              {
                type: "InputNumber",
                name: "distanceOnduleur",
                branchs: ["main"],
                title: "Distance entre l'onduleur et les modules (m) :",
                placeholder: "ex : 50",
              },
              {
                type: "InputText",
                name: "emplacementDelaPartie",
                branchs: ["main"],
                title: "Emplacement de l'onduleur :",
                placeholder: "ex : exemple emplacement",
              },
              {
                type: "PickUniqueCard",
                name: "presencedeFourreauxExterieurs",
                branchs: ["main"],
                title: "Présence de fourreaux extérieurs utilisables ?",
                cards: [0, 1],
              },
              {
                type: "PickUniqueCard",
                name: "combiendeFourreaux",
                branchs: ["presenceFourreauxExterieurs"],
                title: "Combien ?",
                cards: [1, 2, 3, 4, 5, 6],
              },
              {
                type: "InputText",
                name: "commentairePartieElectrique",
                branchs: ["main"],
                title: "Commentaire partie électrique :",
                placeholder:
                  "ex : emplacement compteur proche d'une arrivée d'eau",
              },
            ],
          },
        ],
        [
          {
            categoryName: "Logistique",
            questions: [
              {
                type: "PickUniqueCard",
                name: "portanceWifi",
                branchs: ["main"],
                title: "Portance réseau WIFI :",
                cards: [629950000, 629950001],
              },
              {
                type: "PickUniqueCard",
                name: "planDisponible",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Les plans sont-ils disponibles :",
                cards: [0, 1],
              },
              {
                type: "PickUniqueCard",
                name: "accessibilityMax",
                branchs: ["main"],
                pictureNeeded: true,
                title: "Accessibilité maximum par camion :",
                cards: [629950000, 629950001, 629950002],
              },
              {
                type: "InputText",
                name: "commentairePartieLogistique",
                branchs: ["main"],
                title: "Commentaire partie logistique :",
                placeholder: "ex : largeur portail 230 cm",
              },
            ],
          },
        ],
      ],
    },
    extraChargesFormPages: {
      steps: ["5. Spécificités du chantier"],
      pages: [
        [
          {
            categoryName: "Tranchée(s)",
            infoBox: (
              <>
                Une trancheuse est nécessaire pour la/les{" "}
                <strong>tranchée(s) dans la terre</strong>. <br /> Une minipelle
                est nécessaire pour la/les{" "}
                <strong>tranchée(s) dans le béton ou le remblais</strong>.
              </>
            ),
            questions: [
              {
                type: "PickUniqueCard",
                name: "trancheeaPrevoir",
                branchs: ["main"],
                title: "Une tranchée est-elle à prévoir :",
                cards: [0, 1],
              },
              {
                type: "PickUniqueCard",
                name: "nombreTrancheeAPrevoir",
                branchs: ["trahcheeAPrevoir"],
                title: "Combien de tranchée(s) à prévoir ?",
                cards: [1, 2, 3, 4],
              },
              {
                type: "PotentialTranchees",
                name: "potentialTranchees",
                branchs: ["trahcheeAPrevoir"],
                title: "",
                subQuestions: potentialTrancheeQuestions, // This key is not used but it allows to underline the link with potentialPanQuestions
              },
            ],
          },
          {
            categoryName: "Carrotage",
            questions: [
              {
                type: "PickUniqueCard",
                name: "carrotageaPrevoir",
                branchs: ["main"],
                title: "Un carottage sur plus de 60 cm est-il à prévoir ?",
                cards: [0, 1],
              },
              {
                type: "PickUniqueCard",
                name: "nombreDeCarrotage",
                branchs: ["carrotageAPrevoir"],
                title: "Combien de carottage à prévoir ? ",
                cards: [1, 2, 3, 4],
              },
            ],
          },
          {
            categoryName: "Accès",
            infoBox: (
              <>
                Une nacelle est à prévoir systématiquement si le bas de toiture
                est <strong>supérieur à 10 mètres</strong>
              </>
            ),
            questions: [
              {
                type: "PickUniqueCard",
                name: "nacelleaPrevoir",
                branchs: ["main"],
                title: "Une nacelle est-elle à prévoir ?",
                cards: [0, 1],
              },
              {
                type: "PickUniqueCard",
                name: "echelleaPrevoir",
                branchs: ["main"],
                title: "Une grande échelle est-elle à prévoir ?",
                cards: [0, 1],
              },
            ],
          },
          {
            categoryName: "Charpente",
            questions: [
              {
                type: "PickUniqueCard",
                name: "renforcementCharpenteaPrevoir",
                branchs: ["main"],
                title: "Un renforcement de la charpente est-il à prévoir ?",
                cards: [0, 1],
              },
              {
                type: "InputText",
                name: "commentaireEtatCharpente",
                branchs: ["commentaireEtatCharpente"],
                title: "Commentaire sur l'état de la charpente :",
                placeholder: "Informations sur la charpente",
              },
            ],
          },
          {
            categoryName: "Bacs à lester",
            infoBox: (
              <>
                Des bacs à lester sont systématiquement à prévoir si les
                panneaux sont <strong>au sol ou sur une toiture plate</strong>.
              </>
            ),
            questions: [
              {
                type: "PickUniqueCard",
                name: "bacsALesteraPrevoir",
                branchs: ["main"],
                title: "Des bacs à lester sont à prévoir ?",
                cards: [0, 1],
              },
              {
                type: "InputNumber",
                name: "nombreBacsALester",
                branchs: ["bacsALesteraPrevoir"],
                title: "Combien de bacs à lester ?",
                placeholder: "ex : 2",
              },
            ],
          },
          {
            categoryName: "Autres frais supplémenaires ?",
            questions: [
              {
                type: "InputText",
                name: "commentaireFraisSupplementaires",
                branchs: ["main"],
                title: "Commentaire frais supplémentaires :",
                placeholder: "commentaire additionnel",
              },
              {
                type: "InputNumber",
                name: "montantEstimeFraisSupplementaires",
                branchs: ["main"],
                title: "Montant estimé (€) :",
                placeholder: "ex : 50",
              },
            ],
          },
        ],
      ],
    },
    validateCalepinagePages: {
      steps: ["6. Calepinage automatique proposé"],
      pages: ["ValidateCalepinage"],
    },
  },
};
