import "./pickMultipleCards.scss";

// Components import
import { PickCard } from "@web/shared/dist/components/Old/FormComponents/PickCard/PickCard";
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Service import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setPVPickMultipleCardsAction } from "../../../../redux/appActions";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../../services/Forms/checkTypes";
import { valueToLabelDictionnary } from "../../../../services/Forms/valueToLabelDictionnary";

// Interfaces import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  userAnswer?: string | string[] | number;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const PickMultipleCards = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const formsMutableData = useAppSelector((state) => state.formsMutableData);

  const userAnswer =
    (() => {
      // Standard case when we need to selected the input value at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return formsMutableData[formName][questionData.name]?.value;
      }

      // Case when we need to get the input value from the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].panInformations?.[answerIndex || 0]?.[
            questionData.name
          ]?.value ?? ""
        );
      }
      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].potentialTranchees?.[answerIndex || 0]?.[
            questionData.name
          ]?.value || ""
        );
      }
    })() ?? "";

  const dispatch = useAppDispatch();
  // Event handler
  const onCardClick = (textCard: string | number) => {
    dispatch(
      // We check if the question expects a string answer. If it is the case we can create or update the user's answer state.
      setPVPickMultipleCardsAction({
        textCard,
        formName: formName,
        formQuestionName: questionData.name,
        answerIndex: answerIndex,
        visitId,
      })
    );
  };

  return (
    <div className="pickMultipleCards">
      <div className="pickMultipleCards__Title">
        <p>{questionData.title}</p>
      </div>
      <div>
        <div className={"pickMultipleCards__pickCards"}>
          {questionData.cards?.map((CardValue, index) => {
            const cardText = valueToLabelDictionnary(
              questionData.name,
              CardValue
            );
            return (
              <div className={"pickMultipleCards__Card"} key={index}>
                <PickCard
                  text={typeof cardText === "string" ? cardText : ""}
                  isSelected={
                    Array.isArray(userAnswer) && userAnswer.includes(CardValue)
                      ? true
                      : false
                  }
                  onClick={() => onCardClick(CardValue)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {questionData.pictureNeeded && (
        <div className={`pickMultipleCards__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionData={questionData}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
