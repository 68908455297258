import "./singleChoiceBtnContainer.scss";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";

import { ChoixUnique } from "@web/shared/dist/components/DesignSystem/Boutons/ChoixUnique/ChoixUnique";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

// Local interface declaration
interface IOption {
  label: string;
  value: string;
}

interface Props {
  title: string | JSX.Element;
  optionList: IOption[];
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onCardClick?: (cardValue: string) => void;
  onUploadPicture?: (pictureUrl: string) => void;
  gap?: boolean;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const SingleChoiceBtnContainer = ({
  title,
  formName,
  optionList,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  onCardClick,
  onUploadPicture,
  gap = true,
  wallIndex,
  pointSingulierName,
}: Props) => {
  return (
    <div className={`singleChoiceBtnContainer`}>
      <section
        className={`singleChoiceBtnContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"singleChoiceBtnContainer__QuestionTitle"}>
          {title}&nbsp;:
        </p>

        <ChoixUnique
          color={"#ED8C00"}
          gap={gap}
          optionsList={optionList}
          onSelect={(option: IOption) => {
            onCardClick && onCardClick(option.value);
          }}
          optionSelected={optionList.find(
            (option) => option.value === userAnswer?.value?.toString()
          )}
        />
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
