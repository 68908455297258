import "./resetPasswordForm.scss";

// Libraries import
import React, { useState, KeyboardEvent } from "react";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";

// Components import
import { ButtonConnect } from "../../../components/ButtonConnect/ButtonConnect";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Services import
import { useResetClientPassword } from "../../../services/ResetPassword/useResetClientPassword";
import { useIsResetPasswordInputValid } from "../../../services/ResetPassword/useIsResetPasswordInputValid";

export const ResetPasswordForm: React.FC = () => {
  // Custom hooks calls
  const {
    sendResetClientPasswordRequest,
    isLoading,
    errorMessage: requestErrorMessage,
  } = useResetClientPassword();
  const { isResetPasswordInputValid, errorMessage: inputsErrorMessage } =
    useIsResetPasswordInputValid();

  // Retrieve data from URL
  const search = useLocation().search;
  const resetCode = new URLSearchParams(search).get("resetCode");

  // States initialization
  const [userPassword, setUserPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // Events handlers
  const onUserPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserPassword(event.target.value);
  };

  const onConfirmPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  // On click on the "Réinitialiser" button, check user inputs and send reset password request. Debounce function : max 1 call every 350ms.
  const onClickConnectButton = debounce(
    async () => {
      resetCode &&
        userPassword &&
        sendResetClientPasswordRequest(resetCode, userPassword);
    },
    350,
    { leading: true }
  );

  // On press on the "Enter" key, check user inputs and send password request. Debounce function : max 1 call every 350ms.
  const handleValidateOnPress = debounce(
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        isResetPasswordInputValid(resetCode, userPassword, confirmPassword) &&
          resetCode &&
          userPassword &&
          (await sendResetClientPasswordRequest(resetCode, userPassword));
      }
    },
    350,
    { leading: true }
  );

  return (
    <div className="reset-password-form">
      {isLoading ? (
        <div className="loading-container">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <div className="reset-password-inputs">
            <div className="reset-password-notice">
              <p className="reset-password-user-email">Bonjour</p>
            </div>
            <div className="reset-password-input">
              <PasswordInput
                value={userPassword}
                onChange={onUserPasswordInputChange}
                onKeyPress={handleValidateOnPress}
                placeholder="Mot de passe"
              />
            </div>
            <div className="reset-password-input">
              <PasswordInput
                value={confirmPassword}
                onChange={onConfirmPasswordInputChange}
                onKeyPress={handleValidateOnPress}
                placeholder="Confirmez votre mot de passe"
              />
            </div>
          </div>
          <div className="reset-password-message">
            {(inputsErrorMessage || requestErrorMessage) && (
              <ErrorMessage
                errorMessage={inputsErrorMessage || requestErrorMessage}
              />
            )}
          </div>
          <div className="reset-password-buttons">
            <ButtonConnect
              name="Réinitialiser"
              onClick={onClickConnectButton}
            />
          </div>
        </>
      )}
    </div>
  );
};
