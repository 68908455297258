import "./headerMenu.scss";

// Libraries import
import React, { FC, useState } from "react";

// Component import
import { HeaderMenuDropdown } from "../HeaderMenuDropdown/HeaderMenuDropdown";

// Images import
import { ReactComponent as BurgerMenuIcon } from "../../../assets/images/burger_menu_icon_1.svg";

export const HeaderMenu: FC = () => {
  const [isMenuDisplayed, setIsMenuDisplayed] = useState<boolean>(false);

  // Event handlers
  const toggleMenu = () => {
    setIsMenuDisplayed((state) => !state);
  };

  return (
    <div className="header-menu" data-testid="header-menu">
      <HeaderMenuDropdown
        toggleMenu={toggleMenu}
        isMenuDisplayed={isMenuDisplayed}
      />
      <p>Menu</p>
      <button onClick={toggleMenu} data-testid="burger-menu-icon">
        <BurgerMenuIcon />
      </button>
    </div>
  );
};
