// Libraries import
import { useState } from "react";

// Services import
import { passwordRegex } from "../../constants/validationPaterns";

// Interface delcaration
interface IFunctionReturn {
  isResetPasswordInputValid: (
    invitationCode: string | null,
    userPassword: string,
    confirmPassword: string
  ) => boolean;
  errorMessage: string;
}

// useIsResetPasswordInputValid : return tools to check reset password inputs format
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function isResetPasswordInputValid
//     Function to check reset password input format
// errorMessage : string
//      Error message if format invalid

export const useIsResetPasswordInputValid = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  // isResetPasswordInputValid : function to check reset password inputs format
  // Parameters
  // ----------
  // resetCode : string
  //       reset code generated by the CRM
  // userPassword : string
  //       Password typed by the user
  // confirmPassword : string
  //       Confirmation password typed by the user
  //
  // Returns
  // ----------
  // Boolean : true if valid format, false otherwise
  const isResetPasswordInputValid = (
    resetCode: string | null,
    userPassword: string,
    confirmPassword: string
  ): boolean => {
    setErrorMessage("");
    if (userPassword && confirmPassword) {
      if (resetCode) {
        if (passwordRegex.test(userPassword)) {
          if (userPassword === confirmPassword) {
            return true;
          } else {
            setErrorMessage("Vos mots de passe doivent être identiques.");
          }
        } else {
          setErrorMessage(
            "Le mot de passe doit contenir minimum 8 caractères dont 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial."
          );
        }
      } else {
        setErrorMessage("Le lien n'est pas valide.");
      }
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
    return false;
  };

  return { isResetPasswordInputValid, errorMessage };
};
