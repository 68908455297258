//Lib import
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Link,
} from "@react-pdf/renderer";

// Components
import { PdfLayout } from "../components/Pdf/PdfLayout/PdfLayout";
import { PdfHeader } from "../components/Pdf/PdfHeader/PdfHeader";
import { PdfPagination } from "../components/Pdf/PdfPagination/PdfPagination";

// Interfaces import
import {
  IUserProfileInfo,
  IITEFormData,
  IVisiteTechniqueInfo,
} from "../interfaces/generalInterfaces";
import {
  IITEOuvrant,
  IITEPointsSinguliers,
  IITEWallAll,
} from "../interfaces/ITEFormInterface";

// Fonts
import DMSansBold from "../assets/pdf/fonts/DMSansBold.ttf";
import DMSansMedium from "../assets/pdf/fonts/DMSansMedium.ttf";
import DMSansRegular from "../assets/pdf/fonts/DMSansRegular.ttf";
import DMSansItalic from "../assets/pdf/fonts/DMSansItalic.ttf";

// Services import
import {
  getsurfaceisoler,
  getSurfaceCrepiSeul,
  getProfilAngle,
  getProfilDeDepart,
  getProfilDeCouronnement,
  getProfilGoutteDeau,
  getFlechetteDeRenfort,
  getAppuiFenetre,
  getAppuiFenetreProfond,
} from "../services/Forms/ITE/resultatPageCalculation";
import { valueToLabelDictionnary } from "../services/Forms/valueToLabelDictionnary";
import { pointsSinguliersDictionnary } from "../services/Forms/ITE/pointsSinguliersDictionnary";

// Register font
Font?.register({
  family: "DMSansBold",
  src: DMSansBold,
  format: "truetype",
});
Font?.register({
  family: "DMSansRegular",
  src: DMSansRegular,
  format: "truetype",
});
Font?.register({
  family: "DMSansMedium",
  src: DMSansMedium,
  format: "truetype",
});
Font?.register({
  family: "DMSansItalic",
  src: DMSansItalic,
  format: "truetype",
});

// Create styles
const styles = StyleSheet?.create({
  page: {
    backgroundColor: "#F8F8F8",
    position: "relative",
    height: "100%",
    width: "100%",
    paddingBottom: 40,
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 130,
    objectFit: "contain",
  },
  title: {
    fontSize: 24,
    fontFamily: "DMSansBold",
  },
  containerInfos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "DMSansRegular",
    color: "#44474F",
    marginBottom: 20,
  },
  containerInfos__left: {},
  containerInfos__left__title: {
    fontSize: 16,
    fontFamily: "DMSansBold",
  },
  containerInfos__left__normal: {
    fontSize: 12,
  },

  containerInfos__right: {
    fontSize: 12,
  },
  containerInfos__right__row: {
    display: "flex",
    flexDirection: "row",
  },
  containerInfos__right__gras: {
    fontFamily: "DMSansBold",
  },
  containerBandeau: {
    fontFamily: "DMSansRegular",

    fontSize: 12,
    color: "white",
    backgroundColor: "#0073E0",
    padding: 10,
    marginBottom: 20,
  },
  containerResultats: {
    marginTop: 20,
    fontFamily: "DMSansRegular",
  },
  containerResultats__title: {
    fontSize: 14,
    fontFamily: "DMSansBold",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  containerResultats__item: {
    fontSize: 8,
    color: "#44474F",
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    backgroundColor: "white",
    padding: "10 15 0 15",
    marginBottom: 10,
  },
  containerResultats__itemSpecial: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 8,
    color: "#44474F",
    borderRadius: 4,
    backgroundColor: "white",
    padding: "10 0 ",
    marginBottom: 10,
  },
  containerResultats__itemSpecial__container: {
    width: "100%",
  },
  containerResultats__itemSpecial__stick: {
    width: 1,
    height: "auto",
    backgroundColor: "#50545F",
    margin: "0 20 10",
  },

  containerResultats__itemSpecial_row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
    padding: "0 10",
  },

  containerResultats__itemSpecial_row__bonus: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
    backgroundColor: "#FFF2D6",
    padding: "0 10",
  },
  containerResultats__itemSpecial__left: {
    color: "#44474F",
    width: "70%",
  },
  containerResultats__itemSpecial__middle: {
    color: "#7C7F87",
    width: "10%",
  },
  containerResultats__itemSpecial__right: {
    fontFamily: "DMSansBold",
    color: "black",
    width: "20%",
  },
  containerResultats__item_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 10,
  },
  containerResultats__item__left: {
    color: "#44474F",
    width: "30%",
    paddingRight: "3%",
  },
  containerResultats__item__middle: {
    color: "#7C7F87",
    width: "5%",
  },
  containerResultats__item__right: {
    fontFamily: "DMSansBold",
    color: "black",
  },
  containerImage: {
    margin: "30 0 10",
    flexWrap: "wrap",
  },
  containerImage__item: {
    fontSize: 8,
    color: "#50545F",
    marginBottom: 15,
  },
  containerImage__image: {
    height: "330px",
    width: "100%",
    objectFit: "cover",
  },
  containerImage__image2: {
    height: "185px",
    width: "100%",
    objectFit: "cover",
    marginBottom: 10,
  },
  containerDetail: {},
  containerDetail__walls: {
    backgroundColor: "white",
    padding: "10 15 0 15",
    paddingBottom: 10,
  },
  containerDetail__walls__container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  containerDetail__walls__title: {
    fontSize: 12,
    fontFamily: "DMSansBold",
    marginBottom: 10,
    color: "#44474F",
  },
  containerDetail__walls__text: {
    fontSize: 8,
    color: "#44474F",
    marginBottom: 10,
  },
  containerDetail__walls__textBold: {
    fontSize: 8,
    color: "black",
    marginBottom: 10,
    fontFamily: "DMSansBold",
  },
  container__walls__containerFenetre: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    marginBottom: 10,
  },
  container__walls__fenetre: {
    backgroundColor: "#F3F8FC",
    padding: 10,
    width: "33.333%",
    borderRadius: 4,
    marginTop: 10,
  },
  container__walls__fenetre__title: {
    fontSize: 10,
    fontFamily: "DMSansBold",
    color: "#44474F",

    marginBottom: 10,
  },

  container__walls__containerDoors: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
  },
  containerLesPhotos: {
    backgroundColor: "white",
    padding: "10",
  },
  containerLesPhotos__title: {
    fontSize: 10,
    fontFamily: "DMSansBold",
    color: "#44474F",
    marginBottom: 10,
  },
  containerLesPhotos__containerImages: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    flexWrap: "wrap",
  },
  containerLesPhotos__containerImages__image: {
    width: 100,
    height: 70,
    objectFit: "cover",
  },
  containerPointSingulier: {
    backgroundColor: "white",
    marginTop: 10,
  },
  containerPointSingulier__containerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4px 0",
    marginTop: 10,
  },
  containerPointSingulier__title: {
    fontSize: 10,
    fontFamily: "DMSansBold",
    color: "#191B1E",
  },
  containerPointSingulier__titleSuccess: {
    fontSize: 8,
    fontFamily: "DMSansBold",
    color: "#05A358",
    backgroundColor: "#E1F4EB",
    padding: "4px 8px",
    marginLeft: 10,
    borderRadius: 10,
  },
  containerPointSingulier__titleFailed: {
    fontSize: 8,
    fontFamily: "DMSansBold",
    color: "#E02819",
    backgroundColor: "#F4B3AE",
    padding: "4px 8px",
    marginLeft: 10,
    borderRadius: 10,
  },
  containerPointSingulier__containerImages: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
  containerPointSingulier__containerImagesSpecial: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: "#F3F8FC",
    padding: "10 15 0 15",
  },
  containerPointSingulier__containerImages__image: {
    width: 100,
    height: 70,
    marginBottom: 10,
    objectFit: "cover",
  },
});

export interface IPDFITE {
  userProfileInfo: IUserProfileInfo;
  visiteTechniqueInfo: IVisiteTechniqueInfo;
  ITEFormMutableData: IITEFormData;
  extraChargesITEAReposerWithoutDoublon: (IITEPointsSinguliers | IITEOuvrant)[];
  picturesFromCRM?:
    | {
        fileName: string;
        url: string;
      }[]
    | null;
}

// Create Document Component
export const PDFITE = ({
  visiteTechniqueInfo,
  userProfileInfo,
  ITEFormMutableData,
  picturesFromCRM,
  extraChargesITEAReposerWithoutDoublon,
}: IPDFITE) => {
  const dateTimeUTC = new Date(visiteTechniqueInfo.dateVisite || "");
  const nombreMursIsoler = ITEFormMutableData.walls?.filter(
    (wall) => wall.actionssurlemur?.value === "629950000"
  ).length;

  const nombreDoorsIsoler = ITEFormMutableData.doors?.filter((door) =>
    ITEFormMutableData.walls?.find((wall) => {
      return (
        wall.murId?.value === door.murId?.value &&
        wall.actionssurlemur?.value === "629950000"
      );
    })
  ).length;

  const nombreWindowsIsoler = ITEFormMutableData.windows?.filter((window) =>
    ITEFormMutableData.walls?.find((wall) => {
      return (
        wall.murId?.value === window.murId?.value &&
        wall.actionssurlemur?.value === "629950000"
      );
    })
  ).length;

  const nombreMursCrepis = ITEFormMutableData.walls?.filter(
    (wall) => wall.actionssurlemur?.value === "629950001"
  ).length;

  const nombreDoorsCrepis = ITEFormMutableData.doors?.filter((door) =>
    ITEFormMutableData.walls?.find((wall) => {
      return (
        wall.murId?.value === door.murId?.value &&
        wall.actionssurlemur?.value === "629950001"
      );
    })
  ).length;

  const nombreWindowsCrepis = ITEFormMutableData.windows?.filter((window) =>
    ITEFormMutableData.walls?.find((wall) => {
      return (
        wall.murId?.value === window.murId?.value &&
        wall.actionssurlemur?.value === "629950001"
      );
    })
  ).length;

  const surfaceOuvrants = ({
    ITEFormMutableData,
    wall,
  }: {
    ITEFormMutableData?: IITEFormData;
    wall: IITEWallAll;
  }) => {
    let surfaceWindows = 0;
    let surfaceDoors = 0;
    ITEFormMutableData?.windows
      ?.filter((window) => window.murId?.value === wall.murId?.value)
      .forEach((window) => {
        surfaceWindows =
          surfaceWindows +
          Math.round(
            (Number(window?.windowHeigh?.value) || 0) *
              (Number(window?.windowWidth?.value) || 0) *
              100
          ) /
            100;
      });
    ITEFormMutableData?.doors
      ?.filter((door) => door.murId?.value === wall.murId?.value)
      .forEach((door) => {
        surfaceDoors =
          surfaceDoors +
          Math.round(
            (Number(door?.doorHeigh?.value) || 0) *
              (Number(door?.doorWidth?.value) || 0) *
              100
          ) /
            100;
      });
    return Math.round((surfaceWindows + surfaceDoors) * 100) / 100;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PdfLayout>
          <PdfHeader />
          <View style={styles.containerInfos}>
            <View style={styles.containerInfos__left}>
              {visiteTechniqueInfo.customerLastName &&
                visiteTechniqueInfo.customerFirstName && (
                  <Text style={styles.containerInfos__left__title}>
                    {visiteTechniqueInfo.customerLastName +
                      " " +
                      visiteTechniqueInfo.customerFirstName}
                  </Text>
                )}
              {visiteTechniqueInfo.customerStreet ||
                (visiteTechniqueInfo.customerStreet !== " " && (
                  <Text style={styles.containerInfos__left__normal}>
                    {visiteTechniqueInfo.customerStreet}
                  </Text>
                ))}
              {visiteTechniqueInfo.customerPostalCode &&
                visiteTechniqueInfo.customerCity && (
                  <Text style={styles.containerInfos__left__normal}>
                    {visiteTechniqueInfo.customerPostalCode +
                      " " +
                      visiteTechniqueInfo.customerCity}
                  </Text>
                )}
            </View>
            <View style={styles.containerInfos__right}>
              <View style={styles.containerInfos__right__row}>
                <Text style={styles.containerInfos__right__gras}>
                  Auditeur :
                </Text>
                <Text>
                  {" "}
                  Tucoenergie - {userProfileInfo.firstname}{" "}
                  {userProfileInfo.lastname}
                </Text>
              </View>
              <View style={styles.containerInfos__right__row}>
                <Text style={styles.containerInfos__right__gras}>
                  Date de la visite :
                </Text>
                <Text>
                  {" "}
                  {visiteTechniqueInfo.dateVisite
                    ? dateTimeUTC.toLocaleDateString() +
                      " à " +
                      `${dateTimeUTC?.getHours()}h${
                        dateTimeUTC.getMinutes().toLocaleString().length === 1
                          ? "0" + dateTimeUTC.getMinutes()
                          : dateTimeUTC.getMinutes()
                      }`
                    : "date non renseignée"}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.containerBandeau}>
            <Text>
              {visiteTechniqueInfo.products?.map((item) => {
                return item;
              })}
            </Text>
          </View>
          <View style={styles.containerResultats}>
            <View style={styles.containerResultats__title}>
              <Text>les résultats</Text>
            </View>

            <View style={styles.containerResultats__itemSpecial}>
              <View style={styles.containerResultats__itemSpecial__container}>
                <View style={styles.containerResultats__itemSpecial_row__bonus}>
                  <Text style={styles.containerResultats__itemSpecial__left}>
                    Surface à isoler (m²)
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__middle}>
                    |
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__right}>
                    {getsurfaceisoler({ ITEFormMutableData })}
                  </Text>
                </View>

                {ITEFormMutableData.walls && (
                  <View style={styles.containerResultats__itemSpecial_row}>
                    <Text style={styles.containerResultats__itemSpecial__left}>
                      Nombre de mur à isoler
                    </Text>
                    <Text
                      style={styles.containerResultats__itemSpecial__middle}
                    >
                      |
                    </Text>
                    <Text style={styles.containerResultats__itemSpecial__right}>
                      {nombreMursIsoler}
                    </Text>
                  </View>
                )}
                {ITEFormMutableData.doors && (
                  <View style={styles.containerResultats__itemSpecial_row}>
                    <Text style={styles.containerResultats__itemSpecial__left}>
                      Nombre de porte
                    </Text>
                    <Text
                      style={styles.containerResultats__itemSpecial__middle}
                    >
                      |
                    </Text>
                    <Text style={styles.containerResultats__itemSpecial__right}>
                      {nombreDoorsIsoler}
                    </Text>
                  </View>
                )}
                {ITEFormMutableData.windows && (
                  <View style={styles.containerResultats__itemSpecial_row}>
                    <Text style={styles.containerResultats__itemSpecial__left}>
                      Nombre de fenêtre
                    </Text>
                    <Text
                      style={styles.containerResultats__itemSpecial__middle}
                    >
                      |
                    </Text>
                    <Text style={styles.containerResultats__itemSpecial__right}>
                      {nombreWindowsIsoler}
                    </Text>
                  </View>
                )}
              </View>
              <View
                style={styles.containerResultats__itemSpecial__stick}
              ></View>
              <View style={styles.containerResultats__itemSpecial__container}>
                <View style={styles.containerResultats__itemSpecial_row__bonus}>
                  <Text style={styles.containerResultats__itemSpecial__left}>
                    Surface Crépis seul (m²)
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__middle}>
                    |
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__right}>
                    {getSurfaceCrepiSeul({ ITEFormMutableData })}
                  </Text>
                </View>

                <View style={styles.containerResultats__itemSpecial_row}>
                  <Text style={styles.containerResultats__itemSpecial__left}>
                    Nombre de mur crépis seul
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__middle}>
                    |
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__right}>
                    {nombreMursCrepis}
                  </Text>
                </View>
                <View style={styles.containerResultats__itemSpecial_row}>
                  <Text style={styles.containerResultats__itemSpecial__left}>
                    Nombre de porte
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__middle}>
                    |
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__right}>
                    {nombreDoorsCrepis}
                  </Text>
                </View>
                <View style={styles.containerResultats__itemSpecial_row}>
                  <Text style={styles.containerResultats__itemSpecial__left}>
                    Nombre de fenêtre
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__middle}>
                    |
                  </Text>
                  <Text style={styles.containerResultats__itemSpecial__right}>
                    {nombreWindowsCrepis}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.containerResultats__item}>
              {
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Profil d'angle (ml)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {getProfilAngle({ ITEFormMutableData })}
                  </Text>
                </View>
              }
              {
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Somme de la hauteur des façades (m)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {ITEFormMutableData.sommeDeLaHauteurDesFacades?.value}
                  </Text>
                </View>
              }
            </View>
            {
              <View style={styles.containerResultats__item}>
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Profil de départ (ml)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {getProfilDeDepart({ ITEFormMutableData })}
                  </Text>
                </View>
              </View>
            }
            {
              <View style={styles.containerResultats__item}>
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Profil de couronnement (ml)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {getProfilDeCouronnement({ ITEFormMutableData })}
                  </Text>
                </View>
              </View>
            }
            {
              <View style={styles.containerResultats__item}>
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Profil goutte d’eau (ml)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {getProfilGoutteDeau({ ITEFormMutableData })}
                  </Text>
                </View>
              </View>
            }

            {
              <View style={styles.containerResultats__item}>
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Fléchette de renfort (unité)
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {getFlechetteDeRenfort({ ITEFormMutableData })}
                  </Text>
                </View>
              </View>
            }
            <View style={styles.containerResultats__item}>
              <View style={styles.containerResultats__item_row}>
                <Text style={styles.containerResultats__item__left}>
                  Appui fenêtre
                </Text>
                <Text style={styles.containerResultats__item__middle}>|</Text>
                <Text style={styles.containerResultats__item__right}>
                  {getAppuiFenetre({ ITEFormMutableData })}
                </Text>
              </View>
              <View style={styles.containerResultats__item_row}>
                <Text style={styles.containerResultats__item__left}>
                  {`Nombre d'appui fenêtre profond ( > 60 ou 90cm)`}
                </Text>
                <Text style={styles.containerResultats__item__middle}>|</Text>
                <Text style={styles.containerResultats__item__right}>
                  {getAppuiFenetreProfond({ ITEFormMutableData })}
                </Text>
              </View>
            </View>
            {
              <View style={styles.containerResultats__item}>
                <View style={styles.containerResultats__item_row}>
                  <Text style={styles.containerResultats__item__left}>
                    Couleur
                  </Text>
                  <Text style={styles.containerResultats__item__middle}>|</Text>
                  <Text style={styles.containerResultats__item__right}>
                    {ITEFormMutableData?.couleur?.value || "Non spécifiée"}
                  </Text>
                </View>
              </View>
            }
          </View>
        </PdfLayout>
        <PdfPagination />
      </Page>
      <Page size="A4" style={styles.page}>
        <PdfLayout>
          <PdfHeader />
          <View fixed style={styles.containerInfos}>
            <View style={styles.containerInfos__left}>
              {visiteTechniqueInfo.customerLastName &&
                visiteTechniqueInfo.customerFirstName && (
                  <Text style={styles.containerInfos__left__title}>
                    {visiteTechniqueInfo.customerLastName +
                      " " +
                      visiteTechniqueInfo.customerFirstName}
                  </Text>
                )}
              {visiteTechniqueInfo.customerStreet ||
                (visiteTechniqueInfo.customerStreet !== " " && (
                  <Text style={styles.containerInfos__left__normal}>
                    {visiteTechniqueInfo.customerStreet}
                  </Text>
                ))}
              {visiteTechniqueInfo.customerPostalCode &&
                visiteTechniqueInfo.customerCity && (
                  <Text style={styles.containerInfos__left__normal}>
                    {visiteTechniqueInfo.customerPostalCode +
                      " " +
                      visiteTechniqueInfo.customerCity}
                  </Text>
                )}
            </View>
            <View style={styles.containerInfos__right}>
              <View style={styles.containerInfos__right__row}>
                <Text style={styles.containerInfos__right__gras}>
                  Auditeur :
                </Text>
                <Text>
                  {" "}
                  Tucoenergie - {userProfileInfo.firstname}{" "}
                  {userProfileInfo.lastname}
                </Text>
              </View>
              <View style={styles.containerInfos__right__row}>
                <Text style={styles.containerInfos__right__gras}>
                  Date de la visite :
                </Text>
                <Text>
                  {" "}
                  {visiteTechniqueInfo.dateVisite
                    ? dateTimeUTC.toLocaleDateString() +
                      " à " +
                      `${dateTimeUTC?.getHours()}h${
                        dateTimeUTC.getMinutes().toLocaleString().length === 1
                          ? "0" + dateTimeUTC.getMinutes()
                          : dateTimeUTC.getMinutes()
                      }`
                    : "date non renseignée"}
                </Text>
              </View>
            </View>
          </View>
          <View fixed style={styles.containerBandeau}>
            <Text>
              {visiteTechniqueInfo.products?.map((item) => {
                return item;
              })}
            </Text>
          </View>

          <View style={styles.containerDetail}>
            <View style={styles.containerResultats}>
              <View style={styles.containerResultats__title}>
                <Text>L'extérieur</Text>
              </View>
              {ITEFormMutableData.walls?.map((wall, index) => {
                const pointSinguliersAReposer =
                  extraChargesITEAReposerWithoutDoublon.filter(
                    (pointSingulier) => {
                      return pointSingulier?.murId?.value === wall.murId?.value;
                    }
                  );

                const pointSinguliersANePasReposer = [
                  ...(ITEFormMutableData?.pointsSinguliers || []),
                  ...(ITEFormMutableData?.otherPointsSinguliers || []),
                ].filter((pointSingulier) => {
                  return (
                    pointSingulier?.murId?.value === wall.murId?.value &&
                    pointSingulier?.isPointSingulierAReposer?.value === "false"
                  );
                });
                return (
                  <View
                    style={styles.containerDetail__walls}
                    key={wall.murId?.value}
                  >
                    {wall.wallPictures?.pictures?.[0] ? (
                      <Link src={wall.wallPictures?.pictures?.[0]}>
                        <Image
                          style={styles.containerImage__image2}
                          src={wall.wallPictures?.pictures?.[0]}
                        />
                      </Link>
                    ) : null}
                    <Text style={styles.containerDetail__walls__title}>
                      {"Mur n° " + (index + 1)}
                    </Text>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Le mur est :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {valueToLabelDictionnary(
                          "actionssurlemur",
                          wall.actionssurlemur?.value
                        )}
                      </Text>
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Surface du mur :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {wall.surfaceisoler?.value + "m²"}
                      </Text>
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Surface à isoler :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {Number(wall.surfaceisoler?.value) -
                          surfaceOuvrants({
                            ITEFormMutableData,
                            wall,
                          }) +
                          "m²"}
                      </Text>
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Mètres linéaires à comptabiliser pour le profil de
                        départ :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {wall.metreslineairescomptabiliserprofildepart?.value +
                          " mètres linéaires"}
                      </Text>
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Mètres linéaires à comptabiliser pour le profil de
                        couronnement :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {wall.metreslineairesprofilcouronnement?.value +
                          " mètres linéaires"}
                      </Text>
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Nombre de fenêtre :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {
                          ITEFormMutableData?.windows?.filter(
                            (window) =>
                              window.murId?.value === wall.murId?.value
                          ).length
                        }
                      </Text>
                    </View>
                    <View style={styles.container__walls__containerFenetre}>
                      {ITEFormMutableData?.windows
                        ?.filter(
                          (window) => window.murId?.value === wall.murId?.value
                        )
                        .map((window, index) => {
                          return (
                            <View
                              style={styles.container__walls__fenetre}
                              break
                              key={window.ouvrantId?.value}
                            >
                              <Text
                                style={styles.container__walls__fenetre__title}
                              >
                                {"Fenêtre " + (index + 1)}
                              </Text>
                              <View
                                style={styles.containerDetail__walls__container}
                              >
                                <Text
                                  style={styles.containerDetail__walls__text}
                                >
                                  Hauteur :{" "}
                                </Text>
                                <Text
                                  style={
                                    styles.containerDetail__walls__textBold
                                  }
                                >
                                  {window.windowHeigh?.value + "m"}
                                </Text>
                              </View>
                              <View
                                style={styles.containerDetail__walls__container}
                              >
                                <Text
                                  style={styles.containerDetail__walls__text}
                                >
                                  Largeur :{" "}
                                </Text>
                                <Text
                                  style={
                                    styles.containerDetail__walls__textBold
                                  }
                                >
                                  {window.windowWidth?.value + "m"}
                                </Text>
                              </View>
                              <View
                                style={styles.containerDetail__walls__container}
                              >
                                <Text
                                  style={styles.containerDetail__walls__text}
                                >
                                  Appui fenêtre :{" "}
                                </Text>
                                <Text
                                  style={
                                    styles.containerDetail__walls__textBold
                                  }
                                >
                                  {(ITEFormMutableData.pointsSinguliers?.filter(
                                    (pointSingulier) => {
                                      return (
                                        pointSingulier?.ouvrantId?.value ===
                                          window.ouvrantId?.value &&
                                        pointSingulier?.pointSingulierName
                                          ?.value === "appuiFenetreExistant"
                                      );
                                    }
                                  ).length || 0) > 0
                                    ? "OUI"
                                    : "NON"}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                    <View style={styles.containerDetail__walls__container}>
                      <Text style={styles.containerDetail__walls__text}>
                        Nombre de porte :{" "}
                      </Text>
                      <Text style={styles.containerDetail__walls__textBold}>
                        {
                          ITEFormMutableData?.doors?.filter(
                            (door) => door.murId?.value === wall.murId?.value
                          ).length
                        }
                      </Text>
                    </View>
                    <View style={styles.container__walls__containerDoors}>
                      {ITEFormMutableData?.doors
                        ?.filter(
                          (door) => door.murId?.value === wall.murId?.value
                        )
                        .map((door, index) => {
                          return (
                            <View
                              style={styles.container__walls__fenetre}
                              break
                              key={door.ouvrantId?.value}
                            >
                              <Text
                                style={styles.container__walls__fenetre__title}
                              >
                                {"Porte" + (index + 1)}
                              </Text>
                              <View
                                style={styles.containerDetail__walls__container}
                              >
                                <Text
                                  style={styles.containerDetail__walls__text}
                                >
                                  Hauteur :{" "}
                                </Text>
                                <Text
                                  style={
                                    styles.containerDetail__walls__textBold
                                  }
                                >
                                  {door.doorHeigh?.value + "m"}
                                </Text>
                              </View>
                              <View
                                style={styles.containerDetail__walls__container}
                              >
                                <Text
                                  style={styles.containerDetail__walls__text}
                                >
                                  Largeur :{" "}
                                </Text>
                                <Text
                                  style={
                                    styles.containerDetail__walls__textBold
                                  }
                                >
                                  {door.doorWidth?.value + "m"}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                    {(wall?.wallPictures?.pictures?.length || 0) > 0 ? (
                      <View style={styles.containerLesPhotos} wrap={false}>
                        <Text style={styles.containerLesPhotos__title}>
                          Les photos
                        </Text>
                        <View
                          style={styles.containerLesPhotos__containerImages}
                        >
                          {wall.wallPictures?.pictures?.map(
                            (picture, pictureIndex) => {
                              return (
                                <Link src={picture} key={pictureIndex}>
                                  <Image
                                    src={picture}
                                    style={
                                      styles.containerLesPhotos__containerImages__image
                                    }
                                  />
                                </Link>
                              );
                            }
                          )}
                        </View>
                      </View>
                    ) : null}
                    {pointSinguliersAReposer.length > 0 ? (
                      <View
                        style={styles.containerPointSingulier__containerTitle}
                        wrap={false}
                      >
                        <Text style={styles.containerPointSingulier__title}>
                          {"Point(s) singulier(s) du mur " + (index + 1)}
                        </Text>
                        <Text
                          style={styles.containerPointSingulier__titleSuccess}
                        >
                          À reposer par TUCOENERGIE
                        </Text>
                      </View>
                    ) : null}
                    {pointSinguliersAReposer.map((pointSingulier) => {
                      return (
                        <View
                          style={styles.containerPointSingulier}
                          wrap={false}
                          key={pointSingulier?.pointSingulierId?.value}
                        >
                          <View
                            style={styles.containerDetail__walls__container}
                          >
                            <Text style={styles.containerDetail__walls__text}>
                              {(pointsSinguliersDictionnary?.[
                                pointSingulier?.pointSingulierName?.value || ""
                              ]?.title ||
                                pointSingulier?.pointSingulierName?.value) +
                                " : "}
                            </Text>
                            <Text
                              style={styles.containerDetail__walls__textBold}
                            >
                              Oui
                            </Text>
                          </View>
                          <View
                            style={
                              styles.containerPointSingulier__containerImagesSpecial
                            }
                          >
                            <View
                              style={styles.containerDetail__walls__container}
                            >
                              <Text style={styles.containerDetail__walls__text}>
                                Quantité :{" "}
                              </Text>
                              <Text
                                style={styles.containerDetail__walls__textBold}
                              >
                                {pointSingulier?.pointSingulierQuantity?.value}
                              </Text>
                            </View>
                            <View
                              style={
                                styles.containerPointSingulier__containerImages
                              }
                            >
                              {pointSingulier?.pointSingulierPictures?.pictures?.map(
                                (picture, pictureIndex) => {
                                  return (
                                    <Link src={picture} key={pictureIndex}>
                                      <Image
                                        src={picture}
                                        style={
                                          styles.containerPointSingulier__containerImages__image
                                        }
                                      />
                                    </Link>
                                  );
                                }
                              )}
                            </View>
                            <View
                              style={styles.containerDetail__walls__container}
                            >
                              <Text style={styles.containerDetail__walls__text}>
                                Le client souhaite reposer ce(s) point(s)
                                singulier(s) après ITE (donc à réaliser par{" "}
                              </Text>
                              <Text
                                style={styles.containerDetail__walls__textBold}
                              >
                                TUCOENERGIE
                              </Text>
                              <Text style={styles.containerDetail__walls__text}>
                                ).
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    })}
                    {pointSinguliersANePasReposer.length > 0 ? (
                      <View
                        style={styles.containerPointSingulier__containerTitle}
                      >
                        <Text style={styles.containerPointSingulier__title}>
                          {"Point(s) singulier(s) du mur " + (index + 1)}
                        </Text>
                        <Text
                          style={styles.containerPointSingulier__titleFailed}
                        >
                          À ne pas reposer par TUCOENERGIE
                        </Text>
                      </View>
                    ) : null}
                    {pointSinguliersANePasReposer.map((pointSingulier) => {
                      return (
                        <View
                          style={styles.containerPointSingulier}
                          key={pointSingulier?.pointSingulierId?.value}
                        >
                          <View
                            style={styles.containerDetail__walls__container}
                          >
                            <Text style={styles.containerDetail__walls__text}>
                              {(pointsSinguliersDictionnary?.[
                                pointSingulier?.pointSingulierName?.value || ""
                              ]?.title ||
                                pointSingulier?.pointSingulierName?.value) +
                                " : "}
                            </Text>
                            <Text
                              style={styles.containerDetail__walls__textBold}
                            >
                              Oui
                            </Text>
                          </View>
                          <View
                            style={
                              styles.containerPointSingulier__containerImagesSpecial
                            }
                          >
                            <View
                              style={styles.containerDetail__walls__container}
                            >
                              <Text style={styles.containerDetail__walls__text}>
                                Quantité :{" "}
                              </Text>
                              <Text
                                style={styles.containerDetail__walls__textBold}
                              >
                                {pointSingulier?.pointSingulierQuantity?.value}
                              </Text>
                            </View>
                            <View
                              style={
                                styles.containerPointSingulier__containerImages
                              }
                            >
                              {pointSingulier?.pointSingulierPictures?.pictures?.map(
                                (picture, pictureIndex) => {
                                  return (
                                    <Link src={picture} key={pictureIndex}>
                                      <Image
                                        src={picture}
                                        style={
                                          styles.containerPointSingulier__containerImages__image
                                        }
                                      />
                                    </Link>
                                  );
                                }
                              )}
                            </View>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>
        </PdfLayout>
        <PdfPagination />
      </Page>
      {(picturesFromCRM?.length || 0) > 0 && (
        <Page size="A4" style={styles.page}>
          <PdfLayout>
            <PdfHeader />
            <View>
              {picturesFromCRM?.map((pictureInfo, pictureIndex) => {
                return (
                  <View style={styles.containerImage__item} key={pictureIndex}>
                    <Link src={pictureInfo.url}>
                      <Image
                        style={styles.containerImage__image}
                        src={pictureInfo.url}
                      />
                    </Link>
                    <Text>{pictureInfo.fileName}</Text>
                  </View>
                );
              })}
            </View>
          </PdfLayout>

          <PdfPagination />
        </Page>
      )}
    </Document>
  );
};
