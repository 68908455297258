import "./buttonArrowBack.scss";
import ArrowLeft from "../../assets/images/arrow-left-black.svg";

export const ButtonArrowBack = () => {
  return (
    <button className="buttonArrowBack" onClick={() => {}}>
      <img src={ArrowLeft} alt="arrow-back" />
    </button>
  );
};
