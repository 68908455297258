import "./RequiredQuestionsComponent.scss";

export const RequiredQuestionsComponent = ({
  missingRequiredQuestions,
}: {
  missingRequiredQuestions: string[];
}) => {
  return (
    <div className="RequiredQuestionsComponent">
      <h3>
        Il manque des réponses à certaines questions pour finir votre visite
        technique.
      </h3>
      <p>Voici la liste des réponses manquantes :</p>
      <ul>
        {missingRequiredQuestions.map((missingQuestion, index) => {
          return (
            <li key={index}>
              {missingQuestion}
              {index + 1 === missingRequiredQuestions.length ? "." : " ;"}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
