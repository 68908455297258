import "./TextAreaContainer.scss";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { TextArea } from "@web/shared/dist/components/DesignSystem/Inputs/TextArea/TextArea";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

interface Props {
  title: string;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  onUploadPicture?: (pictureUrl: string) => void;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const TextAreaContainer = ({
  title,
  formName,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  onChange,
  placeholder,
  onUploadPicture,
  wallIndex,
  pointSingulierName,
}: Props) => {
  return (
    <div className="TextAreaContainer">
      <section
        className={`TextAreaContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <TextArea
          inputLableText={title}
          inputLableHidden={false}
          value={userAnswer?.value?.toString() ?? undefined}
          onChange={(e) => onChange && onChange(e.currentTarget.value)}
          placeholder={placeholder}
        />
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
