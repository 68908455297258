import styles from "./logo.module.scss";
import logo from "../../assets/images/main-logo.svg";
import logoWhite from "../../assets/images/main-logo-white.svg";

// Local interface declaration
interface Props {
  color?: string;
}

export const Logo = ({ color = "grey" }: Props) => {
  return (
    <div className={styles.logo}>
      {/* eslint-disable */}
      {color !== "grey" ? (
        <img src={logoWhite} alt="Tucoenergie logo" height={46} width={261} />
      ) : (
        <img src={logo} alt="Tucoenergie logo" height={46} width={261} />
      )}
      {/* eslint-enable */}
    </div>
  );
};
