import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../../styles/variables";

// Local interface declaration
export interface NumberInputComponentIpadProps {
  value?: number | string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  id?: string;
  placeholder?: string;
}

const Input = styled.input`
  width: 100%;
  height: 3rem;
  font-size: 1.1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${stylesVariables.StableGrayLight04};
  padding: 5px 10px;
  background-color: ${stylesVariables.MainBackgroundColor};
  font-family: "PulpDisplay";
  &::placeholder {
    font-weight: 200;
    font-size: 1.1rem;
    font-family: "DM Sans";
    color: ${stylesVariables.StableGrayLight03};
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  flex: 1;
  max-width: 30rem;
`;

export const NumberInputComponentIpad = ({
  onChange,
  value,
  name,
  id,
  placeholder,
}: NumberInputComponentIpadProps) => {
  return (
    <InputWrapper>
      <InputContainer>
        <Input
          type="number"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </InputContainer>
    </InputWrapper>
  );
};
