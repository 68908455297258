// import { useEffect, useState } from "react";
import "./formPagesITE.scss";

// Interfaces import
import { IITEFormData } from "../../../../interfaces/generalInterfaces";
import { LineDividedBlock } from "./Components/LineDividedBlock";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";
import { TooltipInfoForm } from "../../../../components/TooltipInfoForm/TooltipInfoForm";
import { InputContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/InputContainer/InputContainer";
import { useAppDispatch } from "../../../../redux/store/hook";
import { setFormCommonInfoAction } from "../../../../redux/appActions";

// Services import
import {
  getsurfaceisoler,
  getSurfaceCrepiSeul,
  getProfilAngle,
  getProfilDeDepart,
  getProfilDeCouronnement,
  getProfilGoutteDeau,
  getFlechetteDeRenfort,
  getAppuiFenetre,
  getAppuiFenetreProfond,
} from "../../../../services/Forms/ITE/resultatPageCalculation";

//Components

import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  visitId?: string;
}

export const ResultatsPage = ({ ITEFormMutableData, visitId }: Props) => {
  const dispatch = useAppDispatch();
  const formName = "ITE";

  return (
    <div className="formPagesITE">
      <div className="formPagesITE__Title__Resultats">
        <H2 title={"Les résultats"} />
      </div>
      <div className={"ITE_questionContainer__Resultats__Container"}>
        {/*Surface à isoler (m²) ---> ∑ Surface à isoler de chaque mur à isoler - ∑ Surface des ouvrants de chaque mur à isoler*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Surface à isoler (m²)</p>}
            value={<p>{getsurfaceisoler({ ITEFormMutableData })}</p>}
          />
        </div>

        {/*Surface Crépis seul ---> ∑ Surface Crépis seul de chaque mur crépis seul - ∑ Surface des ouvrants de chaque mur crépis seul*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Surface Crépis seul</p>}
            value={<p>{getSurfaceCrepiSeul({ ITEFormMutableData })}</p>}
          />
        </div>

        {/*Profil d'angle (ml) & Somme de la hauteur des façades ---> (∑ hauteur de tous les ouvrants) x 2 + hauteur totale des façades (champ d'input auditeur)*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Profil d'angle (ml)</p>}
            value={<p>{getProfilAngle({ ITEFormMutableData })}</p>}
          />
          <div className={"ITE_questionContainer__Resultats__Block__Element"}>
            <InputContainer
              title={"Somme de la hauteur des façades :"}
              formName={formName}
              visitId={visitId}
              questionName={"sommeDeLaHauteurDesFacades"}
              iconType={"meters"}
              placeholder={"XXXX"}
              userAnswer={ITEFormMutableData?.sommeDeLaHauteurDesFacades}
              onChange={(value) => {
                dispatch(
                  setFormCommonInfoAction({
                    value: value.toString(),
                    visitId: visitId ?? null,
                    formQuestionName: "sommeDeLaHauteurDesFacades",
                    formName: formName,
                  })
                );
              }}
            />
          </div>
          <div
            className={
              "ITE_questionContainer__Resultats__Block__Element__Tooltip"
            }
          >
            <TooltipInfoForm
              textContent={
                <p>
                  Pour votre information, la somme des ouvrants est déjà
                  comptabilisée. C'est uniquement la somme des longueurs des
                  façades que vous devez calculer et renseigner ici.
                </p>
              }
            />
          </div>
        </div>

        {/*Profil de départ (ml) --- > ∑ profils de départ de tous le murs*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Profil de départ (ml)</p>}
            value={<p>{getProfilDeDepart({ ITEFormMutableData })}</p>}
          />
        </div>
        {/*Profil de couronnement (ml)*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Profil de couronnement (ml)</p>}
            value={<p>{getProfilDeCouronnement({ ITEFormMutableData })}</p>}
          />
        </div>

        {/*Profil goutte d’eau (ml) ---> ∑ largeurs des fenêtres et des portes*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Profil goutte d’eau (ml)</p>}
            value={
              <p>{getProfilGoutteDeau({ ITEFormMutableData })?.toFixed(2)}</p>
            }
          />
        </div>

        {/*Fléchette de renfort (unité)*/}
        {/*∑ fléchettes de renfort selon ces conditions :*/}
        {/*Pour chaque fenêtre sans appui fenêtre : 4 fléchettes de renfort*/}
        {/*Pour chaque fenêtre avec appui fenêtre : 2 fléchettes de renfort*/}
        {/*Pour chaque porte : 2 fléchettes de renfort*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Fléchette de renfort (unité)</p>}
            value={<p>{getFlechetteDeRenfort({ ITEFormMutableData })}</p>}
          />
        </div>

        {/*Appui fenêtre ---> Nombre total d'appui fenêtre ( correspond à Appui fenêtre existant = Oui dans chaque bloc fenêtre)*/}
        {/*Appui fenêtre profond (> 60cm) --->  Parmi tous les appui fenêtre, correspond au nombre d'appui fenêtre profond >60 cm*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Appui fenêtre</p>}
            value={<p>{getAppuiFenetre({ ITEFormMutableData })}</p>}
          />
          <LineDividedBlock
            label={
              <p>
                Nombre d'appui fenêtre profonds
                <br />
                (&gt; 60cm) :
              </p>
            }
            value={<p>{getAppuiFenetreProfond({ ITEFormMutableData })}</p>}
          />
          <div
            className={
              "ITE_questionContainer__Resultats__Block__Element__Tooltip"
            }
          >
            <TooltipInfoForm
              textContent={
                <p>
                  Nous distinguons les appuis de fenêtre profonds car ils sont
                  soumis à des frais supplémentaires plus importants que les
                  appuis fenêtre standards. Il est donc important de les
                  mentionner.
                </p>
              }
            />
          </div>
        </div>
        {/*Couleur*/}
        <div className={"ITE_questionContainer__Resultats__Block"}>
          <LineDividedBlock
            label={<p>Couleur</p>}
            value={
              <TextInput
                value={String(ITEFormMutableData?.couleur?.value || "")}
                onChange={(e) => {
                  dispatch(
                    setFormCommonInfoAction({
                      value: e.target.value.toString(),
                      visitId: visitId ?? null,
                      formQuestionName: "couleur",
                      formName: formName,
                    })
                  );
                }}
                placeholder={"Ton pierre"}
                inputLableHidden={true}
                inputDescriptionHidden={true}
                iconHidden={true}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
