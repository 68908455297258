import "./PickPositionMap.scss";
/* global google */
// Libraries import
import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useNavigate, useLocation } from "react-router-dom";

// Services import
import { useGetCalepinage } from "../../services/CalepinageValidation/useGetCalepinage";
import { determineFormUsedFromProductType } from "../../services/Forms/determineFormNameFromProductType";

// Redux imports
import { useAppSelector } from "../../redux/store/hook";
import { useAppDispatch } from "../../redux/store/hook";
import { setVisiteTechniqueInfoAction } from "../../redux/appActions";

// Components imports
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { SecondaryButton } from "../../components/SecondaryButton/SecondaryButton";
import { Modal } from "../../components/Modal/Modal";
import { PickPositionMapModal } from "../../components/PickPositionMapModal/PickPositionMapModal";

export const PickPositionMap = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { visiteTechniqueInfo, userAuthInfo } = useAppSelector(
    (state) => state
  );
  const userProfileInfo = useAppSelector((states) => states.userProfileInfo);

  const { getCalepinage } = useGetCalepinage();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  });

  const [map, setMap] = useState<
    google.maps.Map | google.maps.StreetViewPanorama | null
  >(null);
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>({
    lat: visiteTechniqueInfo.customerlatitude ?? 47.9,
    lng: visiteTechniqueInfo.customerlongitude ?? 1.906,
  }); //map is centered on Orleans by default
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Events handler

  const onClickOnValidatePosition = () => {
    if (!marker && isModalVisible === false) {
      setIsModalVisible(true);
      return;
    }

    getCalepinage({
      userToken: userAuthInfo.eeroToken,
      latitude: visiteTechniqueInfo.customerlatitude,
      longitude: visiteTechniqueInfo.customerlongitude,
      visitId: visiteTechniqueInfo.id,
      formName: determineFormUsedFromProductType(
        visiteTechniqueInfo.typeDeProduit
      ),
      client_firstname: visiteTechniqueInfo.customerFirstName,
      client_lastname: visiteTechniqueInfo.customerLastName,
      technicien_vt_firstname: userProfileInfo.firstname,
      technicien_vt_lastname: userProfileInfo.lastname,
    });
    // The slice(0, -3) remove the map from the pathname /visite-technique/:visitId/map
    if (determineFormUsedFromProductType(visiteTechniqueInfo.typeDeProduit)) {
      navigate(
        `${pathname.slice(0, -3)}` +
          determineFormUsedFromProductType(visiteTechniqueInfo.typeDeProduit)
      );
      return;
    }
    navigate(`${pathname.slice(0, -3)}CET`); //Default value for product types that are not handled yet
    return;
  };

  const onMarkerChange = (marker: google.maps.Marker | null) => {
    if (marker) {
      const latLng = marker.getPosition();
      const markerLat = latLng && latLng.lat();
      const markerLng = latLng && latLng.lng();

      dispatch(
        setVisiteTechniqueInfoAction({
          visiteTechniqueInfo: {
            ...visiteTechniqueInfo,
            customerlatitude: markerLat ?? null,
            customerlongitude: markerLng ?? null,
          },
        })
      );
    }
  };

  const onLoad = useCallback(function callback(
    map: google.maps.Map | google.maps.StreetViewPanorama | null
  ) {
    setMap(map);
  },
  []);

  const placeMarker = (location: google.maps.LatLng | null) => {
    if (marker === null) {
      const newMarker = new window.google.maps.Marker({
        position: location,
        map: map,
      });
      newMarker.setMap(map);

      onMarkerChange(newMarker);

      setMarker(newMarker);

      setMapCenter(null);
    } else {
      marker.setPosition(location);
      marker.setMap(map);
      onMarkerChange(marker);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    placeMarker(event.latLng);
  };

  return (
    <>
      <Modal isVisible={isModalVisible}>
        <PickPositionMapModal
          onClickButtonBack={() => setIsModalVisible(false)}
          onClickButton={onClickOnValidatePosition}
        />
      </Modal>
      <div className="PickPositionMap">
        <div className="PickPositionMap__Instruction">
          <h2>Cliquez sur la toiture du logement à équiper</h2>
          <p>
            Si vous n'êtes pas au bon endroit, vous pouvez vous déplacer sur la
            carte.
          </p>
        </div>
        <div className="PickPositionMap__Map">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              zoom={20}
              onLoad={onLoad}
              onClick={handleMapClick}
              clickableIcons={false}
              options={{
                streetViewControl: false,
                mapTypeId: "hybrid",
                fullscreenControl: false,
                mapTypeControl: false,
                center: mapCenter,
                tilt: 0,
                zoomControl: false,
              }}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
        <div className="PickPositionMap__Buttons">
          <SecondaryButton name="Précédent" onClick={() => navigate(-1)} />
          <MainButton
            name="Valider la position"
            onClick={onClickOnValidatePosition}
            size="big"
          />
        </div>
      </div>
    </>
  );
};
