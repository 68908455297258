import "./formPages.scss";

// Librairie import
import React, { useEffect } from "react";

// Services import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setInputNumberAction } from "../../../../redux/appActions";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { InputText } from "../../../../containers/Forms/FormStepsNew/InputText/InputText";

// Data import
import { departementsFrance } from "@web/shared/dist/datas/departementsFranceZoneClimatiqueInfo";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const Dimensionnement = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const formName = "PacAirEau";

  const { formsMutableData, visiteTechniqueInfo } = useAppSelector(
    (state) => state
  );
  const {
    surfaceenm2,
    hauteurmoyennesousplafond,
    temperaturedeconfort,
    valeurinitialeduG,
    correctionduG,
  } = formsMutableData[formName];
  const temperatureDeBaseInCelcius =
    departementsFrance.find(
      (departement) =>
        departement.num_dep ===
        visiteTechniqueInfo.customerPostalCode?.slice(0, 2)
    )?.TemperatureExterieur || 0;

  const deperditionThermique = Math.round(
    Number(correctionduG?.value || valeurinitialeduG?.value) *
      Number(surfaceenm2?.value || 0) *
      Number(hauteurmoyennesousplafond?.value || 0) *
      (Number(temperaturedeconfort?.value || 0) -
        Number(temperatureDeBaseInCelcius || 0))
  );

  const dimensionnementdelaPACkW = Math.round(
    (Number(deperditionThermique) * 1.2) / 1000
  );

  useEffect(() => {
    dispatch(
      setInputNumberAction({
        inputNumber: deperditionThermique,
        formName: formName,
        formQuestionName: "calculdedeperditionthermiquekW",
        visitId,
      })
    );
    dispatch(
      setInputNumberAction({
        inputNumber: dimensionnementdelaPACkW,
        formName: formName,
        formQuestionName: "dimensionnementdelaPACkW",
        visitId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionnementdelaPACkW]);

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Calculs de dimensionnement</h4>
        <div className="formPages__Trait" />
      </div>
      <div style={{ display: "flex", marginLeft: "2rem", marginTop: "1rem" }}>
        <p style={{ fontSize: "1.1rem" }}>Valeur initial du G : </p>
        <p style={{ fontSize: "1.2rem", marginLeft: "1rem" }}>
          <strong>{PacAirEauFormMutableData?.valeurinitialeduG?.value}</strong>
        </p>
      </div>
      <PickUniqueCard
        title="Correction du G"
        cards={[1.3, 1.2, 1.1, 1, 0.9, 0.8, 0.65]}
        formName={formName}
        questionName="correctionduG"
        userAnswer={PacAirEauFormMutableData?.correctionduG}
        visitId={visitId}
      />
      <div className="formPages__BlueContainerCalcul">
        <strong>Calcul de déperdition thermique </strong>:{" "}
        {PacAirEauFormMutableData?.calculdedeperditionthermiquekW?.value}
      </div>
      <div className="formPages__BlueContainerCalcul">
        <strong>Dimensionnement de la PAC </strong>:{" "}
        {PacAirEauFormMutableData?.dimensionnementdelaPACkW?.value + " kW"}
      </div>
      <div className="formPages__Paragraph">
        <p>
          Pour rappel, le produit proposé au client est le suivant :<br />{" "}
          {visiteTechniqueInfo.products?.[0]?.toString()}
        </p>
      </div>

      <PickUniqueCard
        title="La PAC A/E proposée est-elle correctement dimensionnée ?"
        cards={[0, 1]}
        formName={formName}
        questionName="dimensionnementinitialcorrect"
        userAnswer={PacAirEauFormMutableData?.dimensionnementinitialcorrect}
        visitId={visitId}
      />
      <InputText
        title="Commentaires sur les calculs de dimensionnement :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentairespartiedimensionnement}
        questionName="commentairespartiedimensionnement"
        visitId={visitId}
      />
    </div>
  );
};
