// Interface import
import { ICETFormDataInterface } from "../../interfaces/CETFormInterface";
import { IFormQuestions } from "../../interfaces/generalInterfaces";

export const ExistingSituationQuestions: IFormQuestions[] = [
  {
    type: "PickUniqueCard",
    name: "typeEcsExistant",
    branchs: ["main"],
    title: "Quel est le type d’ECS existant ?",
    cards: [629950000, 629950001, 629950002, 629950003], //Fioul, Gaz, Electricité, Autres
    pictureNeeded: true,
    isAnswerRequired: true,
  },
  {
    type: "InputText",
    name: "descriptionAutreTypeECS",
    branchs: ["typeECSExistant"],
    title: "Si autre :",
  },
  {
    type: "InputText",
    name: "existingCetBrand",
    branchs: ["main"],
    title: "Marque du chauffe-eau existant :",
    isAnswerRequired: true,
  },
  {
    type: "InputText",
    name: "existingCetModel",
    branchs: ["main"],
    title: "Modèle du chauffe-eau existant : ",
    pictureNeeded: true,
    isAnswerRequired: true,
  },
  {
    type: "PickUniqueCard",
    name: "cetExistingLocation",
    branchs: ["main"],
    title: "Emplacement actuel du chauffe-eau :",
    cards: [629950000, 629950001, 629950002, 629950003], //Garage, Buanderie, Sous-sol, Autres
    pictureNeeded: true,
    isAnswerRequired: true,
  },
  {
    type: "InputText",
    name: "existingCetOtherLocation",
    branchs: ["emplacementECSExistant"],
    title: "Si autre :",
  },
  {
    type: "PickUniqueCard",
    name: "installationZone",
    branchs: ["main"],
    title: "Zone d'installation :",
    cards: [629950000, 629950001, 629950002], //Zonne chauffée, Zone non chauffée, Exterieur
    pictureNeeded: true,
  },
  {
    type: "InputText",
    name: "existingCetLocationComments",
    branchs: ["main"],
    title: "Précisions sur l'emplacement du ballon :",
    placeholder: "",
  },
];

export const consumptionHabitsQuestions: IFormQuestions[] = [
  {
    type: "InputNumber",
    name: "numberofHouseOccupation",
    branchs: ["main"],
    title: "Nombre de personnes composant le foyer de 1 à 10 :",
    placeholder: "",
    isAnswerRequired: true,
  },
  {
    type: "PickUniqueCard",
    name: "cetUsage",
    branchs: ["main"],
    title: "Type d'utilisation :",
    cards: [629950000, 629950001, 629950002], //Economique, Modéré, Intense
  },
];

export const TargetSystemQuestions: IFormQuestions[] = [
  {
    type: "PickUniqueCard",
    name: "cetFutureLocation",
    branchs: ["main"],
    title: "Emplacement à prevoir ?",
    cards: [629950000, 629950001],
  },
  {
    type: "InputNumber",
    name: "hauteurSousPlafondDisponible",
    branchs: ["main"],
    title: "Quelle est la hauteur sous plafond disponible dans la pièce ?",
    isAnswerRequired: true,
  },
  {
    type: "InputText",
    name: "cetFutureLocationComments",
    branchs: ["emplacementAPrevoir"],
    title: "Indiquer le nouvel emplacement :",
  },
  {
    type: "PickUniqueCard",
    name: "cetInstallationType",
    branchs: ["main"],
    title: "Type d'installation du chauffe-eau thermodyanmique ?",
    cards: [629950000, 629950001, 629950002], // Sur air ambiant (si > 20m3), Sur air extrait (gainé si < à 20m3), Sur air extérieur (groupe extérieur)
    isAnswerRequired: true,
  },
  {
    type: "InputNumber",
    name: "distanceGroupeExtUniteInt",
    branchs: ["typeInstallationCETAirExtrait"],
    title: "Distance entre le groupe extérieur et l'unité intérieure :",
    placeholder: "en mètre",
  },
  {
    type: "PickUniqueCard",
    name: "changePressionReducer",
    branchs: ["typeInstallationCET"],
    title: "Réducteur de pression à remplacer ?",
    cards: [0, 1],
  },
];

export const electricalPartQuestions: IFormQuestions[] = [
  {
    type: "PickUniqueCard",
    name: "typeAlimentation",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Type d'installation électrique :",
    cards: [629950000, 629950001],
    isAnswerRequired: true,
  },
  {
    type: "PickUniqueCard",
    name: "puissanceSouscrite",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Puissance souscrite :",
    cards: [
      629950000, 629950001, 629950002, 629950004, 629950003, 629950005,
      629950006, 629950007,
    ],
    isAnswerRequired: true,
  },
  {
    type: "PickUniqueCard",
    name: "emplacementCompteur",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Emplacement du compteur :",
    cards: [629950000, 629950001, 629950002],
  },
  {
    type: "PickUniqueCard",
    name: "emplacementTableauElec",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Emplacement du tableau électrique :",
    cards: [629950000, 629950001],
  },
  {
    type: "PickUniqueCard",
    name: "typeCompteur",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Type de compteur :",
    cards: [629950000, 629950001, 629950002],
  },
  {
    type: "InputNumber",
    name: "distanceAlimentationEauCoffretElectrique",
    branchs: ["main"],
    title: "Distance entre l’alimentation d’eau et le coffret électrique (m)",
    placeholder: "ex : 50",
  },
  {
    type: "InputText",
    name: "commentairePartieElectrique",
    branchs: ["main"],
    title: "Commentaire partie électrique :",
    placeholder: "ex : emplacement compteur proche d'une arrivée d'eau",
  },
];

export const logPartQuestions: IFormQuestions[] = [
  {
    type: "PickUniqueCard",
    name: "accessibilityMax",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Accessibilité maximum par camion :",
    cards: [629950000, 629950001, 629950002],
  },
  {
    type: "PickUniqueCard",
    name: "portanceWifi",
    branchs: ["main"],
    title: "Portance réseau WIFI :",
    cards: [629950000, 629950001],
  },
  {
    type: "PickUniqueCard",
    name: "planDisponible",
    branchs: ["main"],
    pictureNeeded: true,
    title: "Les plans sont-ils disponibles :",
    cards: [0, 1],
  },

  {
    type: "InputText",
    name: "commentairePartieLogistique",
    branchs: ["main"],
    title: "Commentaire partie logistique :",
    placeholder: "ex : largeur portail 230 cm",
  },
];

export const CETFormData: ICETFormDataInterface = {
  formName: "CET",
  formScreensName: ["visiteTechniqueFormPages", "extraChargesFormPages"],
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "1. Installation existante",
        "2. Installation cible",
        "3. Partie électrique",
        "4. Logistique",
      ],
      pages: [
        [
          {
            categoryName: "Système existante",
            questions: ExistingSituationQuestions,
          },
        ],
        [
          {
            categoryName: "Habitudes de consommations",
            questions: consumptionHabitsQuestions,
          },
          {
            categoryName: "Système cible",
            questions: TargetSystemQuestions,
          },
        ],
        [
          {
            categoryName: "Partie électrique",
            questions: electricalPartQuestions,
          },
        ],
        [
          {
            categoryName: "Logistique",
            questions: logPartQuestions,
          },
        ],
      ],
    },
    extraChargesFormPages: {
      steps: ["5. Spécificités du chantier"],
      pages: [
        [
          {
            categoryName: "Carrotage",
            questions: [
              {
                type: "PickUniqueCard",
                name: "carrotageaPrevoir",
                branchs: ["main"],
                title: "Un carottage sur plus de 60 cm est-il à prévoir ?",
                cards: [0, 1],
              },
              {
                type: "InputNumber",
                name: "nombreDeCarrotage",
                branchs: ["carrotageAPrevoir"],
                title: "Combien de carottage à prévoir ? ",
              },
            ],
          },
          {
            categoryName: "Déport du ballon",
            infoBox:
              "Le déport du ballon est compté si il est supérieur à un mètre.",
            questions: [
              {
                type: "PickUniqueCard",
                name: "deportDuBallon",
                branchs: ["main"],
                title: "Le déport du ballon est-il nécessaire ? ",
                cards: [0, 1],
              },
              {
                type: "InputNumber",
                name: "combienDeMetresDeportDuBallon",
                branchs: ["main"],
                title: "Si oui, combien de mètres ?",
              },
              {
                type: "InputText",
                name: "commentaireDeportDuBallon",
                branchs: ["main"],
                title: "Commentaire sur le déport du ballon :",
              },
            ],
          },
          {
            categoryName: "Pompe de relevage",
            infoBox:
              "En general,  la pompe de relevage doit être ajoutée sur les ballons afin de fluidifier l’évacuation de l’eau au cas où l’installation ne comporte aucune évacuation existante (ou si une évacuation en cuivre est présente).",
            questions: [
              {
                type: "PickUniqueCard",
                name: "pompeDeRelevageNecessaire",
                branchs: ["main"],
                title:
                  "Est-ce qu’une pompe de relevage sera nécessaire au projet ?",
                cards: [0, 1],
              },
            ],
          },
          {
            categoryName: "Dalle bétonnée",
            infoBox: (
              <>
                Les dalles bétonnées ne sont à prévoir que pour les ballons
                bi-bloc. Les dalles seront gravillonnées. Si le client souhaite
                réaliser la dalle lui-même, alors choisir <strong>“non”</strong>
                .
              </>
            ),
            questions: [
              {
                type: "PickUniqueCard",
                name: "dalleBetonneeNecessaire",
                branchs: ["main"],
                title: "Est-ce qu’une dalle doit être prévue ? ",
                cards: [0, 1],
              },
              {
                type: "InputText",
                name: "commentaireDalleBetonnee",
                branchs: ["main"],
                title: "Commentaire sur la dalle de béton :",
              },
            ],
          },
          {
            categoryName: "Autres frais supplémentaires ?",
            infoBox: (
              <>
                Merci de ne pas reprendre les frais supplémentaires renseignés
                dans le formulaire ci-dessus.{" "}
                <strong>
                  La zone ci-après concerne les frais supplémentaires non
                  listés.
                </strong>
              </>
            ),
            questions: [
              {
                type: "InputText",
                name: "commentaireFraisSupplementaires",
                branchs: ["main"],
                title: "Commentaire frais supplémentaires additionnels",
                cards: [0, 1],
              },
              {
                type: "InputNumber",
                name: "montantEstimeFraisSupplementaires",
                branchs: ["main"],
                title: "Montant estimé (€) :",
              },
            ],
          },
        ],
      ],
    },
  },
};
