import "./potentialPan.scss";

// Libraries import
import React from "react";

// Components import
import * as FormComponents from "../../../containers/Forms/FormSteps";

// Services import
import { useUpdatePanInformationFormAccordingToUserAnswer } from "../../../services/Forms/PVForm/useUpdatePanInformationFormAccordingToUserAnswer";

// Interface import
import {
  formNames,
  IPhotovoltaiqueFormMutableData,
} from "../../../interfaces/generalInterfaces";
import { IFormPanInformations } from "../../../interfaces/photovoltaiqueFormInterfaces";

// Data import
import { potentialPanQuestions } from "../../../data/forms/Photovoltaique";

// Interfaces declaration
interface Props {
  panInformation: IFormPanInformations;
  formName: formNames;
  answerIndex: number;
  visitId?: string;
  formCurrentBranchs: string[];
  formsMutableData: IPhotovoltaiqueFormMutableData;
}

export const PotentialPan: React.FC<Props> = ({
  panInformation,
  formName,
  answerIndex,
  visitId,
  formCurrentBranchs,
  formsMutableData,
}: Props) => {
  const { surfaceDisponible } = panInformation;

  // Hook that allows to update the form according to the user's form answer
  useUpdatePanInformationFormAccordingToUserAnswer({
    formName,
    formsMutableData,
    visitId,
    panSelected: answerIndex || 0,
    formPageName: "Potential pans",
  });

  return (
    <div className="potentialPan">
      <h3>Potentiel Pan {answerIndex + 1}</h3>
      <div className="potentialPan__Informations">
        <div className="potentialPan__Surface">
          <p>
            Surface disponible :{" "}
            <strong>{surfaceDisponible?.value || "xxx"} m²</strong>
          </p>
        </div>
      </div>
      <div className="potentialPan__QuestionsContainer">
        {potentialPanQuestions.map((category) => {
          return category.questions
            .filter((question) => {
              // We filter the questions according to the branch we want to display
              return question.branchs.every((branch) =>
                formCurrentBranchs.includes(branch)
              );
            })
            .map((question, index) => {
              const Component = FormComponents[question.type];
              return (
                <div className="selectPans__Question" key={index}>
                  <Component
                    questionData={question}
                    formName={formName}
                    answerIndex={answerIndex}
                    visitId={visitId}
                  />
                </div>
              );
            });
        })}
      </div>
    </div>
  );
};
