import "./App.scss";

// Component import
import { Navigation } from "./navigation/Navigation";

// Libraries import
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEyeSlash,
  faEye,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
library.add(faEyeSlash, faEye, faSortDown, faSortUp);

const App: React.FC = () => {
  return (
    <div className="App">
      <Navigation />
    </div>
  );
};

export default Sentry.withProfiler(App);
