// Services import
import { pointsSinguliersDictionnary } from "./pointsSinguliersDictionnary";
import { StringToNumber } from "./formatStringToNumber";

// Interfaces import
import { IFormsMutableData } from "../../../interfaces/generalInterfaces";
import { ISaveVTExtraChargesRequestPayload } from "../useSaveVTExtraChargesInfoToCRM";
import {
  IITEOuvrant,
  IITEPointsSinguliers,
} from "../../../interfaces/ITEFormInterface";

// Local interface declaration
interface Props {
  formsMutableData: IFormsMutableData;
}

interface IFormatITEExtraChargesBeforeSendingToCRMReturn
  extends ISaveVTExtraChargesRequestPayload {
  extraChargesITEAReposerWithoutDoublon: (IITEPointsSinguliers | IITEOuvrant)[];
}

export const formatITEExtraChargesBeforeSendingToCRM = ({
  formsMutableData,
}: Props): IFormatITEExtraChargesBeforeSendingToCRMReturn => {
  const extraChargesITEWithoutWindowsExtraCharges = [
    ...(formsMutableData.ITE?.pointsSinguliers || []),
    ...(formsMutableData.ITE?.otherPointsSinguliers || []),
  ];

  const extraChargesITEAReposerWithoutWindowsExtraCharges = [
    ...(formsMutableData.ITE?.pointsSinguliers || []),
    ...(formsMutableData.ITE?.otherPointsSinguliers || []),
  ].filter(
    (extraCharge) =>
      extraCharge.isPointSingulierAReposer?.value === "true" &&
      (StringToNumber(extraCharge.pointSingulierQuantity?.value) || 0) > 0
  );

  const extraChargesITEAReposerWithoutDoublon =
    extraChargesITEAReposerWithoutWindowsExtraCharges.reduce(
      (acc, currentPointSingulier, index) => {
        if (index === 0) {
          // We don't take into account the first item as it is already the initial value in the acc
          return acc;
        }
        const existingObjIndex = acc.findIndex(
          (item) =>
            item.pointSingulierName?.value ===
            currentPointSingulier.pointSingulierName?.value
        );
        if (existingObjIndex >= 0) {
          acc[existingObjIndex].pointSingulierQuantity = {
            value: (
              (StringToNumber(
                acc[existingObjIndex]?.pointSingulierQuantity?.value
              ) || 0) +
              (StringToNumber(
                currentPointSingulier?.pointSingulierQuantity?.value
              ) || 0)
            ).toString(),
          };
        } else {
          acc.push(currentPointSingulier);
        }
        return acc;
      },
      [extraChargesITEWithoutWindowsExtraCharges[0]]
    );

  const CRMFormatedExtraChargesITE = extraChargesITEAReposerWithoutDoublon.map(
    (pointSingulier) => {
      return {
        name: pointSingulier?.pointSingulierName?.value || "",
        nombre:
          StringToNumber(pointSingulier?.pointSingulierQuantity?.value) || 0,
        typedeTravaux:
          pointsSinguliersDictionnary?.[
            pointSingulier?.pointSingulierName?.value || ""
          ]?.typedeTravaux || 629950032,
        amount:
          (pointsSinguliersDictionnary?.[
            pointSingulier?.pointSingulierName?.value || ""
          ]?.amount || 0) *
            (StringToNumber(pointSingulier?.pointSingulierQuantity?.value) ||
              0) ||
          StringToNumber(pointSingulier?.pointSingulierAmount?.value) ||
          0,
        ...(pointSingulier?.pointSingulierId &&
          !pointSingulier?.pointSingulierId?.value?.startsWith("VITEE") && {
            id: pointSingulier?.pointSingulierId?.value,
          }),
      };
    }
  );

  const globalDescriptionArray = extraChargesITEAReposerWithoutDoublon.map(
    (pointSingulier) => {
      const str = ` ${pointSingulier?.pointSingulierQuantity?.value || 0} ${
        pointsSinguliersDictionnary?.[
          pointSingulier?.pointSingulierName?.value || ""
        ]?.title || pointSingulier?.pointSingulierName?.value
      } pour ${
        (pointsSinguliersDictionnary?.[
          pointSingulier?.pointSingulierName?.value || ""
        ]?.amount || 0) *
          (StringToNumber(pointSingulier?.pointSingulierQuantity?.value) ||
            0) ||
        StringToNumber(pointSingulier?.pointSingulierAmount?.value) ||
        0
      } €`;

      return str;
    }
  );

  return {
    extraCharges: CRMFormatedExtraChargesITE,
    globalDescriptionString: globalDescriptionArray.toString(),
    extraChargesITEAReposerWithoutDoublon,
  };
};
