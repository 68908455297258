// Services import
import { useEffect, useRef } from "react";
import { useAppDispatch } from "../../../redux/store/hook";
import {
  updateFormBranchStateAction,
  setPickUniqueCardAction,
  setInputNumberAction,
} from "../../../redux/appActions";
import { rampantLengthCalculation } from "../../FormCalculations/rampantLengthCalculation";
import { roofInclinationCalculation } from "../../FormCalculations/roofInclinationCalculation";
import { availableSurfaceCalculation } from "../../FormCalculations/availableSurfaceCalculation";

// Interfaces import
import {
  formNames,
  IPhotovoltaiqueFormMutableData,
} from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  formName: formNames;
  formsMutableData: IPhotovoltaiqueFormMutableData;
  panSelected: number;
  visitId?: string;
  formPageName: "Pan Informations" | "Potential pans";
}

export const useUpdatePanInformationFormAccordingToUserAnswer = ({
  formName,
  formsMutableData,
  panSelected,
  visitId,
  formPageName,
}: Props) => {
  const dispatch = useAppDispatch();

  // Rules to update branch according to user's answer : type de support
  const typedeSupport =
    formsMutableData.panInformations?.[panSelected || 0]?.typedeSupport?.value;
  useEffect(() => {
    if (typedeSupport === 629950001 || typedeSupport === 629950002) {
      // See services/FormAnswers/valueToLabelDictionnary for the label values
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "panSupportToiture",
          branchToRemove: "panAuSol",
        })
      );
    }
    if (typedeSupport === 629950000) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "panAuSol",
          branchToRemove: "panSupportToiture",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedeSupport]);

  // Rule to update branch according to the answer of : cheminee
  const presencedeCheminee =
    formsMutableData.panInformations?.[panSelected || 0]?.presencedeCheminee
      ?.value;
  useEffect(() => {
    if (presencedeCheminee === 1) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "presenceDeCheminee",
        })
      );
    } else {
      // We update the branch
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "presenceDeCheminee",
        })
      );
      if (presencedeCheminee !== undefined) {
        // If presenceCheminee key exist but there is no presenceCheminee, we update the number of cheminee to null (we don't want to create a cheminee key with a null value if there is no presenceOfCheminee key)
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "cheminee",
            answerIndex: panSelected,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencedeCheminee]);

  // Rule to update branch according to the answer of : velux
  const presencedeVelux =
    formsMutableData.panInformations?.[panSelected || 0]?.presencedeVelux
      ?.value;
  useEffect(() => {
    if (presencedeVelux === 1) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "presenceDeVelux",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "presenceDeVelux",
        })
      );
      if (presencedeVelux !== undefined) {
        // If presencedeVelux key exist but there is no presencedeVelux, we update the number of velux to null (we don't want to create a velux key with a null value if there is no presencedeVelux key)
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "velux",
            answerIndex: panSelected,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencedeVelux]);

  // Rule to update branch according to the answer of:  presencedeChienAssis
  const presencedeChienAssis =
    formsMutableData.panInformations?.[panSelected || 0]?.presencedeChienAssis
      ?.value;
  useEffect(() => {
    if (presencedeChienAssis === 1) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "presenceDeChiensAssis",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "presenceDeChiensAssis",
        })
      );
      if (presencedeChienAssis !== undefined) {
        // If presencedeChienAssis key exist but there is no presencedeChienAssis, we update the number of chienAssis to null (we don't want to create a chienAssis key with a null value if there is no presencedeChienAssis key)
        dispatch(
          setInputNumberAction({
            inputNumber: null,
            formName: formName,
            visitId,
            formQuestionName: "chienAssis",
            answerIndex: panSelected,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencedeChienAssis]);

  // Rule to update branch according to the answer of : accessibiliteDesComnbles
  const accessibiliteDesComnbles =
    formsMutableData.panInformations?.[panSelected || 0]
      ?.accessibiliteDesComnbles?.value;
  useEffect(() => {
    if (accessibiliteDesComnbles === 1) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "comblesAccessibles",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "comblesAccessibles",
        })
      );

      if (accessibiliteDesComnbles !== undefined) {
        // If there is no accessibiliteDesComnbles, we update the state of etatdelaCharpente to null
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "etatdelaCharpente",
            answerIndex: panSelected,
          })
        );
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "typeDaccesDesCombles",
            answerIndex: panSelected,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibiliteDesComnbles]);

  const masqueSolaires =
    formsMutableData.panInformations?.[panSelected || 0]?.masqueSolaires?.value;
  const elagageaPrevoir =
    formsMutableData.panInformations?.[panSelected || 0]?.elagageaPrevoir
      ?.value;

  // Rules to update branch according to user's answer
  useEffect(() => {
    if (masqueSolaires?.includes(629950000)) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "masqueSolaireArbre",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "masqueSolaireArbre",
        })
      );
      if (elagageaPrevoir !== undefined) {
        // We reset elagageaPrevoir if no masque solaire arbre
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "elagageaPrevoir",
            answerIndex: panSelected,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masqueSolaires]);

  // Rules to update surface disponible according to user's answer
  const hauteurdelaToitureGoutiere =
    formsMutableData.panInformations?.[panSelected || 0]
      ?.hauteurdelaToitureGoutiere?.value;
  const hauteurdelaToitureFaitage =
    formsMutableData.panInformations?.[panSelected || 0]
      ?.hauteurdelaToitureFaitage?.value;
  const distanceduPignon =
    formsMutableData.panInformations?.[panSelected || 0]?.distanceduPignon
      ?.value;
  const longueurdelaFacade =
    formsMutableData.panInformations?.[panSelected || 0]?.longueurdelaFacade
      ?.value;
  const velux =
    formsMutableData.panInformations?.[panSelected || 0]?.velux?.value;
  const cheminee =
    formsMutableData.panInformations?.[panSelected || 0]?.cheminee?.value;
  const chienAssis =
    formsMutableData.panInformations?.[panSelected || 0]?.chienAssis?.value;
  const longueurduRampant =
    formsMutableData.panInformations?.[panSelected || 0]?.longueurduRampant
      ?.value;
  const inclinaison =
    formsMutableData.panInformations?.[panSelected || 0]?.inclinaison?.value;

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      // Allow to not trigger the useEffect when component mount
      if (
        (hauteurdelaToitureFaitage &&
          hauteurdelaToitureGoutiere &&
          distanceduPignon) ||
        longueurduRampant
      ) {
        dispatch(
          setInputNumberAction({
            inputNumber:
              formPageName === "Pan Informations"
                ? rampantLengthCalculation({
                    hauteurdelaToitureFaitage: hauteurdelaToitureFaitage,
                    hauteurdelaToitureGoutiere: hauteurdelaToitureGoutiere,
                    distanceduPignon: distanceduPignon,
                  })
                : longueurduRampant ?? null,
            formName: formName,
            visitId,
            formQuestionName: "longueurduRampant",
            answerIndex: panSelected,
          })
        );
      }

      if (
        (hauteurdelaToitureFaitage &&
          hauteurdelaToitureGoutiere &&
          distanceduPignon) ||
        inclinaison
      ) {
        dispatch(
          setInputNumberAction({
            inputNumber:
              formPageName === "Pan Informations"
                ? roofInclinationCalculation({
                    hauteurdelaToitureFaitage: hauteurdelaToitureFaitage,
                    hauteurdelaToitureGoutiere: hauteurdelaToitureGoutiere,
                    distanceduPignon: distanceduPignon,
                  })
                : inclinaison ?? null,
            formName: formName,
            visitId,
            formQuestionName: "inclinaison",
            answerIndex: panSelected,
          })
        );
      }
    } else {
      isMounted.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hauteurdelaToitureGoutiere,
    hauteurdelaToitureFaitage,
    distanceduPignon,
    longueurduRampant,
    inclinaison,
  ]);

  useEffect(() => {
    if (isMounted.current) {
      // Allow to not trigger the useEffect when component mount

      // We calculate the surface only if we have the information needed (i.e. type de support = surimposition or intégration au batit)
      if (
        longueurduRampant &&
        longueurdelaFacade &&
        (typedeSupport === 629950001 || typedeSupport === 629950002)
      ) {
        dispatch(
          setInputNumberAction({
            inputNumber:
              availableSurfaceCalculation({
                longueurRampant: longueurduRampant,
                longueurdelaFacade: longueurdelaFacade,
                velux: velux,
                cheminee: cheminee,
                chienAssis: chienAssis,
              }) || null,
            formName: formName,
            visitId,
            formQuestionName: "surfaceDisponible",
            answerIndex: panSelected,
          })
        );
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longueurduRampant, longueurdelaFacade, velux, cheminee, chienAssis]);
};
