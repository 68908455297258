// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../../redux/store/hook";
import { setITEExtraChargesCRMIdAction } from "../../../redux/appActions";

// Interfaces import
import { ICRMExtraCharge } from "../../../interfaces/ExtraChargesInterfaces";

// Interface delcaration
interface IFunctionReturn {
  getITEExtraChargesFromCRM: ({
    userToken,
    visiteTechniqueId,
  }: {
    userToken: string | null;
    visiteTechniqueId: string;
  }) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

export const useGetITEExtraChargesFromCRM = (): IFunctionReturn => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getITEExtraChargesFromCRM = async ({
    userToken,
    visiteTechniqueId,
  }: {
    userToken: string | null;
    visiteTechniqueId: string;
  }): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<ICRMExtraCharge[]>(
      `${process.env.REACT_APP_VITEE_AZURE_API_URL}/api/VT/${visiteTechniqueId}/GetExtraCharges`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      dispatch(
        setITEExtraChargesCRMIdAction({
          // We only need to update the extra charges ID because the extra charges have already been added with the getITEWallsFromCRM call
          visitId: visiteTechniqueId,
          CRMExtraCharges: response,
        })
      );
    }
  };

  return {
    getITEExtraChargesFromCRM,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
