import { MouseEventHandler, ReactElement, forwardRef } from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../styles/variables";

// Local interface declaration
export interface MainButtonProps {
  name?: string;
  shadow?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  color?: string;
  disabled?: boolean;
  isLoading?: boolean;
  id?: string;
  type?: "submit" | "button" | "reset";
  form?: string;
  size?: "big" | "medium";
  dataTestId?: string;
  fontFamily?: "DM Sans" | "PulpDisplay";
  style?: React.CSSProperties;
}

interface IButtonStyles {
  shadow: boolean;
  size?: "big" | "medium";
  fontFamily: "DM Sans" | "PulpDisplay";
}

const Button = styled.button<IButtonStyles>`
  background-color: ${(props) =>
    props.color === "white"
      ? "#FFFFFF"
      : props.color === "lightOrange"
      ? "#fecc97"
      : props.color === "blue"
      ? "#0073e0"
      : props.color === "black"
      ? "#191B1E"
      : props.color === "grey"
      ? stylesVariables.MainBackgroundColor
      : "#ed8c00"};
  color: ${(props) =>
    props.color === "white"
      ? "#50545f"
      : props.color === "lightOrange"
      ? "#50545f"
      : props.color === "blue"
      ? "#ffffff"
      : props.color === "black"
      ? "#ffffff"
      : props.color === "grey"
      ? stylesVariables.MainGrey
      : "#ffffff"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  height: ${(props) => (props.size === "big" ? "50px" : "40px")};
  padding: 10px 30px;
  min-width: ${(props) => (props.size === "big" ? "160px" : "120px")};
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  border: 2px solid transparent;
  box-shadow: ${(props) =>
    props.shadow ? "0px 2px 4px 0px #00000040" : "unset"};
  > p {
    font-size: ${(props) => (props.size === "big" ? "1.1rem" : "1rem")};
    font-family: ${(props) => props.fontFamily};
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: 0rem;
    @media (max-width: $smallWindow) {
      font-size: #{"min(0.938rem,4vw)"};
      letter-spacing: 0.02rem;
    }
  }
  &:hover {
    background-color: ${(props) =>
      props.color === "lightOrange"
        ? "#ffffff"
        : props.color === "blue"
        ? "#ffffff"
        : props.color === "grey"
        ? stylesVariables.BackgroundColorTwo
        : "#ffffff"};
    color: ${(props) =>
      props.color === "lightOrange"
        ? "#ed8c00"
        : props.color === "blue"
        ? "#0073e0"
        : props.color === "black"
        ? "#191B1E"
        : "#ed8c00"};
    border: ${(props) =>
      props.color === "white"
        ? "none"
        : props.color === "lightOrange"
        ? "2px solid #fecc97"
        : props.color === "blue"
        ? "2px solid #0073e0"
        : props.color === "black"
        ? "2px solid #191B1E"
        : "2px solid #ed8c00"};
  }
  &:disabled {
    background-color: ${(props) =>
      props.color === "orange"
        ? "rgba($color: #ed8c00, $alpha: 0.3)"
        : "unset"};
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
- None of the props are mandatory
- Default props are listed below
**/

export const MainButton = forwardRef<HTMLButtonElement, MainButtonProps>(
  (props, ref): ReactElement => {
    const {
      name = "Button",
      shadow = true,
      onClick,
      color = "orange",
      disabled = false,
      isLoading = false,
      id,
      type = "button",
      form,
      size = "medium",
      dataTestId,
      fontFamily = "DM Sans",
      style,
    } = props;

    return (
      <Button
        id={id}
        disabled={disabled}
        onClick={onClick}
        shadow={shadow}
        color={color}
        type={type}
        form={form}
        ref={ref}
        size={size}
        data-testid={dataTestId}
        fontFamily={fontFamily}
        style={style}
      >
        {isLoading && (
          <Loader>
            <ReactLoading
              type={"spin"}
              color={"#e4e3e3"}
              height={25}
              width={25}
            />
          </Loader>
        )}
        <p style={isLoading ? { color: "transparent" } : undefined}>{name}</p>
      </Button>
    );
  }
);
