// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";

export interface DualiteProps {
  binaryValue: boolean | null;
  onSelect: (option: boolean) => void;
  borderColor?: string;
  size?: "small" | null;
  isRequired?: boolean;
}

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  > input {
    all: unset;
    width: 1px;
    text-align: left;
    caret-color: transparent;
    color: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }
`;
const CardYes = styled.button<{
  binaryValue: boolean | null;
  borderColor?: string;
  size?: "small" | null;
}>`
  width: ${(props) => (props.size === "small" ? "132px" : "175px")};
  height: ${(props) => (props.size === "small" ? "48px" : "60px")};
  background-color: #ffffff;
  border: ${(props) => (props.binaryValue ? "2px" : "1px")} solid
    ${(props) => (props.binaryValue ? props.borderColor : "#D3D4D7")};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const CardNo = styled.button<{
  binaryValue: boolean | null;
  borderColor?: string;
  size?: "small" | null;
}>`
  width: ${(props) => (props.size === "small" ? "132px" : "175px")};
  height: ${(props) => (props.size === "small" ? "48px" : "60px")};
  background-color: #ffffff;
  border: ${(props) => (props.binaryValue === false ? "2px" : "1px")} solid
    ${(props) => (props.binaryValue === false ? props.borderColor : "#D3D4D7")};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const PYes = styled.p<{ binaryValue?: boolean | null }>`
  font-size: 14px;
  font-family: "DM Sans", serif;
  color: #50545f;
  font-weight: ${(props) => (props.binaryValue ? 500 : 400)};
`;
const PNo = styled.p<{ binaryValue?: boolean | null }>`
  font-size: 14px;
  font-family: "DM Sans", serif;
  color: #50545f;
  font-weight: ${(props) => (props.binaryValue === false ? 500 : 400)};
`;

export const Dualite: React.FC<DualiteProps> = ({
  binaryValue = null,
  onSelect,
  borderColor = "#0073E0",
  size = null,
  isRequired = false,
}) => {
  return (
    <ButtonsWrapper>
      <CardYes
        binaryValue={binaryValue}
        onClick={(e) => {
          e.preventDefault();
          onSelect(true);
        }}
        borderColor={borderColor}
        size={size}
      >
        <PYes binaryValue={binaryValue}>Oui</PYes>
      </CardYes>
      <CardNo
        binaryValue={binaryValue}
        onClick={(e) => {
          e.preventDefault();
          onSelect(false);
        }}
        borderColor={borderColor}
        size={size}
      >
        <PNo binaryValue={binaryValue}>Non</PNo>
      </CardNo>
      {/* Input value only here to handle the is required message in the browser */}
      {binaryValue === true || binaryValue === false ? null : (
        <input
          required={isRequired}
          onKeyDown={(event) => event.preventDefault()}
          autoComplete="off"
          value={undefined}
          onChange={() => {}}
        />
      )}
    </ButtonsWrapper>
  );
};
