// Function to calculate the lenght of the roof's "rampant"
export const rampantLengthCalculation = ({
  hauteurdelaToitureFaitage,
  hauteurdelaToitureGoutiere,
  distanceduPignon,
}: {
  hauteurdelaToitureFaitage?: number | null;
  hauteurdelaToitureGoutiere?: number | null;
  distanceduPignon?: number | null;
}): number => {
  if (
    hauteurdelaToitureFaitage &&
    hauteurdelaToitureGoutiere &&
    distanceduPignon
  ) {
    return (
      Math.round(
        Math.sqrt(
          Math.pow(hauteurdelaToitureFaitage - hauteurdelaToitureGoutiere, 2) +
            Math.pow(distanceduPignon, 2)
        ) * 100
      ) / 100
    ); // Round less or equal with two digits after the integer
  }
  return 0;
};
