import "./login.scss";

// Libraries import
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components import
import { AuthenticationWindow } from "../../containers/Authentication/AuthenticationWindow/AuthenticationWindow";
import { Header } from "../../containers/Header/Header";

// Local interface declaration
interface Props {
  isGetProfileInfoIsLoading: boolean;
}

export const Login = ({ isGetProfileInfoIsLoading }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/"); // Allow to come back to the login URL when disconnecting from another URL
  }, [navigate]);

  return (
    <>
      <Header />
      <div className="login">
        <AuthenticationWindow
          isGetProfileInfoIsLoading={isGetProfileInfoIsLoading}
        />
      </div>
    </>
  );
};
