// Librairie import
import { CSSTransition } from "react-transition-group";
import { useEffect, useRef, useState } from "react";

// Components import
import { InputContainer } from "../FormStepsWithSharedComponents/InputContainer/InputContainer";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import { SingleChoiceBtnContainer } from "../../Forms/FormStepsWithSharedComponents/singleChoiceBtnContainer/SingleChoiceBtnContainer";
import { BooleanChoiceBtnContainer } from "../../Forms/FormStepsWithSharedComponents/BooleanChoiceBtnContainer/BooleanChoiceBtnContainer";
import { TextAreaContainer } from "../../Forms/FormStepsWithSharedComponents/TextAreaContainer/TextAreaContainer";
import { UpDownButtonContainer } from "../../Forms/FormStepsWithSharedComponents/UpDownButtonContainer/UpDownButtonContainer";
import { UploadPicturesBtn } from "../../Forms/FormStepsWithSharedComponents/UploadPicturesBtn/UploadPicturesBtn";
import ChevronDown from "@web/shared/dist/assets/images/DesignSystemIcons/chevron-down.svg";
import { ITEWindow } from "../ITEWindow/ITEWindow";
import { ITEDoor } from "../ITEDoor/ITEDoor";
import { ITEOtherPointSingulier } from "../ITEOtherPointSingulier/ITEOtherPointSingulier";
import Plus from "@web/shared/dist/assets/images/DesignSystemIcons/plus.svg";
import { TextLink } from "@web/shared/dist/components/DesignSystem/TextLink/TextLink";
import { ITEPointSingulier } from "../ITEPointSingulier/ITEPointSingulier";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
// Services import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setITEWallQuestionAction,
  deleteITEWallAction,
  deleteITEOuvrantAction,
  createITEOuvrantAction,
  createITEOtherPointSingulierAction,
  deleteITEOtherPointSingulierAction,
} from "../../../redux/appActions";

// Interfaces import
import { IITEFormData } from "../../../interfaces/generalInterfaces";
import { KeyOfIITEWallAllQuestion } from "../../../interfaces/ITEFormInterface";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  wallIndex: number;
  formName?: "ITE";
  visitId?: string;
  isBlocksOpen: boolean[];
  setIsBlocksOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
  blocksHeight: number[];
  setBlocksHeight: React.Dispatch<React.SetStateAction<number[]>>;
  murId?: string | null;
}

export const ITEWall = ({
  ITEFormMutableData,
  wallIndex,
  murId,
  formName = "ITE",
  visitId,
  isBlocksOpen,
  setIsBlocksOpen,
  blocksHeight,
  setBlocksHeight,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const wall = ITEFormMutableData?.walls?.find(
    (wall) => wall.murId?.value === murId
  );

  const windowsForThisWall = ITEFormMutableData?.windows?.filter((window) => {
    return window.murId?.value === murId;
  });
  const numberOfWindowsForThisWall = windowsForThisWall?.length || 0;
  const doorsForThisWall = ITEFormMutableData?.doors?.filter(
    (door) => door.murId?.value === murId
  );
  const numberOfDoorsForThisWall = doorsForThisWall?.length || 0;
  const otherPointsSinguliersForThisWall =
    ITEFormMutableData?.otherPointsSinguliers?.filter(
      (otherPointSingulier) => otherPointSingulier.murId?.value === murId
    );
  const numberOfOtherPointsSinguliersForThisWall =
    otherPointsSinguliersForThisWall?.length || 0;

  const onClickDeleteWall = ({ murId }: { murId: string | null }) => {
    setIsDeleteModaleOpen(false);
    dispatch(
      deleteITEWallAction({
        visitId: visitId ?? null,
        murId,
      })
    );
  };

  const calcHeight = (DOMelement: HTMLElement, index: number) => {
    const height = DOMelement.offsetHeight;
    setBlocksHeight((state) => {
      const newState = [...state];
      newState[index] = height + 60;
      return newState;
    });
  };

  const onExit = (index: number) => {
    setBlocksHeight((state) => {
      const newState = [...state];
      newState[index] = 0;
      return newState;
    });
  };

  const onClickBlock = (index: number) => {
    setIsBlocksOpen((state) => {
      const newState = [...state];
      newState[index] = !state[index];
      return newState;
    });
  };

  const wallDomElement: React.LegacyRef<HTMLDivElement> = useRef(null);

  // If a window, a door or a pointSingulier is added we recalculate the DOM element height
  useEffect(() => {
    wallDomElement.current && calcHeight(wallDomElement.current, wallIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ITEFormMutableData?.windows?.length,
    ITEFormMutableData?.doors?.length,
    ITEFormMutableData?.otherPointsSinguliers?.length,
    ITEFormMutableData?.pointsSinguliers?.length,
  ]);

  const onClickOnWallQuestion = ({
    questionName,
    value,
    wallId,
    pictureUrl,
  }: {
    questionName: KeyOfIITEWallAllQuestion;
    value?: string;
    wallId: string | null;
    pictureUrl?: string;
  }) => {
    dispatch(
      setITEWallQuestionAction({
        value,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId ?? null,
        wallId,
        pictureUrl,
      })
    );
  };

  const onClickOnUpdateNumberOfOuvrant = ({
    newValue,
    ouvrant,
    murId,
    numberOfOuvrantsForThisWall,
    ouvrantId,
  }: {
    newValue: number;
    ouvrantId?: string | null;
    murId: string | null;
    numberOfOuvrantsForThisWall: number;
    ouvrant: "windows" | "doors";
  }) => {
    if (newValue > numberOfOuvrantsForThisWall) {
      dispatch(
        createITEOuvrantAction({
          visitId: visitId ?? null,
          murId,
          ouvrant,
        })
      );
      return;
    }

    // If numberOfOuvrantsForThisWall > 0 we delete the last element (the last window)
    numberOfOuvrantsForThisWall &&
      numberOfOuvrantsForThisWall > 0 &&
      dispatch(
        deleteITEOuvrantAction({
          visitId: visitId ?? null,
          isDeleteLastElement: true,
          murId,
          ouvrant,
          ouvrantId,
        })
      );

    return;
  };

  const onClickOnUpdateNumberOfOtherPointsSinguliers = ({
    newValue,
    murId,
    numberOfOtherPointsSinguliersForThisWall,
    otherPointSingulierId,
  }: {
    newValue: number;
    otherPointSingulierId?: string | null;
    murId: string | null;
    numberOfOtherPointsSinguliersForThisWall: number;
  }) => {
    if (newValue > numberOfOtherPointsSinguliersForThisWall) {
      dispatch(
        createITEOtherPointSingulierAction({
          visitId: visitId ?? null,
          murId,
        })
      );
      return;
    }

    // If numberOfOuvrantsForThisWall > 0 we delete the last element (the last window)
    numberOfOtherPointsSinguliersForThisWall &&
      numberOfOtherPointsSinguliersForThisWall > 0 &&
      dispatch(
        deleteITEOtherPointSingulierAction({
          visitId: visitId ?? null,
          isDeleteLastElement: true,
          murId,
          otherPointSingulierId,
        })
      );

    return;
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer le mur ${wallIndex + 1} ?`}
      >
        <div className="Modale_Buttons">
          <CTAButton
            name="Non"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          />
          <CTAButton
            name="Oui"
            family="vitee"
            onClick={() => onClickDeleteWall({ murId: murId || null })}
          />
        </div>
      </Modale>
      <div className="formPagesITE__Block" key={wallIndex}>
        <div className="formPagesITE__BlockTitle">
          <div
            className="formPagesITE__BlockTitleLeftContainer"
            onClick={() => onClickBlock(wallIndex)}
          >
            <div className="formPagesITE__BlockTitleLeft">
              <div className="formPagesITE__Chevron">
                <CSSTransition
                  in={isBlocksOpen[wallIndex]}
                  timeout={300}
                  classNames={"tab-chevron"}
                >
                  <img src={ChevronDown} alt="arrow-down" />
                </CSSTransition>
              </div>
              <H2 title={"Mur " + (wallIndex + 1)} />
            </div>
          </div>
          <UploadPicturesBtn
            questionName="wallPictures"
            uploadedPictures={wall?.wallPictures?.pictures}
            formName={formName}
            visitId={visitId}
            wallIndex={wallIndex}
            onUpload={(pictureUrl) =>
              onClickOnWallQuestion({
                questionName: "wallPictures",
                wallId: murId || null,
                pictureUrl: pictureUrl,
              })
            }
          />
        </div>
        <div className="formPagesITE__Trait" />
        <div
          className="formPagesITE__BlockContainer"
          style={{ height: blocksHeight[wallIndex] }}
        >
          <CSSTransition
            in={isBlocksOpen[wallIndex]}
            unmountOnExit
            timeout={300}
            classNames={"tab-content"}
            onEnter={(DomElement: HTMLElement) =>
              calcHeight(DomElement, wallIndex)
            }
            onExit={() => onExit(wallIndex)}
          >
            <div className="formPagesITE__BlockContent" ref={wallDomElement}>
              <div className="formPagesITE__Question">
                <SingleChoiceBtnContainer
                  title={<>Le&nbsp;mur&nbsp;est</>}
                  gap={false}
                  formName={formName}
                  userAnswer={wall?.actionssurlemur}
                  questionName="actionssurlemur"
                  visitId={visitId}
                  wallIndex={wallIndex}
                  isPictureNeeded={false}
                  optionList={[
                    {
                      label: "À isoler",
                      value: "629950000",
                    },
                    {
                      label: "Crépis seul",
                      value: "629950001",
                    },
                  ]}
                  areAnswersAlign={true}
                  onCardClick={(value) => {
                    onClickOnWallQuestion({
                      questionName: "actionssurlemur",
                      wallId: murId || null,
                      value: value,
                    });
                  }}
                />
              </div>
              <div className="formPagesITE__Question">
                <InputContainer
                  title="Surface du mur (m²) :"
                  questionName="surfaceisoler"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  iconType="squaredMeters"
                  placeholder="XXXX"
                  type="number"
                  wallIndex={wallIndex}
                  userAnswer={wall?.surfaceisoler}
                  onChange={(value) => {
                    onClickOnWallQuestion({
                      questionName: "surfaceisoler",
                      wallId: murId || null,
                      value: value,
                    });
                  }}
                />
              </div>
              <div className="formPagesITE__Question">
                <InputContainer
                  title="Mètres linéaires à comptabiliser pour le profil de départ :"
                  questionName="metreslineairescomptabiliserprofildepart"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  iconType="meters"
                  wallIndex={wallIndex}
                  placeholder="XXXX"
                  type="number"
                  userAnswer={wall?.metreslineairescomptabiliserprofildepart}
                  onChange={(value) => {
                    onClickOnWallQuestion({
                      questionName: "metreslineairescomptabiliserprofildepart",
                      wallId: murId || null,
                      value: value,
                    });
                  }}
                />
              </div>
              <div className="formPagesITE__Question">
                <InputContainer
                  title="Mètres linéaires à comptabiliser pour le profil de couronnement :"
                  questionName="metreslineairesprofilcouronnement"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  iconType="meters"
                  placeholder="XXXX"
                  type="number"
                  wallIndex={wallIndex}
                  userAnswer={wall?.metreslineairesprofilcouronnement}
                  onChange={(value) => {
                    onClickOnWallQuestion({
                      questionName: "metreslineairesprofilcouronnement",
                      wallId: murId || null,
                      value: value,
                    });
                  }}
                />
              </div>
              <div className="formPagesITE__Question">
                <BooleanChoiceBtnContainer
                  title=" Est-ce qu'un câble à haute densité EDF et/ou câble fibre est présent sur le mur ?"
                  questionName="presenceduncableahautedensiteEDF"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  wallIndex={wallIndex}
                  areAnswersAlign={false}
                  userAnswer={wall?.presenceduncableahautedensiteEDF}
                  onCardClick={(value) => {
                    onClickOnWallQuestion({
                      questionName: "presenceduncableahautedensiteEDF",
                      wallId: murId || null,
                      value: value.toString(),
                    });
                  }}
                />
              </div>
              <div className="formPagesITE__Trait" />
              <div className="formPagesITE__Question">
                <UpDownButtonContainer
                  title="Nombre de fenêtre :"
                  formName={formName}
                  areAnswersAlign={true}
                  wallIndex={wallIndex}
                  visitId={visitId}
                  isPictureNeeded={false}
                  userAnswer={{
                    value: numberOfWindowsForThisWall.toString(),
                  }}
                  onPlusMinusClick={(value) =>
                    onClickOnUpdateNumberOfOuvrant({
                      murId: murId || null,
                      newValue: value,
                      numberOfOuvrantsForThisWall: numberOfWindowsForThisWall,
                      ouvrant: "windows",
                      ouvrantId:
                        windowsForThisWall?.[numberOfWindowsForThisWall - 1]
                          ?.ouvrantId?.value, // Last window of the windows array
                    })
                  }
                />
              </div>
              {windowsForThisWall?.map((window, windowIndex) => {
                return (
                  <ITEWindow
                    ITEFormMutableData={ITEFormMutableData}
                    murId={window.murId?.value || null}
                    windowId={window.ouvrantId?.value || null}
                    windowIndex={windowIndex}
                    visitId={visitId}
                    key={window.ouvrantId?.value}
                    wallIndex={wallIndex}
                  />
                );
              })}
              <div className="formPagesITE__Question">
                <UpDownButtonContainer
                  title="Nombre de porte :"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  wallIndex={wallIndex}
                  userAnswer={{
                    value: numberOfDoorsForThisWall.toString(),
                  }}
                  onPlusMinusClick={(value) =>
                    onClickOnUpdateNumberOfOuvrant({
                      murId: murId || null,
                      newValue: value,
                      numberOfOuvrantsForThisWall: numberOfDoorsForThisWall,
                      ouvrant: "doors",
                    })
                  }
                />
              </div>
              {doorsForThisWall?.map((door, doorIndex) => {
                return (
                  <ITEDoor
                    ITEFormMutableData={ITEFormMutableData}
                    murId={murId || null}
                    doorIndex={doorIndex}
                    doorId={door.ouvrantId?.value || null}
                    visitId={visitId}
                    key={doorIndex}
                  />
                );
              })}
              <div className="formPagesITE__Question">
                <TextAreaContainer
                  title={`Commentaire sur le mur ${wallIndex + 1} :`}
                  questionName="commentairesurlemur"
                  formName={formName}
                  visitId={visitId}
                  isPictureNeeded={false}
                  userAnswer={wall?.commentairesurlemur}
                  wallIndex={wallIndex}
                  onChange={(value) => {
                    onClickOnWallQuestion({
                      questionName: "commentairesurlemur",
                      wallId: murId || null,
                      value: value,
                    });
                  }}
                  placeholder={"Ton commentaire ici"}
                />
              </div>
              <div className="formPagesITE__TraitDotted" />
              <div className="formPagesITE__SubTitle">
                <H2 title={`Points singulier du mur ${wallIndex + 1}`} />
              </div>
              <ITEPointSingulier
                pointSingulierName="isGoutiere"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Gouttière :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isGroupeExterieur"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Groupe extérieur&nbsp;:"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isEvacuationEaux"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Évacuation des eaux usées&nbsp;:"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isMiseEnGoulotteCableExterieur"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Mise en goulotte câble extérieur :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isPriseElectrique"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Prise électrique :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isRobinetExterieur"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Robinet d’extérieur :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isAppliqueMurale"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Applique murale :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isStoreBannes"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Store bannes :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isParabole"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Parabole :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isGondDeVolet"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Gond de volet :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isGardeCorpFenetreScelllement"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Garde corps de fenêtre en scellement :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isGardeCorpFenetrePlatine"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Garde corps de fenêtre sur platine :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isAérationGrille"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Aération grille :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isReagreage"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Réagréage :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isMarquiseDePorte"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Marquise de porte :"
                wallIndex={wallIndex}
              />
              <div className="formPagesITE__Trait" />
              <ITEPointSingulier
                pointSingulierName="isMarquiseDeFenetre"
                pointsSinguliers={ITEFormMutableData?.pointsSinguliers}
                visitId={visitId}
                wallId={murId || null}
                title="Marquise de fenêtre :"
                wallIndex={wallIndex}
              />
              {otherPointsSinguliersForThisWall?.map(
                (otherPointSingulier, pointSingulierIndex) => {
                  return (
                    <ITEOtherPointSingulier
                      ITEFormMutableData={ITEFormMutableData}
                      murId={murId || null}
                      otherPointSingulier={otherPointSingulier}
                      visitId={visitId}
                      key={pointSingulierIndex}
                      wallIndex={wallIndex}
                    />
                  );
                }
              )}
              <div className="formPagesITE__Trait" />
              <div className="formPagesITE__Button">
                <div className={"formPagesITE__Button__Wrapper"}>
                  <CTAButton
                    name="Ajouter un point singulier"
                    family="vitee"
                    category="tertiary"
                    iconSrc={Plus}
                    onClick={() =>
                      onClickOnUpdateNumberOfOtherPointsSinguliers({
                        murId: murId || null,
                        newValue: numberOfOtherPointsSinguliersForThisWall + 1,
                        numberOfOtherPointsSinguliersForThisWall:
                          numberOfOtherPointsSinguliersForThisWall,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="formPagesITE__DeleteWall">
          <TextLink
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
            name={`Supprimer le mur ${wallIndex + 1}`}
            color="red"
          />
        </div>
      </div>
    </>
  );
};
