import "./potentialTranchees.scss";

// Components import
import * as FormComponents from "../index";

// Data import
import { potentialTrancheeQuestions } from "../../../../data/forms/Photovoltaique";

// Service import
import { useAppSelector } from "../../../../redux/store/hook";

// Interfaces import
import {
  formNames,
  IFormQuestions,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  formName: formNames;
  questionData: IFormQuestions;
  visitId?: string;
  answerIndex?: number;
}

export const PotentialTranchees = ({
  formName,
  visitId,
  answerIndex,
}: Props) => {
  const { formsMutableData } = useAppSelector((state) => state);

  const { formCurrentBranchs, nombreTrancheeAPrevoir } =
    formsMutableData.photovoltaique;

  const renderQuestion = (
    nombreTrancheeAPrevoir: string | number | null | undefined
  ) => {
    let content = [];
    if (typeof nombreTrancheeAPrevoir === "number") {
      for (let i = 0; i < nombreTrancheeAPrevoir; i++) {
        let trancheeIndex = i;
        content.push(
          <div className="potentialTranchees__Tranchee" key={i}>
            <p>{"Tranchée " + (i + 1)}</p>
            {potentialTrancheeQuestions.map((category) => {
              return category.questions
                .filter((question) => {
                  // We filter the questions according to the branch we want to display
                  return question.branchs.every((branch) =>
                    formCurrentBranchs.includes(branch)
                  );
                })
                .map((question, index) => {
                  const Component = FormComponents[question.type];
                  return (
                    <div className="potentialTranchees__Question" key={index}>
                      <Component
                        questionData={question}
                        formName={formName}
                        answerIndex={trancheeIndex}
                        visitId={visitId}
                      />
                    </div>
                  );
                });
            })}
          </div>
        );
      }
    }
    return content;
  };

  return (
    <div className="potentialTranchees">
      {renderQuestion(nombreTrancheeAPrevoir?.value)}
    </div>
  );
};
