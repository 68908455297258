import "./potentialPans.scss";

// Components import
import { PotentialPan } from "../../../../components/Form/PotentialPan/PotentialPan";

// Service import
import { useAppSelector } from "../../../../redux/store/hook";

// Interfaces import
import {
  formNames,
  IFormQuestions,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  formName: formNames;
  questionData: IFormQuestions;
  visitId?: string;
}

export const PotentialPans = ({ formName, visitId }: Props) => {
  const { visiteTechniqueInfo, formsMutableData } = useAppSelector(
    (state) => state
  );

  if (formName === "photovoltaique") {
    const { panInformations, nombredePantoEquip, formCurrentBranchs } =
      formsMutableData[formName];
    const numberOfPan = nombredePantoEquip?.value ?? 1; // Default value is 1
    return (
      <div className="potentialPans">
        <div className="potentialPans__ProductName">
          {!visiteTechniqueInfo.products ||
          visiteTechniqueInfo.products.length === 0 ? (
            <h4>Nom du produit non renseigné</h4>
          ) : typeof visiteTechniqueInfo.products === "object" ? (
            visiteTechniqueInfo.products?.map((product, index) => {
              return <h4 key={index}>{product}</h4>;
            })
          ) : (
            //if string
            <h4>{visiteTechniqueInfo.products}</h4>
          )}
        </div>
        {panInformations
          ?.filter((panInformation, index) => index < numberOfPan)
          .map((panInformation, index) => {
            return (
              <div className="potentialPans__Pan" key={index}>
                <PotentialPan
                  panInformation={panInformation}
                  formName={formName}
                  answerIndex={index}
                  visitId={visitId}
                  formCurrentBranchs={formCurrentBranchs}
                  formsMutableData={formsMutableData[formName]}
                />
              </div>
            );
          })}
      </div>
    );
  }
  return <></>;
};
