// Import libraries
import { createStore } from "redux";
import { appReducer } from "../appReducer";
import { composeWithDevTools } from "@redux-devtools/extension";

export const store = createStore(appReducer, composeWithDevTools());

// Type declaration for useSelector
export type RootState = ReturnType<typeof store.getState>;
// Type declaration for useDispatch
export type AppDispatch = typeof store.dispatch;
