import "./UpDownButtonContainer.scss";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";
import { Quantite } from "@web/shared/dist/components/DesignSystem/Boutons/Quantite/Quantite";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

interface Props {
  title: string;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: IQuestionRedux;
  questionName?: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onPlusMinusClick?: (value: number) => void;
  onUploadPicture?: (pictureUrl: string) => void;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const UpDownButtonContainer = ({
  title,
  formName,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  onPlusMinusClick,
  onUploadPicture,
  wallIndex,
  pointSingulierName,
}: Props) => {
  return (
    <div className="UpDownButtonContainer">
      <section
        className={`UpDownButtonContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"UpDownButtonContainer__QuestionTitle"}>{title}</p>

        <Quantite
          value={Number(userAnswer?.value) ?? undefined}
          onPlusMinusClick={(value) => {
            onPlusMinusClick && onPlusMinusClick(value);
          }}
        />
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
