import "./FinalCommentComponent.scss";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { TextareaComponent } from "@web/shared/dist/components/Old/FormComponents/TextareaComponent/TextareaComponent";
import { CheckboxIpad } from "@web/shared/dist/components/Old/FormComponents/CheckboxIpad/CheckboxIpad";

// Services import
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import {
  setInputTextAction,
  setSelectedInputAction,
} from "../../../redux/appActions";

// Interface import
import { formNames } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  onClickButton: () => void;
  formName: formNames;
}

export const FinalCommentComponent = ({ onClickButton, formName }: Props) => {
  const dispatch = useAppDispatch();
  const textAreaInput = useAppSelector(
    (state) => state.formsMutableData[formName].commentairesRapportVT
  );
  const checkBoxInput =
    useAppSelector((state) => state.formsMutableData[formName].statutRapportVT)
      ?.value === 629950006
      ? true
      : false; // "Envoyé - à contrôler"

  // Event handler
  const setTextareaInput = (textareaInput: string) => {
    // If the input of the user is a number we store it as a number

    dispatch(
      setInputTextAction({
        inputText: textareaInput,
        formName,
        formQuestionName: "commentairesRapportVT",
      })
    );
  };

  const setCheckboxInput = () => {
    if (checkBoxInput) {
      dispatch(
        setSelectedInputAction({
          selectedInput: 629950000, // "Validé"
          formQuestionName: "statutRapportVT",
          formName,
        })
      );
      return;
    }
    dispatch(
      setSelectedInputAction({
        selectedInput: 629950006, // "Envoyé - à contrôler"
        formQuestionName: "statutRapportVT",
        formName,
      })
    );

    // If status rapport VT is "à controller" we ensure that the resultat status VT is null
    dispatch(
      setSelectedInputAction({
        selectedInput: null,
        formQuestionName: "resultatVTStatus",
        formName,
      })
    );

    return;
  };

  return (
    <div className="FinalCommentComponent">
      <div className="FinalCommentComponent__TextBlock">
        <h3>Vous allez terminer votre visite technique.</h3>
        <p>
          Précisez dans la zone ci-dessous tous les éléments à porter à
          l'attention du pôle Opération et/ou Commerce.
        </p>
      </div>
      <div className="FinalCommentComponent__TextArea">
        <TextareaComponent
          value={textAreaInput?.value || ""}
          onChange={(event) => setTextareaInput(event.target.value)}
        />
      </div>
      <div className="FinalCommentComponent__Checkbox">
        <CheckboxIpad onClick={setCheckboxInput} checked={checkBoxInput} />
        <p>Demander une vérification de l'équipe opération</p>
      </div>
      <div className="FinalCommentComponent__Buttons">
        <MainButton
          name="Envoyer le rapport"
          onClick={onClickButton}
          size="big"
        />
      </div>
    </div>
  );
};
