// Services import
import { pointsSinguliersDictionnary } from "./pointsSinguliersDictionnary";
import { valueToBoolean } from "../../util/valueToBoolean";
import { StringToNumber } from "./formatStringToNumber";

// Interfaces import
import {
  IITEOuvrant,
  IITEPointsSinguliers,
  ICRMWall,
  ICommentairePointsSinguliersItem,
} from "../../../interfaces/ITEFormInterface";

import { IFormsMutableData } from "../../../interfaces/generalInterfaces";

interface IFormatITEWallsBeforeSendingToCRM {
  formGlobalMutableData: IFormsMutableData;
}

interface IFormatCommentaireSingulier {
  otherPointsSinguliers: IITEOuvrant[];
  pointsSinguliers: IITEPointsSinguliers[];
  murId: string | null | undefined;
  windows: IITEOuvrant[];
}

export const formatITEWallsBeforeSendingToCRM = ({
  formGlobalMutableData,
}: IFormatITEWallsBeforeSendingToCRM) => {
  return (
    formGlobalMutableData.ITE?.walls?.map((wall, wallIndex) => {
      const windows =
        formGlobalMutableData.ITE?.windows
          ?.filter((window) => window.murId?.value === wall.murId?.value)
          .map((window, windowIndex) => {
            return {
              ...(window.ouvrantId?.value &&
                !window.ouvrantId?.value?.startsWith("VITEE") && {
                  ouvrantId: window.ouvrantId?.value,
                }),
              name: window.ouvrantName?.value || "",
              hauteur: StringToNumber(window.windowHeigh?.value) || 0,
              largeur: StringToNumber(window.windowWidth?.value) || 0,
            };
          }) || [];

      const doors =
        formGlobalMutableData.ITE?.doors
          ?.filter((door) => door.murId?.value === wall.murId?.value)
          .map((door, doorIndex) => {
            return {
              ...(door.ouvrantId?.value &&
                !door.ouvrantId?.value?.startsWith("VITEE") && {
                  ouvrantId: door.ouvrantId?.value,
                }),
              name: door.ouvrantName?.value || "",
              hauteur: StringToNumber(door.doorHeigh?.value) || 0,
              largeur: StringToNumber(door.doorWidth?.value) || 0,
            };
          }) || [];

      const wallFormatted: ICRMWall = {
        name: `Mur n°${wallIndex + 1}`,
        actionssurlemur: StringToNumber(wall.actionssurlemur?.value),
        surfaceisoler: StringToNumber(wall.surfaceisoler?.value) || 0,
        metreslineairescomptabiliserprofildepart:
          StringToNumber(
            wall.metreslineairescomptabiliserprofildepart?.value
          ) || 0,
        metreslineairesprofilcouronnement: StringToNumber(
          wall.metreslineairesprofilcouronnement?.value || 0
        ),
        presenceduncableahautedensiteEDF:
          valueToBoolean(wall.presenceduncableahautedensiteEDF?.value) ?? false,
        nombredefenetre: windows.length,
        nombredeporte: doors.length,
        commentairesurlemur: wall.commentairesurlemur?.value || "",
        commentairePointssinguliers: JSON.stringify(
          formatCommentairePointsSinguliers({
            pointsSinguliers: formGlobalMutableData.ITE.pointsSinguliers || [],
            otherPointsSinguliers:
              formGlobalMutableData.ITE.otherPointsSinguliers || [],
            murId: wall.murId?.value,
            windows: formGlobalMutableData.ITE.windows || [],
          })
        ),
        ouvrantItem: [...windows, ...doors],
        ...(wall.murId?.value &&
          !wall.murId.value?.startsWith("VITEE") && {
            murId: wall.murId?.value,
          }),
      };
      return wallFormatted || [];
    }) || []
  );
};

const formatCommentairePointsSinguliers = ({
  pointsSinguliers,
  otherPointsSinguliers,
  murId,
  windows,
}: IFormatCommentaireSingulier) => {
  // A point singulier or a otherPointsSingulier = an extra charge
  const allPointsSingulier = [...pointsSinguliers, ...otherPointsSinguliers]
    .filter((pointSingulier) => pointSingulier.murId?.value === murId)
    .map((pointSingulier) => {
      const pointSingulierObj: ICommentairePointsSinguliersItem = {
        nom:
          pointsSinguliersDictionnary?.[
            pointSingulier.pointSingulierName?.value || ""
          ]?.title || pointSingulier.pointSingulierName?.value,
        quantité:
          StringToNumber(pointSingulier.pointSingulierQuantity?.value) || 0,
        montant:
          (pointsSinguliersDictionnary?.[
            pointSingulier.pointSingulierName?.value || ""
          ]?.amount || 0) *
            StringToNumber(
              pointSingulier?.pointSingulierQuantity?.value || 0
            ) ||
          StringToNumber(pointSingulier.pointSingulierAmount?.value) ||
          0,
        reposer:
          pointSingulier.isPointSingulierAReposer?.value === "true"
            ? "oui"
            : "non",
        windowName:
          windows.find(
            (window) =>
              window.ouvrantId?.value === pointSingulier.ouvrantId?.value
          )?.ouvrantName?.value || null,
      };
      return pointSingulierObj;
    });

  return JSON.stringify(allPointsSingulier);
};
