import React from "react";
import "./infoBoxForm.scss";

// image import
import lightBulbPicto from "../../assets/images/pictos/light-bulb-light-blue-background.svg";

interface IProps {
  textContent: string | JSX.Element;
}

export const InfoBoxForm = ({ textContent }: IProps) => {
  return (
    <div className="InfoBoxForm">
      <section className="InfoBoxForm__ImageContainer">
        <img src={lightBulbPicto} alt="" width={24} height={24} />
      </section>
      <section className="InfoBoxForm__TextContainer">
        <p>{textContent}</p>
      </section>
    </div>
  );
};
