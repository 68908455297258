import "./visitDetailsProductCard.scss";
import { useNavigate, useLocation } from "react-router-dom";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { SecondaryButton } from "../../../components/SecondaryButton/SecondaryButton";
import { Modal } from "../../../components/Modal/Modal";
import { SendReportConfirmationModalContent } from "../../Forms/SendRepportConfirmationModalContent/SendReportConfirmationModalContent";
import { VisitNotDoableModal } from "../VisitNotDoableModal/VisitNotDoableModal";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useModal } from "../../../services/useModal";
import { valueToLabelDictionnary } from "../../../services/Forms/valueToLabelDictionnary";
import { useSaveVTCommonInfoToCRM } from "../../../services/Forms/useSaveVTCommonInfoToCRM";

// Interfaces import
import { formNames } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  visiteTechniqueId: string;
  formName: formNames;
}

export const VisitDetailsProductCard = ({
  visiteTechniqueId,
  formName,
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    toggleModal: toggleModalVTNotDoable,
    isVisible: isVTNotDoableModalIsVisible,
  } = useModal();
  const {
    toggleModal: toggleConfirmationModal,
    isVisible: isConfirmationModalVisible,
  } = useModal();
  const {
    saveVTCommonInfoToCRM,
    isLoading,
    errorMessage,
    isSuccessfull,
    resetStates,
  } = useSaveVTCommonInfoToCRM();
  const {
    visiteTechniqueInfo,
    userProfileInfo,
    userAuthInfo,
    formsMutableData,
  } = useAppSelector((state) => state);
  const hasVisitBeenStarted = localStorage.getItem(visiteTechniqueId)
    ? true
    : false;

  const dateTimeUTC = new Date(visiteTechniqueInfo.dateVisite || "");

  // Events handlers
  const onClickCTAVT = () => {
    navigate(`${pathname}/map`);
    visiteTechniqueInfo.statutVT !== 629950003 && // 629950003 = "En cours"
      saveVTCommonInfoToCRM({
        VTCommonInfo: { statutVT: 629950003 },
        VTId: visiteTechniqueId,
        crmToken: userAuthInfo.userToken,
        eeroToken: userAuthInfo.eeroToken,
      });
  };

  const onClickSendVisitNotDoable = () => {
    saveVTCommonInfoToCRM({
      VTCommonInfo: {
        statutVT: 629950001, // À planifier
        statutRapportVT: formsMutableData[formName].statutRapportVT?.value,
        dateReceptionVT: new Date().toISOString(),
        tempsPasse: "inconnu",
      },
      VTId: visiteTechniqueId,
      crmToken: userAuthInfo.userToken,
      eeroToken: userAuthInfo.eeroToken,
    });
  };

  const onClickCloseConfirmationModal = () => {
    if (visiteTechniqueId && isSuccessfull) {
      window.location.reload();
    }
    toggleConfirmationModal();
  };

  return (
    <>
      <Modal
        isVisible={isVTNotDoableModalIsVisible}
        onClose={toggleModalVTNotDoable}
      >
        <VisitNotDoableModal
          onClickButton={() => {
            toggleModalVTNotDoable();
            toggleConfirmationModal();
          }}
          visiteTechniqueId={visiteTechniqueId}
          formName={formName}
        />
      </Modal>
      <Modal
        isVisible={isConfirmationModalVisible}
        onClose={onClickCloseConfirmationModal}
      >
        <SendReportConfirmationModalContent
          onClickNo={toggleConfirmationModal}
          onClickYes={onClickSendVisitNotDoable}
          isLoading={isLoading}
          errorMessage={errorMessage}
          isSaveVTSuccessfull={isSuccessfull}
          resetSaveToCRMRequestStates={resetStates}
        />
      </Modal>
      <div className="visitDetailsProductCard">
        <div className="visitDetailsProductCard__Container">
          <div className="visitDetailsProductCard__LeftBlock rightLeftBlock">
            <div className="title">
              {!visiteTechniqueInfo.products ||
              visiteTechniqueInfo.products.length === 0 ? (
                <h4>Nom du produit non renseigné</h4>
              ) : typeof visiteTechniqueInfo.products === "object" ? (
                visiteTechniqueInfo.products?.map((product, index) => {
                  return <h4 key={index}>{product}</h4>;
                })
              ) : (
                //if string
                <h4>{visiteTechniqueInfo.products}</h4>
              )}
            </div>
            <div className="techVisitDetails">
              <p>
                Date de la visite :
                <span className="techVisitDetails__info">
                  {visiteTechniqueInfo.dateVisite
                    ? dateTimeUTC.toLocaleDateString()
                    : "date non renseignée"}
                </span>
              </p>
              <p>
                Heure de la visite :
                {visiteTechniqueInfo.dateVisite ? (
                  <span className="techVisitDetails__info">{`${dateTimeUTC?.getHours()}h${
                    dateTimeUTC.getMinutes().toLocaleString().length === 1
                      ? "0" + dateTimeUTC.getMinutes()
                      : dateTimeUTC.getMinutes()
                  }`}</span>
                ) : (
                  " heure non renseignée"
                )}
              </p>
              <p>
                Nom du technicien :{" "}
                <span className="techVisitDetails__info">
                  {userProfileInfo.firstname + " " + userProfileInfo.lastname}
                </span>
              </p>
            </div>
            <div className="comment">
              <p>
                Commentaire :{" "}
                <span className="techVisitDetails__info">
                  {visiteTechniqueInfo.commentaires}
                </span>
              </p>
            </div>
          </div>
          <div className="visitDetailsProductCard__RightBlock rightLeftBlock">
            <div className="visitDetailsProductCard__RightBlock__buttons">
              <div className="start-visit-button">
                <MainButton
                  name={
                    visiteTechniqueInfo.statutVT === 629950000 // Réalisée
                      ? "Modifier la visite"
                      : hasVisitBeenStarted
                      ? "Reprendre la visite"
                      : "Démarrer la visite"
                  }
                  onClick={onClickCTAVT}
                  size="big"
                />
              </div>
              <div className="impossibleVT-button">
                <SecondaryButton
                  name="VT non réalisable"
                  onClick={toggleModalVTNotDoable}
                />
              </div>
            </div>
            <div className="select-visit-status">
              <label htmlFor="visitStatus">Statut de la visite</label>
              <div className="select-visit-status-value">
                <p>
                  {visiteTechniqueInfo.statutVT &&
                    valueToLabelDictionnary(
                      "statutVt",
                      visiteTechniqueInfo.statutVT
                    )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
