// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";
import { useNavigate } from "react-router-dom";

// Interfaces import
import { IResetPasswordRequestAnswer } from "../../interfaces/generalInterfaces";

// Local interfaces declaration
interface IFunctionReturn {
  sendResetClientPasswordRequest: (
    resetCode: string,
    password: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

export interface IResetPasswordPayload {
  newPassword: string;
}

export interface IDataSendFromResetPasswordToLogin {
  message: string;
}

// useResetClientPassword : return tools to send reset password request
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function sendResetClientPasswordRequest
//     Function to call to send reset password request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (missing parameters or request error message)

export const useResetClientPassword = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const navigate = useNavigate();
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // sendResetClientPasswordRequest : send reset password Post request
  // Parameters
  // ----------
  // resetCode: String
  //      reset code retrieved from the URL from where the user is comming
  // password: String
  //      the password of the user
  // email: String
  //      the email of the user
  // ----------
  // Returns : void -> if successfull redirect to login page. Update error message otherwise.

  const sendResetClientPasswordRequest = async (
    resetCode: string,
    password: string
  ): Promise<void> => {
    setErrorMessage("");
    if (resetCode && password) {
      const requestPayload: IResetPasswordPayload = {
        newPassword: password,
      };
      const response = await axiosPostRequest<IResetPasswordRequestAnswer>(
        `${BACKEND_URL}/api/User/${resetCode}/ResetPassword`,
        requestPayload
      );

      if (response?.status === 200) {
        // return <Navigate to="/home" replace state={state} />
        navigate("/", {
          state: {
            message:
              "Réinitialisation réussie ! Vous pouvez vous connecter avec votre nouveau mot de passe.",
          },
        });
      } else if (response?.data?.error) {
        // Retrieve error message form error reponse
        const textError = response?.data?.error;
        const textErrorClean = textError.substring(
          textError.indexOf("[[") + 3,
          textError.lastIndexOf("]]") - 1
        );
        setErrorMessage(textErrorClean);
      } else {
        setErrorMessage("Une erreur est survenue. Merci de réessayer.");
      }
    } else {
      setErrorMessage("Champs manquants.");
    }
  };

  return {
    sendResetClientPasswordRequest,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
