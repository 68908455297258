// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../redux/store/hook";
import { ICalepinage } from "../../interfaces/generalInterfaces";
import { setCalepinageAction } from "../../redux/appActions";

// Interfaces import
import { formNames } from "../../interfaces/generalInterfaces";

interface IProps {
  latitude: number | null;
  longitude: number | null;
  userToken: string | null;
  visitId: string | null;
  formName: formNames;
  client_firstname?: string | null;
  client_lastname?: string | null;
  technicien_vt_firstname?: string | null;
  technicien_vt_lastname?: string | null;
}

export const useGetCalepinage = () => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getCalepinage = async ({
    latitude,
    longitude,
    userToken,
    visitId,
    formName,
    client_firstname,
    client_lastname,
    technicien_vt_firstname,
    technicien_vt_lastname,
  }: IProps): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<ICalepinage>(
      `${process.env.REACT_APP_EERO_URL}/vitee/getCalepinage?lat=${latitude}&lng=${longitude}&client_firstname=${client_firstname}&client_lastname=${client_lastname}&technicien_vt_firstname=${technicien_vt_firstname}&technicien_vt_lastname=${technicien_vt_lastname}`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    if (response) {
      dispatch(
        setCalepinageAction({
          calepinage: response,
          visitId,
          formName,
        })
      );
    }
  };

  return {
    getCalepinage,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
