// Other imports
import { useAppDispatch } from "../../redux/store/hook";
import { setUserProfileInfoAction } from "../../redux/appActions";

// Interfaces import
import { IUserProfileInfo } from "../../interfaces/generalInterfaces";

// Interfaces delcaration
interface IFunctionReturn {
  setUserProfileInfo: (userProfileInfo: IUserProfileInfo | null) => void;
}

// useSetUserProfileInfo : return tools to save user profile info in redux store and session storage
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function setUserProfileInfo
//     Function to create/delete user's profile info in redux store and sessions storage

export const useSetUserProfileInfo = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  // setUserProfileInfo : create/delete user's profile info in session storage and redux store
  // Parameters
  // ----------
  // userProfileInfo: see IUserProfileInfo interface
  //      object containing the profile information of the user
  // Returns
  // ----------
  // Void

  const setUserProfileInfo = (
    userProfileInfo: IUserProfileInfo | null
  ): void => {
    if (userProfileInfo) {
      localStorage.setItem("userProfileInfo", JSON.stringify(userProfileInfo));
      dispatch(setUserProfileInfoAction({ userProfileInfo: userProfileInfo }));
    } else {
      localStorage.removeItem("userProfileInfo");
      dispatch(
        setUserProfileInfoAction({
          userProfileInfo: {
            id: null,
            username: null,
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
          },
        })
      );
    }
  };
  return { setUserProfileInfo };
};
