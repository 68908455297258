// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
// interface declaration
interface IImage {
  imgSrc?: string;
  imgAltTxt?: string;
}
interface IOption extends IImage {
  label: string;
  value: string;
}

export interface ChoixUniqueProps {
  /** label/value objects list represented as a list of items **/
  optionsList: IOption[] | null;
  /** function to be called on the single item click (the user can select only one item in the list) **/
  onSelect: (option: IOption) => void;
  color?: string;
  optionSelected?: IOption;
  /** if true there will be no gap between cards (cards will be colled to one another) **/
  gap?: boolean;
  size?: "small" | "normal";
}
const Container = styled.div`
  display: flex;
  width: 100%;
`;
const OptionsListContainer = styled.div<{
  gap?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => (props.gap ? "15px" : 0)};
`;
const ButtonWrapper = styled.div<{
  color?: string;
  optionSelected: IOption | null;
  option: IOption;
  gap?: boolean;
  optionsList: IOption[];
  size?: "small" | "normal";
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.size === "small" ? "147px" : "164px")};
  min-height: ${(props) => (props.option.imgSrc ? "103px" : "61px")};
  border: ${(props) =>
      props.option.value === props.optionSelected?.value ? "2px" : "1px"}
    solid
    ${(props) =>
      props.option.value === props.optionSelected?.value
        ? props.color
        : "#D3D4D7"};
  border-top-right-radius: ${(props) =>
    !props.gap && props.optionsList.indexOf(props.option) === 0
      ? "unset"
      : "4px"};
  border-bottom-right-radius: ${(props) =>
    !props.gap && props.optionsList.indexOf(props.option) === 0
      ? "unset"
      : "4px"};
  border-top-left-radius: ${(props) =>
    !props.gap && props.optionsList.indexOf(props.option) !== 0
      ? "unset"
      : "4px"};
  border-bottom-left-radius: ${(props) =>
    !props.gap && props.optionsList.indexOf(props.option) !== 0
      ? "unset"
      : "4px"};
  gap: 5px;
  cursor: pointer;
  background-color: white;
`;
const P = styled.p<{
  optionSelected: IOption | null;
  option: IOption;
  color?: string;
}>`
  font-size: 14px;
  font-family: "DM Sans", serif;
  color: #50545f;
  font-weight: ${(props) =>
    props.option.value === props.optionSelected?.value ? 500 : 400};
  width: 140px;
  text-align: center;
  line-height: 20px;
`;

export const ChoixUnique: React.FC<ChoixUniqueProps> = ({
  optionsList = [],
  onSelect,
  color = "#0073E0",
  optionSelected,
  gap = false,
  size = "normal",
}) => {
  return (
    <Container>
      <OptionsListContainer gap={gap}>
        {optionsList &&
          optionsList.map((option, index) => {
            return (
              <ButtonWrapper
                onClick={() => {
                  onSelect(option);
                }}
                color={color}
                option={option}
                optionSelected={optionSelected ?? null}
                key={index}
                gap={gap}
                optionsList={optionsList}
                size={size}
              >
                {option.imgSrc && (
                  <img
                    src={option.imgSrc}
                    alt={option.imgAltTxt || "icn"}
                    width={41}
                    height={41}
                  />
                )}
                <P
                  key={index}
                  color={color}
                  option={option}
                  optionSelected={optionSelected ?? null}
                >
                  {option && option.label}
                </P>
              </ButtonWrapper>
            );
          })}
      </OptionsListContainer>
    </Container>
  );
};
