// Service import
import { StringToNumber } from "./formatStringToNumber";

// Interfaces import
import { IITEFormData } from "../../../interfaces/generalInterfaces";

// Local interface declaration

interface Props {
  ITEFormMutableData?: IITEFormData;
}

export const getsurfaceisoler = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each wall, if wall is "a isoler" I add the surface a isoler value to the result
  ITEFormMutableData?.walls?.forEach((wall) => {
    if (
      wall.actionssurlemur?.value === "629950000" &&
      wall.surfaceisoler?.value
    ) {
      result += StringToNumber(wall.surfaceisoler?.value || 0);
    }
  });
  // for each window, if the wall on wich it is installed is "a isoler" I subtract the window area to the result
  ITEFormMutableData?.windows?.forEach((window) => {
    const wall = ITEFormMutableData?.walls?.find(
      (wall) => wall.murId?.value === window.murId?.value
    );
    if (wall?.actionssurlemur?.value === "629950000") {
      let windowArea =
        StringToNumber(window.windowHeigh?.value || 0) *
        StringToNumber(window.windowWidth?.value || 0);
      result -= windowArea;
    }
  });
  // for each door, if the wall on wich it is installed is "a isoler" I subtract the window area to the result
  ITEFormMutableData?.doors?.forEach((door) => {
    const wall = ITEFormMutableData?.walls?.find(
      (wall) => wall.murId?.value === door.murId?.value
    );
    if (wall?.actionssurlemur?.value === "629950000") {
      let doorArea =
        StringToNumber(door.doorHeigh?.value || 0) *
        StringToNumber(door.doorWidth?.value || 0);
      result -= doorArea;
    }
  });
  return Math.round(result * 100) / 100;
};

export const getSurfaceCrepiSeul = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each wall, if wall is "crepis seul" I add the surface a isoler value to the result
  ITEFormMutableData?.walls?.forEach((wall) => {
    if (
      wall.actionssurlemur?.value === "629950001" &&
      wall.surfaceisoler?.value
    ) {
      result += StringToNumber(wall.surfaceisoler?.value || 0);
    }
  });
  // for each window, if the wall on wich it is installed is "crepis seul" I subtract the window area to the result
  ITEFormMutableData?.windows?.forEach((window) => {
    const wall = ITEFormMutableData?.walls?.find(
      (wall) => wall.murId?.value === window.murId?.value
    );
    if (wall?.actionssurlemur?.value === "629950001") {
      let windowArea =
        StringToNumber(window.windowHeigh?.value || 0) *
        StringToNumber(window.windowWidth?.value || 0);
      result -= windowArea;
    }
  });
  // for each door, if the wall on wich it is installed is "crepis seul" I subtract the window area to the result
  ITEFormMutableData?.doors?.forEach((door) => {
    const wall = ITEFormMutableData?.walls?.find(
      (wall) => wall.murId?.value === door.murId?.value
    );
    if (wall?.actionssurlemur?.value === "629950001") {
      let doorArea =
        StringToNumber(door.doorHeigh?.value || 0) *
        StringToNumber(door.doorWidth?.value || 0);
      result -= doorArea;
    }
  });
  return Math.round(result * 100) / 100;
};
export const getProfilAngle = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // I add totalfacadesHeight to the result
  if (ITEFormMutableData?.sommeDeLaHauteurDesFacades?.value) {
    result += StringToNumber(
      ITEFormMutableData?.sommeDeLaHauteurDesFacades.value
    );
  }
  // for each window, I add its height to the result
  ITEFormMutableData?.windows?.forEach((window) => {
    if (window.windowHeigh?.value) {
      result += StringToNumber(window.windowHeigh?.value) * 2;
    }
  });
  // for each door, I add its height to the result
  ITEFormMutableData?.doors?.forEach((door) => {
    if (door.doorHeigh?.value) {
      result += StringToNumber(door.doorHeigh?.value) * 2;
    }
  });
  return Math.round(result * 100) / 100;
};
export const getProfilDeDepart = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each wall I add metreslineairescomptabiliserprofildepart to the result
  ITEFormMutableData?.walls?.forEach((wall) => {
    result += StringToNumber(
      wall.metreslineairescomptabiliserprofildepart?.value || 0
    );
  });
  return Math.round(result * 100) / 100;
};
export const getProfilDeCouronnement = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each wall I add metreslineairescomptabiliserprofildepart to the result
  ITEFormMutableData?.walls?.forEach((wall) => {
    result += StringToNumber(
      wall.metreslineairesprofilcouronnement?.value || 0
    );
  });
  return Math.round(result * 100) / 100;
};
export const getProfilGoutteDeau = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each window and door I add its width to the result
  ITEFormMutableData?.windows?.forEach((window) => {
    if (window.windowWidth?.value) {
      result += StringToNumber(window.windowWidth?.value || 0);
    }
  });
  ITEFormMutableData?.doors?.forEach((door) => {
    if (door.doorWidth?.value) {
      result += StringToNumber(door.doorWidth?.value || 0);
    }
  });
  return Math.round(result * 100) / 100;
};
export const getFlechetteDeRenfort = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each window and door I add 2 to the result if there is an appuiFenetreExistant, if there is not, I add 4
  // and for each door I add 2 to the result
  ITEFormMutableData?.windows?.forEach((window) => {
    if (
      ITEFormMutableData.pointsSinguliers?.find(
        (pointSingulier) =>
          pointSingulier.ouvrantId?.value === window.ouvrantId?.value &&
          pointSingulier.pointSingulierName?.value === "appuiFenetreExistant"
      )
    ) {
      result += 2;
      return;
    }
    result += 4;
    return;
  });
  ITEFormMutableData?.doors?.forEach(() => {
    result += 2;
  });
  return result;
};
export const getAppuiFenetre = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each window I add 1 to the result if there is an appuiFenetreExistant
  ITEFormMutableData?.windows?.forEach((window) => {
    if (
      ITEFormMutableData.pointsSinguliers?.find(
        (pointSingulier) =>
          pointSingulier.ouvrantId?.value === window.ouvrantId?.value &&
          pointSingulier.pointSingulierName?.value === "appuiFenetreExistant"
      )
    ) {
      result += 1;
    }
  });
  return result;
};
export const getAppuiFenetreProfond = ({ ITEFormMutableData }: Props) => {
  let result = 0;
  // for each window I add 1 to the result if there is an appuiFenetreExistant
  ITEFormMutableData?.windows?.forEach((window) => {
    if (
      ITEFormMutableData.pointsSinguliers?.find(
        (pointSingulier) =>
          pointSingulier.ouvrantId?.value === window.ouvrantId?.value &&
          pointSingulier.pointSingulierName?.value === "appuiFenetreProfond"
      )
    ) {
      result += 1;
    }
  });
  return result;
};
