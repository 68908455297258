import { IFormPotentialTrancheeInformations } from "../../../interfaces/generalInterfaces";
import { fraisSuppData } from "./fraisSuppData";

type typeToolType = 629950000 | 629950001 | 0; // "Minipelle" | "Trancheuse" | "None", to confirm with CRM;

interface trancheesPricesDetails {
  toolDetails: {
    toolType: typeToolType;
    toolPrice: number;
  };
  trancheesprices: {
    terrain: string | number;
    sizeInMeters: string | number | null | undefined;
    totalTrancheePrice: number;
  }[];
}

export const trancheeCalculation = (
  tranchees: IFormPotentialTrancheeInformations[]
) => {
  const trancheesPricesData = fraisSuppData.pv.trancheesCalculationData;
  const trancheesPricesDetails: trancheesPricesDetails = {
    toolDetails: {
      toolType: 0,
      toolPrice: 0,
    },
    trancheesprices: [],
  };

  if (tranchees.length === 0) {
    return trancheesPricesDetails;
  }

  tranchees.forEach((tranchee) => {
    const longeurTrancheeAbs = tranchee.longueurTranchee?.value
      ? Math.abs(tranchee.longueurTranchee?.value)
      : tranchee.longueurTranchee?.value;
    if (
      tranchee.typedeTerrain?.value === 629950002 && // 629950002 = "Terre"
      trancheesPricesDetails.toolDetails.toolType !== 629950000 // "Minipelle"
    ) {
      trancheesPricesDetails.toolDetails.toolType = 629950001; // "Trancheuse";
    } else {
      trancheesPricesDetails.toolDetails.toolType = 629950000; // "Minipelle";
    }

    const terrain = tranchee.typedeTerrain?.value ?? "Default";

    const trancheeDetailsArray = {
      terrain: terrain,
      sizeInMeters: longeurTrancheeAbs,
      totalTrancheePrice: longeurTrancheeAbs
        ? longeurTrancheeAbs *
          trancheesPricesData.priceTrancheePerMeter[terrain]
        : 0,
    };

    trancheesPricesDetails.trancheesprices.push(trancheeDetailsArray);
  });

  trancheesPricesDetails.toolDetails.toolPrice =
    trancheesPricesDetails.toolDetails.toolType !== 0
      ? trancheesPricesData.toolsPrice[
          trancheesPricesDetails.toolDetails.toolType
        ]
      : 0;

  return trancheesPricesDetails;
};

export const carrotageCalculation = (numberOfCarrotage: number) => {
  const carrotagePriceData = fraisSuppData.pv.carrotage;
  return {
    pricePerCarrotage: carrotagePriceData.pricePerCarrotage,
    total: numberOfCarrotage * carrotagePriceData.pricePerCarrotage,
  };
};

export const roofAccessibilityCalculation = (
  isNacelleNeeded: boolean,
  isEchelleNeeded: boolean
) => {
  const roofAccessibilityPriceData = fraisSuppData.pv.roofAccessibility;
  const totalPrice =
    (isNacelleNeeded ? roofAccessibilityPriceData.priceNacelle.total : 0) +
    (isEchelleNeeded ? roofAccessibilityPriceData.priceEchelle.total : 0);

  return {
    nacelleDetails: isNacelleNeeded ? true : false,
    echelleDetails: isEchelleNeeded ? true : false,
    totalPrice: totalPrice,
  };
};

export const bacALesterCalculation = (numberOfBacALester: string | number) => {
  const bacALesterPriceData = fraisSuppData.pv.bacALester;
  return {
    numberOfBacALester: numberOfBacALester,
    pricePerBac: bacALesterPriceData.pricePerBac,
    totalPrice: bacALesterPriceData.pricePerBac * +numberOfBacALester,
  };
};
