// Service import
import { valueToLabelDictionnary } from "../valueToLabelDictionnary";
import { formatDataBeforeSendingToCRM } from "../formatVTDataBeforeSendingToCRM";
import { formatDate } from "../../formatDate";

// Interfaces import
import {
  IFormsMutableData,
  IVisiteTechniqueInfo,
  IUserProfileInfo,
} from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  formGlobalMutableData: IFormsMutableData;
  visiteTechniqueInfo: IVisiteTechniqueInfo;
  userProfileInfo: IUserProfileInfo;
}

export const formatDataBeforeSendingToGrammateus = ({
  formGlobalMutableData,
  visiteTechniqueInfo,
  userProfileInfo,
}: Props) => {
  const { VTCommonInfoToGrammateus, newPanInformationsCompleted } =
    formatDataBeforeSendingToCRM({
      formGlobalMutableData,
      formName: "photovoltaique",
    });

  type keyVTCommonInfo = keyof typeof VTCommonInfoToGrammateus;
  const VTCommonInfoLabel: {
    [key: string]: string | number | null | undefined | (string | number)[];
  } = {};

  for (const keys in VTCommonInfoToGrammateus) {
    VTCommonInfoLabel[keys as keyVTCommonInfo] = valueToLabelDictionnary(
      keys,
      VTCommonInfoToGrammateus[keys as keyVTCommonInfo] ?? ""
    );
  }

  const newPanInformationsCompletedLabel =
    newPanInformationsCompleted?.map((panInformation, index) => {
      const newPanInformationCompletedLabel: {
        [key: string]: string | number | null | undefined | (string | number)[];
      } = {};

      type keyNewPanInformationCompleted = keyof typeof panInformation;
      for (const keys in panInformation) {
        newPanInformationCompletedLabel[keys as keyNewPanInformationCompleted] =
          valueToLabelDictionnary(
            keys,
            newPanInformationsCompleted[index][
              keys as keyNewPanInformationCompleted
            ] ?? ""
          );
      }

      return newPanInformationCompletedLabel;
    }) || [];

  const vtInformation = {
    fileName: "Rapport_VT_" + visiteTechniqueInfo.id,
    date: formatDate(visiteTechniqueInfo.dateVisite),
    clientFirstName: visiteTechniqueInfo.customerFirstName,
    clientLastName: visiteTechniqueInfo.customerLastName,
    product: visiteTechniqueInfo.products?.toString(),
    centralePvInfos: visiteTechniqueInfo.centralePvInfos,
    auditor:
      userProfileInfo.firstname + " " + userProfileInfo.lastname?.toUpperCase(),
    address: visiteTechniqueInfo.customerStreet,
    city:
      visiteTechniqueInfo.customerPostalCode +
      " " +
      visiteTechniqueInfo.customerCity,
  };

  return {
    vtInformation,
    commonInfoData: VTCommonInfoLabel,
    panInfoData: newPanInformationsCompletedLabel,
  };
};
