export const valueToLabelDictionnary = (
  questionName: string,
  value: string | number | null | undefined | (string | number)[]
): string | number | null | undefined | (string | number)[] => {
  interface Dictionnary {
    [key: string]: {
      [key: number]: string;
    };
  }
  const dictionnary: Dictionnary = {
    // PV section
    implantationduPan: {
      629950000: "Bâtiment principal",
      629950001: "Bâtiment secondaire",
      629950002: "Dépendance",
      629950003: "Pergola",
      629950004: "Jardin",
      629950005: "Toiture terrasse / Toiture plate",
    },
    accessibiliteDesComnbles: {
      0: "Non",
      1: "Oui",
    },
    // TO CHANGE WITH CRM NUMBERS
    typeDaccesDesCombles: {
      629950000: "Échelle ",
      629950001: "Éscaliers",
      629950002: "Trappe",
    },
    nacelleaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    natureDuSupport: {
      629950000: "Tuiles mécaniques",
      629950001: "Ardoise",
      629950002: "Tuiles canal",
      629950003: "Tôle à joint debout",
      629950004: "Fibrociment",
      629950005: "Tôle trapézoïdale",
      629950006: "Tôle ondulée",
      629950007: "Tuiles plates",
      629950008: "Revêtement asphalté / goudron",
      629950009: "Tôle bac acier",
      629950010: "Lauze",
      629950011: "Chaume",
    },
    typedeSupport: {
      629950000: "Au sol",
      629950001: "Surimposition",
      629950002: "Intégration au bâtît",
    },
    masqueSolaires: {
      629950000: "Arbre(s)",
      629950001: "Bâtiment(s)/ maison(s)",
      629950002: "Topographie",
      629950003: "Aucun",
    },
    orientation: {
      629950000: "Sud",
      629950001: "Sud-Est",
      629950002: "Est",
      629950003: "Sud-Ouest",
      629950004: "Ouest",
      629950005: "Nord-Ouest",
      629950006: "Nord-Est",
      629950007: "Nord",
    },
    etatdelaCharpente: {
      629950000: "Bon",
      629950001: "Moyen",
      629950002: "Vétuste",
    },
    elagageaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    puissanceSouscrite: {
      629950000: "6kVA",
      629950001: "9kVA",
      629950002: "12kVA",
      629950003: "18kVA",
      629950004: "15kVA",
      629950005: "24kVA",
      629950006: "30kVA",
      629950007: "36kVA",
    },
    portanceWifi: {
      629950000: "Suffisant",
      629950001: "Insuffisant",
    },
    emplacementTableauElec: {
      629950000: "Intérieur",
      629950001: "Mur de clôture",
    },
    typeCompteur: {
      629950000: "Linky",
      629950001: "Mécanique",
      629950002: "Analogique",
    },
    typologie: {
      629950000: "T1",
      629950001: "T2",
      629950002: "T3",
      629950003: "T4",
      629950004: "T5",
      629950005: "T6",
      629950006: ">T6",
    },
    accessibilityMax: {
      629950000: "Voiture",
      629950001: "Utilitaire",
      629950002: "Semi-remorque",
    },
    trancheeaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    statutVt: {
      629950000: "Réalisée",
      629950001: "À planifier",
      629950002: "Planifiée",
      629950003: "En cours",
      629950004: "Annulée",
    },
    presencedeFourreauxExterieurs: {
      0: "Non",
      1: "Oui",
    },
    statutRapportVT: {
      629950000: "En cours",
      629950001: "Envoyé",
      629950002: "Client absent au RDV",
      629950003: "Problème d'accès",
      629950004: "Je n'ai pas pu me rendre au RDV",
      629950005: "Autre",
      629950006: "Envoyé - à contrôler",
    },
    resultatVTStatus: {
      629950000: "Validé",
      629950001: "Commande à modifier",
      629950002: "KO",
      629950003: "Commande modifiée",
    },
    typeAlimentation: {
      629950000: "Monophasé",
      629950001: "Triphasé",
    },
    planDisponible: {
      0: "Non",
      1: "Oui",
    },
    emplacementCompteur: {
      629950000: "Intérieur",
      629950001: "Mur de clôture",
      629950002: "Sur la façade de la maison",
    },
    VTNonRealisable: {
      629950000: "Client absent au RDV",
      629950001: "Problème d'accès",
      629950002: "Je n'ai pas pu me rendre au RDV",
      629950003: "Autre",
    },
    civility: {
      629950000: "Monsieur",
      629950001: "Madame",
    },
    presencedeCheminee: {
      0: "Non",
      1: "Oui",
    },
    presencedeVelux: {
      0: "Non",
      1: "Oui",
    },
    presencedeChienAssis: {
      0: "Non",
      1: "Oui",
    },

    //------------------
    //
    // CET section
    typeEcsExistant: {
      629950000: "Fioul",
      629950001: "Gaz",
      629950002: "Electrique",
      629950003: "Autre",
    },
    cetExistingLocation: {
      629950000: "Garage",
      629950001: "Buanderie",
      629950002: "Sous-sol",
      629950003: "Autre",
    },
    installationZone: {
      629950000: "Zone chauffée",
      629950001: "Zone non chauffée",
      629950002: "Extérieur",
    },
    cetUsage: {
      629950000: "Économique",
      629950001: "Modéré",
      629950002: "Intense",
    },
    cetFutureLocation: {
      629950000: "Identique à l’existant",
      629950001: "Différent",
    },
    cetInstallationType: {
      629950000: "Sur air ambiant (si > 20m3)",
      629950001: "Sur air extrait (gainé si < à 20m3) ",
      629950002: "Sur air extérieur (groupe ext.)",
    },
    changePressionReducer: {
      0: "Non",
      1: "Oui",
    },
    changePumpLift: {
      0: "Non",
      1: "Oui",
    },
    typedeTerrain: {
      629950002: "Terre",
      629950001: "Remblais",
      629950000: "Béton/Goudron",
    },
    typeDOutilPourTranchee: {
      629950000: "Minipelle",
      629950001: "Trancheuse",
    },
    bacsALesteraPrevoir: {
      0: "Non",
      1: "Oui",
    },
    renforcementCharpenteaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    echelleaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    carrotageaPrevoir: {
      0: "Non",
      1: "Oui",
    },
    deportDuBallon: {
      0: "Non",
      1: "Oui",
    },
    pompeDeRelevageNecessaire: {
      0: "Non",
      1: "Oui",
    },
    dalleBetonneeNecessaire: {
      0: "Non",
      1: "Oui",
    },
    commentairesAutresFraisSupCET: {
      0: "Non",
      1: "Oui",
    },

    typeDeChauffageExistant: {
      629950000: "Fioul",
      629950001: "Gaz",
      629950002: "Biomasse",
      629950003: "Electrique",
      629950004: "PAC",
      629950005: "Autres",
    },
    fonctionChauffageECSintegree: {
      0: "Non",
      1: "Oui",
    },
    // A FAIRE : Demander à YONG si les values sont correctes
    emplacementactueldusystemedechauffage: {
      629950000: "Bâtiment principal",
      629950001: "Bâtiment secondaire",
      629950002: "Dépendance",
      629950003: "Autres",
    },
    typeDeReseauExistants: {
      629950000: "Radiateurs",
      629950001: "Plancher chauffant",
      629950002: "Ventilo convecteur",
      629950003: "Monotube",
    },
    emmitterType: {
      629950000: "Fonte",
      629950001: "Aluminium",
      629950002: "Acier",
    },
    etatreseauradiateurs: {
      629950000: "Bon",
      629950001: "Moyen",
      629950002: "Vétuste",
    },
    radiateursVetustesFonctionnels: {
      0: "Non",
      1: "Oui",
    },
    etatreseauplancherchauffant: {
      629950000: "Bon",
      629950001: "Moyen",
      629950002: "Vétuste",
    },
    pcVetuste: {
      0: "Non",
      1: "Oui",
    },
    nombredezonedechauffage: {
      629950000: "1",
      629950001: "2",
      629950002: "3",
      629950003: "4",
    },
    reducteurdepression: {
      0: "Non",
      1: "Oui",
    },
    desembouageNecessaire: {
      0: "Non",
      1: "Oui",
    },
    ballonPrevu: {
      0: "Non",
      1: "Oui",
    },
    ajoutIsolantLiaisonHydrauliqueChaufferie: {
      0: "Non",
      1: "Oui",
    },
    necessiteKitBiZone: {
      0: "Non",
      1: "Oui",
    },
    necessiteAjoutGaz: {
      0: "Non",
      1: "Oui",
    },
    dimensionnementinitialcorrect: {
      0: "Non",
      1: "Oui",
    },

    // ITE section
    actionssurlemur: {
      629950000: "À isoler",
      629950001: "Crépis seul",
    },
  };
  if (typeof value === "number") {
    return dictionnary?.[questionName]?.[value] ?? value;
  }
  if (typeof value === "string") {
    return dictionnary?.[questionName]?.[parseInt(value)] ?? value;
  }
  if (Array.isArray(value)) {
    return value
      .map((elem) => {
        if (typeof elem === "number") {
          return dictionnary?.[questionName]?.[elem] ?? elem;
        }
        if (typeof elem === "string") {
          return dictionnary?.[questionName]?.[parseInt(elem)] ?? elem;
        }
        return elem;
      })
      ?.toString();
  }
  return value;
};
