// Other imports
import { useAppDispatch } from "../../redux/store/hook";
import { setUserAuthInfoAction } from "../../redux/appActions";

// Interface import
import { IUserAuthInfo } from "../../interfaces/generalInterfaces";

// Interface delcaration
interface IFunctionReturn {
  setUserAuthInfo: (userAuthInfo: IUserAuthInfo | null) => void;
}

// useSetUserAuthInfo : return tools to be able to save user authentication info in redux store and in local storage
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function setUserAuthInfo
//     Function to create/delete user's authentication information (jwt token and user id) in redux store and in local storage

export const useSetUserAuthInfo = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  // setUserAuthInfo : create/delete user's authentication information (jwt token and user id) in redux store and in local storage
  // Parameters
  // ----------
  // userAuthInfo: see IUserAuthInfo interface
  //      the token and the id of the user
  // Returns
  // ----------
  // Void

  const setUserAuthInfo = (userAuthInfo: IUserAuthInfo | null): void => {
    if (userAuthInfo) {
      localStorage.setItem("userAuthInfo", JSON.stringify(userAuthInfo));
      dispatch(setUserAuthInfoAction({ userAuthInfo: userAuthInfo }));
    } else {
      localStorage.removeItem("userAuthInfo");
      dispatch(
        setUserAuthInfoAction({
          userAuthInfo: {
            userToken: null,
            userId: null,
            eeroUserId: null,
            eeroToken: null,
          },
        })
      );
    }
  };
  return { setUserAuthInfo };
};
