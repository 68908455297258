import "./ValidateCalepinage.scss";

// Libraries import
import { useEffect } from "react";

// Components import
import { PickCard } from "@web/shared/dist/components/Old/FormComponents/PickCard/PickCard";
import { TextareaComponent } from "@web/shared/dist/components/Old/FormComponents/TextareaComponent/TextareaComponent";
import { SelectDropdown } from "@web/shared/dist/components/Old/FormComponents/SelectDropdown/SelectDropdown";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { useAppSelector, useAppDispatch } from "../../../../redux/store/hook";
import { setFormCommonInfoAction } from "../../../../redux/appActions";
import { useGetCalepinage } from "../../../../services/CalepinageValidation/useGetCalepinage";

export const ValidateCalepinage = () => {
  const {
    products,
    id,
    customerlatitude,
    customerlongitude,
    customerFirstName,
    customerLastName,
  } = useAppSelector((state) => state.visiteTechniqueInfo);
  const { eeroToken } = useAppSelector((state) => state.userAuthInfo);
  const { commentValidateCalepinage, numberOfPannel, validateCalepinage } =
    useAppSelector((state) => state.formsMutableData.photovoltaique);

  const { calepinage } = useAppSelector((state) => state.formsMutableData);
  const dispatch = useAppDispatch();
  const { getCalepinage, isLoading, errorMessage } = useGetCalepinage();
  const userProfileInfo = useAppSelector((states) => states.userProfileInfo);

  const numberOfPannelOptions = calepinage
    ? Object.keys(calepinage.calepinages).map((key) => {
        return { label: key.toString(), value: key };
      })
    : [];

  useEffect(() => {
    !calepinage?.calepinages_available &&
      getCalepinage({
        userToken: eeroToken,
        latitude: customerlatitude,
        longitude: customerlongitude,
        visitId: id,
        formName: "photovoltaique",
        client_firstname: customerFirstName,
        client_lastname: customerLastName,
        technicien_vt_firstname: userProfileInfo.firstname,
        technicien_vt_lastname: userProfileInfo.lastname,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When opening the page we set the default number of pannel to the first elemement of the numberOfPannelOptions
    numberOfPannelOptions.length > 0 && onSelect(numberOfPannelOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfPannelOptions.length]);

  // Event hanlders
  const onSelect = (option: {
    label: string | null;
    value: string | number | null;
  }) => {
    dispatch(
      setFormCommonInfoAction({
        formName: "photovoltaique",
        formQuestionName: "numberOfPannel",
        value: option.value,
        visitId: id,
      })
    );
  };

  const onClickCard = (value: number | null) => {
    dispatch(
      setFormCommonInfoAction({
        formName: "photovoltaique",
        formQuestionName: "validateCalepinage",
        value: value,
        visitId: id,
      })
    );
  };

  const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setFormCommonInfoAction({
        formName: "photovoltaique",
        formQuestionName: "commentValidateCalepinage",
        value: event.target.value,
        visitId: id,
      })
    );
  };

  return (
    <div className="ValidateCalepinage">
      {isLoading ? (
        <div className="ValidateCalepinage__LoadingComponent">
          <LoadingComponent />
        </div>
      ) : errorMessage ? (
        <div className="ValidateCalepinage__ErrorMessage">
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      ) : !calepinage?.calepinages_available ? (
        <div className="ValidateCalepinage__NoCalepinage">
          <strong>Pas de calepinage automatique disponible</strong>
        </div>
      ) : (
        <div className="ValidateCalepinage__YesCalepinage">
          <div className="ValidateCalepinage__Product">
            <p>
              <strong>Produit proposé dans le bon de commande</strong> :{" "}
              {products?.map((product) => product)}{" "}
            </p>
          </div>

          <div className="ValidateCalepinage__NumberOfPannels">
            <p>Nombre de panneaux : </p>
            <div className="ValidateCalepinage__SelectDropdown">
              <SelectDropdown
                options={numberOfPannelOptions}
                onSelect={onSelect}
                value={numberOfPannel?.value ?? numberOfPannelOptions[0].value}
              />
            </div>
          </div>
          <div className="ValidateCalepinage__ImageCalepinage">
            <img
              src={`data:image/jpeg;base64,${
                calepinage.calepinages?.[Number(numberOfPannel?.value) || 1]
                  ?.calepinage_image
              }`}
              alt="calepinage"
            />
          </div>
          <div className="ValidateCalepinage__CalepinageValidated">
            <p>
              Le calepinage proposé par notre algorithme vous paraît-il
              pertinent ?
            </p>
            <div className="ValidateCalepinage__PickCards">
              <div className="ValidateCalepinage__PickCard">
                <PickCard
                  text={"Oui"}
                  onClick={() =>
                    onClickCard(validateCalepinage?.value === 1 ? null : 1)
                  }
                  isSelected={validateCalepinage?.value === 1}
                />
              </div>
              <div className="ValidateCalepinage__PickCard">
                <PickCard
                  text={"Non"}
                  onClick={() =>
                    onClickCard(validateCalepinage?.value === 0 ? null : 0)
                  }
                  isSelected={validateCalepinage?.value === 0}
                />
              </div>
            </div>
          </div>
          <div className="ValidateCalepinage__Comment">
            <p>Si non pourquoi ? </p>
            <TextareaComponent
              onChange={onTextAreaChange}
              value={commentValidateCalepinage?.value || undefined}
            />
          </div>
        </div>
      )}
    </div>
  );
};
