// Service import
import { availableSurfaceCalculation } from "../FormCalculations/availableSurfaceCalculation";
import { roofInclinationCalculation } from "../FormCalculations/roofInclinationCalculation";
import { rampantLengthCalculation } from "../FormCalculations/rampantLengthCalculation";
import { valueToBoolean } from "../util/valueToBoolean";
import { StringToNumber } from "../Forms/ITE/formatStringToNumber";
import {
  getsurfaceisoler,
  getSurfaceCrepiSeul,
  getProfilAngle,
  getProfilDeDepart,
  getProfilDeCouronnement,
  getProfilGoutteDeau,
  getFlechetteDeRenfort,
  getAppuiFenetre,
} from "./ITE/resultatPageCalculation";

// Interfaces import
import {
  IFormsMutableData,
  formNames,
} from "../../interfaces/generalInterfaces";
import { PanInformationAllSubQuestionsKeys } from "../../interfaces/photovoltaiqueFormInterfaces";

// Local interface declaration
interface Props {
  formGlobalMutableData: IFormsMutableData;
  formName: formNames;
}

export const formatDataBeforeSendingToCRM = ({
  formGlobalMutableData,
  formName,
}: Props) => {
  const { panInformations } = formGlobalMutableData.photovoltaique;

  const {
    planDisponible,
    accessibilityMax,
    typeAlimentation,
    puissanceSouscrite,
    emplacementTableauElec,
    emplacementCompteur,
    typeCompteur,
    nombredePantoEquip,
    distanceOnduleur,
    emplacementDelaPartie,
    trancheeaPrevoir,
    commentairesTranchee,
    commentairePartieElectrique,
    commentairePartieLogistique,
    presencedeFourreauxExterieurs,
    combiendeFourreaux,
    diametreFourreaux,
    nacelleaPrevoir,
    portanceWifi,
    typeEcsExistant,
    descriptionAutreTypeECS,
    existingCetBrand,
    existingCetModel,
    cetExistingLocation,
    existingCetOtherLocation,
    installationZone,
    existingCetLocationComments,
    cetFutureLocation,
    cetFutureLocationComments,
    distanceDeport,
    distanceGroupeExtUniteInt,
    numberofHouseOccupation,
    cetUsage,
    cetInstallationType,
    carrotageExterieur,
    changePressionReducer,
    changePumpLift,
    resultatVTStatus,
    statutRapportVT,
    commentairesRapportVT,
    distanceAlimentationEauCoffretElectrique,
    hauteurSousPlafondDisponible,
    typeDeChauffageExistant,
    otherKindOfHeater,
    heaterExistingBrand,
    heaterExistingModel,
    fonctionChauffageECSintegree,
    puissanceduchauffageenkW,
    emplacementactueldusystemedechauffage,
    otherPACLocation,
    typeDeReseauExistants,
    nombredeRadiateurs,
    emmitterType,
    nombredePiecesChauffeByRadiateurs,
    etatreseauradiateurs,
    radiateursVetustesFonctionnels,
    commentairesradiateurs,
    nombredeboucleschauffeesparleplancherchauff,
    pcVetuste,
    commentairesplancherchauffant,
    temperaturedeconfort,
    anneedeconstructionourenovation,
    nombredetages,
    surfaceenm2,
    hauteurmoyennesousplafond,
    nombredezonedechauffage,
    reducteurdepression,
    longueurdeliaisonfrigorifiqueetelectrique,
    distancegroupeexterieurauTGBTm,
    distanceTGBTgroupehydrauliquem,
    valeurinitialeduG,
    correctionduG,
    calculdedeperditionthermiquekW,
    dimensionnementdelaPACkW,
    dimensionnementinitialcorrect,
    commentairespartiedimensionnement,
    etatreseauplancherchauffant,
    couleur,
    sommeDeLaHauteurDesFacades,
  } = formGlobalMutableData[formName];

  const VTCommonInfoToCRM = {
    accessibilityMax: StringToNumber(accessibilityMax?.value),
    planDisponible: StringToNumber(planDisponible?.value),
    typeAlimentation: StringToNumber(typeAlimentation?.value),
    puissanceSouscrite: StringToNumber(puissanceSouscrite?.value),
    emplacementTableauElec: StringToNumber(emplacementTableauElec?.value),
    emplacementCompteur: StringToNumber(emplacementCompteur?.value),
    typeCompteur: StringToNumber(typeCompteur?.value),
    nombredePantoEquip: StringToNumber(nombredePantoEquip?.value) || 1, // Default value is 1
    distanceOnduleur: StringToNumber(distanceOnduleur?.value),
    emplacementDelaPartie: emplacementDelaPartie?.value || "", // Mandatory field in the CRM
    commentairePartieElectrique: commentairePartieElectrique?.value,
    commentairePartieLogistique: commentairePartieLogistique?.value,
    presencedeFourreauxExterieurs: StringToNumber(
      presencedeFourreauxExterieurs?.value
    ),
    combiendeFourreaux: combiendeFourreaux?.value,
    diametreFourreaux: diametreFourreaux?.value,
    nacelleaPrevoir: nacelleaPrevoir?.value,
    portanceWifi: portanceWifi?.value,
    commentairesRapportVT: commentairesRapportVT?.value,
    statutVT: 629950000, // Réalisée
    statutRapportVT: statutRapportVT?.value || 629950001, // Envoyé
    resultatVTStatus: StringToNumber(resultatVTStatus?.value) || null,
    dateReceptionVT: new Date().toISOString(),
    tempsPasse: "Inconnu", // For now the time spent in not calculated
    typeEcsExistant: typeEcsExistant?.value || null,
    descriptionAutreTypeECS: descriptionAutreTypeECS?.value,
    existingCetBrand: existingCetBrand?.value,
    existingCetModel: existingCetModel?.value,
    cetExistingLocation: cetExistingLocation?.value,
    existingCetOtherLocation: existingCetOtherLocation?.value,
    installationZone: installationZone?.value,
    existingCetLocationComments: existingCetLocationComments?.value,
    cetFutureLocation: cetFutureLocation?.value,
    cetFutureLocationComments: cetFutureLocationComments?.value,
    distanceDeport: distanceDeport?.value,
    numberofHouseOccupation: numberofHouseOccupation?.value,
    cetUsage: cetUsage?.value,
    cetInstallationType: cetInstallationType?.value,
    carrotageExterieur: carrotageExterieur?.value,
    changePressionReducer: changePressionReducer?.value,
    changePumpLift: changePumpLift?.value,
    distanceGroupeExtUniteInt: distanceGroupeExtUniteInt?.value,
    distanceAlimentationEauCoffretElectrique: StringToNumber(
      distanceAlimentationEauCoffretElectrique?.value
    ),
    hauteurSousPlafondDisponible: hauteurSousPlafondDisponible?.value,
    trancheeaPrevoir: trancheeaPrevoir?.value,
    commentairesTranchee: commentairesTranchee?.value,
    typeDeChauffageExistant: typeDeChauffageExistant?.value,
    nombredeRadiateurs: nombredeRadiateurs?.value,
    otherKindOfHeater: otherKindOfHeater?.value ?? "",
    heaterExistingBrand: heaterExistingBrand?.value ?? "",
    heaterExistingModel: heaterExistingModel?.value ?? "",
    fonctionChauffageECSintegree:
      fonctionChauffageECSintegree?.value === 1 ? true : false,
    puissanceduchauffageenkW: puissanceduchauffageenkW?.value,
    emplacementactueldusystemedechauffage:
      emplacementactueldusystemedechauffage?.value,
    otherPACLocation: otherPACLocation?.value ?? "",
    typeDeReseauExistants: Array.isArray(typeDeReseauExistants?.value)
      ? typeDeReseauExistants?.value
      : typeDeReseauExistants?.value
      ? [typeDeReseauExistants?.value]
      : [],
    emmitterType: emmitterType?.value,
    nombredePiecesChauffeByRadiateurs: nombredePiecesChauffeByRadiateurs?.value,
    etatreseauradiateurs: etatreseauradiateurs?.value,
    radiateursVetustesFonctionnels:
      radiateursVetustesFonctionnels?.value === 1 ? true : false,
    commentairesradiateurs: commentairesradiateurs?.value ?? "",
    nombredeboucleschauffeesparleplancherchauff:
      nombredeboucleschauffeesparleplancherchauff?.value,
    pcVetuste: pcVetuste?.value === 1 ? true : false,
    commentairesplancherchauffant: commentairesplancherchauffant?.value || "",
    temperaturedeconfort: temperaturedeconfort?.value,
    anneedeconstructionourenovation: anneedeconstructionourenovation?.value,
    nombredetages: nombredetages?.value,
    surfaceenm2: surfaceenm2?.value,
    hauteurmoyennesousplafond: hauteurmoyennesousplafond?.value,
    nombredezonedechauffage: nombredezonedechauffage?.value,
    reducteurdepression: reducteurdepression?.value === 1 ? true : false,
    longueurdeliaisonfrigorifiqueetelectrique:
      longueurdeliaisonfrigorifiqueetelectrique?.value,
    distancegroupeexterieurauTGBTm: distancegroupeexterieurauTGBTm?.value,
    distanceTGBTgroupehydrauliquem: distanceTGBTgroupehydrauliquem?.value,
    valeurinitialeduG: valeurinitialeduG?.value,
    correctionduG: correctionduG?.value,
    calculdedeperditionthermiquekW: calculdedeperditionthermiquekW?.value,
    dimensionnementdelaPACkW: dimensionnementdelaPACkW?.value,
    dimensionnementinitialcorrect:
      valueToBoolean(dimensionnementinitialcorrect?.value) ?? undefined,
    commentairespartiedimensionnement:
      commentairespartiedimensionnement?.value ?? "",
    etatreseauplancherchauffant: etatreseauplancherchauffant?.value,
    couleur: couleur?.value,
    surfaceisolerITE:
      getsurfaceisoler({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    surfaceCrpisseulITE:
      getSurfaceCrepiSeul({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    profildangleITE:
      getProfilAngle({ ITEFormMutableData: formGlobalMutableData[formName] }) ||
      null,
    profildedpartITE:
      getProfilDeDepart({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    profildecouronnementITE:
      getProfilDeCouronnement({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    profilgouttedeauITE:
      getProfilGoutteDeau({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    flchettederenfort:
      getFlechetteDeRenfort({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    appuifentre:
      getAppuiFenetre({
        ITEFormMutableData: formGlobalMutableData[formName],
      }) || null,
    sommeDeLaHauteurDesFacades:
      StringToNumber(sommeDeLaHauteurDesFacades?.value) || null,
  };

  const VTCommonInfoToGrammateus = {
    accessibilityMax: accessibilityMax?.value,
    planDisponible: planDisponible?.value,
    typeAlimentation: typeAlimentation?.value,
    puissanceSouscrite: puissanceSouscrite?.value,
    emplacementTableauElec: emplacementTableauElec?.value,
    emplacementCompteur: emplacementCompteur?.value,
    typeCompteur: typeCompteur?.value,
    nombredePantoEquip: nombredePantoEquip?.value || 1, // Default value is 1
    distanceOnduleur: distanceOnduleur?.value,
    emplacementDelaPartie: emplacementDelaPartie?.value || "", // Mandatory field in the CRM
    commentairePartieElectrique: commentairePartieElectrique?.value,
    commentairePartieLogistique: commentairePartieLogistique?.value,
    presencedeFourreauxExterieurs: presencedeFourreauxExterieurs?.value,
    combiendeFourreaux: combiendeFourreaux?.value || null,
    diametreFourreaux: diametreFourreaux?.value,
    nacelleaPrevoir: nacelleaPrevoir?.value,
    portanceWifi: portanceWifi?.value,
    commentairesRapportVT: commentairesRapportVT?.value,
    statutVT: 629950000, // Réalisée
    statutRapportVT: statutRapportVT?.value || 629950001, // Envoyé
    resultatVTStatus: resultatVTStatus?.value || null,
    dateReceptionVT: new Date().toISOString(),
    tempsPasse: "Inconnu", // For now the time spent in not calculated
    typeEcsExistant: typeEcsExistant?.value,
    descriptionAutreTypeECS: descriptionAutreTypeECS?.value,
    existingCetBrand: existingCetBrand?.value,
    existingCetModel: existingCetModel?.value,
    cetExistingLocation: cetExistingLocation?.value,
    existingCetOtherLocation: existingCetOtherLocation?.value,
    installationZone: installationZone?.value,
    existingCetLocationComments: existingCetLocationComments?.value,
    cetFutureLocation: cetFutureLocation?.value,
    cetFutureLocationComments: cetFutureLocationComments?.value,
    distanceDeport: distanceDeport?.value,
    numberofHouseOccupation: numberofHouseOccupation?.value,
    cetUsage: cetUsage?.value,
    cetInstallationType: cetInstallationType?.value,
    carrotageExterieur: carrotageExterieur?.value,
    changePressionReducer: changePressionReducer?.value,
    changePumpLift: changePumpLift?.value,
    distanceGroupeExtUniteInt: distanceGroupeExtUniteInt?.value,
    distanceAlimentationEauCoffretElectrique:
      distanceAlimentationEauCoffretElectrique?.value,
    hauteurSousPlafondDisponible: hauteurSousPlafondDisponible?.value,
    trancheeaPrevoir: trancheeaPrevoir?.value,
    commentairesTranchee: commentairesTranchee?.value,
    typeDeChauffageExistant: typeDeChauffageExistant?.value,
    nombredeRadiateurs: nombredeRadiateurs?.value,
    otherKindOfHeater: otherKindOfHeater?.value,
    heaterExistingBrand: heaterExistingBrand?.value,
    heaterExistingModel: heaterExistingModel?.value,
    fonctionChauffageECSintegree: fonctionChauffageECSintegree?.value,
    puissanceduchauffageenkW: puissanceduchauffageenkW?.value,
    emplacementactueldusystemedechauffage:
      emplacementactueldusystemedechauffage?.value,
    otherPACLocation: otherPACLocation?.value ?? "",
    typeDeReseauExistants: typeDeReseauExistants?.value,
    emmitterType: emmitterType?.value,
    nombredePiecesChauffeByRadiateurs: nombredePiecesChauffeByRadiateurs?.value,
    etatreseauradiateurs: etatreseauradiateurs?.value,
    radiateursVetustesFonctionnels: radiateursVetustesFonctionnels?.value,
    commentairesradiateurs: commentairesradiateurs?.value,
    nombredeboucleschauffeesparleplancherchauff:
      nombredeboucleschauffeesparleplancherchauff?.value,
    pcVetuste: pcVetuste?.value,
    commentairesplancherchauffant: commentairesplancherchauffant?.value || "",
    temperaturedeconfort: temperaturedeconfort?.value,
    anneedeconstructionourenovation: anneedeconstructionourenovation?.value,
    nombredetages: nombredetages?.value,
    surfaceenm2: surfaceenm2?.value,
    hauteurmoyennesousplafond:
      StringToNumber(hauteurmoyennesousplafond?.value) || null,
    nombredezonedechauffage: nombredezonedechauffage?.value,
    reducteurdepression: reducteurdepression?.value,
    longueurdeliaisonfrigorifiqueetelectrique:
      longueurdeliaisonfrigorifiqueetelectrique?.value,
    distancegroupeexterieurauTGBTm: distancegroupeexterieurauTGBTm?.value,
    distanceTGBTgroupehydrauliquem: distanceTGBTgroupehydrauliquem?.value,
    valeurinitialeduG: valeurinitialeduG?.value,
    correctionduG: correctionduG?.value,
    calculdedeperditionthermiquekW: calculdedeperditionthermiquekW?.value,
    dimensionnementdelaPACkW: dimensionnementdelaPACkW?.value,
    dimensionnementinitialcorrect: dimensionnementinitialcorrect?.value,
    commentairespartiedimensionnement: commentairespartiedimensionnement?.value,
    etatreseauplancherchauffant: etatreseauplancherchauffant?.value,
  };

  const newPanInformationsCompleted = panInformations?.map((pan, index) => {
    const panInformationWithoutPictures: {
      [key: string]: string | number | null | undefined | (string | number)[];
    } = {};
    for (const keys in pan) {
      panInformationWithoutPictures[keys as PanInformationAllSubQuestionsKeys] =
        pan[keys as PanInformationAllSubQuestionsKeys]?.value;
    }
    const {
      hauteurdelaToitureFaitage,
      hauteurdelaToitureGoutiere,
      distanceduPignon,
      longueurdelaFacade,
      velux,
      cheminee,
      chienAssis,
      commentairePartieToiture,
      calepinage,
      masqueSolaires,
      commentairePartieSolMasque,
      surfaceDisponible,
      inclinaison,
      longueurduRampant,
      accessibiliteDesComnbles,
      typeDaccesDesCombles,
    } = pan;
    return {
      ...panInformationWithoutPictures,
      calepinage: calepinage?.value || "", // Mandatory field in the CRM
      commentairePartieToiture: commentairePartieToiture?.value || "", // Mandatory field in the CRM
      masqueSolaires: masqueSolaires?.value || [], // Mandatory field in the CRM
      commentairePartieSolMasque: commentairePartieSolMasque?.value || "", // Mandatory field in the CRM
      accessibiliteDesComnbles: accessibiliteDesComnbles?.value || null,
      typeDaccesDesCombles: typeDaccesDesCombles?.value || null,
      inclinaison:
        (inclinaison?.value ||
          roofInclinationCalculation({
            hauteurdelaToitureFaitage: hauteurdelaToitureFaitage?.value,
            hauteurdelaToitureGoutiere: hauteurdelaToitureGoutiere?.value,
            distanceduPignon: distanceduPignon?.value,
          })) ??
        null,
      surfaceDisponible:
        (surfaceDisponible?.value ||
          availableSurfaceCalculation({
            longueurRampant: rampantLengthCalculation({
              hauteurdelaToitureFaitage: hauteurdelaToitureFaitage?.value,
              hauteurdelaToitureGoutiere: hauteurdelaToitureGoutiere?.value,
              distanceduPignon: distanceduPignon?.value,
            }),
            longueurdelaFacade: longueurdelaFacade?.value,
            velux: velux?.value,
            cheminee: cheminee?.value,
            chienAssis: chienAssis?.value,
          })) ??
        null,
      longueurduRampant:
        (longueurduRampant?.value ||
          rampantLengthCalculation({
            hauteurdelaToitureFaitage: hauteurdelaToitureFaitage?.value,
            hauteurdelaToitureGoutiere: hauteurdelaToitureGoutiere?.value,
            distanceduPignon: distanceduPignon?.value,
          })) ??
        null,
    };
  });

  return {
    VTCommonInfoToCRM,
    VTCommonInfoToGrammateus,
    newPanInformationsCompleted,
  };
};
