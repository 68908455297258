// Libraries imports
import { createAction } from "@reduxjs/toolkit";

// Other imports
import * as types from "./constants";

// Interfaces import
import {
  IUserProfileInfo,
  IUserAuthInfo,
  formNames,
  IAllFormQuestionsKeys,
  IVisiteTechniqueList,
  IVisiteTechniqueInfo,
  IFormsMutableDataObect,
  IFormScreenName,
  IFormCommonInfo,
  IFormCommonInfoKeys,
  IQuestionMultiplesKey,
  ICalepinage,
} from "../interfaces/generalInterfaces";
import { IPhotovoltaiqueFormsAnswers } from "../interfaces/photovoltaiqueFormInterfaces";
import {
  KeyOfIITEWallAllQuestion,
  KeyOfIITEOuvrant,
  KeyOfIITEPointsSinguliers,
  ICRMWall,
  ISaveMurWithOuvrantItemsResponse,
} from "../interfaces/ITEFormInterface";
import { ICRMExtraCharge } from "../interfaces/ExtraChargesInterfaces";

// REDUX ACTIONS TO CALL WHEN REDUX STORE MODIFICATION IS NEEDED

// Reset redux state
export const setInitalStateAction = createAction(types.SET_INITAL_STATE);

// Save user authentication information in redux
export const setUserAuthInfoAction = createAction<{
  userAuthInfo: IUserAuthInfo;
}>(types.SET_USER_AUTH_INFO);

// Save user profile information in redux
export const setUserProfileInfoAction = createAction<{
  userProfileInfo: IUserProfileInfo;
}>(types.SET_USER_PROFILE_INFO);

export const setPickUniqueCardAction = createAction<{
  cardValue: number | null;
  formName: formNames;
  visitId?: string | null;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
}>(types.SET_PICK_UNIQUE_CARD);

export const setPickMultipleCardsAction = createAction<{
  textCard: string | number;
  formName: formNames;
  visitId?: string;
  formQuestionName: IQuestionMultiplesKey;
  answerIndex?: number;
}>(types.SET_PICK_MULTIPLE_CARDS);

export const setPVPickMultipleCardsAction = createAction<{
  textCard: string | number;
  formName: formNames;
  visitId?: string;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
}>(types.SET_PV_PICK_MULTIPLE_CARDS);

export const setSelectedInputAction = createAction<{
  selectedInput: number | null;
  formName: formNames;
  visitId?: string;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
}>(types.SET_SELECTED_INPUT);

export const setInputNumberAction = createAction<{
  inputNumber: number | null;
  formName: formNames;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
  visitId?: string;
}>(types.SET_INPUT_NUMBER);

export const setInputTextAction = createAction<{
  inputText: string;
  formName: formNames;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
  visitId?: string;
}>(types.SET_INPUT_TEXT);

export const setGoToNextPageAction = createAction<{
  formName: formNames;
  formCurrentScreen: IFormScreenName;
  formPagesLength: number;
}>(types.SET_GO_TO_NEXT_PAGE);

export const setGoToPreviousPageAction = createAction<{
  formName: formNames;
  formCurrentScreen: IFormScreenName;
  formPagesLength: number;
}>(types.SET_GO_TO_PREVIOUS_PAGE);

export const setGoToSpecificPageAction = createAction<{
  formName: formNames;
  pageIndex: number;
  formCurrentScreen: IFormScreenName;
}>(types.SET_GO_TO_SPECIFIC_PAGE);

export const setFormMutableDataStateAction = createAction<{
  newFormsMutableDataState: IFormsMutableDataObect;
  formName: formNames;
}>(types.SET_FORM_MUTABLE_DATA_STATE);

export const setFormAnswersStateAction = createAction<{
  formAnswers: IPhotovoltaiqueFormsAnswers | IFormCommonInfo | null;
  formName: formNames;
  visiteTechniqueId: string;
}>(types.SET_FORM_ANSWERS_STATE);

// Reset form answer state
export const resetFormAnswersStateAction = createAction(
  types.RESET_FORM_ANSWERS
);

export const updateFormBranchStateAction = createAction<{
  branchToAdd?: string;
  branchToRemove?: string;
  formName: formNames;
}>(types.UPDATE_FORM_BRANCH);

export const setVisitesTechniquesListAction = createAction<{
  visitesTechniquesList: IVisiteTechniqueList[] | null;
}>(types.SET_VISITES_TECHNIQUES_LIST);

export const setVisiteTechniqueInfoAction = createAction<{
  visiteTechniqueInfo: IVisiteTechniqueInfo | null;
}>(types.SET_VISITE_TECHNIQUE_INFO);

export const setPanSelectedAction = createAction<{
  panSelected: number;
}>(types.SET_PAN_SELECTED);

export const setQuestionPictureAction = createAction<{
  pictureUrl: string;
  formName: formNames;
  visitId?: string;
  formQuestionName: IAllFormQuestionsKeys;
  answerIndex?: number;
}>(types.SET_QUESTION_PICTURE);

export const setCalepinageAction = createAction<{
  calepinage: ICalepinage;
  visitId: string | null;
  formName: formNames;
}>(types.SET_CALEPINAGE);

export const setFormCommonInfoAction = createAction<{
  value?: string | number | null;
  formName: formNames;
  formQuestionName: IFormCommonInfoKeys;
  visitId: string | null;
  pictureUrl?: string;
}>(types.SET_FORM_COMMON_INFO);

export const setFormDropDownAction = createAction<{
  value: string | number | null;
  formName: formNames;
  formQuestionName: IAllFormQuestionsKeys;
  visitId?: string | null;
  answerIndex?: number;
}>(types.SET_FORM_DROPDOWN);
// NEW ITE FORM
export const setITEWallAction = createAction<{
  CRMWalls: ICRMWall[];
  visitId: string | null;
}>(types.SET_ITE_WALL);
export const setITEExtraChargesCRMIdAction = createAction<{
  CRMExtraCharges: ICRMExtraCharge[];
  visitId: string | null;
}>(types.SET_ITE_EXTRA_CHARGES);
export const setITEWallQuestionAction = createAction<{
  value?: string | null;
  formName: formNames;
  formQuestionName: KeyOfIITEWallAllQuestion;
  visitId: string | null;
  wallId: string | null;
  pictureUrl?: string;
}>(types.SET_ITE_WALL_QUESTION);

export const deleteITEWallAction = createAction<{
  visitId: string | null;
  murId: string | null;
}>(types.DELETE_ITE_WALL);

export const createITEWallAction = createAction<{
  visitId: string | null;
  setIsBlocksOpen: React.Dispatch<React.SetStateAction<boolean[]>>;
}>(types.CREATE_ITE_WALL);

export const setITEOuvrantQuestionAction = createAction<{
  value?: string | null;
  formName: formNames;
  formQuestionName: KeyOfIITEOuvrant;
  visitId: string | null;
  ouvrantId: string | null;
  murId: string | null;
  ouvrant: "windows" | "doors";
  pictureUrl?: string;
}>(types.SET_ITE_OUVRANT_QUESTION);

export const setITEOtherPointSingulierAction = createAction<{
  value?: string | null;
  formName: formNames;
  formQuestionName: KeyOfIITEOuvrant;
  visitId: string | null;
  otherPointSingulierId: string | null;
  murId: string | null;
  pictureUrl?: string;
}>(types.SET_ITE_OTHER_POINT_SINGULIER);

export const setITEPointSingulierAction = createAction<{
  value?: string | null;
  formName: formNames;
  formQuestionName: KeyOfIITEPointsSinguliers;
  visitId: string | null;
  pictureUrl?: string;
  pointSingulierName?: string;
  wallId: string | null;
}>(types.SET_ITE_POINT_SINGULIER);

export const deleteITEOuvrantAction = createAction<{
  visitId: string | null;
  ouvrantId?: string | null;
  murId: string | null;
  ouvrant: "windows" | "doors";
  isDeleteLastElement?: boolean;
}>(types.DELETE_ITE_OUVRANT);

export const createITEOuvrantAction = createAction<{
  visitId: string | null;
  murId: string | null;
  ouvrant: "windows" | "doors";
}>(types.CREATE_ITE_OUVRANT);

export const deleteITEOtherPointSingulierAction = createAction<{
  visitId: string | null;
  otherPointSingulierId?: string | null;
  murId: string | null;
  isDeleteLastElement?: boolean;
}>(types.DELETE_ITE_OTHER_POINT_SINGULIER);

export const createITEOtherPointSingulierAction = createAction<{
  visitId: string | null;
  murId: string | null;
}>(types.CREATE_ITE_OTHER_POINT_SINGULIER);

export const createITEWindowAction = createAction<{
  visitId: string | null;
  murId: string | null;
  windowId: string | null;
}>(types.CREATE_ITE_WINDOW);

export const duplicateITEWindowAction = createAction<{
  visitId: string | null;
  murId: string | null;
  windowId: string | null;
}>(types.DUPLICATE_ITE_WINDOW);

export const deleteITEWindowAction = createAction<{
  visitId: string | null;
  windowId?: string | null;
  murId: string | null;
  isDeleteLastElement?: boolean;
}>(types.DELETE_ITE_WINDOW);

export const setITEWindowAction = createAction<{
  value?: string | null;
  formName: formNames;
  formQuestionName: KeyOfIITEOuvrant;
  visitId: string | null;
  windowId: string | null;
  murId: string | null;
  pictureUrl?: string;
}>(types.SET_ITE_WINDOW);

export const deleteITEPointSingulierAction = createAction<{
  visitId: string | null;
  wallId: string | null;
  windowId?: string | null;
  pointSingulierId?: string | null;
}>(types.DELETE_ITE_POINT_SINGULIER);

export const createITEPointSingulierAction = createAction<{
  visitId: string | null;
  wallId: string | null;
  windowId?: string | null;
  pointSingulierName?: string;
}>(types.CREATE_ITE_POINT_SINGULIER);

export const duplicateITEOuvrantAction = createAction<{
  visitId: string | null;
  murId: string | null;
  ouvrantId: string | null;
  ouvrant: "windows" | "doors" | "otherPointsSinguliers";
}>(types.DUPLICATE_ITE_OUVRANT);

export const updateReduxAfterSaveMurWithOuvrantItemsAction = createAction<{
  visitId: string | null;
  saveMurWithOuvrantItemsResponse: ISaveMurWithOuvrantItemsResponse[];
}>(types.UPDATE_REDUX_SAVE_MUR_OUVRANT_ITEMS);
