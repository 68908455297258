import React from "react";
import "./visitDetailsClientInfo.scss";
import { Layout } from "../../../components/Layout/Layout";
import { IVisiteTechniqueInfo } from "../../../interfaces/generalInterfaces";

// Services import
import { valueToLabelDictionnary } from "../../../services/Forms/valueToLabelDictionnary";

interface IProps {
  data: IVisiteTechniqueInfo;
}

export const VisitDetailsClientInfo = ({ data }: IProps) => {
  return (
    <div className="visitDetailsClientInfo">
      <Layout>
        <div className="visitDetailsClientInfo__Container">
          <div className="visitDetailsClientInfo__Column1 column">
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Civilité :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerCivility &&
                  valueToLabelDictionnary("civility", data.customerCivility)}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Prénom :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerFirstName}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Nom :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerLastName}
              </p>
            </section>
          </div>
          <div className="visitDetailsClientInfo__Column2 column">
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Téléphone :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerTelephone}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Portable :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerMobilePhone}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Email :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerEmail}
              </p>
            </section>
          </div>
          <div className="visitDetailsClientInfo__Column3 column">
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Adresse :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerStreet}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Code postal :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerPostalCode}
              </p>
            </section>
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Ville :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerCity}
              </p>
            </section>
          </div>
          {/* <div className="visitDetailsClientInfo__Column4 column">
            <section className="visitDetailsClientInfo__Section">
              <p className="visitDetailsClientInfo__Label">Coordonées GPS :</p>
              <p className="visitDetailsClientInfo__Info">
                {data.customerlatitude}, {data.customerlongitude}
              </p>
            </section>
          </div> */}
        </div>
      </Layout>
    </div>
  );
};
