import React from "react";
import { SubHeaderProductInformation } from "../../components/subHeaderProductInformation/SubHeaderProductInfo";
import { IVisiteTechniqueInfo } from "../../interfaces/generalInterfaces";
import "./ranaglyphe.scss";
import "@google/model-viewer";

// creating a type for model-viewer
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      "ios-src": string;
      alt: string;
      ar: boolean;
      autorotate: boolean;
      "ar-scale": string;
      "camera-controls": boolean;
      "disable-zoom": boolean;
      class: string;
    }
  }
}

interface IProps {
  isRanaglypheClicked: boolean;
  setIsRanaglypheClicked: React.Dispatch<React.SetStateAction<boolean>>;
  visiteTechniqueInfo: IVisiteTechniqueInfo;
  areGetDataFromCRMLoading: boolean;
  getDataErrorMessage: string;
}

export const Ranaglyphe = ({
  isRanaglypheClicked,
  setIsRanaglypheClicked,
  visiteTechniqueInfo,
  areGetDataFromCRMLoading,
  getDataErrorMessage,
}: IProps) => {
  return (
    <div className="Ranaglyphe">
      <div className="Ranaglyphe__HeaderContainer">
        <SubHeaderProductInformation
          isRanaglypheClicked={isRanaglypheClicked}
          setIsRanaglypheClicked={setIsRanaglypheClicked}
          visiteTechniqueInfo={visiteTechniqueInfo}
          areGetDataFromCRMLoading={areGetDataFromCRMLoading}
          getDataErrorMessage={getDataErrorMessage}
        />
      </div>
      <div className="Ranaglyphe__ViewerContainer">
        <model-viewer
          src="/models/Ballon_thermo_model.glb"
          ios-src="/models/Ballon_thermo_model_mobile.usdz"
          alt="A 3D model of a thermo ballon"
          ar
          autorotate
          ar-scale={"fixed"}
          camera-controls
          disable-zoom
          class="Ranaglyphe__ModelViewer"
        ></model-viewer>
      </div>
    </div>
  );
};
