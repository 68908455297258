// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../redux/store/hook";
import { setFormAnswersStateAction } from "../../redux/appActions";

// Interfaces import
import {
  formNames,
  IFormCommonInfo,
  IFormCommonInfoKeys,
} from "../../interfaces/generalInterfaces";

// Local interface declaration
interface IFunctionReturn {
  getVTCommonInfoFromCRM: (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// Cf swagger : https://tucoenergieroujoineapi.azurewebsites.net/swagger/index.html
interface IRequestReturn {
  statutVT?: number | string | null;
  statutRapportVT?: number | string | null;
  resultatVTStatus?: number | string | null;
  commentairesRapportVT?: number | string | null;
  tempsPasse?: number | string | null;
  dateReceptionVT?: number | string | null;
  numberOfPannel?: number | string | null;
  validateCalepinage?: number | string | null;
  commentValidateCalepinage?: number | string | null;
  planDisponible?: number | string | null;
  accessibilityMax?: number | string | null;
  typeAlimentation?: number | string | null;
  puissanceSouscrite?: number | string | null;
  emplacementTableauElec?: number | string | null;
  emplacementCompteur?: number | string | null;
  typeCompteur?: number | string | null;
  nombredePantoEquip?: number | string | null;
  distanceOnduleur?: number | string | null;
  emplacementDelaPartie?: number | string | null;
  trancheeaPrevoir?: number | string | null;
  commentairesTranchee?: number | string | null;
  commentairePartieElectrique?: number | string | null;
  commentairePartieLogistique?: number | string | null;
  presencedeFourreauxExterieurs?: number | string | null;
  combiendeFourreaux?: number | string | null;
  diametreFourreaux?: number | string | null;
  nacelleaPrevoir?: number | string | null;
  portanceWifi?: number | string | null;
  typeEcsExistant?: number | string | null;
  descriptionAutreTypeECS?: number | string | null;
  existingCetBrand?: number | string | null;
  existingCetModel?: number | string | null;
  cetExistingLocation?: number | string | null;
  existingCetOtherLocation?: number | string | null;
  installationZone?: number | string | null;
  existingCetLocationComments?: number | string | null;
  cetFutureLocation?: number | string | null;
  cetFutureLocationComments?: number | string | null;
  distanceDeport?: number | string | null;
  distanceGroupeExtUniteInt?: number | string | null;
  numberofHouseOccupation?: number | string | null;
  cetUsage?: number | string | null;
  cetInstallationType?: number | string | null;
  carrotageExterieur?: number | string | null;
  changePressionReducer?: number | string | null;
  changePumpLift?: number | string | null;
  distanceAlimentationEauCoffretElectrique?: number | string | null;
  hauteurSousPlafondDisponible?: number | string | null;
  typeDeChauffageExistant?: number | string | null;
  otherKindOfHeater?: number | string | null;
  heaterExistingBrand?: number | string | null;
  heaterExistingModel?: number | string | null;
  fonctionChauffageECSintegree: number | string | null;
  puissanceduchauffageenkW?: number | string | null;
  emplacementactueldusystemedechauffage?: number | string | null;
  otherPACLocation?: number | string | null;
  typeDeReseauExistants?: number | string | null;
  nombredeRadiateurs?: number | string | null;
  emmitterType?: number | string | null;
  nombredePiecesChauffeByRadiateurs?: number | string | null;
  etatreseauradiateurs?: number | string | null;
  radiateursVetustesFonctionnels: number | string | null;
  commentairesradiateurs?: number | string | null;
  nombredeboucleschauffeesparleplancherchauff?: number | string | null;
  pcVetuste: number | string | null;
  commentairesplancherchauffant?: number | string | null;
  temperaturedeconfort?: number | string | null;
  anneedeconstructionourenovation?: number | string | null;
  nombredetages?: number | string | null;
  surfaceenm2?: number | string | null;
  hauteurmoyennesousplafond?: number | string | null;
  nombredezonedechauffage?: number | string | null;
  reducteurdepression: number | string | null;
  longueurdeliaisonfrigorifiqueetelectrique?: number | string | null;
  distancegroupeexterieurauTGBTm?: number | string | null;
  distanceTGBTgroupehydrauliquem?: number | string | null;
  valeurinitialeduG?: number | string | null;
  correctionduG?: number | string | null;
  calculdedeperditionthermiquekW?: number | string | null;
  dimensionnementdelaPACkW?: number | string | null;
  dimensionnementinitialcorrect: number | string | null;
  commentairespartiedimensionnement?: number | string | null;
  etatreseauplancherchauffant?: number | string | null;
  combienTrancheeAPrevoir?: number | string | null;
  ITEWallGlobalPicture?: number | string | null;
  couleur?: number | string | null;
  sommeDeLaHauteurDesFacades?: number | string | null;
  surfaceisolerITE?: number | string | null;
  surfaceCrpisseulITE?: number | string | null;
  profildangleITE?: number | string | null;
  profildedpartITE?: number | string | null;
  profildecouronnementITE?: number | string | null;
  profilgouttedeauITE?: number | string | null;
  flchettederenfort?: number | string | null;
  appuifentre?: number | string | null;
}

export const useGetVTCommonInfoFromCRM = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getVTCommonInfoFromCRM = async (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IRequestReturn>(
      `${BACKEND_URL}/api/VT/${visiteTechniqueId}/GetVTCommonInfo`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response) {
      // We create a new object to match the app data structure
      const VTCommonInfoToStoreInRedux: IFormCommonInfo = {};
      for (let keys in response) {
        VTCommonInfoToStoreInRedux[keys as IFormCommonInfoKeys] = {
          value: response?.[keys as IFormCommonInfoKeys], // The value received from the CRM
          pictures: undefined, // A new key to store the pictures info
        };
      }

      // We store this new object in redux
      dispatch(
        setFormAnswersStateAction({
          formName,
          formAnswers: VTCommonInfoToStoreInRedux,
          visiteTechniqueId,
        })
      );
    } else {
      setErrorMessage("Erreur lors du chargement des données");
    }
  };

  return {
    getVTCommonInfoFromCRM,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
