import "./modal.scss";
import Cross from "../../assets/images/cross.svg";

import ReactDOM from "react-dom";

interface Props {
  isVisible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export const Modal = ({ isVisible, onClose, children }: Props) => {
  const handleCloseModal = (e: any) => {
    e.preventDefault();
    onClose && onClose();
  };

  return isVisible
    ? ReactDOM.createPortal(
        <div className={"container"}>
          <div className={"container__modalContainer"}>
            <div className={"container__Header"}>
              {onClose && (
                <div
                  onClick={handleCloseModal}
                  className={"container__closingCross"}
                >
                  <img src={Cross} alt="cross" />
                </div>
              )}
            </div>
            {children}
          </div>
        </div>,
        document.body
      )
    : null;
};
