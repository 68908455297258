// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../../redux/store/hook";
import { setITEWallAction } from "../../../redux/appActions";

// Interfaces import
import { ICRMWall } from "../../../interfaces/ITEFormInterface";

// Interface delcaration
interface IFunctionReturn {
  getITEWallsFromCRM: ({
    userToken,
    visiteTechniqueId,
  }: {
    userToken: string | null;
    visiteTechniqueId: string;
  }) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

export const useGetITEWallsFromCRM = (): IFunctionReturn => {
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const getITEWallsFromCRM = async ({
    userToken,
    visiteTechniqueId,
  }: {
    userToken: string | null;
    visiteTechniqueId: string;
  }): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<ICRMWall[]>(
      `${process.env.REACT_APP_VITEE_AZURE_API_URL}/api/VT/${visiteTechniqueId}/GetMurWithOuvrantItems`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response) {
      dispatch(
        setITEWallAction({
          visitId: visiteTechniqueId,
          CRMWalls: response,
        })
      );
    }
  };

  return {
    getITEWallsFromCRM,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
