import "./subHeader.scss";

// Libraries import
import { useNavigate } from "react-router-dom";

// Component import
import { ButtonArrowBack } from "../ButtonArrowBack/ButtonArrowBack";
import { Layout } from "../Layout/Layout";

// Image import
import LogoTeam from "../../assets/images/logo_Teams.png";

// Local interface declaration
interface Props {
  clientName: string | null;
  productType: string[] | string | null;
  commercialFullName: string | null;
  commercialMobilePhone: string | null;
  commercialEmail: string | null;
  urlButtonBack?: string;
  formName?: string;
}

export const SubHeader = ({
  clientName,
  commercialFullName,
  commercialMobilePhone,
  commercialEmail,
  urlButtonBack = "/",
  formName,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div className="subHeader">
      <Layout>
        <div className="subHeader__Container">
          <div className="subHeader__Left">
            <div
              className="subHeader__ButtonBack"
              onClick={() => navigate(urlButtonBack)}
            >
              <ButtonArrowBack />
            </div>
            <div className="subHeader__UserName">
              <p>
                VT {formName || ""} - {clientName}
              </p>
            </div>
          </div>
          <div className="subHeader__Right">
            <div className="subHeader__Commercial">
              <p>Commercial(e) en charge :</p>
              <p>{commercialFullName}</p>
            </div>
            <div
              className={`subHeader__PhoneNumber${
                commercialMobilePhone ? "__True" : ""
              }`}
            >
              <p>{commercialMobilePhone}</p>
            </div>
            <div className="subHeader__Teams">
              {
                <a
                  href={`https://teams.microsoft.com/l/call/0/0?users=${commercialEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LogoTeam} alt="Logo Teams" />
                </a>
              }
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
