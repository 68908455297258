import "./header.scss";

// Libraries import
import React, { FC } from "react";

// Components import
import { HeaderMenu } from "./HeaderMenu/HeaderMenu";

//Other imports
import { useAppSelector } from "../../redux/store/hook";
import { Logo } from "../../components/Logo/Logo";
import { Layout } from "../../components/Layout/Layout";

export const Header: FC = () => {
  const { userAuthInfo } = useAppSelector((states) => states);
  return (
    <div className="header">
      <Layout>
        <div className="header__Container">
          {!userAuthInfo.userToken && (
            <div className="header-logo">
              <a href="https://tucoenergie.fr">
                <Logo />
              </a>
            </div>
          )}

          {userAuthInfo.userToken && ( //if user is authenticated
            <div className="header-left">
              <HeaderMenu />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};
