import "./selectInput.scss";
// Libraries import
import { valueToLabelDictionnary } from "../../../../services/Forms/valueToLabelDictionnary";

// Components import
import { SelectDropdown } from "@web/shared/dist/components/Old/FormComponents/SelectDropdown/SelectDropdown";
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Services import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setSelectedInputAction } from "../../../../redux/appActions";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../../services/Forms/checkTypes";

// Interface import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const SelectInput = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();

  const selectedInput =
    useAppSelector((state) => {
      // Standard case when we need to select the input value at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return state.formsMutableData[formName][questionData.name]?.value ?? "";
      }

      // Case when we need to get the input value from the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[questionData.name]?.value ?? ""
        );
      }

      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].potentialTranchees?.[
            answerIndex || 0
          ]?.[questionData.name]?.value || ""
        );
      }
    }) ?? "";

  const selectItem = (option: {
    label: string | null;
    value: string | number | null;
  }) => {
    if (option.value) {
      typeof option.value === "string"
        ? dispatch(
            setSelectedInputAction({
              selectedInput: parseInt(option.value),
              formName,
              formQuestionName: questionData.name,
              answerIndex,
              visitId,
            })
          )
        : dispatch(
            setSelectedInputAction({
              selectedInput: option.value,
              formName,
              formQuestionName: questionData.name,
              answerIndex,
              visitId,
            })
          );
    }
  };

  return (
    <div className="selectInput">
      <div className="selectInput__Title">
        <p>{questionData.title}</p>
      </div>
      <div className="selectInput__SelectComponent">
        <SelectDropdown
          onSelect={selectItem}
          value={!Array.isArray(selectedInput) ? selectedInput : ""} // We ensure the select input is not an array
          options={
            questionData.selectOptions?.map((value) => {
              return {
                value,
                label:
                  valueToLabelDictionnary(
                    questionData.name,
                    value
                  )?.toString() ?? "",
              };
            }) ?? []
          }
        />
      </div>
      {questionData.pictureNeeded && (
        <div className={`selectInput__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionData={questionData}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
