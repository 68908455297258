interface IDayNumberIntoDay {
  [key: string]: string
}

export const dayNumberIntoDay: IDayNumberIntoDay = {
  0: "Dimanche",
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
};
