// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../services/useAxiosPost";

// Service imports
import { complexRawBase64 } from "./complexRawBase64";
import { useAppSelector } from "../../redux/store/hook";

// Interface delcaration
interface IRequestResponse {
  code: number | null;
  message: string;
}

export const useUploadDocument = () => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const { customerId } = useAppSelector((states) => states.visiteTechniqueInfo);
  const { userToken, eeroToken } = useAppSelector(
    (states) => states.userAuthInfo
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const uploadDocument = async ({
    uploadedFile,
    fileName,
  }: {
    uploadedFile: string | ArrayBuffer;
    fileName: string;
  }): Promise<boolean> => {
    setErrorMessage("");
    if (uploadedFile) {
      const filesInComplexRawBase64 = complexRawBase64(uploadedFile, fileName);
      const filePayload = {
        photoFiles: [
          {
            complexRawBase64: filesInComplexRawBase64,
          },
        ],
        clientId: customerId,
        folderName: "visite_technique",
      };

      const response = await axiosPostRequest<IRequestResponse>(
        `${process.env.REACT_APP_EERO_URL}/vitee/saveFileToSharepoint`,
        { filePayload: filePayload },
        {
          headers: {
            Authorization: `Bearer ${eeroToken}`,
            "crm-authorization": `Bearer ${userToken}`,
          },
        }
      );
      if (response?.data?.code !== 0) {
        setErrorMessage(
          response?.data.message || "Erreur lors de l'upload du fichier"
        );
      } else {
        return true;
      }
    } else {
      setErrorMessage("Merci d'insérer au moins un fichier avant de valider");
    }
    return false;
  };

  return {
    uploadDocument,
    isLoading,
    errorMessage: axiosErrorMessage ? axiosErrorMessage : errorMessage,
    setErrorMessage,
  };
};
