import "./selectPans.scss";

// Components import
import * as FormComponents from "..";

// Services import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setPanSelectedAction } from "../../../../redux/appActions";
import { useUpdatePanInformationFormAccordingToUserAnswer } from "../../../../services/Forms/PVForm/useUpdatePanInformationFormAccordingToUserAnswer";

// Components import
import { PickUniqueCard } from "../PickUniqueCard/PickUniqueCard";

// Data import
import { selectPanQuestions } from "../../../../data/forms/Photovoltaique";

// Interface import
import { IFormQuestions } from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  visitId?: string;
}

export const SelectPans = ({ questionData, visitId }: Props) => {
  const dispatch = useAppDispatch();
  const formName = "photovoltaique"; // Select pan component is only used in photovoltaique form
  const formsMutableData = useAppSelector(
    (state) => state.formsMutableData.photovoltaique
  );
  const panSelected = formsMutableData.panSelected || 0;

  // Hook that allows to update the form according to the user's form answer
  useUpdatePanInformationFormAccordingToUserAnswer({
    formName,
    formsMutableData,
    visitId,
    panSelected,
    formPageName: "Pan Informations",
  });

  // Get input selected (from redux store)
  const selectedInput =
    formsMutableData.nombredePantoEquip?.value ||
    questionData?.cards?.[0] ||
    "";

  // Creation of an array with its length = number of pans, to create the right amount of Pans buttons
  const numberOfPansArray = [];
  for (let i = 0; i < Number(selectedInput); i++) {
    numberOfPansArray.push("");
  }

  return (
    <div className="selectPans">
      <div className="selectPans__Select">
        <div className="selectPans__Top">
          <div className="selectPans__SelectComponent">
            <PickUniqueCard
              questionData={questionData}
              formName={formName}
              visitId={visitId || undefined}
            />
          </div>
        </div>
        <div className="selectPans__Middle">
          {numberOfPansArray.map((elem, index) => {
            return (
              <div
                className={`selectPans__Buttons ${
                  index === panSelected ? "selectPans__Active" : ""
                }`}
                onClick={() =>
                  dispatch(setPanSelectedAction({ panSelected: index }))
                }
                key={index}
              >
                <p>Pan {index + 1}</p>
              </div>
            );
          })}
        </div>
        <div className="selectPans__Bottom">
          <p>{`Pan ${panSelected + 1}`}</p>
        </div>
      </div>
      <div className="selectPans__Questions">
        {selectPanQuestions.map((category, index) => {
          // We filter the questions according to the branch we want to display
          const questionsToDisplayAccordingToTheBranch =
            category.questions.filter((question) => {
              return question.branchs.every((branch) =>
                formsMutableData.formCurrentBranchs.includes(branch)
              );
            });
          return (
            <div
              className={`selectPans__Category ${
                category.image ? "selectPans__CategoryWithImageGrid" : ""
              }`}
              key={index}
            >
              <div className="selectPans__CategoryTop">
                {questionsToDisplayAccordingToTheBranch.length > 0 && (
                  <>
                    <h4>{category.categoryName}</h4>
                    <div className="selectPans__Trait" />
                  </>
                )}
              </div>
              {questionsToDisplayAccordingToTheBranch.length > 0 &&
                category.image && (
                  <div className="selectPans__CategoryImage">
                    <img
                      src={category.image.imgSrc}
                      alt={category.image.imgAlt}
                      width={category.image.imgWidth}
                      height={category.image.imgHeight}
                    />
                  </div>
                )}
              <div className="selectPans__QuestionsContainer">
                {questionsToDisplayAccordingToTheBranch.map(
                  (question, index) => {
                    const Component = FormComponents[question.type];
                    return (
                      <div className="selectPans__Question" key={index}>
                        <Component
                          questionData={question}
                          formName={formName}
                          answerIndex={panSelected}
                          visitId={visitId}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
