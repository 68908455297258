// img import
import xDeselectTag from "../../../../assets/images/DesignSystemIcons/ds-x-deselect-tag.svg";
// import React from "react";
import React, { useState } from "react";
// Styles import
import styled from "styled-components";
// lib import
import SVG from "react-inlinesvg";

// local interface declaration
interface IOption {
  label: string | null;
  value: string | null;
}

export interface TagProps {
  item?: IOption;
  /**
     function to filter original list and deselect the item clicked
     **/
  onclickDeselect: (id: string) => void;
  color?: "blue" | "black";
  isButtonHovered?: boolean;
}

export interface PProps {
  item?: IOption;
  color?: "blue" | "black";
  isButtonHovered?: boolean;
}

export interface IconWrapperProps {
  color?: "blue" | "black";
  isbuttonhovered?: number;
}
export interface ButtonProps {
  color?: "blue" | "black";
  isButtonHovered?: boolean;
}

const Button = styled.button<ButtonProps>`
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  gap: 10px;
  border-radius: 4px;
  height: 28px;
  cursor: default;
  background-color: ${(props) =>
    props.isButtonHovered ? "#F8F8F8" : "#FFFFFF"};
  border: 1px solid
    ${(props) =>
      props.isButtonHovered && props.color === "blue"
        ? "#085FB0"
        : props.isButtonHovered && props.color === "black"
        ? "#50545F"
        : "#7C7F87"};
  flex: 0 0 auto;
`;
const P = styled.p<PProps>`
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    props.isButtonHovered && props.color === "blue"
      ? "#085FB0"
      : props.isButtonHovered && props.color === "black"
      ? "#50545F"
      : "#7C7F87"};
`;
const IconWrapper = styled(SVG)<IconWrapperProps>`
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  cursor: pointer;
  path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    fill: ${(props) =>
      props.isbuttonhovered === 1 && props.color === "blue"
        ? "#085FB0"
        : props.isbuttonhovered === 1 && props.color === "black"
        ? "#50545F"
        : "#7C7F87"}
`;
export const Tag: React.FC<TagProps> = ({
  item = {},
  onclickDeselect,
  color = "blue",
}) => {
  const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);

  return (
    <Button
      color={color}
      isButtonHovered={isButtonHovered}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      <P color={color} isButtonHovered={isButtonHovered}>
        {item.label}
      </P>
      <IconWrapper
        color={color}
        onClick={(event) => {
          event.stopPropagation();
          item && item.value && onclickDeselect(item.value);
        }}
        src={xDeselectTag}
        isbuttonhovered={isButtonHovered ? 1 : 0}
      />
    </Button>
  );
};
