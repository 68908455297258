// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Services import
import { useGetITEExtraChargesFromCRM } from "../../services/Forms/ITE/useGetITEExtraChargesFromCRM";

// Interfaces import
import { ICRMExtraCharge } from "../../interfaces/ExtraChargesInterfaces";

export interface ISaveVTExtraChargesRequestPayload {
  // See Swagger : https://tucoenergieroujoineapi.azurewebsites.net/swagger/index.html
  extraCharges: ICRMExtraCharge[] | undefined;
  globalDescriptionString: string;
}

// useSaveVTExtraChargesInfoToCRM : return tools to post user's form answer to CRM

export const useSaveVTExtraChargesInfoToCRM = () => {
  const { getITEExtraChargesFromCRM } = useGetITEExtraChargesFromCRM();
  const {
    axiosPostRequest,
    isLoading: axiosPostIsLoading,
    errorMessage: axiosPostErrorMessage,
    setErrorMessage: setAxiosPostErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const resetStates = () => {
    setIsSuccessfull(false);
    setErrorMessage("");
    setAxiosPostErrorMessage("");
  };

  const saveVTExtraChargesInfoToCRM = async ({
    extraCharges,
    VTId,
    crmToken,
    eeroToken,
  }: {
    extraCharges: ISaveVTExtraChargesRequestPayload;
    VTId: string;
    crmToken: string | null;
    eeroToken: string | null;
  }): Promise<void> => {
    setErrorMessage("");

    const payload = {
      extraCharges: extraCharges.extraCharges,
      globalDescription: extraCharges.globalDescriptionString,
    };

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/vitee/saveVTExtraChargesInfo/${VTId}`,
      { extraCharges: payload },
      {
        headers: {
          Authorization: `Bearer ${eeroToken}`,
          "crm-authorization": `Bearer ${crmToken}`,
        },
      }
    );

    if (response && response.status === 200) {
      // We get the extra charge to update the redux with the extra charge IDs, in case one of the 3 calls fails and we need to reupload these extra charge (to update the extra charges in the CRM and not create doublons)
      await getITEExtraChargesFromCRM({
        userToken: crmToken,
        visiteTechniqueId: VTId,
      });
      setIsSuccessfull(true);
      return;
    }
    return;
  };

  return {
    saveVTExtraChargesInfoToCRM,
    isLoading: axiosPostIsLoading,
    errorMessage: errorMessage || axiosPostErrorMessage,
    isSuccessfull,
    resetStates,
  };
};
