// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../styles/variables";

// Interfaces declaration
export interface ErrorMessageProps {
  errorMessage: string;
  className?: string;
}

const P = styled.p`
  color: ${stylesVariables.RedErrorMessage};
  font-family: "DM Sans";
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
`;

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage = "",
  className = "",
}) => {
  return <P className={`error-message ${className}`}>{errorMessage}</P>;
};
