// Interfaces import
import { formNames } from "../../interfaces/generalInterfaces";

// Function that return the type of form according to the product name
export const determineFormNameFromProductType = (
  productType?: number | null
) => {
  const productTypeToFormNameDictionnary: { [key: number]: formNames } = {
    629950000: "photovoltaique",
    629950005: "CET",
    629950017: "chauffage",
    629950009: "ITE",
    629950014: "pompe a chaleur Air/Air",
    629950002: "PacAirEau",
    629950003: "batterie de stockage",
  };

  return productType
    ? productTypeToFormNameDictionnary[productType] || "photovoltaique"
    : "photovoltaique"; // Defaut form : photovoltaique
};

// For now only two forms used : photovoltaique and CET
export const determineFormUsedFromProductType = (
  productType?: number | null
) => {
  const productTypeToFormUsedDictionnary: { [key: number]: formNames } = {
    629950000: "photovoltaique",
    629950002: "PacAirEau",
    629950009: "ITE",
  };

  return productType
    ? productTypeToFormUsedDictionnary[productType] || "CET"
    : "CET"; // Defaut form used is CET
};
