// Interfaces import
import {
  IFormsMutableData,
  formNames,
} from "../../interfaces/generalInterfaces";
import { ISaveVTExtraChargesRequestPayload } from "./useSaveVTExtraChargesInfoToCRM";
import { ICRMExtraCharge } from "../../interfaces/ExtraChargesInterfaces";

// Services import
import { formatGlobalDescriptionExtraCharges } from "./FraisSuppCalculation/formatGlobalDescriptionExtraCharges";
import {
  trancheeCalculation,
  carrotageCalculation,
  roofAccessibilityCalculation,
  bacALesterCalculation,
} from "./FraisSuppCalculation/fraisSuppCalculationPV";
import { deportBallonCalculation } from "./FraisSuppCalculation/fraisSuppCalculationCET";
import { fraisSuppData } from "./FraisSuppCalculation/fraisSuppData";

// Local interface declaration
interface Props {
  formsMutableData: IFormsMutableData;
  formName: formNames;
}

export const formatExtraChargesDataBeforeSendingToCRM = ({
  formsMutableData,
  formName,
}: Props): ISaveVTExtraChargesRequestPayload => {
  const {
    carrotageaPrevoir,
    nombreDeCarrotage,
    nacelleaPrevoir,
    echelleaPrevoir,
    renforcementCharpenteaPrevoir,
    bacsALesteraPrevoir,
    nombreBacsALester,
    commentaireFraisSupplementaires,
    montantEstimeFraisSupplementaires,
    potentialTranchees,
    commentaireEtatCharpente,
    nombreTrancheeAPrevoir,
    deportDuBallon,
    combienDeMetresDeportDuBallon,
    commentaireDeportDuBallon,
    pompeDeRelevageNecessaire,
    dalleBetonneeNecessaire,
    commentaireDalleBetonnee,
    desembouageNecessaire,
    ballonPrevu,
    ajoutIsolantLiaisonHydrauliqueChaufferie,
    siOuiLiaisonHydrauliqueCombienMetres,
    necessiteKitBiZone,
    necessiteAjoutGaz,
    siOuiAjoutGazCombienMetres,
  } = formsMutableData[formName];
  let extraCharges: ICRMExtraCharge[] | undefined = [];
  // Add "Tranchée" extra charges information
  for (
    let i = 0;
    i <
    (Number(nombreTrancheeAPrevoir?.value) ?? potentialTranchees?.length ?? 0);
    i++
  ) {
    extraCharges?.push({
      name: `Tranchée n°${i + 1}`,
      id: potentialTranchees?.[i].id?.value,
      typedeTravaux: 629950000, // Tranchée
      typedeTerrain: potentialTranchees?.[i].typedeTerrain?.value,
      longueurTranche:
        Math.abs(potentialTranchees?.[i].longueurTranchee?.value || 0) ?? null,
      description: potentialTranchees?.[i].commentaireTranchee?.value ?? "",
      amount: potentialTranchees
        ? trancheeCalculation(potentialTranchees)?.trancheesprices?.[i]
            ?.totalTrancheePrice
        : 0,
    });
  }

  // Add "Carrotage" extra charges information (PV & CET)
  carrotageaPrevoir?.value === 1 &&
    extraCharges?.push({
      id: carrotageaPrevoir.id || null,
      name: "Nombre de carrotages",
      typedeTravaux: 629950003, // Carrotage
      nombre: nombreDeCarrotage?.value,
      amount:
        typeof nombreDeCarrotage?.value === "number"
          ? carrotageCalculation(nombreDeCarrotage?.value || 0)?.total
          : 0,
    });

  // Add "Accès" extra charges information
  (echelleaPrevoir?.value === 1 || nacelleaPrevoir?.value === 1) &&
    extraCharges?.push({
      name: "Type d'outil pour l'accès au toit",
      id: echelleaPrevoir?.id || nacelleaPrevoir?.id || null,
      typedeTravaux: 629950006, // Accès
      nacelleAPrevoir: nacelleaPrevoir?.value,
      echelleaPrevoir: echelleaPrevoir?.value,
      amount: roofAccessibilityCalculation(
        nacelleaPrevoir?.value === 1 ? true : false,
        echelleaPrevoir?.value === 1 ? true : false
      ).totalPrice,
    });

  // Add "Charpente" extra charges information
  renforcementCharpenteaPrevoir?.value === 1 &&
    extraCharges?.push({
      name: "Renforcement de charpente",
      id: renforcementCharpenteaPrevoir.id || null,
      typedeTravaux: 629950004, // Renforcement charpente
      description: commentaireEtatCharpente?.value,
    });

  // Add "Bacs à lester" extra charges information
  bacsALesteraPrevoir?.value === 1 &&
    extraCharges?.push({
      name: "Nombre de bacs à lester à prévoir",
      id: bacsALesteraPrevoir.id || null,
      typedeTravaux: 629950002, // Bacs à lester
      nombre: nombreBacsALester?.value,
      amount: bacALesterCalculation(nombreBacsALester?.value || 0).totalPrice,
    });

  // Add "Autres frais supplémentaires" extra charges information
  commentaireFraisSupplementaires?.value &&
    extraCharges?.push({
      name: "Autres frais supplémentaires",
      id: commentaireFraisSupplementaires.id || null,
      typedeTravaux: 629950005, // Autres
      description: commentaireFraisSupplementaires?.value,
      amount: montantEstimeFraisSupplementaires?.value,
    });

  // Add "Option tranchée" extra charges information
  trancheeCalculation(potentialTranchees || []).toolDetails.toolType !== 0 &&
    extraCharges?.push({
      name: "Option tranchées",
      typedeTravaux: 629950001, // Option tranchée
      typeOutil: trancheeCalculation(potentialTranchees || []).toolDetails
        .toolType,
      amount: trancheeCalculation(potentialTranchees || []).toolDetails
        .toolPrice,
    });

  // Add "Déport ballon" extra charges information
  deportDuBallon?.value === 1 &&
    extraCharges.push({
      name: "Deport du ballon",
      nombre: combienDeMetresDeportDuBallon?.value,
      typedeTravaux: 629950007, //deport necessaire
      description:
        ballonPrevu?.value === 1
          ? (("Un ballon est à prévoir et à ajouter au devis. " +
              commentaireDeportDuBallon?.value) as string)
          : (commentaireDeportDuBallon?.value as string),
      amount: deportBallonCalculation(
        Number(combienDeMetresDeportDuBallon?.value) || 0
      ).totalPrice,
    });

  // Add "Pompe de relevage" extra charges information (CET, PacAirEau)
  pompeDeRelevageNecessaire?.value === 1 &&
    extraCharges.push({
      name: "Pompe de relevage",
      // id: deportDuBallon.id || null,
      typedeTravaux: 629950008, //vase d'expansion || pompe de relevage (same thing different name)
      description: "Prix pompe de relevage",
      amount: fraisSuppData.cet.pompeDeRelevage,
    });

  dalleBetonneeNecessaire?.value === 1 &&
    extraCharges.push({
      name: "Dalle de béton",
      // id: deportDuBallon.id || null,
      typedeTravaux: 629950009, //dalle bétonnée
      description: commentaireDalleBetonnee?.value as string,
      amount: fraisSuppData.cet.dalleBeton,
    });

  // Add "Désembouage" extra charges information (PacAirEau)
  desembouageNecessaire?.value === 1 &&
    extraCharges?.push({
      name: "Désembouage nécessaire",
      typedeTravaux: 629950010, // Désembouage
      amount:
        Number(desembouageNecessaire?.value ?? 0) *
        fraisSuppData.pacAirEau.desembouage,
    });

  // Add "Déport du réseau hydrolique" extra charges information (PacAirEau)
  ajoutIsolantLiaisonHydrauliqueChaufferie?.value === 1 &&
    extraCharges.push({
      name: "Isolant pour la liaison hydraulique",
      nombre: siOuiLiaisonHydrauliqueCombienMetres?.value,
      typedeTravaux: 629950011, //deport necessaire
      amount:
        fraisSuppData.pacAirEau.poseIsolant +
        fraisSuppData.pacAirEau.isolantPerMeter *
          Number(siOuiLiaisonHydrauliqueCombienMetres?.value ?? 0) +
        fraisSuppData.pacAirEau.kitBiZone *
          Number(necessiteKitBiZone?.value ?? 0),
    });

  // Add "Liaison frigorifique" extra charges information (PacAirEau)
  necessiteAjoutGaz?.value === 1 &&
    extraCharges.push({
      name: "Ajout de gaz",
      nombre: siOuiAjoutGazCombienMetres?.value,
      typedeTravaux: 629950012, // Liaison frigorifique
      amount:
        fraisSuppData.pacAirEau.ajoutGazPerMeter *
        Number(siOuiAjoutGazCombienMetres?.value ?? 0),
    });

  let globalDescriptionString = "";
  const globalDescription = () => {
    extraCharges?.forEach((singleExtraCharge) => {
      globalDescriptionString =
        globalDescriptionString +
        formatGlobalDescriptionExtraCharges(singleExtraCharge);
    });
    globalDescriptionString = ballonPrevu?.value
      ? globalDescriptionString +
        " Un ballon est à prévoir et à ajouter au devis."
      : globalDescriptionString;
  };
  globalDescription();

  return {
    extraCharges,
    globalDescriptionString,
  };
};
