//App reducer actions types
export const SET_USER_AUTH_INFO = "SET_USER_AUTH_INFO";
export const SET_USER_PROFILE_INFO = "SET_USER_PROFILE_INFO";
export const SET_INITAL_STATE = "SET_INITAL_STATE";
export const SET_PICK_UNIQUE_CARD = "SET_PICK_UNIQUE_CARD";
export const SET_SELECTED_INPUT = "SET_SELECTED_INPUT";
export const SET_INPUT_NUMBER = "SET_INPUT_NUMBER";
export const SET_INPUT_TEXT = "SET_INPUT_TEXT";
export const SET_GO_TO_NEXT_PAGE = "SET_GO_TO_NEXT_PAGE";
export const SET_GO_TO_PREVIOUS_PAGE = "SET_GO_TO_PREVIOUS_PAGE";
export const SET_FORM_MUTABLE_DATA_STATE = "SET_FORM_MUTABLE_DATA_STATE";
export const RESET_FORM_ANSWERS = "RESET_FORM_ANSWERS";
export const UPDATE_FORM_BRANCH = "UPDATE_FORM_BRANCH";
export const SET_VISITES_TECHNIQUES_LIST = "SET_VISITES_TECHNIQUES_LIST";
export const SET_VISITE_TECHNIQUE_INFO = "SET_VISITE_TECHNIQUE_INFO";
export const SET_GO_TO_SPECIFIC_PAGE = "SET_GO_TO_SPECIFIC_PAGE";
export const SET_FORM_ANSWERS_STATE = "SET_FORM_ANSWERS_STATE";
export const SET_PICK_MULTIPLE_CARDS = "SET_PICK_MULTIPLE_CARDS";
export const SET_PV_PICK_MULTIPLE_CARDS = "SET_PV_PICK_MULTIPLE_CARDS";
export const SET_PAN_SELECTED = "SET_PAN_SELECTED";
export const SET_QUESTION_IMAGE = "SET_QUESTION_IMAGE";
export const DELETE_QUESTION_IMAGE = "DELETE_QUESTION_IMAGE";
export const SET_IMPORTED_IMAGES_DATA = "SET_IMPORTED_IMAGE_DATA";
export const SET_QUESTION_PICTURE = "SET_QUESTION_PICTURE";
export const SET_CALEPINAGE = "SET_CALEPINAGE";
export const SET_FORM_COMMON_INFO = "SET_FORM_COMMON_INFO";
export const SET_FORM_DROPDOWN = "SET_FORM_DROPDOWN";
export const SET_ITE_WALL = "SET_ITE_WALL";
export const SET_ITE_WALL_QUESTION = "SET_ITE_WALL_QUESTION";
export const DELETE_ITE_WALL = "DELETE_ITE_WALL";
export const CREATE_ITE_WALL = "CREATE_ITE_WALL";
export const SET_ITE_OUVRANT_QUESTION = "SET_ITE_OUVRANT_QUESTION";
export const DELETE_ITE_OUVRANT = "DELETE_ITE_OUVRANT";
export const CREATE_ITE_OUVRANT = "CREATE_ITE_OUVRANT";
export const DUPLICATE_ITE_OUVRANT = "DUPLICATE_ITE_OUVRANT";
export const SET_ITE_POINT_SINGULIER = "SET_ITE_POINT_SINGULIER";
export const DELETE_ITE_POINT_SINGULIER = "DELETE_ITE_POINT_SINGULIER";
export const CREATE_ITE_POINT_SINGULIER = "CREATE_ITE_POINT_SINGULIER";
export const CREATE_ITE_WINDOW = "CREATE_ITE_WINDOW";
export const DELETE_ITE_WINDOW = "DELETE_ITE_WINDOW";
export const DUPLICATE_ITE_WINDOW = "DUPLICATE_ITE_WINDOW";
export const SET_ITE_WINDOW = "SET_ITE_WINDOW";
export const SET_ITE_EXTRA_CHARGES = "SET_ITE_EXTRA_CHARGES";
export const SET_ITE_OTHER_POINT_SINGULIER = "SET_ITE_OTHER_POINT_SINGULIER";
export const DELETE_ITE_OTHER_POINT_SINGULIER =
  "DELETE_ITE_OTHER_POINT_SINGULIER";
export const CREATE_ITE_OTHER_POINT_SINGULIER =
  "CREATE_ITE_OTHER_POINT_SINGULIER";
export const UPDATE_REDUX_SAVE_MUR_OUVRANT_ITEMS =
  "UPDATE_REDUX_SAVE_MUR_OUVRANT_ITEMS";
