// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";
import * as Sentry from "@sentry/browser";

// Local interfaces delcaration

interface IRequestPayload {
  // See Swagger : https://tucoenergieroujoineapi.azurewebsites.net/swagger/index.html
  implantationduPan?: number | null;
  orientation?: number | null;
  typedeSupport?: number | null;
  natureDuSupport?: number | null;
  masqueSolaires?: (string | number)[];
  elagageaPrevoir?: number | null;
  hauteurdelaToitureGoutiere?: number | null;
  hauteurdelaToitureFaitage?: number | null;
  distanceduPignon?: number | null;
  longueurdelaFacade?: number | null;
  accessibiliteDesComnbles?: number | null;
  typeDaccesDesCombles: number | null;
  etatdelaCharpente?: number | null;
  cheminee?: number | null;
  velux?: number | null;
  chienAssis?: number | null;
  commentaires?: string | null;
  id?: string | null;
  calepinage?: string | null;
  presencedeCheminee?: number | null;
  presencedeVelux?: number | null;
  presencedeChienAssis?: number | null;
  surfaceDisponible?: number | null;
  commentairePartieSolMasque?: string | null;
  commentairePartieToiture?: string | null;
  inclinaison: number | null;
  longueurduRampant: number | null;
}

export const useSaveVTPanInfoToCRM = () => {
  const {
    axiosPostRequest,
    isLoading: axiosPostIsLoading,
    errorMessage: axiosPostErrorMessage,
    setErrorMessage: setAxiosPostErrorMessage,
  } = useAxiosPost();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const resetStates = () => {
    setIsSuccessfull(false);
    setErrorMessage("");
    setAxiosPostErrorMessage("");
  };

  const saveVTPanInfoToCRM = async ({
    VTPanInfo,
    numberPan = 1,
    VTId,
    crmToken,
    eeroToken,
  }: {
    VTPanInfo: IRequestPayload[] | undefined;
    numberPan: string | number;
    VTId: string;
    crmToken: string | null;
    eeroToken: string | null;
  }): Promise<void> => {
    setErrorMessage("");

    const VTPanInfoFiltered =
      VTPanInfo?.filter((panInfo) => panInfo !== undefined).filter(
        (panInfo, index) => index < (Number(numberPan) || 1)
      ) || []; // We ensure that we don't send more pan that the number of pan selected. Allow to handle the case where we create one more pan then select one less

    if (VTPanInfoFiltered?.length === 0) {
      setIsSuccessfull(true); // If panInformations array is empty, we don't send the request but we consider the saveVTPanInfoToCRM request as successfull
      return;
    }

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/vitee/saveVTPanInfo/${VTId}`,
      { VTPanInfoToSave: VTPanInfoFiltered },
      {
        headers: {
          Authorization: `Bearer ${eeroToken}`,
          "crm-authorization": `Bearer ${crmToken}`,
        },
      }
    );

    response && setIsSuccessfull(true); // If we have data in the answer it means the request is successfull
    return;
  };

  return {
    saveVTPanInfoToCRM,
    isLoading: axiosPostIsLoading,
    errorMessage: errorMessage || axiosPostErrorMessage,
    isSuccessfull,
    resetStates,
  };
};
