// Libraries import
import { useState } from "react";

// Components import
import { UpDownButtonContainer } from "../FormStepsWithSharedComponents/UpDownButtonContainer/UpDownButtonContainer";
import { UploadPicturesBtn } from "../FormStepsWithSharedComponents/UploadPicturesBtn/UploadPicturesBtn";
import { CheckboxContainer } from "../FormStepsWithSharedComponents/CheckboxContainer/CheckboxContainer";
import { InputContainer } from "../FormStepsWithSharedComponents/InputContainer/InputContainer";
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setITEOtherPointSingulierAction,
  deleteITEOtherPointSingulierAction,
} from "../../../redux/appActions";

// Interfaces import
import { IITEFormData } from "../../../interfaces/generalInterfaces";
import {
  KeyOfIITEOuvrant,
  IITEOuvrant,
} from "../../../interfaces/ITEFormInterface";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  murId: string | null;
  otherPointSingulier: IITEOuvrant;
  formName?: "ITE";
  visitId?: string;
  wallIndex: number;
}

export const ITEOtherPointSingulier = ({
  murId,
  otherPointSingulier,
  formName = "ITE",
  visitId,
  wallIndex,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const onClickOnOuvrantQuestion = ({
    questionName,
    value,
    pointSingulierId,
    murId,
    pictureUrl,
  }: {
    questionName: KeyOfIITEOuvrant;
    value?: string;
    pictureUrl?: string;
    pointSingulierId: string | null;
    murId: string | null;
  }) => {
    dispatch(
      setITEOtherPointSingulierAction({
        value,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId ?? null,
        otherPointSingulierId: pointSingulierId,
        murId,
        pictureUrl,
      })
    );
  };

  const onClickOnDeleteOuvrant = ({
    murId,
    pointSingulierId,
  }: {
    pointSingulierId: string | null;
    murId: string | null;
  }) => {
    setIsDeleteModaleOpen(false);
    dispatch(
      deleteITEOtherPointSingulierAction({
        visitId: visitId ?? null,
        otherPointSingulierId: pointSingulierId,
        murId,
      })
    );
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer le point singulier "${otherPointSingulier?.pointSingulierName?.value}" ?`}
        textAlignMiddle={true}
      >
        <div className="Modale_Buttons">
          <CTAButton
            name="Non"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          />
          <CTAButton
            name="Oui"
            family="vitee"
            onClick={() =>
              onClickOnDeleteOuvrant({
                murId: murId || null,
                pointSingulierId:
                  otherPointSingulier.pointSingulierId?.value || null,
              })
            }
          />
        </div>
      </Modale>
      <div className="formPagesITE__PointSingulierContainer">
        <div className="formPagesITE__Trait" />
        <div className="formPagesITE__SubTitle">
          <div />
          <div
            className="formPagesITE__Trash"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          >
            <img src={Trash2} alt="trash" />
          </div>
        </div>
        <div className="formPagesITE__QuestionLeftRight">
          <div className={"test"}>
            <InputContainer
              title={`Nom du point singulier :`}
              questionName="pointSingulierName"
              iconHidden={true}
              size={"big"}
              areAnswersAlign={false}
              formName={formName}
              visitId={visitId}
              isPictureNeeded={false}
              placeholder="Nom du point singulier"
              type="text"
              userAnswer={otherPointSingulier?.pointSingulierName}
              wallIndex={wallIndex}
              pointSingulierName={otherPointSingulier?.pointSingulierName}
              onChange={(value) => {
                onClickOnOuvrantQuestion({
                  questionName: "pointSingulierName",
                  pointSingulierId:
                    otherPointSingulier.pointSingulierId?.value || null,
                  murId,
                  value: value,
                });
              }}
            />
          </div>
          <UpDownButtonContainer
            title="Quantité :"
            questionName="pointSingulierQuantity"
            areAnswersAlign={false}
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            wallIndex={wallIndex}
            pointSingulierName={otherPointSingulier?.pointSingulierName}
            userAnswer={
              otherPointSingulier?.pointSingulierQuantity || {
                value: "0",
              }
            }
            onPlusMinusClick={(value) =>
              onClickOnOuvrantQuestion({
                questionName: "pointSingulierQuantity",
                pointSingulierId:
                  otherPointSingulier.pointSingulierId?.value || null,
                murId,
                value: value.toString(),
              })
            }
          />
        </div>
        <div className="formPagesITE__Question">
          <InputContainer
            title={`Montant :`}
            questionName="pointSingulierAmount"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            iconType="euros"
            placeholder="100"
            type="number"
            userAnswer={otherPointSingulier?.pointSingulierAmount}
            wallIndex={wallIndex}
            pointSingulierName={otherPointSingulier?.pointSingulierName}
            onChange={(value) => {
              onClickOnOuvrantQuestion({
                questionName: "pointSingulierAmount",
                pointSingulierId:
                  otherPointSingulier.pointSingulierId?.value || null,
                murId,
                value: value,
              });
            }}
          />
        </div>
        <div className="formPagesITE__QuestionPhoto">
          <p className={"formPagesITE__QuestionPhoto__QuestionTitle"}>
            Veuillez prendre une ou des photo(s) :{" "}
          </p>
          <UploadPicturesBtn
            questionName="pointSingulierPictures"
            buttonName={"Ajouter"}
            uploadedPictures={
              otherPointSingulier?.pointSingulierPictures?.pictures
            }
            formName={formName}
            wallIndex={wallIndex}
            pointSingulierName={otherPointSingulier?.pointSingulierName}
            visitId={visitId}
            onUpload={(pictureUrl) =>
              onClickOnOuvrantQuestion({
                questionName: "pointSingulierPictures",
                pointSingulierId:
                  otherPointSingulier.pointSingulierId?.value || null,
                murId,
                pictureUrl: pictureUrl,
              })
            }
          />
        </div>
        <div className="formPagesITE__Question">
          <CheckboxContainer
            title={
              <>
                Le client souhaite reposer ce(s) point(s) singulier(s) après ITE
                (donc à réaliser par <strong>TUCOENERGIE)</strong>.
              </>
            }
            questionName="isPointSingulierAReposer"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            wallIndex={wallIndex}
            pointSingulierName={otherPointSingulier?.pointSingulierName}
            userAnswer={otherPointSingulier?.isPointSingulierAReposer}
            onCheckboxClick={(value) =>
              onClickOnOuvrantQuestion({
                questionName: "isPointSingulierAReposer",
                pointSingulierId:
                  otherPointSingulier.pointSingulierId?.value || null,
                murId,
                value: value.toString(),
              })
            }
          />
        </div>
      </div>
    </>
  );
};
