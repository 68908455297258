export const stylesVariables = {
  MainOrange: "#ed8c00",
  MainBlack: "#0e1315",
  AssuranceBlue: "#0073e0",
  AssuranceBlueDark01: "#085FB0",
  AssuranceBlueDark02: "",
  AssuranceBlueLight01: "",
  AssuranceBlueLight02: "",
  AssuranceBlueLight03: "#B7D3F0",
  AssuranceBlueLight04: "",
  AssuranceBlueLight05: "#DBE9F8",
  AssuranceBlueLight06: "#F3F8FC",
  MainGreen: "#71b051",
  Grey80: "#7e7e7e",
  Grey60: "#9e9e9e",
  Grey35: "#c7c7c7",
  Grey25: "#d7d7d7",
  Grey10: "#efefef",
  Grey05: "#f7f7f7",
  GreyInputBorder: "#8a9ea8",
  RedErrorMessage: "rgb(248, 66, 66)",
  GreenSuccessMessage: "#71b051",
  OffBlack: "#191b1e",
  MainGrey: "#5e5e5e",
  Stablegrey: "#50545f",
  StableGrayLight: "#e9eaeb",
  StableGrayLight02: "#7c7f87",
  StableGrayLight03: "#a8aaaf",
  StableGrayLight04: "#d3d4d7",
  MainBackgroundColor: "#f8f8f8",
  BackgroundColorTwo: "#f5f6f7",
  White: "#FFFFFF",
  PowerRed: "#E02819",
  PowerRedDark01: "#B0261B",

  // Breakpoints & devices
  mobile: "360px",
  smallWindow: "576px",
  mediumWindow: "868px",
  largeWindow: "1180px",
  windowsWidth: "1200px",
};

export const DSColors = {
  white: "#FFFFFF",
  OffBlack: "#191B1E",
  AssuranceBlue: "#0073E0",
  ABDark01: "#085FB0",
  ABLight01: "#4096E8",
  ABLight02: "#80B9F0",
  ABLight03: "#B7D3F0",
  ABLight04: "#C3DAF3",
  ABLight05: "#DBE9F8",
  ABLight06: "#F3F8FC",
  StableGrey: "#50545F",
  SGDark01: "#44474F",
  SGLight01: "#7C7F87",
  SGLight02: "#A8AAAF",
  SGLight03: "#D3D4D7",
  SGLight04: "#E9EAEB",
  SGLight05: "#F8F8F8",
  ZealOrange: "#ED8C00",
  ZODark01: "#B86916",
  ZOLight01: "#F0A14E",
  ZOLight02: "#FECC97",
  ZOLight03: "#FAE0C4",
  ZOLight04: "#FEF8F1",
  PlanetGreen: "#05A358",
  PGDark01: "#0C834A",
  PGLight01: "#44BA82",
  PGLight02: "#82D1AC",
  PGLight03: "#CBECDC",
  PGLight04: "#E1F4EB",
  PowerRed: "#E02819",
  PRDark01: "#B0261B",
  PRLight01: "#E85E53",
  PRLight02: "#EE8981",
  PRLight03: "#F4B3AE",
  CompilerViolet: "#752BAF",
  CVDark01: "#60298B",
  CVLight01: "#9860C3",
  CVLight02: "#BA95D7",
  CVLight03: "#DDCAEB",
  OceanTurquoise: "#46D1CA",
  OTDark01: "#3DA5A0",
  OTLight01: "#74DDD7",
  OTLight02: "#A3E8E4",
  OTLight03: "#D1F3F2",
  OTLight04: "#EFFBFA",
  InnovationFushia: "#D61D98",
  IFDark01: "#3DA5A0",
  IFLight01: "#E056B2",
  IFLight02: "#EB8ECC",
  OptimismYellow: "#FFC043",
  OYDark01: "#C7983A",
  OYLight01: "#FFD072",
  OYLight02: "#FFE0A1",
  OYLight03: "#FFF2D6",
  OYLight04: "#FFF8EB",
};
