import "./pickUniqueCard.scss";

// Components import
import { PickCard } from "@web/shared/dist/components/Old/FormComponents/PickCard/PickCard";
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Service import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../../services/Forms/checkTypes";
import { valueToLabelDictionnary } from "../../../../services/Forms/valueToLabelDictionnary";
import { setPickUniqueCardAction } from "../../../../redux/appActions";

// Interfaces import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  userAnswer?: string | string[] | number;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const PickUniqueCard = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const formsMutableData = useAppSelector((state) => state.formsMutableData);
  const dispatch = useAppDispatch();
  const userAnswer =
    (() => {
      // Standard case when we need to selected the input value at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return formsMutableData[formName][questionData.name]?.value;
      }

      // Case when we need to get the input value from the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].panInformations?.[answerIndex || 0]?.[
            questionData.name
          ]?.value ?? ""
        );
      }

      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].potentialTranchees?.[answerIndex || 0]?.[
            questionData.name
          ]?.value || ""
        );
      }
    })() ??
    questionData.defaultValue ??
    "";

  // Event handler
  const onCardClick = (cardValue: number) => {
    dispatch(
      // We check if the question expects a string answer. If it is the case we can create or update the user's answer state.
      setPickUniqueCardAction({
        cardValue,
        formName: formName,
        formQuestionName: questionData.name,
        answerIndex: answerIndex,
        visitId,
      })
    );
  };

  return (
    <div className="pickUniqueCard">
      <div className="pickUniqueCard__Title">
        <p>{questionData.title}</p>
      </div>
      <div>
        <div className={"pickUniqueCard__pickCards"}>
          {questionData.cards?.map((CardValue, index) => {
            const cardText = valueToLabelDictionnary(
              questionData.name,
              CardValue
            );
            return (
              <div
                className={"pickUniqueCard__Card"}
                key={questionData.name + index}
              >
                <PickCard
                  text={
                    typeof cardText === "string" || typeof cardText === "number"
                      ? cardText
                      : ""
                  }
                  isSelected={userAnswer === CardValue ? true : false}
                  onClick={() => onCardClick(CardValue)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {questionData.pictureNeeded && (
        <div className={`pickUniqueCard__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionData={questionData}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
