import "./formPages.scss";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { InputText } from "../../../../containers/Forms/FormStepsNew/InputText/InputText";
import { InputNumber } from "../../../../containers/Forms/FormStepsNew/InputNumber/InputNumber";
import { InfoBoxForm } from "../../../../components/InfoBoxForm/InfoBoxForm";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const PartieElectriquePage = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const formName = "PacAirEau";

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Installation électrique</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Type d’installation électrique :"
        cards={[629950000, 629950001]}
        formName={formName}
        questionName="typeAlimentation"
        userAnswer={PacAirEauFormMutableData?.typeAlimentation}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Puissance souscrite :"
        cards={[
          629950000, 629950001, 629950002, 629950003, 629950004, 629950005,
          629950006, 629950007,
        ]}
        formName={formName}
        questionName="puissanceSouscrite"
        userAnswer={PacAirEauFormMutableData?.puissanceSouscrite}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InfoBoxForm
        textContent={
          "Pensez à prévenir les clients si une augmentation de la puissance souscrite est à réaliser auprès de son fournisseur d’énergie. Précisez en commentaire la bonne prise en compte de cette information par le client. "
        }
      />
      <PickUniqueCard
        title="Emplacement du tableau électrique :"
        cards={[629950000, 629950001]}
        formName={formName}
        questionName="emplacementTableauElec"
        userAnswer={PacAirEauFormMutableData?.emplacementTableauElec}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Emplacement du compteur :"
        cards={[629950000, 629950001, 629950002]}
        formName={formName}
        questionName="emplacementCompteur"
        userAnswer={PacAirEauFormMutableData?.emplacementCompteur}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Type de compteur :"
        cards={[629950000, 629950001, 629950002]}
        formName={formName}
        questionName="typeCompteur"
        userAnswer={PacAirEauFormMutableData?.typeCompteur}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputNumber
        title="Distance entre l’alimentation d’eau et le coffret électrique (m)"
        formName={formName}
        userAnswer={
          PacAirEauFormMutableData?.distanceAlimentationEauCoffretElectrique
        }
        questionName="distanceAlimentationEauCoffretElectrique"
        placeholder={"ex: 50"}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputText
        title="Commentaires partie électrique :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentairePartieElectrique}
        questionName="commentairePartieElectrique"
        visitId={visitId}
      />
    </div>
  );
};
