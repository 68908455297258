// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Import services
import { useSetUserAuthInfo } from "./useSetUserAuthInfo";

// Interfaces import
import {
  ILoginRequestResponse,
  ILoginUserBody,
} from "../../interfaces/generalInterfaces";

export const useLoginUser = () => {
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setUserAuthInfo } = useSetUserAuthInfo();

  const requestLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> => {
    setErrorMessage("");
    if (email && password) {
      const loginUserBody: ILoginUserBody = {
        email,
        password,
        serviceAccessValue: 3001,
      }; // 3001 = Vitee
      const response = await axiosPostRequest<ILoginRequestResponse>(
        `${process.env.REACT_APP_EERO_URL}/vitee/login`,
        loginUserBody
      );

      response?.data &&
        setUserAuthInfo({
          userToken: response.data.token,
          userId: response.data.userId,
          eeroToken: response.data.eeroToken,
          eeroUserId: response.data.eeroUserId,
        });
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
  };

  return {
    requestLogin,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
