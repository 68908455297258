// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../useAxiosPost";

// Interfaces import
import { IRapportVtData } from "../../../interfaces/generalInterfaces";

// Local interfaces delcaration
interface IGenerateAndSaveVTPdfRapportInCRM {
  customerId: string | null;
  userToken: string | null;
  rapportVtData: IRapportVtData;
}

interface IFunctionReturn {
  generateAndSaveVTPdfRapportInCRM: ({
    customerId,
    userToken,
    rapportVtData,
  }: IGenerateAndSaveVTPdfRapportInCRM) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
  isSuccessfull: boolean;
  resetStates: () => void;
}

// useSaveVTRapport : return tools to post user's form answer to CRM

export const useGenerateAndSaveVTRapportToCRM = (): IFunctionReturn => {
  const REACT_APP_GRAMMATEUS_URL = process.env.REACT_APP_GRAMMATEUS_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
    setErrorMessage: setAxiosPostErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const resetStates = () => {
    setIsSuccessfull(false);
    setErrorMessage("");
    setAxiosPostErrorMessage("");
  };

  // generateAndSaveVTPdfRapportInCRM : send Post request to save user's form answer in CRM
  const generateAndSaveVTPdfRapportInCRM = async ({
    customerId,
    userToken,
    rapportVtData,
  }: IGenerateAndSaveVTPdfRapportInCRM): Promise<void> => {
    setErrorMessage("");
    if (userToken && customerId) {
      const body = {
        customerId,
        userToken,
        rapportVtData,
      };

      const response = await axiosPostRequest(
        `${REACT_APP_GRAMMATEUS_URL}/saveRapportVTInUserSharepoint?apiKey=${process.env.REACT_APP_GRAMMATEUS_API_KEY}`,
        body,
        { headers: { Authorization: `Bearer ${userToken}` } }
      );

      response && setIsSuccessfull(true);
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
  };

  return {
    generateAndSaveVTPdfRapportInCRM,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
    isSuccessfull,
    resetStates,
  };
};
