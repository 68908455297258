import "./inputNumber.scss";

// Components import
import { NumberInputComponentIpad } from "@web/shared/dist/components/Old/FormComponents/NumberInputComponentIpad/NumberInputComponentIpad";
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Services import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setInputNumberAction } from "../../../../redux/appActions";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../../services/Forms/checkTypes";

// Interface import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const InputNumber = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const typedInput =
    useAppSelector((state) => {
      // Standard case when we need to selected the input value at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return state.formsMutableData[formName][questionData.name]?.value;
      }

      // Case when we need to get the input value from the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[questionData.name]?.value || ""
        );
      }

      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].potentialTranchees?.[
            answerIndex || 0
          ]?.[questionData.name]?.value || ""
        );
      }
    }) || "";

  const setTypedInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(event.target.value);
    // If the input of the user is a number we store it as a number
    dispatch(
      setInputNumberAction({
        inputNumber: inputValue,
        formName,
        formQuestionName: questionData.name,
        visitId,
        answerIndex,
      })
    );
  };

  return (
    <div className="inputNumber">
      <div className="inputNumber__Title">
        <p>
          {questionData.preTitleIndication && (
            <span
              style={{
                backgroundColor:
                  questionData.preTitleIndication.backgroundColor,
              }}
              className="inputNumber__PreTitleIndication"
            >
              {questionData.preTitleIndication.text}
            </span>
          )}
          {questionData.title}
        </p>
      </div>
      <div className={`inputNumber__InputComponent`}>
        <NumberInputComponentIpad
          onChange={setTypedInput}
          value={typeof typedInput === "number" ? typedInput : ""} // We ensure the typed input is not an array
          name={questionData.name}
          id={questionData.name}
          placeholder={questionData.placeholder}
        />
      </div>
      {questionData.pictureNeeded && (
        <div className={`inputNumber__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionData={questionData}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
