import "./resetPasswordWelcome.scss";

// Libraries import
import React from "react";

export const ResetPasswordWelcome: React.FC = () => {
  return (
    <div className="reset-password-welcome">
      {/* <LogoOree height={60}  */}
      <div className="reset-password-welcome-message">
        <p>Réinitialiser son</p>
        <p>mot de passe</p>
      </div>
    </div>
  );
};
