// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../redux/store/hook";
import { setFormAnswersStateAction } from "../../redux/appActions";
import { formatGetExtraChargesDataReceivedFromCRM } from "./formatGetExtraChargesDataReceivedFromCRM";

// Interfaces import
import { formNames } from "../../interfaces/generalInterfaces";
import { ICRMExtraCharge } from "../../interfaces/ExtraChargesInterfaces";

// Local interface declaration
interface IFunctionReturn {
  getVTExtraChargesFromCRM: (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetVTExtraChargesInfoFromCRM : return tools to get VT information from CRM

export const useGetVTExtraChargesFromCRM = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  // getVTExtraChargesInfoFromCRM : send get request to CRM. If successfull-> save data in local storage and in redux store

  const getVTExtraChargesFromCRM = async (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<ICRMExtraCharge[]>(
      `${BACKEND_URL}/api/VT/${visiteTechniqueId}/GetExtraCharges`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response) {
      // We store this new object in redux
      dispatch(
        setFormAnswersStateAction({
          formName,
          formAnswers: formatGetExtraChargesDataReceivedFromCRM(response), // We create a new object to match the app data structure
          visiteTechniqueId,
        })
      );
    }
  };

  return {
    getVTExtraChargesFromCRM,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
