import { AnyAction } from "redux";

// Other imports
import * as actions from "../appActions";
import { initialState } from "../initialState";

// Services import
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../services/Forms/checkTypes";

// Interfaces import
import {
  IAppStates,
  IFormCommonInfoKeys,
  IPhotovoltaiqueStandardQuestionKeys,
} from "../../interfaces/generalInterfaces";
import {
  IPhotovoltaiqueFormsAnswers,
  PanInformationAllSubQuestionsKeys,
} from "../../interfaces/photovoltaiqueFormInterfaces";

// REDUCER FUNCTIONS USED IN THE PHOTOVOLTAIQUE FORM ONLY

export const setPVFormPickUniqueCard = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setPickUniqueCardAction.match(action)) {
    const { cardValue, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // Standard case when we need to store the value at the root of the form state
      if (checkIfIsFormCommonQuestion(formQuestionName)) {
        const currentAnswer =
          state.formsMutableData[formName]?.[formQuestionName];
        // Specific rule for nombreDePan question : we reset the selected pan when we change the number of pan
        if (formQuestionName === "nombredePantoEquip") {
          newState.formsMutableData[formName].nombredePantoEquip = {
            ...currentAnswer,
            value: cardValue,
          };
          newState.formsMutableData.photovoltaique.panSelected = 0;
        } else {
          // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
          if (currentAnswer?.value === cardValue) {
            newState.formsMutableData[formName][formQuestionName] = {
              value: null,
              pictures: undefined,
            };
          } else {
            newState.formsMutableData[formName][formQuestionName] = {
              ...currentAnswer, // We keep the other potentials keys that already exist
              value: cardValue,
            };
          }
        }
      }

      // Case when we need to store the input value in the panInformation key
      if (checkIfPanInformationQuestion(formQuestionName)) {
        const panInformations =
          newState.formsMutableData[formName].panInformations || [];
        const currentAnswer =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName];
        // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
        if (currentAnswer?.value === cardValue) {
          panInformations[answerIndex || 0] = {
            ...panInformations[answerIndex || 0],
            [formQuestionName]: {
              value: null,
            },
          };
        } else {
          panInformations[answerIndex || 0] = {
            ...panInformations[answerIndex || 0],
            [formQuestionName]: {
              ...currentAnswer, // We keep the other potentials keys that already exist
              value: cardValue,
            },
          };
        }
        newState.formsMutableData[formName].panInformations = panInformations;
      }
      if (checkIfIsPotentialTrancheeQuestion(formQuestionName)) {
        const potentialTranchees =
          newState.formsMutableData[formName].potentialTranchees || [];
        const currentAnswer =
          state.formsMutableData[formName].potentialTranchees?.[
            answerIndex || 0
          ]?.[formQuestionName];

        // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
        if (currentAnswer?.value === cardValue) {
          potentialTranchees[answerIndex || 0] = {
            ...potentialTranchees[answerIndex || 0],
            [formQuestionName]: {
              value: null,
            },
          };
        } else {
          potentialTranchees[answerIndex || 0] = {
            ...potentialTranchees[answerIndex || 0],
            [formQuestionName]: {
              ...currentAnswer, // We keep the other potentials keys that already exist
              value: cardValue,
            },
          };
        }
        newState.formsMutableData[formName].potentialTranchees =
          potentialTranchees;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPVFormPickMultipleCards = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setPVPickMultipleCardsAction.match(action)) {
    const { textCard, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // For now only masqueSolaires question expect a multiple strings answer
      if (formQuestionName === "masqueSolaires") {
        const panInformations =
          newState.formsMutableData[formName].panInformations || [];
        const currentAnswer =
          panInformations[answerIndex || 0]?.[formQuestionName];

        if (currentAnswer?.value?.includes(textCard)) {
          panInformations[answerIndex || 0] = {
            ...panInformations[answerIndex || 0],
            [formQuestionName]: {
              ...currentAnswer,
              value: currentAnswer.value.filter((item) => item !== textCard),
            },
          };
        } else {
          if (textCard === 629950003) {
            // If click on card "none", unselect every other card
            panInformations[answerIndex || 0] = {
              ...panInformations[answerIndex || 0],
              [formQuestionName]: {
                ...currentAnswer,
                value: [629950003],
              },
            };
          } else {
            if (currentAnswer?.value?.includes(629950003)) {
              panInformations[answerIndex || 0] = {
                ...panInformations[answerIndex || 0],
                [formQuestionName]: {
                  ...currentAnswer,
                  value: [textCard],
                },
              };
            } else {
              panInformations[answerIndex || 0] = {
                ...panInformations[answerIndex || 0],
                [formQuestionName]: {
                  ...currentAnswer,
                  value: [...(currentAnswer?.value || []), textCard],
                },
              };
            }
          }
        }

        newState.formsMutableData[formName].panInformations = panInformations;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPVFormSelectedInput = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setSelectedInputAction.match(action)) {
    const { selectedInput, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // Standard case when we need to store the value at the root of the form state
      if (checkIfIsFormCommonQuestion(formQuestionName)) {
        newState.formsMutableData[formName][formQuestionName] = {
          value: selectedInput,
          pictures:
            state.formsMutableData[formName][formQuestionName]?.pictures,
        };
      }

      // Case when we need to store the input value in the panInformation key
      if (checkIfPanInformationQuestion(formQuestionName)) {
        const currentPictures =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName]?.pictures;
        const panInformations =
          newState.formsMutableData[formName].panInformations || [];
        panInformations[answerIndex || 0] = {
          ...panInformations[answerIndex || 0],
          [formQuestionName]: {
            value: selectedInput,
            pictures: currentPictures,
          },
        };
        newState.formsMutableData[formName].panInformations = panInformations;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPVFormInputNumber = (state: IAppStates, action: AnyAction) => {
  if (actions.setInputNumberAction.match(action)) {
    const { inputNumber, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // Standard case when we need to store the value at the root of the form state
      if (checkIfIsFormCommonQuestion(formQuestionName)) {
        newState.formsMutableData[formName][formQuestionName] = {
          value: inputNumber,
          pictures:
            state.formsMutableData[formName][formQuestionName]?.pictures,
        };
      }

      // Case when we need to store the input value in the panInformation key
      if (checkIfPanInformationQuestion(formQuestionName)) {
        const currentPictures =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName]?.pictures;
        const panInformations =
          state.formsMutableData[formName].panInformations || [];

        panInformations[answerIndex || 0] = {
          ...panInformations[answerIndex || 0],
          [formQuestionName]: { value: inputNumber, pictures: currentPictures },
        };
        newState.formsMutableData[formName].panInformations = panInformations;
      }

      if (checkIfIsPotentialTrancheeQuestion(formQuestionName)) {
        const potentialTranchees =
          newState.formsMutableData[formName].potentialTranchees || [];

        potentialTranchees[answerIndex || 0] = {
          ...potentialTranchees[answerIndex || 0],
          [formQuestionName]: { value: inputNumber },
        };

        newState.formsMutableData[formName].potentialTranchees =
          potentialTranchees;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPVFormInputText = (state: IAppStates, action: AnyAction) => {
  if (actions.setInputTextAction.match(action)) {
    const { inputText, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // Standard case when we need to store the value at the root of the form state
      if (checkIfIsFormCommonQuestion(formQuestionName)) {
        newState.formsMutableData[formName][formQuestionName] = {
          value: inputText,
          pictures:
            state.formsMutableData[formName][formQuestionName]?.pictures,
        };
      }

      // Case when we need to store the input value in the panInformation key
      if (checkIfPanInformationQuestion(formQuestionName)) {
        const currentPictures =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName]?.pictures;
        const panInformations =
          newState.formsMutableData[formName].panInformations || [];
        panInformations[answerIndex || 0] = {
          ...panInformations[answerIndex || 0],
          [formQuestionName]: { value: inputText, pictures: currentPictures },
        };
        newState.formsMutableData[formName].panInformations = panInformations;
      }

      if (checkIfIsPotentialTrancheeQuestion(formQuestionName)) {
        const potentialTranchees =
          newState.formsMutableData[formName].potentialTranchees || [];

        potentialTranchees[answerIndex || 0] = {
          ...potentialTranchees[answerIndex || 0],
          [formQuestionName]: { value: inputText },
        };
        newState.formsMutableData[formName].potentialTranchees =
          potentialTranchees;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPVFormPanSelected = (state: IAppStates, action: AnyAction) => {
  if (actions.setPanSelectedAction.match(action)) {
    const { panSelected } = action.payload;
    const newState = { ...state };
    newState.formsMutableData.photovoltaique.panSelected = panSelected; // Action available only for photovoltaique form
    return newState;
  }
  return state;
};

export const setPVFormAnswersState = (state: IAppStates, action: AnyAction) => {
  if (actions.setFormAnswersStateAction.match(action)) {
    const { formAnswers, formName, visiteTechniqueId } = action.payload;
    const newState = { ...state };

    // We update redux form state as follow :
    // -> If the key exist in redux with a non nullish value, we don't update it : CRM values must not overwrite local values entered by the user
    // -> If the key is nullish (i.e. = undefined or null) : we create/update the key with the CRM values
    // -> We need to handle the case of panInformations keys, where we apply the same logic but in an array of object

    if (formAnswers) {
      if (formName === "photovoltaique") {
        const formAnswersTypedPV = formAnswers as IPhotovoltaiqueFormsAnswers;
        for (let key in formAnswers) {
          // Case panInformation key
          if (key === "panInformations") {
            // Case of the panInformation key
            const newPanInformations =
              state.formsMutableData[formName].panInformations || [];

            formAnswersTypedPV.panInformations?.forEach(
              (panInformation, index) => {
                for (let key in panInformation) {
                  if (
                    newPanInformations?.[index]?.[
                      key as PanInformationAllSubQuestionsKeys
                    ]?.value == null &&
                    newPanInformations?.[index]?.[
                      key as PanInformationAllSubQuestionsKeys
                    ]?.pictures == null // Check if nullish
                  ) {
                    newPanInformations[index] = {
                      ...newPanInformations[index],
                      [key as PanInformationAllSubQuestionsKeys]:
                        formAnswersTypedPV.panInformations?.[index][
                          key as PanInformationAllSubQuestionsKeys
                        ],
                    }; // If nullish we update the redux panInformation key with the CRM value
                  }
                }
                newState.formsMutableData[formName].panInformations =
                  newPanInformations;
              }
            );
          }
          // Standard case (not panInformations key)
          else {
            if (
              newState.formsMutableData[formName]?.[
                key as IPhotovoltaiqueStandardQuestionKeys
              ]?.value == null &&
              newState.formsMutableData[formName]?.[
                key as IPhotovoltaiqueStandardQuestionKeys
              ]?.pictures == null && // Check if value stored in redux is nullish
              formAnswers[key as IFormCommonInfoKeys]?.value !== null // Check if value received from CRM is not nullish
            ) {
              newState.formsMutableData[formName][key as IFormCommonInfoKeys] =
                formAnswersTypedPV[key as IFormCommonInfoKeys]; // If nullish we update the redux key with the CRM value
            }
          }
        }
      }

      // When redux state is updated, we save the new form state in local storage
      localStorage.setItem(
        visiteTechniqueId,
        JSON.stringify(newState.formsMutableData[formName])
      );
      return newState;
    }

    // If we don't have new form mutable data we reset the mutable data of the form

    newState.formsMutableData[formName] = JSON.parse(
      JSON.stringify(initialState.formsMutableData[formName])
    );
    localStorage.removeItem(visiteTechniqueId);
    return newState;
  }
  return state;
};

export const setPVFormQuestionPicture = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setQuestionPictureAction.match(action)) {
    const { pictureUrl, formName, formQuestionName, answerIndex, visitId } =
      action.payload;
    const newState = { ...state };

    if (formName === "photovoltaique") {
      // Standard case when we need to store the value at the root of the form state
      if (checkIfIsFormCommonQuestion(formQuestionName)) {
        const currentAnswer =
          state.formsMutableData[formName]?.[formQuestionName];
        const currentPicture =
          state.formsMutableData[formName][formQuestionName]?.pictures;
        newState.formsMutableData[formName][formQuestionName] = {
          ...currentAnswer, // We keep the other potentials keys that already exist
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : [pictureUrl],
        };
      }

      // Case when we need to store the input value in the panInformation key
      if (checkIfPanInformationQuestion(formQuestionName)) {
        const panInformations =
          newState.formsMutableData[formName].panInformations || [];
        const currentAnswer =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName];
        const currentPicture =
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[formQuestionName]?.pictures;
        // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)

        panInformations[answerIndex || 0] = {
          ...panInformations[answerIndex || 0],
          [formQuestionName]: {
            ...currentAnswer, // We keep the other potentials keys that already exist
            pictures:
              currentPicture && currentPicture?.length > 0
                ? [...currentPicture, pictureUrl]
                : [pictureUrl],
          },
        };

        newState.formsMutableData[formName].panInformations = panInformations;
      }
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};
