import "./BooleanChoiceBtnContainer.scss";

// Services import
import { valueToBoolean } from "../../../../services/util/valueToBoolean";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";
import { Dualite } from "@web/shared/dist/components/DesignSystem/Boutons/Dualite/Dualite";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

// Local interface declaration
interface Props {
  title: string | JSX.Element;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName?: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onCardClick?: (value: boolean) => void;
  onUploadPicture?: (pictureUrl: string) => void;
  size?: "small" | null;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const BooleanChoiceBtnContainer = ({
  title,
  formName,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  onCardClick,
  onUploadPicture,
  size,
  wallIndex,
  pointSingulierName,
}: Props) => {
  const binaryValue = valueToBoolean(userAnswer?.value) ?? null;
  return (
    <div className={`BooleanChoiceBtnContainer`}>
      <section
        className={`BooleanChoiceBtnContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"BooleanChoiceBtnContainer__QuestionTitle"}>{title}</p>

        <Dualite
          binaryValue={binaryValue}
          borderColor={"#ED8C00"}
          onSelect={(option: boolean) => onCardClick && onCardClick(option)}
          size={size}
        />
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
