import "./InputContainer.scss";

// Components import
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";
import { TextInput } from "@web/shared/dist/components/DesignSystem/Inputs/TextInput/TextInput";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

interface Props {
  title: string;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  type?: string;
  iconType?: "squaredMeters" | "meters" | "euros";
  placeholder?: string;
  inputLableText?: string;
  onChange?: (value: string) => void;
  onUploadPicture?: (pictureUrl: string) => void;
  iconHidden?: boolean;
  size?: string;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const InputContainer = ({
  title,
  formName,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  userAnswer,
  iconType,
  type,
  placeholder,
  inputLableText,
  onChange,
  onUploadPicture,
  iconHidden = false,
  size,
  wallIndex,
  pointSingulierName,
}: Props) => {
  return (
    <div className="InputContainer">
      <section
        className={`InputContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"InputContainer__QuestionTitle"}>{title}</p>

        <div
          className={`InputContainer${
            size === "big" ? "__fieldContainerBig" : "__fieldContainer"
          }`}
        >
          <TextInput
            iconHidden={iconHidden}
            value={userAnswer?.value?.toString() ?? undefined}
            onChange={(e) => onChange && onChange(e.currentTarget.value)}
            iconType={iconType}
            type={type}
            placeholder={placeholder}
            inputLableHidden={true}
            inputDescriptionHidden={true}
            inputLableText={inputLableText}
          />
        </div>
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
