export const valueToBoolean = (value?: string | number | boolean | null) => {
  switch (value) {
    case "true":
    case "1":
    case 1:
    case 629950000:
    case "629950000":
      return true;
    case "false":
    case "0":
    case 0:
    case 629950001:
    case "629950001":
      return false;
    case null:
      return null;
    case undefined:
      return false;
    default:
      return value ? true : false;
  }
};
