export const formatDate = (date?: string | null): string => {
  const formatedDate = new Date(date || "");

  return (
    formatedDate.toLocaleDateString("fr") +
    " à " +
    String(formatedDate.getHours() + 1).padStart(2, "0") +
    "h" +
    String(formatedDate.getMinutes()).padStart(2, "0")
  );
};
