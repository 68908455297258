import React from "react";
import "./singleVisit.scss";
import { useNavigate } from "react-router-dom";
import { IVisiteTechniqueList } from "../../../interfaces/generalInterfaces";
import arrowInCircle from "../../../assets/images/arrow-in-blue-circle.svg";

// Services import
import { valueToLabelDictionnary } from "../../../services/Forms/valueToLabelDictionnary";
import { TypeOfProductIcon } from "../TypeOfProductIcon/TypeOfProductIcon";

interface IProps {
  singleVisitData: IVisiteTechniqueList;
}

export const SingleVisit = ({ singleVisitData }: IProps) => {
  const navigate = useNavigate();

  const showDetailsOfSingleVisitOnClick = (
    singleVisitData: IVisiteTechniqueList
  ) => {
    if (singleVisitData.statutVT === 629950000) {
      // for now nothing happens. But in the future, will lead to the pdf of the VT
    } else {
      // in any other case, we go to the visit details
      navigate(`/visite-technique/${singleVisitData.visiteTechniqueId}`);
    }
    // 629950000 = réasliée, 629950002 = Planifiée, 629950003 = En cours
  };

  const buttonStyle = () => {
    if (singleVisitData.statutVT === 629950000) {
      return { filter: "brightness(70%)", cursor: "not-allowed" };
    }
  };

  const dateTimeUTC = new Date(singleVisitData.dateVisite);
  return (
    <div className="singleVisit">
      <div className="singleVisit__Container">
        <div className="singleVisit__Content">
          <p className="flex-item size-12 text-left">
            {dateTimeUTC.toLocaleDateString()}
          </p>
          <p className="flex-item size-08">{`${dateTimeUTC.getHours()}h${
            dateTimeUTC.getMinutes().toLocaleString().length === 1
              ? "0" + dateTimeUTC.getMinutes()
              : dateTimeUTC.getMinutes()
          }`}</p>
          <p className="flex-item size-1 text-left">
            {singleVisitData.customerFirstName}
          </p>
          <p className="flex-item size-1 text-left">
            {singleVisitData.customerLastName}
          </p>
          <p className="flex-item size-1">
            {singleVisitData.customerPostalCode}
          </p>
          <p className="flex-item size-1">
            {singleVisitData.statutVT &&
              valueToLabelDictionnary("statutVt", singleVisitData.statutVT)}
          </p>
          <p className="flex-item size-12">
            {singleVisitData.statutRapportVT &&
              valueToLabelDictionnary(
                "statutRapportVT",
                singleVisitData.statutRapportVT
              )}
          </p>
          <div className="flex-item size-08">
            <button
              onClick={() => showDetailsOfSingleVisitOnClick(singleVisitData)}
            >
              <img
                src={arrowInCircle}
                alt="arrow-in-circle"
                style={buttonStyle()}
              />
            </button>
          </div>
          {/* last item of array to match the arrow in the singleVisit Item */}
        </div>
        <div className="singleVisit__TypeOfProductContainer">
          <TypeOfProductIcon typeOfProduct={singleVisitData.typeDeProduit} />
        </div>
      </div>
    </div>
  );
};
