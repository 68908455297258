import "./formPagesITE.scss";

// Librairie import
import React from "react";

// Components import
import { SingleChoiceBtnContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/singleChoiceBtnContainer/SingleChoiceBtnContainer";
import { DropDownBtnContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/DropDownBtnContainer/DropDownBtnContainer";
import { InputContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/InputContainer/InputContainer";
import { TextAreaContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/TextAreaContainer/TextAreaContainer";
import { TooltipInfoForm } from "../../../../components/TooltipInfoForm/TooltipInfoForm";

// Interfaces import
import {
  IFormCommonInfoKeys,
  IITEFormData,
} from "../../../../interfaces/generalInterfaces";
import { useAppDispatch } from "../../../../redux/store/hook";
import { setFormCommonInfoAction } from "../../../../redux/appActions";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  visitId?: string;
}

export const InstallationElectrique = ({
  ITEFormMutableData,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const formName = "ITE";

  const onClickSaveRedux = ({
    questionName,
    value,
    pictureUrl,
  }: {
    questionName: IFormCommonInfoKeys;
    value?: string | number;
    pictureUrl?: string;
  }) => {
    dispatch(
      setFormCommonInfoAction({
        value: value,
        formName: formName,
        visitId: visitId ?? null,
        formQuestionName: questionName,
        pictureUrl,
      })
    );
  };

  return (
    <div className="formPagesITE">
      <div className="formPages__Category formPagesITE__Category">
        <H2 title={"Installation électrique"} />
      </div>

      <div className="ITE_questionContainer">
        <SingleChoiceBtnContainer
          gap={false}
          title={<>Type&nbsp;d’installation&nbsp;électrique</>}
          formName={formName}
          userAnswer={ITEFormMutableData?.typeAlimentation}
          questionName="typeAlimentation"
          visitId={visitId}
          isPictureNeeded={true}
          optionList={[
            {
              label: "Monophasé",
              value: "629950000",
            },
            {
              label: "Triphasé",
              value: "629950001",
            },
          ]}
          areAnswersAlign={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "typeAlimentation",
              pictureUrl: pictureUrl,
            })
          }
          onCardClick={(cardValue) =>
            onClickSaveRedux({
              questionName: "typeAlimentation",
              value: cardValue,
            })
          }
        />
      </div>

      <div className="ITE_questionContainer">
        <DropDownBtnContainer
          title="Puissance souscrite"
          placeholder={"XXXX"}
          formName={formName}
          userAnswer={ITEFormMutableData?.puissanceSouscrite}
          questionName="puissanceSouscrite"
          visitId={visitId}
          isPictureNeeded={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "puissanceSouscrite",
              pictureUrl: pictureUrl,
            })
          }
          optionList={[
            {
              label: "6kVA",
              value: "629950000",
            },
            {
              label: "9kVA",
              value: "629950001",
            },
            {
              label: "12kVA",
              value: "629950002",
            },
            {
              label: "15kVA",
              value: "629950004",
            },
            {
              label: "18kVA",
              value: "629950003",
            },
            {
              label: "24kVA",
              value: "629950005",
            },
            {
              label: "30kVA",
              value: "629950006",
            },
            {
              label: "36kVA",
              value: "629950007",
            },
          ]}
        />
      </div>
      <div className="ITE_questionContainer">
        <TooltipInfoForm
          textContent={
            <p>
              Pensez à prévenir les clients si une augmentation de la puissance
              souscrite est à réaliser auprès de son fournisseur d’énergie.
              <br />
              Précisez en commentaire la bonne prise en compte de cette
              information par le client.
            </p>
          }
        />
      </div>
      <div className="ITE_questionContainer">
        <SingleChoiceBtnContainer
          title={<>Emplacement&nbsp;du&nbsp;tableau&nbsp;électrique</>}
          gap={false}
          formName={formName}
          userAnswer={ITEFormMutableData?.emplacementTableauElec}
          questionName="emplacementTableauElec"
          visitId={visitId}
          isPictureNeeded={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "emplacementTableauElec",
              pictureUrl: pictureUrl,
            })
          }
          optionList={[
            {
              label: "Intérieur",
              value: "629950000",
            },
            {
              label: "Mur de clôture",
              value: "629950001",
            },
          ]}
          areAnswersAlign={true}
          onCardClick={(cardValue) =>
            onClickSaveRedux({
              questionName: "emplacementTableauElec",
              value: cardValue,
            })
          }
        />
      </div>
      <div className="ITE_questionContainer">
        <SingleChoiceBtnContainer
          title="Emplacement du compteur"
          formName={formName}
          userAnswer={ITEFormMutableData?.emplacementCompteur}
          questionName="emplacementCompteur"
          visitId={visitId}
          isPictureNeeded={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "emplacementCompteur",
              pictureUrl: pictureUrl,
            })
          }
          optionList={[
            {
              label: "Intérieur",
              value: "629950000",
            },
            {
              label: "Mur de clôture",
              value: "629950001",
            },
            {
              label: "Sur la façade de la maison",
              value: "629950002",
            },
          ]}
          areAnswersAlign={false}
          onCardClick={(cardValue) =>
            onClickSaveRedux({
              questionName: "emplacementCompteur",
              value: cardValue,
            })
          }
        />
      </div>
      <div className="ITE_questionContainer">
        <SingleChoiceBtnContainer
          title="Type de compteur"
          formName={formName}
          userAnswer={ITEFormMutableData?.typeCompteur}
          questionName="typeCompteur"
          visitId={visitId}
          isPictureNeeded={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "typeCompteur",
              pictureUrl: pictureUrl,
            })
          }
          optionList={[
            {
              label: "Linky",
              value: "629950000",
            },
            {
              label: "Mécanique",
              value: "629950001",
            },
            {
              label: "Analogique",
              value: "629950002",
            },
          ]}
          areAnswersAlign={false}
          onCardClick={(cardValue) =>
            onClickSaveRedux({
              questionName: "typeCompteur",
              value: cardValue,
            })
          }
        />
      </div>
      <div className="ITE_questionContainer">
        <InputContainer
          title="Distance entre l’alimentation d’eau et le coffret électrique :"
          questionName="distanceAlimentationEauCoffretElectrique"
          formName={formName}
          visitId={visitId}
          isPictureNeeded={true}
          areAnswersAlign={true}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "distanceAlimentationEauCoffretElectrique",
              pictureUrl: pictureUrl,
            })
          }
          userAnswer={
            ITEFormMutableData?.distanceAlimentationEauCoffretElectrique
          }
          placeholder={"XXXX"}
          iconType={"meters"}
          type="number"
          onChange={(value) =>
            onClickSaveRedux({
              value: value,
              questionName: "distanceAlimentationEauCoffretElectrique",
            })
          }
        />
      </div>
      <div className="ITE_questionContainer">
        <TextAreaContainer
          title="Commentaire sur la partie eléctrique :"
          questionName="commentairePartieElectrique"
          placeholder={"Ton commentaire ici"}
          formName={formName}
          userAnswer={ITEFormMutableData?.commentairePartieElectrique}
          visitId={visitId}
          areAnswersAlign={false}
          onUploadPicture={(pictureUrl) =>
            onClickSaveRedux({
              questionName: "commentairePartieElectrique",
              pictureUrl: pictureUrl,
            })
          }
          onChange={(value) =>
            onClickSaveRedux({
              value: value,
              questionName: "commentairePartieElectrique",
            })
          }
        />
      </div>
    </div>
  );
};
