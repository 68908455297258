import { Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet?.create({
  pagination: {
    fontFamily: "DMSansBold",
    fontSize: 10,
    position: "absolute",
    bottom: 20,
    left: "50%",
  },
});

export const PdfPagination = () => {
  return (
    <Text
      fixed
      style={styles.pagination}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
    ></Text>
  );
};
