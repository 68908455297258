import "./headerMenuDropdown.scss";

// Libraries import
import React from "react";

// Components import
import { HeaderMenuNavigation } from "../HeaderMenuNavigation/HeaderMenuNavigation";

// Services import
import { WelcomeMessage } from "../WelcomeMessage/WelcomeMessage";

// Other imports
import { useAppSelector } from "../../../redux/store/hook";

// image import : to remove when api call will return the image
import profilPicture from "../../../assets/images/photo-profil-test.svg";

// Interfaces delcaration
interface Props {
  toggleMenu: () => void;
  isMenuDisplayed: boolean;
}

export const HeaderMenuDropdown: React.FC<Props> = ({
  toggleMenu,
  isMenuDisplayed,
}) => {
  const userProfileInfo = useAppSelector((states) => states.userProfileInfo);

  return (
    <div className={isMenuDisplayed ? "animation-in" : "animation-out"}>
      <div
        className="header-menu-background"
        data-testid="header-menu-background"
        onClick={toggleMenu}
      ></div>
      <menu className="header-menu-dropdown" data-testid="header-menu-dropdown">
        <div className="header-menu-top">
          <WelcomeMessage
            // add here the picture from profile CRM
            userProfilPicture={profilPicture}
            userFirstName={
              // First letter upper case
              userProfileInfo?.firstname
                ? userProfileInfo.firstname[0].toUpperCase() +
                  userProfileInfo.firstname.slice(1).toLocaleLowerCase()
                : null
            }
            userLastName={
              // First letter upper case
              userProfileInfo?.lastname
                ? userProfileInfo.lastname[0].toUpperCase() +
                  userProfileInfo.lastname.slice(1).toLocaleLowerCase()
                : null
            }
          />
        </div>
        <div className="header-menu-navigation-container">
          <HeaderMenuNavigation toggleMenu={toggleMenu} />
        </div>
      </menu>
    </div>
  );
};
