// Interface import
import { ICRMExtraCharge } from "../../interfaces/ExtraChargesInterfaces";
import { IFormAllFraisSupplementaireInformations } from "../../interfaces/photovoltaiqueFormInterfaces";

// Funtion to format data received from CRM to match the redux data structure
export const formatGetExtraChargesDataReceivedFromCRM = (
  rawDataFromCRM: ICRMExtraCharge[]
) => {
  const VTExtraChargesInfoToStoreInRedux: IFormAllFraisSupplementaireInformations =
    {};
  const nombreTrancheeAPrevoir = rawDataFromCRM.filter(
    (extraCharge) => extraCharge.typedeTravaux === 629950000 // "Tranchée"
  )?.length;
  VTExtraChargesInfoToStoreInRedux.trancheeaPrevoir = {
    value: nombreTrancheeAPrevoir ? 1 : 0,
  };
  VTExtraChargesInfoToStoreInRedux.nombreTrancheeAPrevoir = {
    value: nombreTrancheeAPrevoir,
  };
  VTExtraChargesInfoToStoreInRedux.potentialTranchees = rawDataFromCRM
    .filter((extraCharge) => extraCharge.typedeTravaux === 629950000) // "Tranchée"
    .map((tranchee) => {
      return {
        id: {
          value: tranchee.id,
        },
        typedeTerrain: {
          value: tranchee.typedeTerrain,
        },
        longueurTranchee: {
          value: tranchee.longueurTranche,
        },
        commentaireTranchee: {
          value: tranchee.description,
        },
      };
    });

  VTExtraChargesInfoToStoreInRedux.carrotageaPrevoir = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950003 // "Carrotage"
    )
      ? 1
      : 0,
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950003
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.nombreDeCarrotage = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950003 // "Carrotage"
    )?.nombre,
  };
  VTExtraChargesInfoToStoreInRedux.nacelleaPrevoir = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950006
    )?.nacelleAPrevoir, // "Accès"
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950006
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.echelleaPrevoir = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950006
    )?.echelleaPrevoir, // "Accès"
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950006
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.renforcementCharpenteaPrevoir = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950004
    ) // "Renforcement charpente"
      ? 1
      : 0,
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950004
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.commentaireEtatCharpente = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950004
    )?.description, //commentaire etat charpente
  };
  VTExtraChargesInfoToStoreInRedux.bacsALesteraPrevoir = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950002 // "Bacs à lester"
    )
      ? 1
      : 0,
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950002
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.nombreBacsALester = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950002 // "Bacs à lester"
    )?.nombre,
  };
  VTExtraChargesInfoToStoreInRedux.commentaireFraisSupplementaires = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950005
    )?.description, // "Autres"
    id:
      rawDataFromCRM.find(
        (extraCharge) => extraCharge.typedeTravaux === 629950005
      )?.id || null,
  };
  VTExtraChargesInfoToStoreInRedux.montantEstimeFraisSupplementaires = {
    value: rawDataFromCRM.find(
      (extraCharge) => extraCharge.typedeTravaux === 629950005
    )?.amount, // "Autres"
  };
  return VTExtraChargesInfoToStoreInRedux;
};
