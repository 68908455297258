// import React from "react";
import React, { useState } from "react";
// Styles import
import styled from "styled-components";
import { stylesVariables } from "../../../../styles/variables";
export interface TextAreaProps {
  inputLableHidden?: boolean;
  inputDescriptionHidden?: boolean;
  inputLableText?: string;
  inputDescriptionText?: string;
  placeholder?: string;
  error?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
const InputLable = styled.p<{
  error?: boolean;
  isInputOnFocus?: boolean;
  value?: string;
  isDisabled?: boolean;
}>`
  text-align: start;
  font-family: "DM sans", serif;
  font-size: 14px;
  color: ${(props) =>
    props.error
      ? "#E02819"
      : !props.isInputOnFocus
      ? stylesVariables.Stablegrey
      : !!props.value
      ? stylesVariables.AssuranceBlue
      : props.isInputOnFocus
      ? stylesVariables.AssuranceBlue
      : stylesVariables.Stablegrey};
`;
const InputDescription = styled.p<{
  error?: boolean;
  isDisabled?: boolean;
}>`
  text-align: start;
  font-family: "DM sans", serif;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) =>
    props.error ? "#E02819" : stylesVariables.StableGrayLight02};
`;
const TextAreaBox = styled.textarea<{
  inputLableHidden?: boolean;
  inputDescriptionHidden?: boolean;
  inputLableText?: string;
  inputDescriptionText?: string;
  placeholder?: string;
  error?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  isInputOnFocus?: boolean;
}>`
  padding: 10px;
  resize: both;
  max-width: 100%;
  background-color: #ffffff;
  font-family: "DM sans", serif;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => (props.error ? "#E02819" : stylesVariables.OffBlack)};
  &:focus {
    ::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${(props) =>
      props.error ? "#E02819" : stylesVariables.StableGrayLight02};
    font-family: "DM sans", serif;
    font-size: 16px;
  }

  border: 1px solid
    ${(props) =>
      props.error
        ? "#E02819"
        : props.isInputOnFocus || (!props.isInputOnFocus && !!props.value)
        ? stylesVariables.AssuranceBlue
        : stylesVariables.StableGrayLight03};
  &:hover {
    border: 1px solid
      ${(props) => (props.error ? "#E02819" : stylesVariables.AssuranceBlue)};
  }
`;
export const TextArea: React.FC<TextAreaProps> = ({
  inputLableHidden = true,
  inputDescriptionHidden = true,
  inputLableText = "",
  inputDescriptionText = "",
  placeholder = "",
  error = false,
  value = "",
  onChange,
}) => {
  const [isInputOnFocus, setIsInputOnFocus] = useState<boolean>(false);
  return (
    <Container>
      <InputLable
        isInputOnFocus={isInputOnFocus}
        hidden={inputLableHidden}
        error={error}
        value={value}
      >
        {inputLableText}
      </InputLable>
      <TextAreaBox
        isInputOnFocus={isInputOnFocus}
        onChange={onChange}
        onFocus={() => setIsInputOnFocus(true)}
        onBlur={() => setIsInputOnFocus(false)}
        placeholder={placeholder}
        value={value}
      />
      <InputDescription hidden={inputDescriptionHidden} error={error}>
        {inputDescriptionText}
      </InputDescription>
    </Container>
  );
};
