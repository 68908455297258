import "./visitNotDoableModal.scss";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { SelectDropdown } from "@web/shared/dist/components/Old/FormComponents/SelectDropdown/SelectDropdown";

// Services import
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { setSelectedInputAction } from "../../../redux/appActions";
import { valueToLabelDictionnary } from "../../../services/Forms/valueToLabelDictionnary";

// Interfaces import
import { formNames } from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  onClickButton: () => void;
  visiteTechniqueId?: string;
  formName: formNames;
}

export const VisitNotDoableModal = ({
  onClickButton,
  visiteTechniqueId,
  formName,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedInput = useAppSelector(
    (state) => state.formsMutableData[formName].statutRapportVT
  );

  // Event handler
  const selectItem = (option: {
    label: string | null;
    value: string | number | null;
  }) => {
    if (option?.value) {
      typeof option.value === "string"
        ? dispatch(
            setSelectedInputAction({
              selectedInput: parseInt(option.value),
              formQuestionName: "statutRapportVT",
              visitId: visiteTechniqueId,
              formName,
            })
          )
        : dispatch(
            setSelectedInputAction({
              selectedInput: option.value,
              formQuestionName: "statutRapportVT",
              visitId: visiteTechniqueId,
              formName,
            })
          );
    }
  };

  return (
    <div className="visitNotDoableModal">
      <div className="visitNotDoableModal__TextBlock">
        <h3>VT non-réalisable</h3>
      </div>
      <div className="visitNotDoableModal__Select">
        <SelectDropdown
          value={selectedInput?.value || undefined}
          onSelect={selectItem}
          options={[629950002, 629950003, 629950004, 629950005].map((value) => {
            return {
              value,
              label:
                valueToLabelDictionnary("statutRapportVT", value)?.toString() ??
                "",
            };
          })}
        />
      </div>
      <div className="visitNotDoableModal__Buttons">
        <MainButton
          name="Envoyer le rapport"
          onClick={onClickButton}
          size="big"
        />
      </div>
    </div>
  );
};
