// Libraries import
import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import * as Sentry from "@sentry/browser";

// Service import
import { useDisconnectApp } from "./useDisconnectApp";

// Interfaces declaration
interface IFunctionReturn {
  axiosPostRequest: <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<T> | void>;
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

// useAxiosPost : return tools to send POST request and handle error messages
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function axiosPostRequest
//     Function to call to send POST request
// Boolean isLoading
//     Indicate if the asynchronous POST request is onGoing
// String errorMessage
//     POST request error message (if request fails)

export const useAxiosPost = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { disconnectApp } = useDisconnectApp();

  // axiosPostRequest : send POST request and handle error cases
  // Parameters
  // ----------
  // URL: String
  //      the URL of the API
  // body: Object
  //      contain information to send to the API
  // config (optional): Object
  //      contain configuration information to send to the API
  // Returns
  // ----------
  //   Data of the response or void

  const axiosPostRequest = async <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T> | void> => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.post<T>(URL, body, config);
      if (response.status.toString()[0] === "2") {
        setIsLoading(false);
        return response;
      }
      setErrorMessage("Une erreur est survenue, merci de réessayer.");
      setIsLoading(false);

      // Send error event to Sentry
      Sentry.captureException(
        new Error("Successfull post request but status different from 2xx"),
        (scope) => {
          scope.clear();
          scope.setExtra("Request body", body);
          return scope;
        }
      );
      return;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;

      // client received an error response (5xx, 4xx)
      if (axiosError.response) {
        // First we format the error message. If it is an object we need to stringify it otherwise react crashes when it tries to render an object as text
        // Usually format of the data is : { "status": int,"errors": string | Array | object}
        const errorMessage =
          typeof axiosError?.response?.data?.errors === "object" &&
          !Array.isArray(axiosError?.response?.data?.errors)
            ? JSON.stringify(axiosError?.response?.data?.errors)
            : axiosError?.response?.data?.errors;

        if (axiosError.response.status === 401) {
          setErrorMessage(errorMessage || "La requête n'est pas autorisée.");
          // If response is unauthorized we reset user authorization info -> redirect the user to the login page
          disconnectApp();
          setIsLoading(false);
          // Send error event to Sentry
          Sentry.captureException(new Error("Post request failed"), (scope) => {
            scope.clear();
            scope.setExtra("Request body", body);
            scope.setExtra("Request response", axiosError?.response?.data);
            scope.setExtra("Request errors", axiosError?.response?.data.errors);
            return scope;
          });
          return;
        }

        if (errorMessage) {
          // If we have an error message we set it
          setErrorMessage(errorMessage);
          setIsLoading(false);
          // Send error event to Sentry
          Sentry.captureException(new Error("Post request failed"), (scope) => {
            scope.clear();
            scope.setExtra("Request body", body);
            scope.setExtra("Request response", axiosError?.response?.data);
            scope.setExtra("Request errors", axiosError?.response?.data.errors);
            return scope;
          });
          return;
        }

        // Otherwise we set e generic error message
        setErrorMessage(
          `Une erreur code ${axiosError?.response?.status} est survenue coté serveur.`
        );
        setIsLoading(false);
        // Send error event to Sentry
        Sentry.captureException(new Error("Post request failed"), (scope) => {
          scope.clear();
          scope.setExtra("Request body", body);
          scope.setExtra("Request response", axiosError?.response?.data);
          scope.setExtra("Request errors", axiosError?.response?.data.errors);
          return scope;
        });
        return;
      } else if (axiosError.request) {
        // client never received a response, or request never left
        if (typeof axiosError.request.data === "string") {
          setErrorMessage(axiosError.request.data);
          setIsLoading(false);
          // Send error event to Sentry
          Sentry.captureException(new Error("Post request failed"), (scope) => {
            scope.clear();
            scope.setExtra("Request body", body);
            scope.setExtra("Request response", axiosError?.response?.data);
            scope.setExtra("Request errors", axiosError?.response?.data.errors);
            return scope;
          });
          return;
        }
        setErrorMessage("Erreur serveur, merci de réessayer.");
        setIsLoading(false);
        // Send error event to Sentry
        Sentry.captureException(new Error("Post request failed"), (scope) => {
          scope.clear();
          scope.setExtra("Request body", body);
          scope.setExtra("Request response", axiosError?.response?.data);
          scope.setExtra("Request errors", axiosError?.response?.data.errors);
          return scope;
        });
        return;
      } else {
        setErrorMessage("Une erreur est survenue, merci de réessayer.");
      }
      setIsLoading(false);

      // Send error event to Sentry
      Sentry.captureException(new Error("Post request failed"), (scope) => {
        scope.clear();
        scope.setExtra("Request body", body);
        scope.setExtra("Request response", axiosError?.response?.data);
        scope.setExtra("Request errors", axiosError?.response?.data.errors);
        return scope;
      });
    }
  };

  return { axiosPostRequest, isLoading, errorMessage, setErrorMessage };
};
