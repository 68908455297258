import { Reducer, AnyAction } from "redux";

// Other imports
import * as types from "./constants";
import { initialState } from "./initialState";

// Services import
import {
  setUserAuthInfo,
  setUserProfileInfo,
  setVisitesTechniquesList,
  setVisiteTechniqueInfo,
  setFormMutableDataState,
  resetFormAnswersState,
  updateFormBranchState,
  setGoToNextPage,
  setGoToPreviousPage,
  setGoToSpecificPage,
  setInitalState,
  setFormPickUniqueCard,
  setFormSelectedInput,
  setFormInputNumber,
  setFormInputText,
  setFormAnswersState,
  setFormQuestionPicture,
  setCalepinage,
  setFormCommonInfo,
  setPickMultipleCards,
  setFormDropDown,
} from "./reducerFunctions/sharedReducerFunctions";
import {
  setPVFormPickUniqueCard,
  setPVFormPickMultipleCards,
  setPVFormSelectedInput,
  setPVFormInputNumber,
  setPVFormInputText,
  setPVFormPanSelected,
  setPVFormAnswersState,
  setPVFormQuestionPicture,
} from "./reducerFunctions/photovoltaiqueFormReducerFunctions";

import {
  setITEWall,
  setITEWallQuestion,
  setITEOuvrantQuestion,
  createITEOuvrant,
  deleteITEOuvrant,
  duplicateITEOuvrant,
  createITEWall,
  deleteITEWall,
  setITEPointSingulier,
  createITEPointSingulier,
  deleteITEPointSingulier,
  setITEExtraChargesCRMId,
  setITEOtherPointSingulier,
  createITEOtherPointSingulier,
  deleteITEOtherPointSingulier,
  updateReduxAfterSaveMurWithOuvrantItems,
} from "./reducerFunctions/ITEFormReducerFunctions";

import { determineFormNameFromProductType } from "../services/Forms/determineFormNameFromProductType";

// Interfaces import
import { IAppStates } from "../interfaces/generalInterfaces";

// REDUCER FUNCTIONS : UPDATE STATE IN REDUX STORE

// Redux reducer
export const appReducer: Reducer<IAppStates, AnyAction> = (
  state = initialState,
  action
): IAppStates => {
  const formName = determineFormNameFromProductType(
    state.visiteTechniqueInfo.typeDeProduit
  );

  switch (action.type) {
    case types.SET_GO_TO_NEXT_PAGE:
      return setGoToNextPage(state, action);
    case types.SET_GO_TO_PREVIOUS_PAGE:
      return setGoToPreviousPage(state, action);
    case types.SET_GO_TO_SPECIFIC_PAGE:
      return setGoToSpecificPage(state, action);
    case types.SET_PAN_SELECTED:
      return setPVFormPanSelected(state, action);
    case types.SET_USER_AUTH_INFO:
      return setUserAuthInfo(state, action);
    case types.SET_USER_PROFILE_INFO:
      return setUserProfileInfo(state, action);
    case types.SET_VISITES_TECHNIQUES_LIST:
      return setVisitesTechniquesList(state, action);
    case types.SET_VISITE_TECHNIQUE_INFO:
      return setVisiteTechniqueInfo(state, action);
    case types.SET_PICK_UNIQUE_CARD:
      if (formName === "photovoltaique") {
        return setPVFormPickUniqueCard(state, action);
      }
      return setFormPickUniqueCard(state, action);
    case types.SET_PICK_MULTIPLE_CARDS:
      return setPickMultipleCards(state, action);
    case types.SET_PV_PICK_MULTIPLE_CARDS:
      return setPVFormPickMultipleCards(state, action);
    case types.SET_SELECTED_INPUT:
      if (formName === "photovoltaique") {
        return setPVFormSelectedInput(state, action);
      }
      return setFormSelectedInput(state, action);
    case types.SET_INPUT_NUMBER:
      if (formName === "photovoltaique") {
        return setPVFormInputNumber(state, action);
      }
      return setFormInputNumber(state, action);
    case types.SET_INPUT_TEXT:
      if (formName === "photovoltaique") {
        return setPVFormInputText(state, action);
      }
      return setFormInputText(state, action);
    case types.SET_FORM_MUTABLE_DATA_STATE:
      return setFormMutableDataState(state, action);
    case types.SET_FORM_ANSWERS_STATE:
      if (formName === "photovoltaique") {
        return setPVFormAnswersState(state, action);
      }
      return setFormAnswersState(state, action);
    case types.UPDATE_FORM_BRANCH:
      return updateFormBranchState(state, action);
    case types.SET_QUESTION_PICTURE:
      if (formName === "photovoltaique") {
        return setPVFormQuestionPicture(state, action);
      }
      return setFormQuestionPicture(state, action);
    case types.SET_CALEPINAGE:
      return setCalepinage(state, action);
    case types.SET_FORM_DROPDOWN:
      return setFormDropDown(state, action);
    case types.SET_FORM_COMMON_INFO:
      return setFormCommonInfo(state, action);
    case types.RESET_FORM_ANSWERS:
      return resetFormAnswersState(state);
    case types.SET_INITAL_STATE:
      return setInitalState();
    case types.SET_ITE_WALL:
      return setITEWall(state, action);
    case types.SET_ITE_WALL_QUESTION:
      return setITEWallQuestion(state, action);
    case types.CREATE_ITE_WALL:
      return createITEWall(state, action);
    case types.DELETE_ITE_WALL:
      return deleteITEWall(state, action);
    case types.SET_ITE_OUVRANT_QUESTION:
      return setITEOuvrantQuestion(state, action);
    case types.CREATE_ITE_OUVRANT:
      return createITEOuvrant(state, action);
    case types.DELETE_ITE_OUVRANT:
      return deleteITEOuvrant(state, action);
    case types.DUPLICATE_ITE_OUVRANT:
      return duplicateITEOuvrant(state, action);
    case types.SET_ITE_POINT_SINGULIER:
      return setITEPointSingulier(state, action);
    case types.CREATE_ITE_POINT_SINGULIER:
      return createITEPointSingulier(state, action);
    case types.DELETE_ITE_POINT_SINGULIER:
      return deleteITEPointSingulier(state, action);
    case types.SET_ITE_OTHER_POINT_SINGULIER:
      return setITEOtherPointSingulier(state, action);
    case types.CREATE_ITE_OTHER_POINT_SINGULIER:
      return createITEOtherPointSingulier(state, action);
    case types.DELETE_ITE_OTHER_POINT_SINGULIER:
      return deleteITEOtherPointSingulier(state, action);
    case types.SET_ITE_EXTRA_CHARGES:
      return setITEExtraChargesCRMId(state, action);
    case types.UPDATE_REDUX_SAVE_MUR_OUVRANT_ITEMS:
      return updateReduxAfterSaveMurWithOuvrantItems(state, action);
    default:
      return state;
  }
};
