import "./formQuestionsFormatter.scss";

// Components import
import * as FormComponents from "../FormSteps";

// Interfaces import
import {
  formNames,
  IStandardFormPages,
} from "../../../interfaces/generalInterfaces";
import { InfoBoxForm } from "../../../components/InfoBoxForm/InfoBoxForm";

// Local interface declaration
interface Props {
  formScreenData: IStandardFormPages[][];
  formName: formNames;
  visiteTechniqueId?: string;
  formCurrentPage: number;
  formCurrentBranchs: string[];
}

export const FormQuestionsFormatter = ({
  formScreenData,
  formName,
  visiteTechniqueId,
  formCurrentPage,
  formCurrentBranchs,
}: Props) => {
  return (
    <>
      <form className="formQuestionsFormatter">
        {formScreenData[formCurrentPage]?.map((category, index) => {
          // We filter the questions according to the branch we want to display
          const questionsToDisplayAccordingToTheBranch =
            category.questions.filter((question) => {
              return question.branchs.every((branch) =>
                formCurrentBranchs.includes(branch)
              );
            });
          return (
            <div className="formQuestionsFormatter__Category" key={index}>
              <div className="formQuestionsFormatter__CategoryTop">
                {questionsToDisplayAccordingToTheBranch.length > 0 && (
                  <>
                    <h4>{category.categoryName}</h4>
                    <div className="formQuestionsFormatter__Trait" />
                  </>
                )}
              </div>
              {category.infoBox && (
                <InfoBoxForm textContent={category.infoBox} />
              )}
              <div className="formQuestionsFormatter__QuestionsContainer">
                {questionsToDisplayAccordingToTheBranch?.map(
                  (question, index) => {
                    const Component = FormComponents[question.type];
                    return (
                      <div
                        className="formQuestionsFormatter__Question"
                        key={index}
                      >
                        <Component
                          questionData={question}
                          formName={formName}
                          visitId={visiteTechniqueId || undefined}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </form>
    </>
  );
};
