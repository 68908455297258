import moment from "moment";
import { IVisiteTechniqueList } from "../interfaces/generalInterfaces";
import { dayNumberIntoDay } from "../data/dayNumberIntoDay";
import { CalendarDayLine } from "../components/CalendarDayLine/CalendarDayLine";
import { SingleVisit } from "../containers/MesVisites/SingleVisit/SingleVisit";

export const mesVisitesSortAndFilter = (
  mesVisitesArray: IVisiteTechniqueList[],
  buttonsManager: boolean[],
  clickedColumnName: keyof IVisiteTechniqueList,
  sortType: "ascendant" | "descendant" | "default"
) => {
  const filteredArray = mesVisitesArray
    .filter(
      buttonsManager[0] // VT En cours
        ? (visit) => visit.statutVT === 629950003
        : buttonsManager[1] //VT A venir
        ? (visit) => moment(visit.dateVisite).isSameOrAfter(moment(), "d")
        : buttonsManager[2] // else buttonsManager[2] (VT réalisées)
        ? (visit) => visit.statutVT === 629950000
        : (visit) => visit // all visits
    ) // 629950000 = réalisée, 629950002 = Planifiée, 629950003 = En cours
    .sort(
      // we sort by clicked column (date is default value)
      (singleVisitA, singleVisitB) =>
        sortType === "descendant"
          ? // ordre croissant
            singleVisitA[clickedColumnName] > singleVisitB[clickedColumnName]
            ? 1
            : -1
          : // ordre decroissant
          singleVisitA[clickedColumnName] > singleVisitB[clickedColumnName]
          ? -1
          : 1
    )
    .map((singleVisit, index, arr) => {
      const isSingleVisitDateToday = moment().isSame(
        moment(singleVisit.dateVisite),
        "d"
      );
      const todayDate = moment();
      const dayDifference = moment(todayDate).diff(singleVisit.dateVisite, "d");
      const currentIndexDay = new Date(arr[index].dateVisite)
        .getDay()
        .toString();
      const indexMinusOneDay =
        index > 0 && //so index - 1 is not undefined
        new Date(arr[index - 1].dateVisite).getDay().toString();
      return (
        <div className="mesVisites__SingleVisitContainer" key={index}>
          {clickedColumnName === "dateVisite" && //we need the array to be sorted in chronologique order
            currentIndexDay !== indexMinusOneDay && //when day changes, we display the day name
            buttonsManager[1] && // only on VT a venir tab
            dayDifference > -7 && //if day is in more than a week, we don't display the day anymore
            (sortType === "descendant" || sortType === "default") && ( //if the array is sorted descendent, we don't display the day
              <CalendarDayLine
                isToday={isSingleVisitDateToday}
                dayDifference={dayDifference}
                day={dayNumberIntoDay[currentIndexDay]}
                key={index + 1}
              />
            )}
          <SingleVisit singleVisitData={singleVisit} />
        </div>
      );
    });

  return filteredArray;
};
