// Other imports
import { useAppDispatch } from "../../redux/store/hook";
import { setVisitesTechniquesListAction } from "../../redux/appActions";

// Interfaces import
import { IVisiteTechniqueList } from "../../interfaces/generalInterfaces";

// Interfaces delcaration
interface IFunctionReturn {
  setVisitesTechniquesList: (
    visitesTechniquesList: IVisiteTechniqueList[] | null
  ) => void;
}

// useSetUserProfileInfo : return tools to save user profile info in redux store and session storage
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function setUserProfileInfo
//     Function to create/delete user's profile info in redux store and sessions storage

export const useSetVisitesTechniquesList = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  // setUserProfileInfo : create/delete user's profile info in session storage and redux store
  // Parameters
  // ----------
  // userProfileInfo: see IUserProfileInfo interface
  //      object containing the profile information of the user
  // Returns
  // ----------
  // Void

  const setVisitesTechniquesList = (
    visitesTechniquesList: IVisiteTechniqueList[] | null
  ): void => {
    if (visitesTechniquesList) {
      dispatch(setVisitesTechniquesListAction({ visitesTechniquesList }));
    } else {
      dispatch(setVisitesTechniquesListAction({ visitesTechniquesList: [] }));
    }
  };
  return { setVisitesTechniquesList };
};
