import "./welcomeMessage.scss";

// Libraries import
import React, { FC } from "react";

// Interfaces declaration
interface Props {
  userFirstName: string | null;
  userLastName: string | null;
  userProfilPicture: string | null;
}

export const WelcomeMessage: FC<Props> = ({
  userFirstName,
  userLastName,
  userProfilPicture,
}) => {
  return (
    <div className="welcome-message" data-testid="welcome-message">
      {userProfilPicture && (
        <div className="welcome-message__userPicture">
          <img src={userProfilPicture} alt="mon profil" />
        </div>
      )}
      <div className="welcome-message__userName">
        <p className="message">Bonjour</p>
        <p className="message userNames">
          {userFirstName} {userLastName} !
        </p>
      </div>
    </div>
  );
};
