import "./pickUniqueCard.scss";

// Components import
import { PickCard } from "@web/shared/dist/components/Old/FormComponents/PickCard/PickCard";
import { UploadPicturesComponent } from "../UploadPicturesComponent/UploadPicturesComponent";

// Service import
import { valueToLabelDictionnary } from "../../../../services/Forms/valueToLabelDictionnary";
import { useAppDispatch } from "../../../../redux/store/hook";
import { setPickUniqueCardAction } from "../../../../redux/appActions";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  title: string;
  cards: number[];
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
}

export const PickUniqueCard = ({
  title,
  formName,
  cards,
  userAnswer,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
}: Props) => {
  const dispatch = useAppDispatch();

  const onCardClick = (cardValue: number) => {
    dispatch(
      setPickUniqueCardAction({
        cardValue,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId,
        answerIndex,
      })
    );
  };

  return (
    <div className="pickUniqueCard">
      <div className="pickUniqueCard__Title">
        <p>{title}</p>
      </div>
      <div>
        <div className={"pickUniqueCard__pickCards"}>
          {cards?.map((CardValue, index) => {
            const cardText = valueToLabelDictionnary(questionName, CardValue);
            return (
              <div
                className={"pickUniqueCard__Card"}
                key={questionName + index}
              >
                <PickCard
                  text={
                    typeof cardText === "string" || typeof cardText === "number"
                      ? cardText
                      : ""
                  }
                  isSelected={
                    Number(userAnswer?.value) === CardValue ? true : false
                  }
                  onClick={() => onCardClick(CardValue)}
                />
              </div>
            );
          })}
        </div>
      </div>
      {isPictureNeeded && (
        <div className={`pickUniqueCard__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionName={questionName}
            uploadedPictures={userAnswer?.pictures}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
