import "./uploadPicturesComponent.scss";
import Compressor from "compressorjs";

import { useRef } from "react";
import buttonImage from "../../../assets/images/circle-button-plus.svg";

// Components import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Services import
import { useUploadDocument } from "../../../services/UploadDocuments/useUploadDocument";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { setQuestionPictureAction } from "../../../redux/appActions";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../services/Forms/checkTypes";

// Images import
import ValidatedBlue from "../../../assets/images/pictos/validated_blue.svg";

// Interface import
import {
  IFormQuestions,
  formNames,
} from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const UploadPicturesComponent = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const formsMutableData = useAppSelector((state) => state.formsMutableData);
  const uploadPictureInput = useRef<HTMLInputElement>(null);
  const { uploadDocument, isLoading } = useUploadDocument();

  const uploadedPictures =
    (() => {
      // Standard case when the question is at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return formsMutableData[formName][questionData.name]?.pictures;
      }

      // Case when it is a question (=key) of  the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].panInformations?.[answerIndex || 0]?.[
            questionData.name
          ]?.pictures ?? []
        );
      }

      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          formsMutableData[formName].potentialTranchees?.[answerIndex || 0]?.[
            questionData.name
          ]?.pictures ?? []
        );
      }
    })() ?? [];

  const handleUploadDocument = (file: File) => {
    // we double check here that it is an image and not an other type of file
    if (file && file.type.substring(0, 5) === "image") {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (reader.result) {
          if (
            await uploadDocument({
              uploadedFile: reader.result,
              fileName: file.name,
            })
          ) {
            const pictureUrl = URL.createObjectURL(file);
            dispatch(
              setQuestionPictureAction({
                pictureUrl,
                formName,
                visitId,
                formQuestionName: questionData.name,
                answerIndex,
              })
            );
          }
        }
      };
    }
  };

  const handleCompressedImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // https://github.com/fengyuanchen/compressorjs/blob/main/README.md
    const image = event.target.files?.[0];
    if (image) {
      new Compressor(image, {
        quality: image.size > 1000000 ? 0.1 : image.size > 500000 ? 0.5 : 0.8, //if image more than 1MB, we compress it to 90%, if image size more than 500kb we compress it to 50%, else, we comprese it to 80%
        success: (compressedResult) => {
          handleUploadDocument(compressedResult as File);
        },
        error(err) {
          // if error, we upload the non compressed image
          handleUploadDocument(image);
        },
      });
    }
  };

  return (
    <div className="uploadPicturesComponent">
      <div className="uploadPicturesComponent__Container">
        <div className="uploadPicturesComponent__ImageList">
          {uploadedPictures.map((url, index) => {
            return (
              <div
                className="uploadPicturesComponent__singleImageContainer"
                key={index}
                onClick={() => window.open(url)}
              >
                <div className="uploadPicturesComponent__validatedImage">
                  <img src={ValidatedBlue} alt="Picto validated blue" />
                </div>
                <img
                  src={url}
                  onError={(event) => {
                    event.currentTarget.onerror = null; // prevents looping
                    event.currentTarget.src =
                      "/images/produce-my-electricity.svg"; // Fallback image
                  }}
                  alt="preview"
                />
              </div>
            );
          })}
        </div>
        {uploadPictureInput &&
          (isLoading ? (
            <LoadingComponent diameter={90} />
          ) : (
            <div className="uploadPicturesComponent__FormInput">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  uploadPictureInput.current?.click();
                }}
                className="addImageButton"
              >
                <div className="buttonContent">
                  <img src={buttonImage} alt="" />
                  <p>Ajouter une photo</p>
                </div>
              </button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={uploadPictureInput}
                // we only accept images. when file explorer opens, only images will be proposed
                accept="image/*"
                onChange={(event) => {
                  handleCompressedImageUpload(event);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
