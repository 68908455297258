import React from "react";
import "./TooltipInfoForm.scss";

import light_bulb from "../../assets/images/pictos/light-bulb.svg";

interface IProps {
  textContent: JSX.Element;
}

export const TooltipInfoForm = ({ textContent }: IProps) => {
  return (
    <div className="TooltipInfoForm">
      <section className="TooltipInfoForm__ImageContainer">
        <img src={light_bulb} alt="" width={41} height={41} />
      </section>
      <section className="TooltipInfoForm__TextContainer">
        <article>{textContent}</article>
      </section>
    </div>
  );
};
