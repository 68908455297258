// Services import
import { useEffect } from "react";
import { useAppDispatch } from "../../../redux/store/hook";
import {
  updateFormBranchStateAction,
  setPickUniqueCardAction,
} from "../../../redux/appActions";

// Interfaces import
import {
  formNames,
  IPhotovoltaiqueFormMutableData,
} from "../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  formName: formNames;
  formsMutableData: IPhotovoltaiqueFormMutableData;
  visitId?: string;
}

export const useUpdateFormPVAccordingToUserAnswer = ({
  formName,
  formsMutableData,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();

  // Rule to update branch according to the answer of : presencedeFourreauxExterieurs
  const presencedeFourreauxExterieurs =
    formsMutableData.presencedeFourreauxExterieurs?.value;
  const trancheeNeeded = formsMutableData.trancheeaPrevoir?.value;
  const carrotageNeeded = formsMutableData.carrotageaPrevoir?.value;
  const bacsALesteraPrevoir = formsMutableData.bacsALesteraPrevoir?.value;
  const renovationCharpenteAPrevoir =
    formsMutableData.renforcementCharpenteaPrevoir?.value;
  useEffect(() => {
    if (presencedeFourreauxExterieurs === 1) {
      // See services/FormAnswers/valueToLabelDictionnary to have the label
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "presenceFourreauxExterieurs",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "presenceFourreauxExterieurs",
        })
      );
      if (presencedeFourreauxExterieurs !== undefined) {
        // If there is no trahcheeAPrevoir, we update the state of combiendeFourreaux to null
        dispatch(
          setPickUniqueCardAction({
            cardValue: null,
            formName: formName,
            visitId,
            formQuestionName: "combiendeFourreaux",
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencedeFourreauxExterieurs]);

  useEffect(() => {
    if (trancheeNeeded === 1) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "trahcheeAPrevoir",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "trahcheeAPrevoir",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trancheeNeeded]);

  useEffect(() => {
    if (carrotageNeeded === 1) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "carrotageAPrevoir",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "carrotageAPrevoir",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrotageNeeded]);

  useEffect(() => {
    if (bacsALesteraPrevoir === 1) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "bacsALesteraPrevoir",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "bacsALesteraPrevoir",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bacsALesteraPrevoir]);

  useEffect(() => {
    if (renovationCharpenteAPrevoir === 1) {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToAdd: "commentaireEtatCharpente",
        })
      );
    } else {
      dispatch(
        updateFormBranchStateAction({
          formName: formName,
          branchToRemove: "commentaireEtatCharpente",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renovationCharpenteAPrevoir]);
};
