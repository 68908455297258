// Interfaces import
import { ICRMWall } from "../../../interfaces/ITEFormInterface";

export const formatCRMDoorsBeforeSavingInRedux = (CRMWall: ICRMWall) => {
  return CRMWall.ouvrantItem
    .filter((ouvrant) => ouvrant.name.toLowerCase().includes("porte"))
    .map((door) => {
      return {
        murId: {
          value: String(CRMWall.murId),
        },
        ouvrantId: {
          value: String(door.ouvrantId),
        },
        ouvrantName: {
          value: String(door.name),
        },
        doorWidth: {
          value: String(door.largeur),
        },
        doorHeigh: {
          value: String(door.hauteur),
        },
      };
    });
};
