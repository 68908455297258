// Libraries import
import SVG from "react-inlinesvg";
import { useState } from "react";

// Components import
import { InputContainer } from "../FormStepsWithSharedComponents/InputContainer/InputContainer";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import { TooltipInfoForm } from "../../../components/TooltipInfoForm/TooltipInfoForm";
import Trash2 from "@web/shared/dist/assets/images/DesignSystemIcons/trash-2.svg";
import { CTACopyPaste } from "@web/shared/dist/components/DesignSystem/CTACopyPaste/CTACopyPaste";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { useAppDispatch } from "../../../redux/store/hook";
import {
  setITEOuvrantQuestionAction,
  deleteITEOuvrantAction,
  duplicateITEOuvrantAction,
} from "../../../redux/appActions";

// Interfaces import
import { IITEFormData } from "../../../interfaces/generalInterfaces";
import { KeyOfIITEOuvrant } from "../../../interfaces/ITEFormInterface";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  doorId: string | null;
  murId: string | null;
  formName?: "ITE";
  visitId?: string;
  doorIndex: number;
}

export const ITEDoor = ({
  ITEFormMutableData,
  murId,
  doorId,
  doorIndex,
  formName = "ITE",
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isDeleteModaleOpen, setIsDeleteModaleOpen] = useState<boolean>(false);

  const door = ITEFormMutableData?.doors?.find(
    (door) => door.murId?.value === murId && door.ouvrantId?.value === doorId
  );

  const onClickOnOuvrantQuestion = ({
    questionName,
    value,
    doorId,
    murId,
  }: {
    questionName: KeyOfIITEOuvrant;
    value: string;
    doorId: string | null;
    murId: string | null;
  }) => {
    dispatch(
      setITEOuvrantQuestionAction({
        value,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId ?? null,
        ouvrantId: doorId,
        murId,
        ouvrant: "doors",
      })
    );
  };

  const onClickOnDeleteOuvrant = ({
    murId,
    doorId,
  }: {
    murId: string | null;
    doorId: string | null;
  }) => {
    setIsDeleteModaleOpen(false);
    dispatch(
      deleteITEOuvrantAction({
        visitId: visitId ?? null,
        ouvrantId: doorId,
        murId,
        ouvrant: "doors",
      })
    );
  };

  const onClickDuplicateOuvrant = ({
    murId,
    doorId,
  }: {
    murId: string | null;
    doorId: string | null;
  }) => {
    dispatch(
      duplicateITEOuvrantAction({
        visitId: visitId ?? null,
        murId: murId,
        ouvrant: "doors",
        ouvrantId: doorId,
      })
    );
  };

  return (
    <>
      <Modale
        isModaleOpen={isDeleteModaleOpen}
        title={`Êtes-vous sûr de vouloir supprimer la porte ${doorIndex + 1} ?`}
      >
        <div className="Modale_Buttons">
          <CTAButton
            name="Non"
            family="vitee"
            category="secondary"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          />
          <CTAButton
            name="Oui"
            family="vitee"
            onClick={() => onClickOnDeleteOuvrant({ murId, doorId })}
          />
        </div>
      </Modale>
      <div className="formPagesITE__WindowContainer">
        <div className="formPagesITE__SubTitle">
          <div className="formPagesITE__DuplicateContainer">
            <H2 title={`Porte n°${doorIndex + 1}`} />
            <CTACopyPaste
              onClick={() => onClickDuplicateOuvrant({ murId, doorId })}
              name="Dupliquer"
            />
          </div>
          <div
            className="formPagesITE__Trash"
            onClick={() => setIsDeleteModaleOpen((state) => !state)}
          >
            <SVG src={Trash2} />
          </div>
        </div>

        <div className="formPagesITE__Question">
          <InputContainer
            title={`Hauteur porte ${doorIndex + 1} :`}
            questionName="doorHeigh"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            iconType="meters"
            placeholder="XXXX"
            type="number"
            userAnswer={door?.doorHeigh}
            onChange={(value) => {
              onClickOnOuvrantQuestion({
                questionName: "doorHeigh",
                doorId,
                murId,
                value: value,
              });
            }}
          />
        </div>
        <div className="formPagesITE__Question">
          <InputContainer
            title={`Largeur porte ${doorIndex + 1} :`}
            questionName="doorWidth"
            formName={formName}
            visitId={visitId}
            isPictureNeeded={false}
            iconType="meters"
            placeholder="XXXX"
            type="number"
            userAnswer={door?.doorWidth}
            onChange={(value) => {
              onClickOnOuvrantQuestion({
                questionName: "doorWidth",
                doorId,
                murId,
                value: value,
              });
            }}
          />
        </div>
        <div className="formPagesITE__ToolTip">
          <TooltipInfoForm
            textContent={
              <>
                {`N’oubliez pas d’indiquer sur les photos, quelle
        porte correspond à la “Porte n°${doorIndex + 1}"`}
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
