// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Service import
import { useSetVisiteTechniqueInfo } from "./useSetVisitesTechniquesInfo";

// Interfaces import
import { IVisiteTechniqueInfo } from "../../interfaces/generalInterfaces";

// Interface delcaration
interface IFunctionReturn {
  requestVisiteTechniqueInfo: (
    userToken: string,
    visiteTechniqueId: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useRequestVisiteTechniqueInfo : return tools to get the visite technique information
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestVisiteTechniqueInfo
//     Function to call to send get visite technique info request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useRequestVisiteTechniqueInfo = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setVisiteTechniqueInfo } = useSetVisiteTechniqueInfo();

  // requestVisiteTechniqueInfo : send Get visite technique info request. If successfull-> save visite technique information in local storage and in redux store
  // Parameters
  // ----------
  // userToken: String
  //      the JWT authentication token of the user
  // Returns
  // ----------
  // Void

  const requestVisiteTechniqueInfo = async (
    userToken: string,
    visiteTechniqueId: string
  ): Promise<void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<IVisiteTechniqueInfo>(
      `${BACKEND_URL}/api/VT/${visiteTechniqueId}/VisiteTechniqueInfo`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response) {
      setVisiteTechniqueInfo(response);
    } else {
      setErrorMessage("Erreur lors du chargement des données de la visite");
    }
  };

  return {
    requestVisiteTechniqueInfo,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
