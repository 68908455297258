import "./formPagesITE.scss";

// Components import
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";

// Interfaces import
import { IITEFormData } from "../../../../interfaces/generalInterfaces";
import { SingleChoiceBtnContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/singleChoiceBtnContainer/SingleChoiceBtnContainer";
import React from "react";
import { BooleanChoiceBtnContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/BooleanChoiceBtnContainer/BooleanChoiceBtnContainer";
import { TextAreaContainer } from "../../../../containers/Forms/FormStepsWithSharedComponents/TextAreaContainer/TextAreaContainer";
import { useAppDispatch } from "../../../../redux/store/hook";
import { setFormCommonInfoAction } from "../../../../redux/appActions";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  visitId?: string;
}
export const LogistiquePage = ({ ITEFormMutableData, visitId }: Props) => {
  const dispatch = useAppDispatch();
  const formName = "ITE";
  return (
    <div className="formPagesITE">
      {/*title*/}
      <div className="formPagesITE__Title">
        <H2 title={"Logistique"} />
      </div>
      <div className={"ITE_questionContainer__LogistiqueContainer"}>
        {/*accessibilityMax*/}
        <SingleChoiceBtnContainer
          title="Accessibilité maximum pour"
          formName={formName}
          userAnswer={ITEFormMutableData?.accessibilityMax}
          questionName="accessibilityMax"
          visitId={visitId}
          isPictureNeeded={true}
          onUploadPicture={(pictureUrl) =>
            dispatch(
              setFormCommonInfoAction({
                formName: formName,
                visitId: visitId ?? null,
                pictureUrl,
                formQuestionName: "accessibilityMax",
              })
            )
          }
          optionList={[
            {
              label: "Voiture",
              value: "629950000",
            },
            {
              label: "Utilitaire",
              value: "629950001",
            },
            {
              label: "Semi-remorque",
              value: "629950002",
            },
          ]}
          areAnswersAlign={false}
          onCardClick={(cardValue) => {
            dispatch(
              setFormCommonInfoAction({
                value: cardValue,
                visitId: visitId ?? null,
                formQuestionName: "accessibilityMax",
                formName: formName,
              })
            );
          }}
        />

        {/*planDisponible*/}
        <BooleanChoiceBtnContainer
          title={
            <>Les&nbsp;plans&nbsp;sont&#8209;ils&nbsp;disponibles&nbsp;?</>
          }
          formName={formName}
          visitId={visitId}
          questionName={"planDisponible"}
          isPictureNeeded={true}
          areAnswersAlign={true}
          userAnswer={ITEFormMutableData?.planDisponible}
          onUploadPicture={(pictureUrl) =>
            dispatch(
              setFormCommonInfoAction({
                formName: formName,
                visitId: visitId ?? null,
                pictureUrl,
                formQuestionName: "planDisponible",
              })
            )
          }
          onCardClick={(value) => {
            dispatch(
              setFormCommonInfoAction({
                value: value === true ? "1" : value === false ? "0" : null,
                visitId: visitId ?? null,
                formQuestionName: "planDisponible",
                formName: formName,
              })
            );
          }}
        />

        {/*dimensionnementinitialcorrect*/}
        <BooleanChoiceBtnContainer
          title={"Y a-t-il un espace de stockage suffisant chez le client ?"}
          formName={formName}
          visitId={visitId}
          questionName={"dimensionnementinitialcorrect"}
          isPictureNeeded={false}
          areAnswersAlign={false}
          userAnswer={ITEFormMutableData?.dimensionnementinitialcorrect}
          onCardClick={(value) => {
            dispatch(
              setFormCommonInfoAction({
                value: value.toString(),
                visitId: visitId ?? null,
                formQuestionName: "dimensionnementinitialcorrect",
                formName: formName,
              })
            );
          }}
        />
        {/*commentairePartieLogistique*/}
        <TextAreaContainer
          title="Commentaire sur la partie logistique :"
          questionName="commentairePartieLogistique"
          formName={formName}
          visitId={visitId}
          areAnswersAlign={false}
          userAnswer={ITEFormMutableData?.commentairePartieLogistique}
          isPictureNeeded={false}
          placeholder={"Ton commentaire ici"}
          onChange={(value) => {
            dispatch(
              setFormCommonInfoAction({
                value: value.toString(),
                visitId: visitId ?? null,
                formQuestionName: "commentairePartieLogistique",
                formName: formName,
              })
            );
          }}
        />
      </div>
    </div>
  );
};
