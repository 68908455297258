import "./LocalStorageViewer.scss";
import { useState } from "react";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { IFormCommonInfo } from "../../interfaces/generalInterfaces";
import { Modale } from "@web/shared/dist/components/DesignSystem/Modales/Modale/Modale";

// Services import
import { useAppSelector } from "../../redux/store/hook";
import { useAxiosPost } from "../../services/useAxiosPost";

export const LocalStorageViewer = () => {
  const { eeroToken } = useAppSelector((state) => state.userAuthInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visitTechniqueId, setVisitTechniqueId] = useState<string | null>(null);
  const { axiosPostRequest, isLoading } = useAxiosPost();

  const localStorageData = ((): Record<string, string> => {
    const localStorageData: Record<string, string> = {};

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && (key.match(/-/g) || []).length > 3) {
        // Allow to filter only the uuid of the visite technique ids
        const value = localStorage.getItem(key);
        if (value) {
          localStorageData[key] = value;
        }
      }
    }

    return localStorageData;
  })();

  const clearLocalStorage = (item?: string | null) => {
    if (item) {
      localStorage.removeItem(item);
      closeModal();
      return;
    }
    localStorage.clear();
    closeModal();
    return;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVisitTechniqueId(null);
  };

  const sendLogToBackend = async (data: string) => {
    await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/vitee/log`,
      { data },
      { headers: { Authorization: `Bearer ${eeroToken}` } }
    );
  };

  return (
    <div className="LocalStorageViewer">
      <Modale isModaleOpen={isModalOpen} title="Confirmer">
        <div>
          <div className="LocalStorageViewer__ModalButtons">
            <CTAButton
              name="Annuler"
              category="secondary"
              onClick={closeModal}
            />{" "}
            <CTAButton
              name="Supprimer"
              category="primary"
              onClick={() => clearLocalStorage(visitTechniqueId)}
            />
          </div>
        </div>
      </Modale>
      <div className="LocalStorageViewer__Header">
        <h1>Local Storage Data</h1>
        <CTAButton
          name="Supprimer toutes les données"
          size="M"
          onClick={openModal}
          category="tertiary"
        />
      </div>
      <ul className="LocalStorageViewer__List">
        {Object.entries(localStorageData).map(([key, value]) => {
          const VTLocalStorageObject: IFormCommonInfo = JSON.parse(value);

          return (
            <div className="LocalStorageViewer__VTDetail">
              <div className="LocalStorageViewer__VTHeader">
                <h2>{key + " :"}</h2>
                <div className="LocalStorageViewer__VTHeaderButtons">
                  <CTAButton
                    name="Envoyer les données"
                    size="M"
                    onClick={() => {
                      sendLogToBackend(JSON.stringify(VTLocalStorageObject));
                    }}
                    category="secondary"
                    isLoading={isLoading}
                  />
                  <CTAButton
                    name="Supprimer données VT"
                    size="M"
                    onClick={() => {
                      setVisitTechniqueId(key);
                      openModal();
                    }}
                    category="tertiary"
                  />
                </div>
              </div>
              <div className="LocalStorageViewer__VTData">
                {JSON.stringify(VTLocalStorageObject)}
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
