import "./ConfirmationSendRapportComponent.scss";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { SecondaryButton } from "../../../components/SecondaryButton/SecondaryButton";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Local interface declaration
interface Props {
  isLoading: boolean;
  isSaveVTSuccessfull: boolean;
  errorMessage: string;
  onClickYes: () => void;
  onClickNo: () => void;
  sentence: JSX.Element;
}

export const ConfirmationSendRapportComponent = ({
  isLoading,
  isSaveVTSuccessfull,
  errorMessage,
  onClickYes,
  onClickNo,
  sentence,
}: Props) => {
  return (
    <div className="ConfirmationSendRapportComponent">
      <div className="ConfirmationSendRapportComponent__TextBlock">
        {sentence}
      </div>
      {errorMessage && (
        <div className="ConfirmationSendRapportComponent__ErrorMessage">
          {errorMessage}
        </div>
      )}

      {isLoading ? (
        <div className="ConfirmationSendRapportComponent__IsLoading">
          <LoadingComponent diameter={80} />
        </div>
      ) : isSaveVTSuccessfull ? (
        <div className="ConfirmationSendRapportComponent__SuccessMessage">
          <p>Données sauvegardés dans le CRM !</p>
        </div>
      ) : (
        <div className="ConfirmationSendRapportComponent__Buttons">
          <>
            <SecondaryButton name="Non" onClick={onClickNo} />
            <MainButton
              name="Oui"
              onClick={onClickYes}
              isLoading={isLoading}
              size="big"
            />
          </>
        </div>
      )}
    </div>
  );
};
