// data import
import { photovoltaiqueData } from "../../data/forms/Photovoltaique";

import { CETFormData } from "../../data/forms/CETFormData";

import {
  IPhotovoltaiqueFormMutableData,
  ICETFormMutableData,
  IFormQuestions,
  IFormCommonInfoKeys,
} from "../../interfaces/generalInterfaces";

import { PanInformationAllSubQuestionsKeys } from "../../interfaces/photovoltaiqueFormInterfaces";

// Services import
import { determineFormNameFromProductType } from "./determineFormNameFromProductType";

const getRequiredQuestionsFromData = (typeDeProduit?: number | null) => {
  const formName = determineFormNameFromProductType(typeDeProduit);

  let requiredQuestions: IFormQuestions[] = [];
  // depending on the formName, we get the data from all the questions and only keep the required questions name
  if (formName === "photovoltaique") {
    photovoltaiqueData.formScreens.visiteTechniqueFormPages.pages.forEach(
      (page) => {
        page.forEach((questionCategory) => {
          questionCategory.questions.forEach((question) => {
            if (question.subQuestions) {
              question.subQuestions.forEach((subQuestion) => {
                subQuestion.questions.forEach((question) => {
                  question.isAnswerRequired && requiredQuestions.push(question);
                });
              });
            } else {
              question.isAnswerRequired && requiredQuestions.push(question);
            }
          });
        });
      }
    );
  }

  if (formName === "CET") {
    CETFormData.formScreens.visiteTechniqueFormPages.pages.forEach((page) => {
      page.forEach((questionCategory) => {
        questionCategory.questions.forEach((question) => {
          if (question.subQuestions) {
            question.subQuestions.forEach((subQuestion) => {
              subQuestion.questions.forEach((question) => {
                question.isAnswerRequired && requiredQuestions.push(question);
              });
            });
          } else {
            question.isAnswerRequired && requiredQuestions.push(question);
          }
        });
      });
    });
  }

  // We add the commentaire fin de VT as a require question (that is appart from the form data files)
  requiredQuestions.push({
    type: "InputText",
    name: "commentairesRapportVT",
    branchs: ["main"],
    title: "Commentaire fin de VT",
  });

  return requiredQuestions;
};

const checkIfRequiredQuestionsHaveANonNullishValue = (
  formsMutableData: ICETFormMutableData | IPhotovoltaiqueFormMutableData,
  requiredQuestions: IFormQuestions[]
) => {
  let missingRequiredQuestions: string[] = [];
  for (const question of requiredQuestions) {
    if (
      !formsMutableData[question.name as IFormCommonInfoKeys]?.value &&
      formsMutableData[question.name as IFormCommonInfoKeys]?.value !== 0
    ) {
      if (
        (formsMutableData as IPhotovoltaiqueFormMutableData).panInformations
      ) {
        (
          formsMutableData as IPhotovoltaiqueFormMutableData
        ).panInformations?.forEach((pan, index) => {
          // Handle case where user hase selected 2 pans and finally select one -> we check required questions only for the selected pan(s)
          if (index >= (formsMutableData.nombredePantoEquip || 1)) {
            return;
          }
          if (
            !pan[question.name as PanInformationAllSubQuestionsKeys]?.value &&
            pan[question.name as PanInformationAllSubQuestionsKeys]?.value !== 0
          ) {
            // Case where type de fixation = au sol -> we don't need the mesures neither the inclinaison
            if (
              (formsMutableData as IPhotovoltaiqueFormMutableData)
                .panInformations?.[index]?.typedeSupport?.value === 629950000 // Au sol
            ) {
              if (question.name === "hauteurdelaToitureGoutiere") {
                return;
              }
              if (question.name === "hauteurdelaToitureFaitage") {
                return;
              }
              if (question.name === "distanceduPignon") {
                return;
              }
              if (question.name === "longueurdelaFacade") {
                return;
              }
              if (question.name === "inclinaison") {
                return;
              }
            }

            // Case "Etat de la charpente" : mandatory only if type de support = "Surimposition" ou "Intégration au batit" and combles are "accessibles depuis l'intérieur"
            if (question.name === "etatdelaCharpente") {
              if (
                (formsMutableData as IPhotovoltaiqueFormMutableData)
                  .panInformations?.[index]?.typedeSupport?.value ===
                  629950001 ||
                (formsMutableData as IPhotovoltaiqueFormMutableData)
                  .panInformations?.[index]?.typedeSupport?.value === 629950002
              ) {
                if (
                  (formsMutableData as IPhotovoltaiqueFormMutableData)
                    .panInformations?.[index]?.accessibiliteDesComnbles
                    ?.value === 1
                ) {
                  missingRequiredQuestions.push(
                    question.title.replace(/:/g, ``)
                  );
                }
              }
              return;
            }

            // Case elagageaPrevoir" : mandatory only if "nature du masque" include "Arbres"
            if (question.name === "elagageaPrevoir") {
              if (
                (
                  formsMutableData as IPhotovoltaiqueFormMutableData
                ).panInformations?.[index]?.masqueSolaires?.value?.includes(
                  629950000
                ) // Arbres
              ) {
                missingRequiredQuestions.push(question.title.replace(/:/g, ``));
              }
              return;
            }

            missingRequiredQuestions.push(question.title.replace(/:/g, ``));
          }
        });
      } else {
        missingRequiredQuestions.push(question.title.replace(/:/g, ``));
      }
    }
  }

  return missingRequiredQuestions;
};

export const checkRequiredQuestions = (
  formsMutableData: ICETFormMutableData | IPhotovoltaiqueFormMutableData,
  typeDeProduit?: number | null
) => {
  return checkIfRequiredQuestionsHaveANonNullishValue(
    formsMutableData,
    getRequiredQuestionsFromData(typeDeProduit)
  );
};
