// import React from "react";
import React, { MouseEventHandler, useState, forwardRef } from "react";
import ReactLoading from "react-loading";
// Styles import
import styled from "styled-components";
import { stylesVariables } from "../../../../styles/variables";
import SVG from "react-inlinesvg";

export interface CTAButtonProps {
  name?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  id?: string;
  category?: "primary" | "secondary" | "tertiary";
  family?: "vitee" | "becqe" | "danger";
  iconSrc?: string;
  style?: React.CSSProperties;
  isbuttonhoovered?: boolean;
  size?: "M" | "L";
  isLoading?: boolean;
  type?: "button" | "submit";
  form?: string;
  isGap?: boolean;
}

export interface IconWrapperProps {
  iconsrc?: number;
  isdisabled?: number;
  category?: "primary" | "secondary" | "tertiary";
  family?: "vitee" | "becqe" | "danger";
  isbuttonhovered?: number;
  size?: "M" | "L";
}

const Button = styled.button<CTAButtonProps>`
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  background-color: ${(props) =>
    props.isDisabled && props.category === "primary"
      ? "#E9EAEB"
      : props.isDisabled && props.category === "secondary"
      ? "#E9EAEB"
      : props.isDisabled && props.category === "tertiary"
      ? "#E9EAEB"
      : props.family === "vitee" && props.category === "primary"
      ? stylesVariables.AssuranceBlue
      : props.family === "vitee" && props.category === "secondary"
      ? "#FFFFFF"
      : props.family === "vitee" && props.category === "tertiary"
      ? "#DBE9F8"
      : props.family === "becqe" && props.category === "primary"
      ? stylesVariables.OffBlack
      : props.family === "becqe" && props.category === "secondary"
      ? "#FFFFFF"
      : props.family === "becqe" && props.category === "tertiary"
      ? stylesVariables.StableGrayLight04
      : props.family === "danger" && props.category === "primary"
      ? "#E02819"
      : props.family === "danger" && props.category === "secondary"
      ? "#FFFFFF"
      : props.family === "danger" && props.category === "tertiary"
      ? "#F4B3AE"
      : stylesVariables.AssuranceBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  height: ${(props) => (props.size && props.size === "M" ? "37px" : "48px")};
  padding: ${(props) =>
    props.size && props.size === "M" ? "8px 16px" : "13.5px 24px"};
  cursor: pointer;
  position: relative;
  border: 1px solid
    ${(props) =>
      props.isDisabled && props.category === "primary"
        ? "#E9EAEB"
        : props.isDisabled && props.category === "secondary"
        ? "#A8AAAF"
        : props.isDisabled && props.category === "tertiary"
        ? "#E9EAEB"
        : props.family === "vitee" && props.category === "primary"
        ? stylesVariables.AssuranceBlue
        : props.family === "vitee" && props.category === "secondary"
        ? stylesVariables.AssuranceBlue
        : props.family === "vitee" && props.category === "tertiary"
        ? "#DBE9F8"
        : props.family === "becqe" && props.category === "primary"
        ? stylesVariables.OffBlack
        : props.family === "becqe" && props.category === "secondary"
        ? stylesVariables.OffBlack
        : props.family === "becqe" && props.category === "tertiary"
        ? stylesVariables.StableGrayLight04
        : props.family === "danger" && props.category === "primary"
        ? "#E02819"
        : props.family === "danger" && props.category === "secondary"
        ? "#E02819"
        : props.family === "danger" && props.category === "tertiary"
        ? "#F4B3AE"
        : stylesVariables.AssuranceBlue};
  gap: ${(props) => (props.isGap ? "10px" : "0px")};
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) =>
    props.isDisabled
      ? "#A8AAAF"
      : props.family === "vitee" && props.category === "primary"
      ? "#FFFFFF"
      : props.family === "vitee" &&
        props.category === "secondary" &&
        !props.isbuttonhoovered
      ? stylesVariables.AssuranceBlue
      : props.family === "vitee" &&
        props.category === "secondary" &&
        props.isbuttonhoovered
      ? "#085FB0"
      : props.family === "vitee" && props.category === "tertiary"
      ? stylesVariables.AssuranceBlue
      : props.family === "becqe" && props.category === "primary"
      ? "#FFFFFF"
      : props.family === "becqe" && props.category === "secondary"
      ? stylesVariables.OffBlack
      : props.family === "becqe" && props.category === "tertiary"
      ? stylesVariables.OffBlack
      : props.family === "danger" && props.category === "primary"
      ? "#FFFFFF"
      : props.family === "danger" &&
        props.category === "secondary" &&
        props.isbuttonhoovered
      ? "#B0261B"
      : props.family === "danger" && props.category === "secondary"
      ? "#E02819"
      : props.family === "danger" && props.category === "tertiary"
      ? "#E02819"
      : stylesVariables.AssuranceBlue};
  > p {
    white-space: nowrap;
    letter-spacing: 0rem;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled
        ? "#E9EAEB"
        : props.family === "vitee" && props.category === "primary"
        ? "#085FB0"
        : props.family === "vitee" && props.category === "secondary"
        ? "#FFFFFF"
        : props.family === "vitee" && props.category === "tertiary"
        ? "#B7D3F0"
        : props.family === "becqe" && props.category === "primary"
        ? "#50545F"
        : props.family === "becqe" && props.category === "secondary"
        ? "#E9EAEB"
        : props.family === "becqe" && props.category === "tertiary"
        ? "#A8AAAF"
        : props.family === "danger" && props.category === "primary"
        ? "#B0261B"
        : props.family === "danger" && props.category === "secondary"
        ? "#FFFFFF"
        : props.family === "danger" && props.category === "tertiary"
        ? "#EE8981"
        : stylesVariables.AssuranceBlue};
    border: 1px solid
      ${(props) =>
        props.disabled && props.category === "secondary"
          ? "#A8AAAF"
          : props.disabled && props.category === "primary"
          ? "#E9EAEB"
          : props.disabled && props.category === "tertiary"
          ? "#E9EAEB"
          : props.family === "vitee" && props.category === "primary"
          ? "#085FB0"
          : props.family === "vitee" && props.category === "secondary"
          ? "#085FB0"
          : props.family === "vitee" && props.category === "tertiary"
          ? "#B7D3F0"
          : props.family === "becqe" && props.category === "primary"
          ? "#50545F"
          : props.family === "becqe" && props.category === "secondary"
          ? "#191B1E"
          : props.family === "becqe" && props.category === "tertiary"
          ? "#A8AAAF"
          : props.family === "danger" && props.category === "primary"
          ? "#B0261B"
          : props.family === "danger" && props.category === "secondary"
          ? "#B0261B"
          : props.family === "danger" && props.category === "tertiary"
          ? "#EE8981"
          : stylesVariables.AssuranceBlue};
  }
`;

const IconWrapper = styled(SVG)<IconWrapperProps>`
  -webkit-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  display: ${(props) => (props.iconsrc === 0 ? "none" : "")};
  height: ${(props) => (props.size && props.size === "M" ? "20px" : "unset")};
  width: ${(props) => (props.size && props.size === "M" ? "20px" : "unset")};
  path {
    -webkit-transition: all 0.6s ease-out;
    -moz-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    fill: ${(props) =>
      props.isdisabled === 1
        ? `#A8AAAF`
        : props.category === "secondary" &&
          props.family === "vitee" &&
          props.isbuttonhovered === 1
        ? "#085FB0"
        : props.category === "secondary" &&
          props.family === "danger" &&
          props.isbuttonhovered === 1
        ? "#B0261B"
        : props.category !== "primary" && props.family === "vitee"
        ? "#0073E0"
        : props.category !== "primary" && props.family === "becqe"
        ? "#191B1E"
        : props.category !== "primary" && props.family === "danger"
        ? "#E02819"
        : "#FFFFFF"};
  }
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 - No mandatory props
 - Default props are listed below
 **/
export const CTAButton = forwardRef<HTMLButtonElement, CTAButtonProps>(
  (props, ref) => {
    const {
      name = "Primary button",
      onClick,
      isDisabled = false,
      id,
      category = "primary",
      family = "vitee",
      iconSrc = "",
      size,
      isLoading = false,
      type = "button",
      form,
      isGap = true,
    } = props;

    const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);

    return (
      <Button
        onClick={onClick}
        disabled={isDisabled || isLoading}
        isDisabled={isDisabled}
        id={id}
        category={category}
        family={family}
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        isbuttonhoovered={isButtonHovered}
        size={size}
        type={type}
        ref={ref}
        form={form}
        isGap={isGap}
      >
        <IconWrapper
          isdisabled={isDisabled ? 1 : 0}
          category={category}
          family={family}
          iconsrc={!!iconSrc ? 1 : 0}
          isbuttonhovered={isButtonHovered ? 1 : 0}
          src={iconSrc}
          size={size}
        ></IconWrapper>
        {isLoading && (
          <Loader>
            <ReactLoading
              type={"spin"}
              color={"#e4e3e3"}
              height={25}
              width={25}
            />
          </Loader>
        )}
        <p style={isLoading ? { color: "transparent" } : undefined}>{name}</p>
      </Button>
    );
  }
);
