import "./inputNumber.scss";

// Components import
import { NumberInputComponentIpad } from "@web/shared/dist/components/Old/FormComponents/NumberInputComponentIpad/NumberInputComponentIpad";
import { UploadPicturesComponent } from "../UploadPicturesComponent/UploadPicturesComponent";

// Service import
import { useAppDispatch } from "../../../../redux/store/hook";
import { setInputNumberAction } from "../../../../redux/appActions";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  title: string;
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName?: IAllFormQuestionsKeys;
  placeholder?: string;
  isPictureNeeded?: boolean;

  answerIndex?: number;
}

export const InputNumber = ({
  title,
  userAnswer,
  questionName,
  formName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  placeholder,
}: Props) => {
  const dispatch = useAppDispatch();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseFloat(event.target.value);
    // If the input of the user is a number we store it as a number
    questionName &&
      dispatch(
        setInputNumberAction({
          inputNumber: inputValue,
          formName,
          formQuestionName: questionName,
          visitId,
          answerIndex,
        })
      );
  };

  return (
    <div className="inputNumber">
      <div className="inputNumber__Title">
        <p>{title}</p>
      </div>
      <div className={`inputNumber__InputComponent`}>
        <NumberInputComponentIpad
          onChange={onChange}
          value={typeof userAnswer?.value === "number" ? userAnswer.value : ""} // We ensure the typed input is not an array
          name={questionName}
          id={questionName}
          placeholder={placeholder}
        />
      </div>
      {isPictureNeeded && (
        <div className={`inputNumber__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionName={questionName}
            uploadedPictures={userAnswer?.pictures}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
