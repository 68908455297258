import "./dropDownBtnContainer.scss";

// Components import
// import { PickCard } from "@web/shared/dist/components/FormComponents/PickCard/PickCard";
import { UploadPicturesBtn } from "../UploadPicturesBtn/UploadPicturesBtn";

import { Dropdown } from "@web/shared/dist/components/DesignSystem/Dropdowns/Dropdown/Dropdown";

// Service import
import { useAppDispatch } from "../../../../redux/store/hook";

// Interfaces import
import {
  IAllFormQuestionsKeys,
  formNames,
} from "../../../../interfaces/generalInterfaces";
import { setFormDropDownAction } from "../../../../redux/appActions";
import { IQuestionRedux } from "../../../../interfaces/ITEFormInterface";

// Local interface declaration
interface IOption {
  label: string | null;
  value: string | null;
}

interface Props {
  title: string;
  placeholder?: string;
  optionList: IOption[];
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: string | number | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: IAllFormQuestionsKeys;
  isPictureNeeded?: boolean;
  answerIndex?: number;
  areAnswersAlign?: boolean;
  onUploadPicture?: (pictureUrl: string) => void;
  wallIndex?: number;
  pointSingulierName?: IQuestionRedux;
}

export const DropDownBtnContainer = ({
  title,
  formName,
  optionList,
  placeholder,
  // selectedValue,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
  areAnswersAlign = true,
  onUploadPicture,
  userAnswer,
  wallIndex,
  pointSingulierName,
}: Props) => {
  const dispatch = useAppDispatch();

  const onOptionClick = (option: number) => {
    dispatch(
      setFormDropDownAction({
        value: option,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId,
        answerIndex,
      })
    );
  };

  return (
    <div className="DropDownBtnContainer">
      <section
        className={`DropDownBtnContainer__question-answer_container ${
          !areAnswersAlign ? "answerUnderTitle" : ""
        }`}
      >
        <p className={"DropDownBtnContainer__QuestionTitle"}>{title}</p>

        <div className={"DropDownBtnContainer__DropdownWrapper"}>
          <Dropdown
            placeholder={placeholder}
            options={optionList}
            optionsSelected={optionList.filter(
              (item) => Number(item.value) === Number(userAnswer?.value)
            )}
            onSelect={(option: IOption) => {
              onOptionClick(parseInt(option.value || ""));
            }}
            tags={false}
          />
        </div>
      </section>

      {isPictureNeeded && (
        <UploadPicturesBtn
          questionName={questionName}
          uploadedPictures={userAnswer?.pictures}
          formName={formName}
          visitId={visitId}
          answerIndex={answerIndex}
          onUpload={onUploadPicture}
          wallIndex={wallIndex}
          pointSingulierName={pointSingulierName}
        />
      )}
    </div>
  );
};
