// Interfaces import

interface IPacAirEauFormData {
  formName: "PacAirEau";
  formScreensName: ["visiteTechniqueFormPages", "extraChargesFormPages"];
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "1. Installation existante",
        "2. Installation cible",
        "3. Dimensionnement",
        "4. Partie électrique",
        "5. Logistique"
      ];
      pages: [
        "InstallationExistantePage",
        "InstallationCiblePage",
        "Dimensionnement",
        "PartieElectriquePage",
        "LogistiquePage"
      ];
    };
    extraChargesFormPages: {
      steps: ["5. Spécificités du chantier"];
      pages: ["ExtraChargesPage"];
    };
  };
}

export const PacAirEauFormData: IPacAirEauFormData = {
  formName: "PacAirEau",
  formScreensName: ["visiteTechniqueFormPages", "extraChargesFormPages"],
  formScreens: {
    visiteTechniqueFormPages: {
      steps: [
        "1. Installation existante",
        "2. Installation cible",
        "3. Dimensionnement",
        "4. Partie électrique",
        "5. Logistique",
      ],
      pages: [
        "InstallationExistantePage",
        "InstallationCiblePage",
        "Dimensionnement",
        "PartieElectriquePage",
        "LogistiquePage",
      ],
    },
    extraChargesFormPages: {
      steps: ["5. Spécificités du chantier"],
      pages: ["ExtraChargesPage"],
    },
  },
};
