// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Other import
import { emailRegex } from "../../constants/validationPaterns";

// Interfaces import
import { IResetPasswordSendEmailResponse } from "../../interfaces/generalInterfaces";

// Interfaces delcaration
interface IFunctionReturn {
  resetPasswordSendEmail: (email: string) => Promise<boolean>;
  isLoading: boolean;
  errorMessage: string;
}

// useResetPasswordSendEmail : return tools to send email to reset password
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function resetPasswordSendEmail
//     Function to call to send email request to reset password
// Boolean isLoading
//     Indicate if the asynchronous request is on going
// String errorMessage
//     Error message (no parameters or request error message)

export const useResetPasswordSendEmail = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // resetPasswordSendEmail : send Post request with email as payload
  // Parameters
  // ----------
  // Email: String
  //      the email of the user
  // Returns
  // ----------
  // Boolean -> indicate if the email is valid

  const resetPasswordSendEmail = async (email: string): Promise<boolean> => {
    setErrorMessage("");
    if (email) {
      if (email.match(emailRegex)) {
        const bodyRequest = JSON.stringify(email);
        const response =
          await axiosPostRequest<IResetPasswordSendEmailResponse>(
            `${BACKEND_URL}/api/User/SendPasswordResetEmail`,
            bodyRequest,
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );
        if (response && response.data.code === 0) {
          return true;
        } else if (response && response?.data?.error) {
          // Retrieve error message form error reponse
          const textError = response?.data?.error;
          const textErrorClean = textError.substring(
            textError.indexOf("[[") + 3,
            textError.lastIndexOf("]]") - 1
          );
          setErrorMessage(textErrorClean);
        }
      } else {
        setErrorMessage("Format de l'email invalide.");
      }
    } else {
      setErrorMessage("Veuillez renseigner votre email.");
    }
    return false;
  };

  return {
    resetPasswordSendEmail,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
