import "./formPages.scss";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { InputText } from "../../../../containers/Forms/FormStepsNew/InputText/InputText";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const LogistiquePage = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const formName = "PacAirEau";

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Logistique</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Accessibilité maximum pour :"
        cards={[629950000, 629950001, 629950002]}
        formName={formName}
        questionName="accessibilityMax"
        userAnswer={PacAirEauFormMutableData?.accessibilityMax}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <PickUniqueCard
        title="Les plans sont-ils disponibles ?"
        cards={[0, 1]}
        formName={formName}
        questionName="planDisponible"
        userAnswer={PacAirEauFormMutableData?.planDisponible}
        visitId={visitId}
        isPictureNeeded={true}
      />
      <InputText
        title="Commentaires partie logistique :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentairePartieLogistique}
        questionName="commentairePartieLogistique"
        visitId={visitId}
      />
    </div>
  );
};
