import React from "react";
import "./subHeaderProductInfo.scss";

// Libraries import
import ReactLoading from "react-loading";

// Images import
import ARPicto from "../../assets/images/pictos/augmented-reality.png";

// components import
import { Layout } from "../Layout/Layout";

// Interfaces import
import { IVisiteTechniqueInfo } from "../../interfaces/generalInterfaces";

interface IProps {
  visiteTechniqueInfo: IVisiteTechniqueInfo;
  areGetDataFromCRMLoading: boolean;
  getDataErrorMessage: string;
  isRanaglypheClicked?: boolean;
  setIsRanaglypheClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SubHeaderProductInformation = ({
  visiteTechniqueInfo,
  areGetDataFromCRMLoading,
  getDataErrorMessage,
  setIsRanaglypheClicked,
  isRanaglypheClicked,
}: IProps) => {
  const onClickARButton = () => {
    setIsRanaglypheClicked && setIsRanaglypheClicked(!isRanaglypheClicked);
  };

  return (
    <Layout>
      <div className="SubHeaderProductInformation__HeaderContainer">
        <div className="SubHeaderProductInformation__HeaderLeft">
          {!visiteTechniqueInfo.products ||
          visiteTechniqueInfo.products.length === 0 ? (
            <h4 className="SubHeaderProductInformation__HeaderLeft__ProductName">
              Nom du produit non renseigné
            </h4>
          ) : typeof visiteTechniqueInfo.products === "object" ? (
            visiteTechniqueInfo.products?.map((product, index) => {
              return (
                <h4
                  className="SubHeaderProductInformation__HeaderLeft__ProductName"
                  key={index}
                >
                  {product}
                </h4>
              );
            })
          ) : (
            //if string
            <h4 className="SubHeaderProductInformation__HeaderLeft__ProductName">
              {visiteTechniqueInfo.products}
            </h4>
          )}
        </div>
        {/*todo: confirm delete with product*/}
        <div className="SubHeaderProductInformation__HeaderRight">
          {areGetDataFromCRMLoading ? (
            <>
              <p>Données CRM en cours de chargement</p>
              <ReactLoading
                type={"spin"}
                color={"#7C7F87"}
                height={25}
                width={25}
              />
            </>
          ) : getDataErrorMessage ? (
            getDataErrorMessage
          ) : (
            <>
              Données du CRM chargées !
              {visiteTechniqueInfo.typeDeProduit === 629950005 && (
                <div
                  className="SubHeaderProductInformation__ARButton"
                  onClick={() => onClickARButton()}
                >
                  <p>
                    {!isRanaglypheClicked
                      ? "Visualisation 3D"
                      : "Revenir à la VT"}
                  </p>
                  <img src={ARPicto} alt="Ouvrir la réalité augmentée" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
