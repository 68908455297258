import "./sendReportConfirmationModalContent.scss";

// Components import
import { useEffect, useState } from "react";
import { RequiredQuestionsComponent } from "../RequiredQuestionsComponent/RequiredQuestionsComponent";
import { ConfirmationSendRapportComponent } from "../ConfirmationSendRapportComponent/ConfirmationSendRapportComponent";
import { BonDeCommandeAModifierComponent } from "../BonDeCommandeAModifierComponent/BonDeCommandeAModifierComponent";
import { FinalCommentComponent } from "../FinalCommentComponent/FinalCommentComponent";

// Services import
import { checkRequiredQuestions } from "../../../services/Forms/checkRequiredQuestions";
import { useAppSelector, useAppDispatch } from "../../../redux/store/hook";
import { setSelectedInputAction } from "../../../redux/appActions";
import { determineFormNameFromProductType } from "../../../services/Forms/determineFormNameFromProductType";

// Interface import
import {
  formNames,
  IPhotovoltaiqueFormMutableData,
  ICETFormMutableData,
} from "../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  onClickYes: () => void;
  onClickNo: () => void;
  isLoading?: boolean;
  errorMessage?: string;
  isSaveVTSuccessfull?: boolean;
  resetSaveToCRMRequestStates?: () => void;
  formName?: formNames;
  formMutableData?: ICETFormMutableData | IPhotovoltaiqueFormMutableData;
  typeDeProduit?: number | null;
}

export const SendReportConfirmationModalContent = ({
  onClickYes,
  onClickNo,
  isLoading = false,
  errorMessage = "",
  isSaveVTSuccessfull = false,
  resetSaveToCRMRequestStates,
  formMutableData,
  formName = "CET",
  typeDeProduit,
}: Props) => {
  const dispatch = useAppDispatch();
  const [
    isFinalCommentComponentDisplayed,
    setIsFinalCommentComponentDisplayed,
  ] = useState(true);
  const [isConfirmSendRapportDisplayed, setIsConfirmSendRapportDisplayed] =
    useState(false);
  const statutRapportVT = useAppSelector(
    (state) => state.formsMutableData[formName].statutRapportVT?.value
  );

  // Reset success and error message
  useEffect(() => {
    resetSaveToCRMRequestStates && resetSaveToCRMRequestStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If no formMutableData or no formName-> no required question
  const missingRequiredQuestions: string[] =
    formMutableData && formName
      ? checkRequiredQuestions(formMutableData, typeDeProduit)
      : [];

  // Events handlers
  const onClickBonDeCommandeAModifier = (boolean: boolean) => {
    dispatch(
      setSelectedInputAction({
        selectedInput: boolean ? 629950001 : 629950000, //"Commande à modifier" / "Validé"
        formQuestionName: "resultatVTStatus",
        formName,
      })
    );
    setIsConfirmSendRapportDisplayed(() => true);
  };

  return (
    <div className="sendReportConfirmationModalContent">
      {isFinalCommentComponentDisplayed ? (
        <FinalCommentComponent
          formName={formName}
          onClickButton={() => setIsFinalCommentComponentDisplayed(() => false)}
        />
      ) : !isSaveVTSuccessfull && missingRequiredQuestions.length > 0 ? (
        <RequiredQuestionsComponent
          missingRequiredQuestions={missingRequiredQuestions}
        />
      ) : statutRapportVT === 629950006 ? ( // "Envoyé - à contrôler"
        <ConfirmationSendRapportComponent
          isLoading={isLoading}
          isSaveVTSuccessfull={isSaveVTSuccessfull}
          errorMessage={errorMessage}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          sentence={
            <p>
              Confirmez-vous <strong>l’envoi pour vérification</strong> de la
              visite du projet{" "}
              {`${
                typeDeProduit && determineFormNameFromProductType(typeDeProduit)
              } ?`}
            </p>
          }
        />
      ) : !isConfirmSendRapportDisplayed ? (
        <BonDeCommandeAModifierComponent
          onClickYes={() => onClickBonDeCommandeAModifier(true)}
          onClickNo={() => onClickBonDeCommandeAModifier(false)}
        />
      ) : (
        <ConfirmationSendRapportComponent
          isLoading={isLoading}
          isSaveVTSuccessfull={isSaveVTSuccessfull}
          errorMessage={errorMessage}
          onClickYes={onClickYes}
          onClickNo={onClickNo}
          sentence={
            <p>
              {`Confirmez-vous l’envoi du rapport de visite du projet ${
                typeDeProduit && determineFormNameFromProductType(typeDeProduit)
              } ?`}
            </p>
          }
        />
      )}
    </div>
  );
};
