// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import { stylesVariables } from "../../../../styles/variables";
import SVG from "react-inlinesvg";

export interface TabBarProps {
  steps: string[];
  iconsSrcs?: string[];
  onclickOnProgressBarStep: (pageIndex: number) => void;
  selectedIndex?: number;
}

const Container = styled.div`
  display: flex;
  width: 100%;
`;
const IconAndDescriptionWrapper = styled.div<{
  index?: number;
  selectedIndex?: number;
  iconHidden?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  gap: 5px;

  > p {
    color: ${(props) =>
      props.index === props.selectedIndex
        ? `${stylesVariables.AssuranceBlue}`
        : `${stylesVariables.StableGrayLight02}`};
    font-weight: ${(props) =>
      props.index === props.selectedIndex ? 700 : 500};
    font-size: 16px;
    font-family: "DM Sans", serif;
  }
`;
const SingleTab = styled.div<{
  index?: number;
  selectedIndex?: number;
}>`
  display: flex;
  cursor: pointer;
  min-height: 62px;
  border-bottom: ${(props) =>
    props.index === props.selectedIndex
      ? `3px solid ${stylesVariables.AssuranceBlue}`
      : ""};

  &:hover {
    background-color: ${stylesVariables.AssuranceBlueLight06};
    border-bottom: 3px solid ${stylesVariables.AssuranceBlue};

    > div {
      > p {
        color: ${stylesVariables.AssuranceBlue};
      }

      SVG {
        path {
          fill: ${stylesVariables.AssuranceBlue};
        }
      }
    }

  }
}
`;
const Icon = styled(SVG)<{
  index?: number;
  selectedIndex?: number;
  iconHidden?: boolean;
}>`
  display: ${(props) => (props.iconHidden ? "none" : "")};

  path {
    fill: ${(props) =>
      props.index === props.selectedIndex
        ? `${stylesVariables.AssuranceBlue}`
        : `${stylesVariables.StableGrayLight02}`};
  }
`;

export const TabBar: React.FC<TabBarProps> = ({
  steps = [],
  onclickOnProgressBarStep,
  iconsSrcs = [],
  selectedIndex = 0,
}) => {
  const progress = [];
  for (let i = 0; i < steps.length; i++) {
    progress.push(
      <SingleTab
        index={i}
        selectedIndex={selectedIndex}
        key={i}
        onClick={() => {
          onclickOnProgressBarStep(i);
        }}
      >
        <IconAndDescriptionWrapper
          iconHidden={!!iconsSrcs[i]}
          index={i}
          selectedIndex={selectedIndex}
        >
          <Icon
            iconHidden={!iconsSrcs[i]}
            index={i}
            selectedIndex={selectedIndex}
            src={iconsSrcs[i]}
          />
          <p>{steps[i]}</p>
        </IconAndDescriptionWrapper>
      </SingleTab>
    );
  }

  return <Container>{progress}</Container>;
};
