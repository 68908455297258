import { IFormCommonInfoKeys } from "../../interfaces/generalInterfaces";
import {
  PanInformationAllSubQuestionsKeys,
  IFormPotentialTrancheeSubQuestionsKeys,
} from "../../interfaces/photovoltaiqueFormInterfaces";

import {
  selectPanQuestions,
  potentialPanQuestions,
  potentialTrancheeQuestions,
} from "../../data/forms/Photovoltaique";

// These functions allow to determine, according to the question name, if it's a standard question or an pan question. It allow to know where to store the question's answer in redux.
export const checkIfPanInformationQuestion = (
  questionName: string
): questionName is PanInformationAllSubQuestionsKeys => {
  if (
    selectPanQuestions.find((category) =>
      category.questions.find((question) => question.name === questionName)
    ) ||
    potentialPanQuestions.find((category) =>
      category.questions.find((question) => question.name === questionName)
    )
  ) {
    return true;
  }
  return false;
};

export const checkIfIsFormCommonQuestion = (
  questionName: string
): questionName is IFormCommonInfoKeys => {
  return !(
    checkIfPanInformationQuestion(questionName) ||
    checkIfIsPotentialTrancheeQuestion(questionName)
  ); // If it is not a panInformation or potential tranchee question it is a standard question
};

export const checkIfIsPotentialTrancheeQuestion = (
  questionName: string
): questionName is IFormPotentialTrancheeSubQuestionsKeys => {
  if (
    potentialTrancheeQuestions.find((category) =>
      category.questions.find((question) => question.name === questionName)
    )
  ) {
    return true;
  }
  return false;
};
