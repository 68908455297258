// INterface import
import { IFraisSuppData } from "../../../interfaces/generalInterfaces";

export const fraisSuppData: IFraisSuppData = {
  pv: {
    trancheesCalculationData: {
      priceTrancheePerMeter: {
        629950000: 90, // "Béton/Goudron"
        629950001: 60, // Remblais
        629950002: 35, // TErre
      },
      toolsPrice: {
        629950000: 700, // "Minipelle";
        629950001: 400, // "Trancheuse";
      },
    },
    carrotage: {
      pricePerCarrotage: 300,
    },
    roofAccessibility: {
      priceNacelle: {
        location: 800,
        manutention: 0,
        total: 800,
      },
      priceEchelle: {
        total: 100,
      },
    },
    bacALester: {
      pricePerBac: 30,
    },
  },

  cet: {
    carrotagePrice: 300,
    deportBallonPerMeter: 100,
    pompeDeRelevage: 80,
    dalleBeton: 0, // Passage à 0 sur demande d'Andréa le 26/01/23
  },
  pacAirEau: {
    desembouage: 800,
    poseIsolant: 150,
    isolantPerMeter: 20,
    kitBiZone: 1000,
    dalleBeton: 800,
    ajoutGazPerMeter: 50,
  },
};
