import "./lineDividedBlock.scss";

// Local interfaces declaration
interface Props {
  label: JSX.Element;
  value: JSX.Element;
}

export const LineDividedBlock = ({ label, value }: Props) => {
  return (
    <div className={"lineDividedBlock"}>
      <div className={"lineDividedBlock__Label"}>{label}</div>
      <div className={"lineDividedBlock__DividingLine"}></div>
      <div className={"lineDividedBlock__Value"}>{value}</div>
    </div>
  );
};
