import "./formWrapper.scss";

// Libraries import
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";

// Components import
import { ProgressBar } from "../../../components/ProgressBar/ProgressBar";
import { Layout } from "../../../components/Layout/Layout";
import { Modal } from "../../../components/Modal/Modal";
import { SendReportConfirmationModalContent } from "../SendRepportConfirmationModalContent/SendReportConfirmationModalContent";
import { SubHeaderProductInformation } from "../../../components/subHeaderProductInformation/SubHeaderProductInfo";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { PDFITE } from "../../../documents/PDFITE";

// Service import
import { useAppSelector, useAppDispatch } from "../../../redux/store/hook";
import {
  setGoToNextPageAction,
  setGoToPreviousPageAction,
  setVisiteTechniqueInfoAction,
  setFormAnswersStateAction,
} from "../../../redux/appActions";
import { useModal } from "../../../services/useModal";
import { useSaveVTCommonInfoToCRM } from "../../../services/Forms/useSaveVTCommonInfoToCRM";
import { useSaveVTPanInfoToCRM } from "../../../services/Forms/PVForm/useSaveVTPanInfoToCRM";
import { useSaveVTExtraChargesInfoToCRM } from "../../../services/Forms/useSaveVTExtraChargesInfoToCRM";
import { useGenerateAndSaveVTRapportToCRM } from "../../../services/Forms/PVForm/useGenerateAndSaveVTRapportToCRM";
import { formatDataBeforeSendingToCRM } from "../../../services/Forms/formatVTDataBeforeSendingToCRM";
import { formatDataBeforeSendingToGrammateus } from "../../../services/Forms/PVForm/formatDataBeforeSendingToGrammateus";
import { usePostCalepinageRelevant } from "../../../services/CalepinageValidation/usePostCalepinageRelevant";
import { useSaveITEWallsInCRM } from "../../../services/Forms/ITE/useSaveITEWallsInCRM";
import { formatITEWallsBeforeSendingToCRM } from "../../../services/Forms/ITE/formatITEWallsBeforeSendingToCRM";
import { formatITEExtraChargesBeforeSendingToCRM } from "../../../services/Forms/ITE/formatITEExtraChargesBeforeSendingToCRM";
import { formatExtraChargesDataBeforeSendingToCRM } from "../../../services/Forms/formatExtraChargesDataBeforeSendingToCRM";
import { useUploadDocument } from "../../../services/UploadDocuments/useUploadDocument";
import { useGetVTFileInformations } from "../../../services/UploadDocuments/useGetVTFileInformations";

// Interfaces import
import {
  IITEFormData,
  IUserProfileInfo,
  IVisiteTechniqueInfo,
  formNames,
} from "../../../interfaces/generalInterfaces";
import { IFormScreenName } from "../../../interfaces/generalInterfaces";
import {
  IITEOuvrant,
  IITEPointsSinguliers,
} from "../../../interfaces/ITEFormInterface";

// Local interface declaration
interface Props {
  children: React.ReactNode;
  renderNextButton?: boolean;
  visiteTechniqueInfo: IVisiteTechniqueInfo;
  formName: formNames;
  currentScreenName: IFormScreenName;
  areGetDataFromCRMLoading: boolean;
  getDataErrorMessage: string;
  formPagesLength: number;
  visiteTechniqueId?: string;
  formScreenSteps: string[];
  numberOfScreens: number;
  isRanaglypheClicked?: boolean;
  setIsRanaglypheClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormWrapper = ({
  children,
  visiteTechniqueInfo,
  formName,
  areGetDataFromCRMLoading,
  getDataErrorMessage,
  formPagesLength,
  visiteTechniqueId,
  formScreenSteps,
  currentScreenName,
  numberOfScreens,
  isRanaglypheClicked,
  setIsRanaglypheClicked,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getVTFileInformations } = useGetVTFileInformations();
  const [isSendingToCRMLoading, setIsSendingToCRMLoading] = useState(false);

  const {
    userAuthInfo,
    formsMutableData: formGlobalMutableData,
    userProfileInfo,
  } = useAppSelector((state) => state);
  const { uploadDocument } = useUploadDocument();

  const formMutableData = formGlobalMutableData[formName];
  const { userToken, eeroToken } = userAuthInfo;

  const disableNextButton =
    (formMutableData.currentPageIndex?.[currentScreenName] || 0) + 1 >=
      formPagesLength &&
    formMutableData.currentScreenIndex + 1 >= numberOfScreens;

  const disablePreviousButton =
    (formMutableData.currentPageIndex?.[currentScreenName] || 0) <= 0 &&
    (formGlobalMutableData.photovoltaique.panSelected || 0) === 0 &&
    formMutableData.currentScreenIndex <= 0;

  const {
    saveVTCommonInfoToCRM,
    errorMessage: saveCommonInfoErrorMessage,
    isSuccessfull: isSaveVTCommonInfoSuccessfull,
    resetStates: resetSaveVTInfoStates,
  } = useSaveVTCommonInfoToCRM();
  const {
    saveVTPanInfoToCRM,
    errorMessage: savePanInfoErrorMessage,
    isSuccessfull: isSaveVTPanInfoSuccessfull,
    resetStates: resetSavePanInfoStates,
  } = useSaveVTPanInfoToCRM();
  const {
    saveVTExtraChargesInfoToCRM,
    isSuccessfull: isSaveVTExtraChargesSuccessfull,
    errorMessage: saveVTExtraChargesErrorMessage,
  } = useSaveVTExtraChargesInfoToCRM();
  const { generateAndSaveVTPdfRapportInCRM } =
    useGenerateAndSaveVTRapportToCRM();
  const { postCalepinageRelevant } = usePostCalepinageRelevant();

  const {
    saveITEWallsInCRM,
    isSuccessfull: isSaveITEWallsSuccessfull,
    errorMessage: saveITEWallsErrorMessage,
  } = useSaveITEWallsInCRM();
  const {
    toggleModal: toggleConfirmationModal,
    isVisible: isConfirmationModalVisible,
  } = useModal();

  const { VTCommonInfoToCRM, newPanInformationsCompleted } =
    formatDataBeforeSendingToCRM({ formGlobalMutableData, formName });

  const resetSaveToCRMRequestStates = () => {
    resetSaveVTInfoStates();
    resetSavePanInfoStates();
  };

  function blobToBase64(blob: Blob | undefined): Promise<string | ArrayBuffer> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result !== null) {
          resolve(reader.result);
        } else {
          // Handle the case where reader.result is null
          resolve("");
        }
      };
      blob && reader.readAsDataURL(blob);
    });
  }

  const BlobPdfITE = async ({
    picturesFromCRM,
    ITEFormMutableData,
    visiteTechniqueInfo,
    userProfileInfo,
    extraChargesITEAReposerWithoutDoublon,
  }: {
    ITEFormMutableData: IITEFormData;
    visiteTechniqueInfo: IVisiteTechniqueInfo;
    userProfileInfo: IUserProfileInfo;
    extraChargesITEAReposerWithoutDoublon: (
      | IITEPointsSinguliers
      | IITEOuvrant
    )[];
    picturesFromCRM:
      | {
          fileName: string;
          url: string;
        }[]
      | null;
  }) => {
    const obj = pdf(
      <PDFITE
        visiteTechniqueInfo={visiteTechniqueInfo}
        userProfileInfo={userProfileInfo}
        ITEFormMutableData={ITEFormMutableData}
        picturesFromCRM={picturesFromCRM}
        extraChargesITEAReposerWithoutDoublon={
          extraChargesITEAReposerWithoutDoublon
        }
      />
    ).toBlob();

    return obj;
  };

  const onSendDataToCRM = async () => {
    if (visiteTechniqueId) {
      if (formName === "photovoltaique") {
        setIsSendingToCRMLoading(true);
        await Promise.allSettled([
          saveVTCommonInfoToCRM({
            VTCommonInfo: VTCommonInfoToCRM,
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
          saveVTPanInfoToCRM({
            VTPanInfo: newPanInformationsCompleted,
            VTId: visiteTechniqueId,
            numberPan: VTCommonInfoToCRM.nombredePantoEquip || 1,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
          saveVTExtraChargesInfoToCRM({
            extraCharges: formatExtraChargesDataBeforeSendingToCRM({
              formsMutableData: formGlobalMutableData,
              formName: "photovoltaique",
            }),
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
        ]);
        setIsSendingToCRMLoading(false);
        postCalepinageRelevant({
          userToken: eeroToken,
          building_id: formGlobalMutableData.calepinage?.building_id ?? null,
          commentCalepinage:
            formGlobalMutableData.photovoltaique.commentValidateCalepinage
              ?.value ?? null,
          isCalepinageRelevant:
            formGlobalMutableData.photovoltaique.validateCalepinage?.value ==
            null
              ? null
              : !!formGlobalMutableData.photovoltaique.validateCalepinage
                  ?.value,
          numberOfPanels:
            formGlobalMutableData.photovoltaique.numberOfPannel?.value,
        });
      }

      if (formName === "CET") {
        setIsSendingToCRMLoading(true);
        await Promise.allSettled([
          saveVTCommonInfoToCRM({
            VTCommonInfo: VTCommonInfoToCRM,
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
          saveVTExtraChargesInfoToCRM({
            extraCharges: formatExtraChargesDataBeforeSendingToCRM({
              formsMutableData: formGlobalMutableData,
              formName: "CET",
            }),
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
        ]);
        setIsSendingToCRMLoading(false);
      }
      if (formName === "PacAirEau") {
        setIsSendingToCRMLoading(true);
        await Promise.allSettled([
          saveVTCommonInfoToCRM({
            VTCommonInfo: VTCommonInfoToCRM,
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
          saveVTExtraChargesInfoToCRM({
            extraCharges: formatExtraChargesDataBeforeSendingToCRM({
              formsMutableData: formGlobalMutableData,
              formName: "PacAirEau",
            }),
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
        ]);
        setIsSendingToCRMLoading(false);
      }

      if (formName === "ITE") {
        setIsSendingToCRMLoading(true);
        const {
          extraCharges,
          globalDescriptionString,
          extraChargesITEAReposerWithoutDoublon,
        } = formatITEExtraChargesBeforeSendingToCRM({
          formsMutableData: formGlobalMutableData,
        });
        const picturesFromCRM = await getVTFileInformations({
          clientId: visiteTechniqueInfo.customerId,
          userToken,
          visiteTechniqueId,
        });

        const blobPdfIte = await BlobPdfITE({
          picturesFromCRM: picturesFromCRM || null,
          ITEFormMutableData: formMutableData,
          visiteTechniqueInfo,
          userProfileInfo,
          extraChargesITEAReposerWithoutDoublon,
        });
        await Promise.allSettled([
          saveVTCommonInfoToCRM({
            VTCommonInfo: VTCommonInfoToCRM,
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
          saveITEWallsInCRM({
            VTId: visiteTechniqueId,
            crmToken: userToken,
            ITEWallData: formatITEWallsBeforeSendingToCRM({
              formGlobalMutableData,
            }),
            eeroToken: eeroToken,
          }),
          saveVTExtraChargesInfoToCRM({
            extraCharges: { extraCharges, globalDescriptionString },
            VTId: visiteTechniqueId,
            crmToken: userToken,
            eeroToken: eeroToken,
          }),
        ]);

        const base64PdfIte = await blobToBase64(blobPdfIte);
        await uploadDocument({
          uploadedFile: base64PdfIte,
          fileName: `Rapport-VT-ITE-${Date.now()}.pdf`,
        });
        setIsSendingToCRMLoading(false);
      }
    }
  };

  // If save form data to CRM is successfull we send visite report to CRM and update the visite technique statut to "Réalisée"
  useEffect(() => {
    if (visiteTechniqueId) {
      if (
        (formName === "photovoltaique" &&
          isSaveVTPanInfoSuccessfull &&
          isSaveVTCommonInfoSuccessfull &&
          isSaveVTExtraChargesSuccessfull) ||
        ((formName === "CET" || formName === "PacAirEau") &&
          isSaveVTCommonInfoSuccessfull &&
          isSaveVTExtraChargesSuccessfull) ||
        (formName === "ITE" &&
          isSaveITEWallsSuccessfull &&
          isSaveVTCommonInfoSuccessfull &&
          isSaveVTExtraChargesSuccessfull)
      ) {
        // Asynchronous : we don't wait for the answer. If it fails -> the data will still be saved in CRM
        formName === "photovoltaique" &&
          generateAndSaveVTPdfRapportInCRM({
            customerId: visiteTechniqueInfo.customerId,
            userToken,
            rapportVtData: formatDataBeforeSendingToGrammateus({
              formGlobalMutableData,
              visiteTechniqueInfo,
              userProfileInfo,
            }),
          });

        setTimeout(() => {
          // If the VT report is successfully saved in CRM we reset all visit technique info in redux
          dispatch(
            setFormAnswersStateAction({
              formName: formName,
              formAnswers: null,
              visiteTechniqueId,
            })
          ); // We reset user's form answer

          dispatch(
            setVisiteTechniqueInfoAction({
              visiteTechniqueInfo: null,
            })
          ); // We reset visite technique info to update statutVT

          navigate(0); // Navigate to landing page and refresh page -> update visites techniques list
        }, 3000);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSaveVTPanInfoSuccessfull,
    isSaveVTCommonInfoSuccessfull,
    isSaveVTExtraChargesSuccessfull,
    isSaveITEWallsSuccessfull,
  ]);

  // Events handler
  const onClickNext = () => {
    if (!disableNextButton) {
      dispatch(
        setGoToNextPageAction({
          formName: formName,
          formCurrentScreen: currentScreenName,
          formPagesLength: formPagesLength,
        })
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onClickPrevious = () => {
    if (!disablePreviousButton) {
      dispatch(
        setGoToPreviousPageAction({
          formName: formName,
          formCurrentScreen: currentScreenName,
          formPagesLength: formPagesLength,
        })
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Modal
        isVisible={isConfirmationModalVisible}
        onClose={toggleConfirmationModal}
      >
        <SendReportConfirmationModalContent
          onClickNo={toggleConfirmationModal}
          onClickYes={onSendDataToCRM}
          formMutableData={formMutableData}
          formName={formName}
          typeDeProduit={visiteTechniqueInfo.typeDeProduit}
          isLoading={isSendingToCRMLoading}
          errorMessage={
            saveCommonInfoErrorMessage ||
            savePanInfoErrorMessage ||
            saveVTExtraChargesErrorMessage ||
            saveITEWallsErrorMessage
          }
          resetSaveToCRMRequestStates={resetSaveToCRMRequestStates}
          isSaveVTSuccessfull={
            (formName === "photovoltaique" &&
              isSaveVTPanInfoSuccessfull &&
              isSaveVTCommonInfoSuccessfull &&
              isSaveVTExtraChargesSuccessfull) ||
            ((formName === "CET" || formName === "PacAirEau") &&
              isSaveVTCommonInfoSuccessfull &&
              isSaveVTExtraChargesSuccessfull) ||
            (formName === "ITE" &&
              isSaveITEWallsSuccessfull &&
              isSaveVTCommonInfoSuccessfull &&
              isSaveVTExtraChargesSuccessfull)
          }
        />
      </Modal>
      <div className="formWrapper">
        <div className="formWrapper__Header">
          <SubHeaderProductInformation
            isRanaglypheClicked={isRanaglypheClicked}
            setIsRanaglypheClicked={setIsRanaglypheClicked}
            visiteTechniqueInfo={visiteTechniqueInfo}
            areGetDataFromCRMLoading={areGetDataFromCRMLoading}
            getDataErrorMessage={getDataErrorMessage}
          />
        </div>
        <div className="formWrapper__ProgressBar">
          <ProgressBar
            formName={formName}
            steps={formScreenSteps}
            activeNumber={
              (formMutableData.currentPageIndex?.[currentScreenName] || 0) + 1
            }
            formCurrentScreen={currentScreenName}
            currentScreenIndex={formMutableData.currentScreenIndex}
          />
        </div>
        <div className="formWrapper__FormContent">
          <div className="formWrapper__FormQuestionsFormatter">
            {children}
            <Layout>
              <div className="formWrapper__FormBottom">
                {disableNextButton ? (
                  <>
                    <div className="formWrapper__PreviousButton">
                      <CTAButton
                        name={"Précédent"}
                        onClick={onClickPrevious}
                        isDisabled={disablePreviousButton}
                        family={"vitee"}
                        category={"secondary"}
                      />
                    </div>
                    <div className="formWrapper__TerminateButton">
                      <CTAButton
                        name={"Terminer la visite"}
                        onClick={toggleConfirmationModal}
                        isDisabled={disablePreviousButton}
                        family={"vitee"}
                        category={"primary"}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="formWrapper__PreviousButton">
                      <CTAButton
                        name={"Précédent"}
                        onClick={onClickPrevious}
                        isDisabled={disablePreviousButton}
                        family={"vitee"}
                        category={"secondary"}
                      />
                    </div>
                    <div className="formWrapper__NextButton">
                      <CTAButton
                        name={"Suivant"}
                        onClick={onClickNext}
                        isDisabled={disableNextButton}
                        family={"vitee"}
                        category={"primary"}
                      />
                    </div>
                  </>
                )}
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
};
