import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { IVisiteTechniqueList } from "../../../interfaces/generalInterfaces";
import "./arrayHeader.scss";

interface IProps {
  onClickOnColumnName: Dispatch<SetStateAction<keyof IVisiteTechniqueList>>;
  setSortType: Dispatch<SetStateAction<"ascendant" | "descendant" | "default">>;
  sortType: "ascendant" | "descendant" | "default";
}

export const ArrayHeader = ({
  onClickOnColumnName,
  setSortType,
  sortType,
}: IProps) => {
  const [clickedItem, setClickedItem] =
    useState<keyof IVisiteTechniqueList>("dateVisite");
  const [previousClickedItem, setPreviousClickedItem] = useState<
    keyof IVisiteTechniqueList | ""
  >("");

  const onClick = (elementID: keyof IVisiteTechniqueList) => {
    setPreviousClickedItem(clickedItem);
    onClickOnColumnName(elementID);
    setClickedItem(elementID);

    if (previousClickedItem === clickedItem) {
      sortType === "ascendant" || sortType === "default"
        ? setSortType("descendant")
        : setSortType("ascendant");
    } else {
      setSortType("descendant");
    }
  };

  useEffect(() => {
    if (previousClickedItem === clickedItem) {
      sortType === "ascendant" || sortType === "default"
        ? setSortType("descendant")
        : setSortType("ascendant");
    } else {
      setSortType("descendant");
    }
    // missing dependencies ==>
    // eslint-disable-next-line
  }, [previousClickedItem, clickedItem]);

  return (
    <div className="arrayHeader">
      <div className="arrayHeader__Container">
        <p className="flex-item size-12" onClick={() => onClick("dateVisite")}>
          Date de visite
          <i
            className={`fa-solid fa-${
              clickedItem === "dateVisite" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "dateVisite" && sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p className="flex-item size-08">Heure</p>
        <p
          className="flex-item size-1"
          onClick={() => onClick("customerFirstName")}
        >
          Prénom
          <i
            className={`fa-solid fa-${
              clickedItem === "customerFirstName" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "customerFirstName" &&
                  sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p
          className="flex-item size-1"
          onClick={() => onClick("customerLastName")}
        >
          Nom
          <i
            className={`fa-solid fa-${
              clickedItem === "customerLastName" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "customerLastName" && sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p
          className="flex-item size-1"
          onClick={() => onClick("customerPostalCode")}
        >
          Code postal
          <i
            className={`fa-solid fa-${
              clickedItem === "customerPostalCode" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "customerPostalCode" &&
                  sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p className="flex-item size-1" onClick={() => onClick("statutVT")}>
          Statut VT
          <i
            className={`fa-solid fa-${
              clickedItem === "statutVT" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "statutVT" && sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p
          className="flex-item size-12"
          onClick={() => onClick("statutRapportVT")}
        >
          Rapport VT
          <i
            className={`fa-solid fa-${
              clickedItem === "statutRapportVT" && sortType === "descendant"
                ? "sort-down"
                : clickedItem === "statutRapportVT" && sortType === "ascendant"
                ? "sort-up"
                : "sort"
            } `}
          ></i>
        </p>
        <p className="flex-item size-08"></p>
        {/* last item of array to match the arrow in the singleVisit Item */}
      </div>
    </div>
  );
};
