// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../../styles/variables";

export interface TextareaComponentProps {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  id?: string;
  name?: string;
  value?: string | number | readonly string[] | undefined;
  placeholder?: string | undefined;
}

const Textarea = styled.textarea`
  width: 100%;
  height: 5rem;
  font-size: 1.1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${stylesVariables.StableGrayLight04};
  padding: 5px 10px;
  background-color: ${stylesVariables.MainBackgroundColor};
  font-family: "PulpDisplay";
  &::placeholder {
    font-weight: 200;
    font-size: 1.1rem;
    font-family: "DM Sans";
    color: ${stylesVariables.StableGrayLight03};
  }
`;

export const TextareaComponent = ({
  onChange,
  id,
  name,
  value,
  placeholder,
}: TextareaComponentProps) => {
  return (
    <Textarea
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
