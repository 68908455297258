import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../../styles/variables";

// Local interface declaration
export interface CheckboxIpadProps {
  checked?: boolean;
  onClick?: () => void;
  id?: string;
  name?: string;
  required?: boolean;
}

const DivContainer = styled.div`
  position: relative;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  transition: all 0.05s ease-in;
  :hover input ~ .checkmark {
    background-color: #ccc;
  }
`;

const Label = styled.label`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
`;

const Input = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;

  height: 2rem;
  width: 2rem;
  border-radius: 0.4rem;
  border: 1px solid black;
  :checked ~ .checkmark {
    background-color: ${stylesVariables.MainOrange};
    border: 1px solid ${stylesVariables.MainOrange};
  }
  :checked ~ .checkmark:after {
    display: block;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  border-radius: 0.4rem;
  border: 1px solid ${stylesVariables.StableGrayLight04};
  background-color: ${stylesVariables.MainBackgroundColor};
  :after {
    content: "";
    position: absolute;
    display: none;
    left: 0.6rem;
    top: 0.3rem;
    width: 0.4rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckboxIpad = ({
  onClick,
  checked = false,
  id,
  name,
  required = false,
}: CheckboxIpadProps) => {
  return (
    <>
      <DivContainer>
        <Label>
          <Input
            type={"checkbox"}
            id={id}
            name={name}
            onChange={onClick}
            required={required}
            checked={checked}
          />
          <Span className="checkmark" />
        </Label>
      </DivContainer>
    </>
  );
};
