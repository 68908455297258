import "./formPagesITE.scss";

// Librairie import
import { useState } from "react";

// Components import
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";
import { H2 } from "@web/shared/dist/components/DesignSystem/Typographie/H2/H2";
import Plus from "@web/shared/dist/assets/images/DesignSystemIcons/plus.svg";
import { UploadPicturesBtn } from "../../../../containers/Forms/FormStepsWithSharedComponents/UploadPicturesBtn/UploadPicturesBtn";
import { ITEWall } from "../../../../containers/Forms/ITEWall/ITEWall";

// Services import
import { useAppDispatch } from "../../../../redux/store/hook";
import {
  setQuestionPictureAction,
  createITEWallAction,
} from "../../../../redux/appActions";

// Interfaces import
import {
  IITEFormData,
  IAllFormQuestionsKeys,
} from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  ITEFormMutableData?: IITEFormData;
  visitId?: string;
}

export const ExterieurPage = ({ ITEFormMutableData, visitId }: Props) => {
  const dispatch = useAppDispatch();
  const formName = "ITE";

  const [isBlocksOpen, setIsBlocksOpen] = useState<boolean[]>(
    ITEFormMutableData?.walls?.map(() => false) || [false]
  );
  const [blocksHeight, setBlocksHeight] = useState<number[]>(
    ITEFormMutableData?.walls?.map(() => 0) || [0]
  );

  const onClickAddWall = () => {
    dispatch(
      createITEWallAction({
        visitId: visitId ?? null,
        setIsBlocksOpen,
      })
    );
  };

  const onUploadPicture = ({
    pictureUrl,
    questionName,
  }: {
    pictureUrl: string;
    questionName: IAllFormQuestionsKeys;
  }) => {
    dispatch(
      setQuestionPictureAction({
        pictureUrl,
        formName: formName,
        formQuestionName: questionName,
        visitId: visitId ?? undefined,
      })
    );
  };

  return (
    <div className="formPagesITE">
      <div className="formPagesITE__MainPhoto">
        <p className={"formPagesITE__MainPhoto__text"}>
          Veuillez prendre une photo globale :
        </p>
        <UploadPicturesBtn
          buttonName={"Ajouter"}
          questionName="ITEWallGlobalPicture"
          uploadedPictures={ITEFormMutableData?.ITEWallGlobalPicture?.pictures}
          formName={formName}
          visitId={visitId}
          onUpload={(pictureUrl) =>
            onUploadPicture({
              pictureUrl,
              questionName: "ITEWallGlobalPicture",
            })
          }
        />
      </div>
      <div className="formPagesITE__Title">
        <H2 title={"Isolation thermique par l’extérieur"} />
      </div>

      {ITEFormMutableData?.walls?.map((wall, wallIndex) => {
        return (
          <ITEWall
            ITEFormMutableData={ITEFormMutableData}
            wallIndex={wallIndex}
            visitId={visitId}
            isBlocksOpen={isBlocksOpen}
            setIsBlocksOpen={setIsBlocksOpen}
            blocksHeight={blocksHeight}
            setBlocksHeight={setBlocksHeight}
            key={wallIndex}
            murId={wall.murId?.value}
          />
        );
      })}

      <div className="formPagesITE__ButtonLeft">
        <CTAButton
          name="Ajouter un mur"
          family="vitee"
          category="secondary"
          iconSrc={Plus}
          onClick={onClickAddWall}
        />
      </div>
    </div>
  );
};
