import "./progressBar.scss";

// Services import
import { useAppDispatch } from "../../redux/store/hook";
import {
  setGoToSpecificPageAction,
  setGoToPreviousPageAction,
} from "../../redux/appActions";

// Interfaces import
import { formNames } from "../../interfaces/generalInterfaces";
import { IFormScreenName } from "../../interfaces/generalInterfaces";
import { TabBar } from "@web/shared/dist/components/DesignSystem/Tabs/TabBar/TabBar";
import { Layout } from "../Layout/Layout";

// Local interface declaration
interface Props {
  steps: string[];
  activeNumber: number;
  formName: formNames;
  formCurrentScreen: IFormScreenName;
  currentScreenIndex: number;
}

export const ProgressBar = ({
  steps,
  activeNumber,
  formName,
  formCurrentScreen,
  currentScreenIndex,
}: Props) => {
  const dispatch = useAppDispatch();

  const onclickOnProgressBarStep = (pageIndex: number) => {
    dispatch(
      setGoToSpecificPageAction({ formName, pageIndex, formCurrentScreen })
    );
  };
  const onClickOnPrevious = () => {
    dispatch(
      setGoToPreviousPageAction({
        formName,
        formCurrentScreen,
        formPagesLength: steps.length,
      })
    );
  };
  return (
    <Layout>
      <div className={"progressBar"}>
        {currentScreenIndex > 0 && (
          <button
            className="progressBar__PreviousButton"
            onClick={onClickOnPrevious}
          >
            Retour visite technique
          </button>
        )}
        <TabBar
          steps={steps}
          onclickOnProgressBarStep={(index) => onclickOnProgressBarStep(index)}
          selectedIndex={activeNumber - 1}
        />
      </div>
    </Layout>
  );
};
