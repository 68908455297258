// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../../styles/variables";

// Interfaces declaration
export interface H2Props {
  title?: string | number;
}

const H2Title = styled.h2`
  color: ${stylesVariables.OffBlack};
  font-size: 24px;
  line-height: 24px;
  font-family: PulpDisplay;
  font-weight: 700;
`;

export const H2: React.FC<H2Props> = ({ title = "Header 2" }) => {
  return <H2Title>{title}</H2Title>;
};
