import "./pickMultipleCards.scss";

// Components import
import { PickCard } from "@web/shared/dist/components/Old/FormComponents/PickCard/PickCard";

// Service import
import { useAppDispatch } from "../../../../redux/store/hook";
import { setPickMultipleCardsAction } from "../../../../redux/appActions";
import { valueToLabelDictionnary } from "../../../../services/Forms/valueToLabelDictionnary";

// Interfaces import
import { formNames } from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  title: string;
  cards: number[];
  formName: formNames;
  visitId: string | undefined;
  userAnswer?: {
    value?: (string | number)[] | null | undefined;
    pictures?: string[] | undefined;
  };
  questionName: "typeDeReseauExistants";
  isPictureNeeded?: boolean;
  answerIndex?: number;
}

export const PickMultipleCards = ({
  title,
  formName,
  cards,
  userAnswer,
  questionName,
  isPictureNeeded = false,
  visitId,
  answerIndex,
}: Props) => {
  const dispatch = useAppDispatch();
  // Event handler
  const onCardClick = (textCard: string | number) => {
    dispatch(
      // We check if the question expects a string answer. If it is the case we can create or update the user's answer state.
      setPickMultipleCardsAction({
        textCard,
        formName: formName,
        formQuestionName: questionName,
        answerIndex: answerIndex,
        visitId,
      })
    );
  };

  return (
    <div className="pickMultipleCards">
      <div className="pickMultipleCards__Title">
        <p>{title}</p>
      </div>
      <div>
        <div className={"pickMultipleCards__pickCards"}>
          {cards?.map((CardValue, index) => {
            const cardText = valueToLabelDictionnary(questionName, CardValue);
            return (
              <div className={"pickMultipleCards__Card"} key={index}>
                <PickCard
                  text={typeof cardText === "string" ? cardText : ""}
                  isSelected={
                    Array.isArray(userAnswer?.value) &&
                    userAnswer?.value.includes(CardValue)
                      ? true
                      : false
                  }
                  onClick={() => onCardClick(CardValue)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
