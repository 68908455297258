import "./secondaryButton.scss";
import { MouseEventHandler } from "react";

// Local interface declaration
interface Props {
  name?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const SecondaryButton = ({
  name = "Button",
  onClick,
  disabled = false,
}: Props) => {
  return (
    <button className={"secondaryButton"} onClick={onClick} disabled={disabled}>
      <p>{name}</p>
    </button>
  );
};
