// Initial state
import { IAppStates } from "../interfaces/generalInterfaces";

export const initialState: IAppStates = {
  userAuthInfo: {
    userToken: null,
    userId: null,
    eeroToken: null,
    eeroUserId: null,
  },
  userProfileInfo: {
    id: null,
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
  },
  visitesTechniquesList: [],
  visiteTechniqueInfo: {
    id: null,
    customerId: null,
    customerFirstName: null,
    customerLastName: null,
    customerPostalCode: null,
    customerCity: null,
    customerStreet: null,
    customerlatitude: null,
    customerlongitude: null,
    statutVT: null,
    statutRapportVT: null,
    commentaires: null,
    dateVisite: null,
    products: null,
    typeDeProduit: null,
    customerMobilePhone: null,
    customerTelephone: null,
    customerEmail: null,
    customerCivility: null,
    commercialFullName: null,
    commercialMobilePhone: null,
    commercialEmail: null,
    commentaireRapportVT: null,
    centralePvInfos: {
      marque: null,
      marqueModuleSolaire: null,
      nomModuleSolaire: null,
      gammeModuleSolaire: null,
    },
  },
  formsMutableData: {
    photovoltaique: {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
      panSelected: 0,
    },
    CET: {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
    chauffage: {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
    "batterie de stockage": {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
    "pompe a chaleur Air/Air": {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
    PacAirEau: {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
    ITE: {
      formCurrentBranchs: ["main"],
      currentScreenIndex: 0,
      currentPageIndex: {
        visiteTechniqueFormPages: 0,
        extraChargesFormPages: 0,
        validateCalepinagePages: 0,
      },
    },
  },
};
