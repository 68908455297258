import "./inputText.scss";

// Components import
import { TextareaComponent } from "@web/shared/dist/components/Old/FormComponents/TextareaComponent/TextareaComponent";
import { UploadPicturesComponent } from "../../../../components/Form/UploadPicturesComponent/UploadPicturesComponent";

// Services import
import { useAppDispatch, useAppSelector } from "../../../../redux/store/hook";
import { setInputTextAction } from "../../../../redux/appActions";
import {
  checkIfIsFormCommonQuestion,
  checkIfPanInformationQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../../../services/Forms/checkTypes";

// Interface import
import {
  IFormQuestions,
  formNames,
} from "../../../../interfaces/generalInterfaces";

// Local interface declaration
interface Props {
  questionData: IFormQuestions;
  formName: formNames;
  answerIndex?: number;
  visitId?: string;
}

export const InputText = ({
  questionData,
  formName,
  answerIndex,
  visitId,
}: Props) => {
  const dispatch = useAppDispatch();
  const typedInput =
    useAppSelector((state) => {
      // Standard case when we need to selected the input value at the root of the form state
      if (checkIfIsFormCommonQuestion(questionData.name)) {
        return state.formsMutableData[formName][questionData.name]?.value ?? "";
      }

      // Case when we need to get the input value from the panInformations key
      if (
        formName === "photovoltaique" &&
        checkIfPanInformationQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].panInformations?.[
            answerIndex || 0
          ]?.[questionData.name]?.value ?? ""
        );
      }
      if (
        formName === "photovoltaique" &&
        checkIfIsPotentialTrancheeQuestion(questionData.name)
      ) {
        return (
          state.formsMutableData[formName].potentialTranchees?.[
            answerIndex || 0
          ]?.[questionData.name]?.value || ""
        );
      }
    }) ?? "";

  const setTypedInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setInputTextAction({
        inputText: event.target.value,
        formName,
        formQuestionName: questionData.name,
        visitId,
        answerIndex,
      })
    );
  };

  return (
    <div className="inputText">
      <div className="inputText__Title">
        <p>{questionData.title}</p>
      </div>
      <div className={`inputText__InputComponent`}>
        <TextareaComponent
          onChange={setTypedInput}
          value={typeof typedInput === "string" ? typedInput : ""} // We ensure the typed input is string
          id={questionData.name}
          name={questionData.name}
          placeholder={questionData.placeholder}
        />
      </div>
      {questionData.pictureNeeded && (
        <div className={`inputText__UploadPicturesComponent`}>
          <UploadPicturesComponent
            questionData={questionData}
            formName={formName}
            visitId={visitId}
            answerIndex={answerIndex}
          />
        </div>
      )}
    </div>
  );
};
