import "./photovoltaique.scss";

// Libraries import
import { useParams } from "react-router-dom";
import { useEffect } from "react";

// Components import
import { Layout } from "../../../components/Layout/Layout";
import { FormWrapper } from "../../../containers/Forms/FormWrapper/FormWrapper";
import { SubHeader } from "../../../components/SubHeader/SubHeader";
import { FormQuestionsFormatter } from "../../../containers/Forms/FormQuestionsFormatter/FormQuestionsFormatter";
import * as StandalonePages from "./standalonePages";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetVTCommonInfoFromCRM } from "../../../services/Forms/useGetVTCommonInfoFromCRM";
import { useGetVTPanInfoFromCRM } from "../../../services/Forms/PVForm/useGetVTPanInfoFromCRM";
import { useGetVTExtraChargesFromCRM } from "../../../services/Forms/useGetVTExtraChargesFromCRM";
import { useUpdateFormPVAccordingToUserAnswer } from "../../../services/Forms/PVForm/useUpdateFormPVAccordingToUserAnswer";
import { useGetFormAnswerFromLocalStorage } from "../../../services/Forms/useGetFormAnswerFromLocalStorage";

// Data import
import { photovoltaiqueData } from "../../../data/forms/Photovoltaique";

// Interfaces import
import {
  IFormattedFormScreenName,
  IStandardFormScreenName,
} from "../../../interfaces/generalInterfaces";

export const Photovoltaique = () => {
  const singleVisitId = useParams().visitId;
  const formName = photovoltaiqueData.formName;
  const formsMutableData = useAppSelector(
    (state) => state.formsMutableData[formName]
  );
  const currentScreenIndex = formsMutableData.currentScreenIndex;
  const currentScreenName =
    photovoltaiqueData.formScreensName[currentScreenIndex];
  const {
    getVTCommonInfoFromCRM,
    isLoading: isGetVTCommonInfoLoading,
    errorMessage: getVTCommonInfoErrorMessage,
  } = useGetVTCommonInfoFromCRM();
  const {
    getVTPanInfoFromCRM,
    isLoading: isGetVTPanInfoLoading,
    errorMessage: getVTPanInfoErrorMessage,
  } = useGetVTPanInfoFromCRM();
  const {
    getVTExtraChargesFromCRM,
    isLoading: isGetVTExtraChargesInfoLoading,
    errorMessage: getVTExtraChargesInfoErrorMessage,
  } = useGetVTExtraChargesFromCRM();
  const { visiteTechniqueInfo, userAuthInfo } = useAppSelector(
    (state) => state
  );

  // Get saved form answer from local storage if there are any
  useGetFormAnswerFromLocalStorage({ formName, singleVisitId });
  useEffect(() => {
    // At the opening of the form we download data from CRM
    if (singleVisitId) {
      getVTCommonInfoFromCRM(userAuthInfo.userToken, formName, singleVisitId);
      getVTPanInfoFromCRM(userAuthInfo.userToken, formName, singleVisitId);
      getVTExtraChargesFromCRM(userAuthInfo.userToken, formName, singleVisitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleVisitId]);

  // Hook that allows to update the form according to the user's form answer
  useUpdateFormPVAccordingToUserAnswer({
    formName,
    formsMutableData,
    visitId: singleVisitId,
  });

  return (
    <div className="photovoltaique">
      <div className="photovoltaique__SubHeader">
        <SubHeader
          clientName={
            visiteTechniqueInfo.customerFirstName +
            " " +
            visiteTechniqueInfo.customerLastName
          }
          productType={visiteTechniqueInfo.products}
          commercialFullName={visiteTechniqueInfo.commercialFullName}
          commercialMobilePhone={visiteTechniqueInfo.commercialMobilePhone}
          urlButtonBack={`/visite-technique/${singleVisitId}`}
          commercialEmail={visiteTechniqueInfo.commercialEmail}
          formName="PV"
        />
      </div>
      <FormWrapper
        visiteTechniqueInfo={visiteTechniqueInfo}
        formName={formName}
        currentScreenName={currentScreenName}
        areGetDataFromCRMLoading={
          isGetVTCommonInfoLoading &&
          isGetVTPanInfoLoading &&
          isGetVTExtraChargesInfoLoading
        }
        getDataErrorMessage={
          getVTCommonInfoErrorMessage ||
          getVTPanInfoErrorMessage ||
          getVTExtraChargesInfoErrorMessage
        }
        formPagesLength={
          photovoltaiqueData.formScreens[currentScreenName].pages.length
        }
        visiteTechniqueId={singleVisitId}
        formScreenSteps={
          photovoltaiqueData.formScreens[currentScreenName].steps
        }
        numberOfScreens={photovoltaiqueData.formScreensName.length}
      >
        <Layout>
          <div className="photovoltaique__Content">
            {photovoltaiqueData.formScreens[
              currentScreenName as IFormattedFormScreenName
            ]?.pages?.[0]?.[0]?.questions ? (
              <FormQuestionsFormatter
                formScreenData={
                  photovoltaiqueData.formScreens[
                    currentScreenName as IFormattedFormScreenName
                  ].pages
                }
                formName={photovoltaiqueData.formName}
                visiteTechniqueId={singleVisitId}
                formCurrentPage={
                  formsMutableData.currentPageIndex[
                    currentScreenName as IFormattedFormScreenName
                  ]
                }
                formCurrentBranchs={formsMutableData.formCurrentBranchs}
              />
            ) : (
              // ValidateCalepinage page is a different format page : it is a normal JSX page that we display as it is
              photovoltaiqueData.formScreens[
                currentScreenName as IStandardFormScreenName
              ]?.pages?.map((page, index) => {
                const Page = StandalonePages[page];
                return (
                  <div key={index}>
                    <Page />
                  </div>
                );
              })
            )}
          </div>
        </Layout>
      </FormWrapper>
    </div>
  );
};
