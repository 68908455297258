// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../useAxiosGet";

// Services imports
import { useAppDispatch } from "../../../redux/store/hook";
import { setFormAnswersStateAction } from "../../../redux/appActions";

// Interfaces import
import { formNames } from "../../../interfaces/generalInterfaces";
import {
  IFormPanInformations,
  PanInformationAllSubQuestionsKeys,
} from "../../../interfaces/photovoltaiqueFormInterfaces";

// Interface delcaration
interface IFunctionReturn {
  getVTPanInfoFromCRM: (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

interface IRequestReturn {
  implantationduPan?: number | null;
  orientation?: number | null;
  typedeSupport?: number | null;
  natureDuSupport?: number | null;
  masqueSolaires?: (string | number)[];
  elagageaPrevoir?: number | null;
  hauteurdelaToitureGoutiere?: number | null;
  hauteurdelaToitureFaitage?: number | null;
  distanceduPignon?: number | null;
  longueurdelaFacade?: number | null;
  accessibiliteDesComnbles?: number | null;
  typeDaccesDesCombles?: number | null;
  etatdelaCharpente?: number | null;
  cheminee?: number | null;
  velux?: number | null;
  chienAssis?: number | null;
  commentaires?: string | null;
  id?: string | null;
  calepinage?: string | null;
  presencedeCheminee?: number | null;
  presencedeVelux?: number | null;
  presencedeChienAssis?: number | null;
  surfaceDisponible?: number | null;
  commentairePartieSolMasque?: string | null;
  commentairePartieToiture?: string | null;
  formatted?: string;
  inclinaison?: number | null;
  longueurduRampant?: number | null;
}

// useGetVTPanInfoFromCRM : return tools to get VT information from CRM
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function getVTPanInfoFromCRM
//     Function to call to get the VT's information
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useGetVTPanInfoFromCRM = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  // getVTPanInfoFromCRM : send get request to CRM. If successfull-> save data in local storage and in redux store
  // Parameters
  // ----------
  // userToken: String
  //      the JWT authentication token of the user
  // formName: String
  //      the form name
  // visiteTechniqueId: String
  //      the id of the visite technique
  // Returns
  // ----------
  // Void

  const getVTPanInfoFromCRM = async (
    userToken: string | null,
    formName: formNames,
    visiteTechniqueId: string
  ): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IRequestReturn[]>(
      `${BACKEND_URL}/api/VT/${visiteTechniqueId}/GetPanItems`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response) {
      const VTPanInfoToStoreInRedux: IFormPanInformations[] = [];
      response.forEach((pan, index) => {
        delete pan.formatted; // We delete the formatted key that is not usefull
        // We create a new object to match the app data structure

        for (let keys in pan) {
          VTPanInfoToStoreInRedux[index] = {
            ...VTPanInfoToStoreInRedux[index],
            [keys as PanInformationAllSubQuestionsKeys]: {
              value: pan[keys as PanInformationAllSubQuestionsKeys], // The value received from the CRM
              pictures: undefined, // A new key to store the pictures info
            },
          };
        }
      });
      dispatch(
        setFormAnswersStateAction({
          formName,
          formAnswers: { panInformations: VTPanInfoToStoreInRedux },
          visiteTechniqueId,
        })
      );
    }
  };

  return {
    getVTPanInfoFromCRM,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
