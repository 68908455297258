import "./headerMenuNavigation.scss";

// Libraries import
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

// components import
import { ButtonDisconnect } from "../../../components/ButtonDisconnect/ButtonDisconnect";
import { CTAButton } from "@web/shared/dist/components/DesignSystem/Boutons/CTAButton/CTAButton";

// Services import
import { useDisconnectApp } from "../../../services/useDisconnectApp";

// Interfaces delcaration
interface Props {
  toggleMenu: () => void;
}

// Navigation not working yet, will come in future version
export const HeaderMenuNavigation: React.FC<Props> = ({ toggleMenu }) => {
  const navigate = useNavigate();
  const { disconnectApp } = useDisconnectApp();

  const onClickLocalStorageButton = () => {
    navigate(`/localstorage`);
    toggleMenu();
  };

  return (
    // links to modify when pages will be done
    <nav className="header-menu-navigation">
      <ul>
        <li>
          <NavLink to="/" onClick={toggleMenu}>
            Mes visites
          </NavLink>
        </li>
        <li>
          <ButtonDisconnect onClick={disconnectApp} />
        </li>
      </ul>
      <CTAButton
        name="Base de données locale"
        family="vitee"
        category="tertiary"
        onClick={onClickLocalStorageButton}
      />
    </nav>
  );
};
