// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../useAxiosPost";

// Local interfaces delcaration

interface IVTCommonInfo {
  // See Swagger : https://tucoenergieroujoineapi.azurewebsites.net/swagger/index.html
  accessibilityMax?: string | number | null;
  planDisponible?: string | number | null;
  typeAlimentation?: string | number | null;
  puissanceSouscrite?: string | number | null;
  emplacementTableauElec?: string | number | null;
  emplacementCompteur?: string | number | null;
  typeCompteur?: string | number | null;
  nombredePantoEquip?: string | number | null;
  distanceOnduleur?: string | number | null;
  emplacementDelaPartie?: string | number | null;
  trancheeaPrevoir?: string | number | null;
  commentairesTranchee?: string | number | null;
  commentairePartieElectrique?: string | number | null;
  commentairePartieLogistique?: string | number | null;
  presencedeFourreauxExterieurs?: string | number | null;
  combiendeFourreaux?: string | number | null;
  diametreFourreaux?: string | number | null;
  nacelleaPrevoir?: string | number | null;
  portanceWifi?: string | number | null;
  typeEcsExistant?: string | number | null;
  descriptionAutreTypeECS?: string | number | null;
  existingCetBrand?: string | number | null;
  existingCetModel?: string | number | null;
  cetExistingLocation?: string | number | null;
  existingCetOtherLocation?: string | number | null;
  installationZone?: string | number | null;
  existingCetLocationComments?: string | number | null;
  cetFutureLocation?: string | number | null;
  cetFutureLocationComments?: string | number | null;
  distanceDeport?: string | number | null;
  distanceGroupeExtUniteInt?: string | number | null;
  distanceAlimentationEauCoffretElectrique?: string | number | null;
  numberofHouseOccupation?: string | number | null;
  cetUsage?: string | number | null;
  cetInstallationType?: string | number | null;
  carrotageExterieur?: string | number | null;
  changePressionReducer?: string | number | null;
  changePumpLift?: string | number | null;
  statutVT?: string | number | null;
  statutRapportVT?: string | number | null;
  resultatVTStatus?: string | number | null;
  commentairesRapportVT?: string | number | null;
  tempsPasse?: string | number | null;
  dateReceptionVT?: string | number | null;
  typeDeChauffageExistant?: string | number | null;
  otherKindOfHeater?: string | number | null;
  heaterExistingBrand?: string | number | null;
  heaterExistingModel?: string | number | null;
  fonctionChauffageECSintegree?: boolean;
  puissanceduchauffageenkW?: string | number | null;
  emplacementactueldusystemedechauffage?: string | number | null;
  otherPACLocation?: string | number | null;
  typeDeReseauExistants?: (string | number | null | undefined)[] | null;
  emmitterType?: string | number | null;
  nombredePiecesChauffeByRadiateurs?: string | number | null;
  etatreseauradiateurs?: string | number | null;
  radiateursVetustesFonctionnels?: boolean;
  nombredeboucleschauffeesparleplancherchauff?: string | number | null;
  pcVetuste?: boolean;
  commentairesplancherchauffant?: string | number | null;
  temperaturedeconfort?: string | number | null;
  anneedeconstructionourenovation?: string | number | null;
  nombredetages?: string | number | null;
  surfaceenm2?: string | number | null;
  hauteurSousPlafondDisponible?: string | number | null;
  nombredezonedechauffage?: string | number | null;
  reducteurdepression?: boolean;
  longueurdeliaisonfrigorifiqueetelectrique?: string | number | null;
  distancegroupeexterieurauTGBTmm?: string | number | null;
  distanceTGBTgroupehydrauliquemm?: string | number | null;
  valeurinitialeduG?: string | number | null;
  correctionduG?: string | number | null;
  calculdedeperditionthermiquekW?: string | number | null;
  dimensionnementdelaPACkW?: string | number | null;
  dimensionnementinitialcorrect?: boolean;
  commentairespartiedimensionnement?: string | number | null;
}

export const useSaveVTCommonInfoToCRM = () => {
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
    setErrorMessage: setAxiosPostErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);

  const resetStates = () => {
    setIsSuccessfull(false);
    setErrorMessage("");
    setAxiosPostErrorMessage("");
  };

  const saveVTCommonInfoToCRM = async ({
    VTCommonInfo,
    VTId,
    crmToken,
    eeroToken,
  }: {
    VTCommonInfo: IVTCommonInfo;
    VTId: string;
    crmToken: string | null;
    eeroToken: string | null;
  }): Promise<void> => {
    setErrorMessage("");

    const response = await axiosPostRequest(
      `${process.env.REACT_APP_EERO_URL}/vitee/saveCommonInfo?visiteTechniqueId=${VTId}`,
      { VTCommonInfo: VTCommonInfo },
      {
        headers: {
          Authorization: `Bearer ${eeroToken}`,
          "crm-authorization": `Bearer ${crmToken}`,
        },
      }
    );
    response && setIsSuccessfull(true);
    return;
  };

  return {
    saveVTCommonInfoToCRM,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
    isSuccessfull,
    resetStates,
  };
};
