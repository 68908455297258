// Libraries import
import React from "react";
import styled from "styled-components";

// Styles import
import { stylesVariables } from "../../../styles/variables";

// Interfaces declaration
export interface TextInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  autoComplete?: string;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

const Input = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "DM Sans";
  color: ${stylesVariables.MainBlack};
  height: 1.8rem;
  font-size: 1rem;
  text-align: left;
  cursor: text;
  &::placeholder {
    font-family: "DM Sans";
    letter-spacing: 0.02rem;
    color: ${stylesVariables.Grey35};
    font-size: 1rem;
  }
`;

const InputWrapper = styled.div`
  border-bottom: 0.1em solid ${stylesVariables.Grey10};
  width: 100%;
  max-width: 30rem;
`;

export const TextInput: React.FC<TextInputProps> = ({
  value = "",
  onChange,
  onKeyPress,
  placeholder = "",
  autoComplete,
  id = "",

  disabled = false,
}) => {
  return (
    <InputWrapper>
      <label htmlFor={id}>
        <Input
          placeholder={placeholder}
          value={value}
          autoComplete={autoComplete}
          onChange={onChange}
          type={"text"}
          onKeyPress={onKeyPress}
          id={id}
          disabled={disabled}
        />
      </label>
    </InputWrapper>
  );
};
