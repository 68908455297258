import { AnyAction } from "redux";

// Services import
import {
  checkIfIsFormCommonQuestion,
  checkIfIsPotentialTrancheeQuestion,
} from "../../services/Forms/checkTypes";

// Interfaces import
import {
  IAppStates,
  IFormCommonInfoKeys,
  IFormCommonInfo,
} from "../../interfaces/generalInterfaces";

// Other imports
import * as actions from "../appActions";
import { initialState } from "../initialState";

// SHARED REDUCER FUNCTIONS USED ACCROSS THE WHOLE APP
export const setUserAuthInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserAuthInfoAction.match(action)) {
    return {
      ...state,
      userAuthInfo: action.payload.userAuthInfo,
    };
  }
  return state;
};

export const setUserProfileInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserProfileInfoAction.match(action)) {
    return {
      ...state,
      userProfileInfo: action.payload.userProfileInfo,
    };
  }
  return state;
};

export const setVisitesTechniquesList = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setVisitesTechniquesListAction.match(action)) {
    if (action.payload.visitesTechniquesList) {
      return {
        ...state,
        visitesTechniquesList: action.payload.visitesTechniquesList,
      };
    }
    return {
      ...state,
      visitesTechniquesList: JSON.parse(
        JSON.stringify(initialState.visitesTechniquesList)
      ),
    };
  }
  return state;
};

export const setVisiteTechniqueInfo = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setVisiteTechniqueInfoAction.match(action)) {
    if (action.payload.visiteTechniqueInfo) {
      return {
        ...state,
        visiteTechniqueInfo: action.payload.visiteTechniqueInfo,
      };
    }
    return {
      ...state,
      visiteTechniqueInfo: JSON.parse(
        JSON.stringify(initialState.userProfileInfo)
      ),
    };
  }
  return state;
};

export const setFormMutableDataState = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setFormMutableDataStateAction.match(action)) {
    const { newFormsMutableDataState, formName } = action.payload;
    const newState = { ...state };
    newState.formsMutableData[formName] = {
      ...newFormsMutableDataState,
      ...state.formsMutableData[formName],
    }; // We merge the local storage state and the redux state, to be sure to not miss any key if local storage misses some
    return newState;
  }
  return state;
};

export const resetFormAnswersState = (state: IAppStates) => {
  const newState = { ...state };
  newState.formsMutableData = JSON.parse(
    JSON.stringify(initialState.formsMutableData)
  );
  return newState;
};

export const updateFormBranchState = (state: IAppStates, action: AnyAction) => {
  if (actions.updateFormBranchStateAction.match(action)) {
    const { formName, branchToAdd, branchToRemove } = action.payload;
    const newState = { ...state };

    // Add new branch to branch array if not already in it
    branchToAdd &&
      !newState.formsMutableData[formName].formCurrentBranchs.includes(
        branchToAdd
      ) &&
      newState.formsMutableData[formName].formCurrentBranchs.push(branchToAdd);

    // Remove branch from branch array
    if (branchToRemove) {
      const newBranchArray = state.formsMutableData[
        formName
      ].formCurrentBranchs.filter((item) => item !== branchToRemove);
      newState.formsMutableData[formName].formCurrentBranchs = newBranchArray;
    }

    return newState;
  }
  return state;
};

export const setGoToNextPage = (state: IAppStates, action: AnyAction) => {
  if (actions.setGoToNextPageAction.match(action)) {
    const { formName, formCurrentScreen, formPagesLength } = action.payload;
    const newState = { ...state };
    const currentPageIndex =
      state.formsMutableData[formName].currentPageIndex[formCurrentScreen];

    // Specific case for photovoltaique form : if there is several pans to equip, on click on next the user go to the next pan to equipe before going to the next page
    if (
      formName === "photovoltaique" &&
      formCurrentScreen === "visiteTechniqueFormPages" &&
      currentPageIndex === 0
    ) {
      // CurrentPageIndex === 0 : we are on the pan page
      const { panSelected, nombredePantoEquip } =
        state.formsMutableData[formName];
      const nombreDePanToEquipNumber =
        typeof nombredePantoEquip?.value === "string"
          ? parseInt(nombredePantoEquip.value)
          : nombredePantoEquip?.value;
      if (
        panSelected !== undefined &&
        nombreDePanToEquipNumber !== undefined &&
        nombreDePanToEquipNumber !== null &&
        panSelected < nombreDePanToEquipNumber - 1
      ) {
        newState.formsMutableData[formName].panSelected = panSelected + 1;
        return newState;
      }
    }

    // Normal case
    if (currentPageIndex + 1 === formPagesLength) {
      // If we are at the last page of a screen we go to the next screen
      newState.formsMutableData[formName].currentScreenIndex =
        state.formsMutableData[formName].currentScreenIndex + 1;
      return newState;
    }
    newState.formsMutableData[formName].currentPageIndex[formCurrentScreen] =
      currentPageIndex + 1;
    return newState;
  }
  return state;
};

export const setGoToPreviousPage = (state: IAppStates, action: AnyAction) => {
  if (actions.setGoToPreviousPageAction.match(action)) {
    const { formName, formCurrentScreen } = action.payload;
    const newState = { ...state };
    const currentPageIndex =
      state.formsMutableData[formName].currentPageIndex[formCurrentScreen];
    const currentScreenIndex =
      state.formsMutableData[formName].currentScreenIndex;

    // Specific case for photovoltaique form : if there is several pans to equip, on click on previous the user go to the previous pan to equipe before going to the previous page
    if (
      formName === "photovoltaique" &&
      formCurrentScreen === "visiteTechniqueFormPages" &&
      currentPageIndex === 0
    ) {
      // CurrentPageIndex === 0 : we are on the pan page
      const { panSelected } = state.formsMutableData[formName];
      if (panSelected !== undefined && panSelected > 0) {
        newState.formsMutableData[formName].panSelected = panSelected - 1;
        return newState;
      }
    }

    // Normal case
    if (currentScreenIndex > 0 && currentPageIndex === 0) {
      // Case when we need to go to previsous screen
      newState.formsMutableData[formName].currentScreenIndex =
        currentScreenIndex - 1;
      return newState;
    }

    if (currentPageIndex > 0) {
      // Case we neend to go to previous page of the same screen
      newState.formsMutableData[formName].currentPageIndex[formCurrentScreen] =
        currentPageIndex - 1;
      return newState;
    }
  }
  return state;
};

export const setGoToSpecificPage = (state: IAppStates, action: AnyAction) => {
  if (actions.setGoToSpecificPageAction.match(action)) {
    const { formName, pageIndex, formCurrentScreen } = action.payload;
    const newState = { ...state };
    newState.formsMutableData[formName].currentPageIndex[formCurrentScreen] =
      pageIndex;
    return newState;
  }
  return state;
};

export const setInitalState = () => {
  return initialState;
};

export const setFormPickUniqueCard = (state: IAppStates, action: AnyAction) => {
  if (actions.setPickUniqueCardAction.match(action)) {
    const { cardValue, formName, formQuestionName, visitId, answerIndex } =
      action.payload;
    const newState = { ...state };
    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      const currentAnswer =
        state.formsMutableData[formName]?.[formQuestionName];
      // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
      if (currentAnswer?.value === cardValue) {
        newState.formsMutableData[formName][formQuestionName] = {
          value: null,
          pictures: undefined,
        };
      } else {
        newState.formsMutableData[formName][formQuestionName] = {
          ...currentAnswer, // We keep the other potentials keys that already exist
          value: cardValue,
        };
      }
    }

    if (checkIfIsPotentialTrancheeQuestion(formQuestionName)) {
      const potentialTranchees =
        newState.formsMutableData[formName].potentialTranchees || [];
      const currentAnswer =
        state.formsMutableData[formName].potentialTranchees?.[
          answerIndex || 0
        ]?.[formQuestionName];

      // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
      if (currentAnswer?.value === cardValue) {
        potentialTranchees[answerIndex || 0] = {
          ...potentialTranchees[answerIndex || 0],
          [formQuestionName]: {
            value: null,
          },
        };
      } else {
        potentialTranchees[answerIndex || 0] = {
          ...potentialTranchees[answerIndex || 0],
          [formQuestionName]: {
            ...currentAnswer, // We keep the other potentials keys that already exist
            value: cardValue,
          },
        };
      }
      newState.formsMutableData[formName].potentialTranchees =
        potentialTranchees;
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setPickMultipleCards = (state: IAppStates, action: AnyAction) => {
  if (actions.setPickMultipleCardsAction.match(action)) {
    const { textCard, formName, visitId, formQuestionName } = action.payload;
    const newState = { ...state };

    const currentAnswer = state.formsMutableData[formName]?.[formQuestionName];
    const currentValue = currentAnswer?.value || [];
    // If same value is entered by the user -> we reset the user's answer (allow to undo an answer)
    if (currentAnswer?.value?.includes(textCard)) {
      newState.formsMutableData[formName][formQuestionName] = {
        value: currentAnswer?.value.filter((elem) => elem !== textCard),
        pictures: undefined,
      };
    } else {
      newState.formsMutableData[formName][formQuestionName] = {
        ...currentAnswer, // We keep the other potentials keys that already exist
        value: [...currentValue, textCard],
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setFormSelectedInput = (state: IAppStates, action: AnyAction) => {
  if (actions.setSelectedInputAction.match(action)) {
    const { selectedInput, formName, formQuestionName, visitId } =
      action.payload;
    const newState = { ...state };

    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      newState.formsMutableData[formName][formQuestionName] = {
        value: selectedInput,
        pictures: state.formsMutableData[formName][formQuestionName]?.pictures,
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setFormInputNumber = (state: IAppStates, action: AnyAction) => {
  if (actions.setInputNumberAction.match(action)) {
    const { inputNumber, formName, formQuestionName, visitId, answerIndex } =
      action.payload;
    const newState = { ...state };
    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      newState.formsMutableData[formName][formQuestionName] = {
        value: inputNumber,
        pictures: state.formsMutableData[formName][formQuestionName]?.pictures,
      };
    }

    if (checkIfIsPotentialTrancheeQuestion(formQuestionName)) {
      const potentialTranchees =
        newState.formsMutableData[formName].potentialTranchees || [];

      potentialTranchees[answerIndex || 0] = {
        ...potentialTranchees[answerIndex || 0],
        [formQuestionName]: { value: inputNumber },
      };

      newState.formsMutableData[formName].potentialTranchees =
        potentialTranchees;
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setFormInputText = (state: IAppStates, action: AnyAction) => {
  if (actions.setInputTextAction.match(action)) {
    const { inputText, formName, formQuestionName, visitId } = action.payload;
    const newState = { ...state };

    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      newState.formsMutableData[formName][formQuestionName] = {
        value: inputText,
        pictures: state.formsMutableData[formName][formQuestionName]?.pictures,
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setFormAnswersState = (state: IAppStates, action: AnyAction) => {
  if (actions.setFormAnswersStateAction.match(action)) {
    const { formAnswers, formName, visiteTechniqueId } = action.payload;
    const newState = { ...state };

    // We update redux form state as follow :
    // -> If the key exist in redux with a non nullish value, we don't update it : CRM values must not overwrite local values entered by the user
    // -> If the key is nullish (i.e. = undefined or null) : we create/update the key with the CRM values

    if (formAnswers) {
      const formAnswersTypedPV = formAnswers as IFormCommonInfo;
      for (let key in formAnswersTypedPV) {
        // Standard case (not panInformations key)

        if (
          newState.formsMutableData[formName]?.[key as IFormCommonInfoKeys]
            ?.value == null &&
          newState.formsMutableData[formName]?.[key as IFormCommonInfoKeys]
            ?.pictures == null // Check if nullish
        ) {
          newState.formsMutableData[formName][key as IFormCommonInfoKeys] =
            formAnswersTypedPV[key as IFormCommonInfoKeys]; // If nullish we update the redux key with the CRM value
        }
      }

      // When redux state is updated, we save the new form state in local storage
      localStorage.setItem(
        visiteTechniqueId,
        JSON.stringify(newState.formsMutableData[formName])
      );
      return newState;
    }

    // If we don't have new form mutable data we reset the mutable data of the form

    newState.formsMutableData[formName] = JSON.parse(
      JSON.stringify(initialState.formsMutableData[formName])
    );
    localStorage.removeItem(visiteTechniqueId);
    return newState;
  }
  return state;
};

export const setFormQuestionPicture = (
  state: IAppStates,
  action: AnyAction
) => {
  if (actions.setQuestionPictureAction.match(action)) {
    const { pictureUrl, formName, formQuestionName, visitId } = action.payload;
    const newState = { ...state };
    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      const currentAnswer =
        state.formsMutableData[formName]?.[formQuestionName];
      const currentPicture =
        state.formsMutableData[formName][formQuestionName]?.pictures;
      newState.formsMutableData[formName][formQuestionName] = {
        ...currentAnswer, // We keep the other potentials keys that already exist
        pictures:
          currentPicture && currentPicture?.length > 0
            ? [...currentPicture, pictureUrl]
            : [pictureUrl],
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setCalepinage = (state: IAppStates, action: AnyAction) => {
  if (actions.setCalepinageAction.match(action)) {
    const { calepinage } = action.payload;
    const newState = { ...state };
    newState.formsMutableData.calepinage = calepinage;
    return newState;
  }

  return state;
};

export const setFormCommonInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setFormCommonInfoAction.match(action)) {
    const { value, formName, formQuestionName, visitId, pictureUrl } =
      action.payload;
    const newState = { ...state };

    const currentAnswer = state.formsMutableData[formName][formQuestionName];
    const currentPicture = currentAnswer?.pictures;

    if (currentAnswer?.value === value) {
      newState.formsMutableData[formName][formQuestionName] = {
        ...state.formsMutableData[formName][formQuestionName],
        value: null,
        ...(pictureUrl && {
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        }),
      };
    } else {
      newState.formsMutableData[formName][formQuestionName] = {
        ...state.formsMutableData[formName][formQuestionName],
        value: value !== undefined ? value : currentAnswer?.value,
        ...(pictureUrl && {
          pictures:
            currentPicture && currentPicture?.length > 0
              ? [...currentPicture, pictureUrl]
              : pictureUrl
              ? [pictureUrl]
              : currentPicture,
        }),
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};

export const setFormDropDown = (state: IAppStates, action: AnyAction) => {
  if (actions.setFormDropDownAction.match(action)) {
    const { value, formName, formQuestionName, visitId } = action.payload;
    const newState = { ...state };

    if (checkIfIsFormCommonQuestion(formQuestionName)) {
      newState.formsMutableData[formName][formQuestionName] = {
        value: value,
        pictures: state.formsMutableData[formName][formQuestionName]?.pictures,
      };
    }

    // When redux state is updated, we save the new form state in local storage
    visitId &&
      localStorage.setItem(
        visitId,
        JSON.stringify(newState.formsMutableData[formName])
      );
    return newState;
  }
  return state;
};
