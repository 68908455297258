import "./formPages.scss";

// Librairie import
import React from "react";

// Components import
import { PickUniqueCard } from "../../../../containers/Forms/FormStepsNew/PickUniqueCard/PickUniqueCard";
import { InputText } from "../../../../containers/Forms/FormStepsNew/InputText/InputText";
import { InputNumber } from "../../../../containers/Forms/FormStepsNew/InputNumber/InputNumber";
import { InfoBoxForm } from "../../../../components/InfoBoxForm/InfoBoxForm";

// Interfaces import
import { IPacAirEauFormMutableData } from "../../../../interfaces/generalInterfaces";

// Local interfaces declaration
interface Props {
  PacAirEauFormMutableData?: IPacAirEauFormMutableData;
  visitId?: string;
}

export const ExtraChargesPage = ({
  PacAirEauFormMutableData,
  visitId,
}: Props) => {
  const formName = "PacAirEau";

  return (
    <div className="formPages">
      <div className="formPages__Category">
        <h4>Tranchée(s)</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Une tranchée est-elle à prévoir ?"
        cards={[0, 1]}
        formName={formName}
        questionName="trancheeaPrevoir"
        userAnswer={PacAirEauFormMutableData?.trancheeaPrevoir}
        visitId={visitId}
      />
      <PickUniqueCard
        title="Combien de tranchée à prévoir ?"
        formName={formName}
        cards={[0, 1, 2, 3, 4]}
        questionName="combienTrancheeAPrevoir"
        userAnswer={PacAirEauFormMutableData?.combienTrancheeAPrevoir}
        visitId={visitId}
      />
      {Number(PacAirEauFormMutableData?.combienTrancheeAPrevoir?.value) > 0 &&
        Array.from(
          Array(
            Number(PacAirEauFormMutableData?.combienTrancheeAPrevoir?.value)
          )
        ).map((num, index) => (
          <div key={index} className="formPages__Tranchee">
            <h5 className="title">Tranchée {index + 1} </h5>
            <PickUniqueCard
              title="Dans quel terrain  ?"
              cards={[629950000, 629950001, 629950002]}
              formName={formName}
              questionName="typedeTerrain"
              userAnswer={
                PacAirEauFormMutableData?.potentialTranchees?.[index]
                  ?.typedeTerrain
              }
              visitId={visitId}
              answerIndex={index}
            />
            <InputNumber
              title="Longueur de la tranchée 1 (m) :"
              formName={formName}
              userAnswer={
                PacAirEauFormMutableData?.potentialTranchees?.[index]
                  ?.longueurTranchee
              }
              questionName="longueurTranchee"
              placeholder="Ex : 50"
              visitId={visitId}
              answerIndex={index}
            />
          </div>
        ))}
      <div className="formPages__Category">
        <h4>Désembouage</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Un désembouage est-il nécessaire ?"
        cards={[0, 1]}
        formName={formName}
        questionName="desembouageNecessaire"
        userAnswer={PacAirEauFormMutableData?.desembouageNecessaire}
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Pompe de relevage</h4>
        <div className="formPages__Trait" />
      </div>
      <InfoBoxForm
        textContent={
          "En général,  la pompe de relevage doit être ajoutée sur les ballons afin de fluidifier l’évacuation de l’eau au cas où l’installation ne comporte aucune évacuation existante (ou si une évacuation en cuivre est présente)."
        }
      />
      <PickUniqueCard
        title="Est-ce qu’une pompe de relevage sera nécessaire au projet ?"
        cards={[0, 1]}
        formName={formName}
        questionName="pompeDeRelevageNecessaire"
        userAnswer={PacAirEauFormMutableData?.pompeDeRelevageNecessaire}
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Carottage</h4>
        <div className="formPages__Trait" />
      </div>
      <PickUniqueCard
        title="Un carottage sur plus de 60 cm est-il à prévoir ?"
        cards={[0, 1]}
        formName={formName}
        questionName="carrotageaPrevoir"
        userAnswer={PacAirEauFormMutableData?.carrotageaPrevoir}
        visitId={visitId}
      />
      <PickUniqueCard
        title="Combien de carottage à prévoir ? "
        formName={formName}
        cards={[1, 2, 3, 4]}
        questionName="nombreDeCarrotage"
        userAnswer={PacAirEauFormMutableData?.nombreDeCarrotage}
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Déport du réseau hydraulique</h4>
        <div className="formPages__Trait" />
      </div>
      <InfoBoxForm
        textContent={
          "Le déport du ballon est compté si il est supérieur à un mètre."
        }
      />
      <PickUniqueCard
        title="Le déport du réseau hydraulique est-il nécessaire ?"
        cards={[0, 1]}
        formName={formName}
        questionName="deportDuBallon"
        userAnswer={PacAirEauFormMutableData?.deportDuBallon}
        visitId={visitId}
      />
      <InputNumber
        title="Si Oui, sur combien de mètres ?"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.combienDeMetresDeportDuBallon}
        questionName="combienDeMetresDeportDuBallon"
        placeholder="Ex : 50"
        visitId={visitId}
      />
      <PickUniqueCard
        title="Est-ce qu’un ballon doit être prévu ? Attention si VT CET a déjà eu lieu alors NON"
        cards={[0, 1]}
        formName={formName}
        questionName="ballonPrevu"
        userAnswer={PacAirEauFormMutableData?.ballonPrevu}
        visitId={visitId}
      />
      <InputText
        title="Commentaire sur le déport de ballon"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentaireDeportDuBallon}
        questionName="commentaireDeportDuBallon"
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Dalle bétonnée</h4>
        <div className="formPages__Trait" />
      </div>
      <InfoBoxForm
        textContent={
          "Les dalles bétonnées ne sont à prévoir que pour les ballons bi-bloc. Les dalles seront gravillonnées. Si le client souhaite prendre en charge la dalle et la faire, alors mettre NON."
        }
      />
      <PickUniqueCard
        title="Est-ce qu’une dalle doit être prévue ?"
        cards={[0, 1]}
        formName={formName}
        questionName="dalleBetonneeNecessaire"
        userAnswer={PacAirEauFormMutableData?.dalleBetonneeNecessaire}
        visitId={visitId}
      />
      <InputText
        title="Commentaire sur la dalle"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentaireDalleBetonnee}
        questionName="commentaireDalleBetonnee"
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Liaison hydraulique</h4>
        <div className="formPages__Trait" />
      </div>

      <PickUniqueCard
        title="Est-il nécessaire d’ajouter un isolant pour la liaison hydraulique côté chaufferie  ?"
        cards={[0, 1]}
        formName={formName}
        questionName="ajoutIsolantLiaisonHydrauliqueChaufferie"
        userAnswer={
          PacAirEauFormMutableData?.ajoutIsolantLiaisonHydrauliqueChaufferie
        }
        visitId={visitId}
      />
      <InputNumber
        title="Si Oui, sur combien de mètres ?"
        formName={formName}
        userAnswer={
          PacAirEauFormMutableData?.siOuiLiaisonHydrauliqueCombienMetres
        }
        questionName="siOuiLiaisonHydrauliqueCombienMetres"
        placeholder="Ex : 50"
        visitId={visitId}
      />
      <PickUniqueCard
        title="Nécessité d’un kit bi-zone ?"
        cards={[0, 1]}
        formName={formName}
        questionName="necessiteKitBiZone"
        userAnswer={PacAirEauFormMutableData?.necessiteKitBiZone}
        visitId={visitId}
      />
      <InfoBoxForm
        textContent={
          "Le kit bi-zone est obligatoire lorsque deux zones doivent être chauffées à des températures différentes sur un même réseau. "
        }
      />
      <div className="formPages__Category">
        <h4>Liaison frigorifique</h4>
        <div className="formPages__Trait" />
      </div>
      <InfoBoxForm
        textContent={"Au dessus de 15m, la recharge de gaz est nécessaire. "}
      />
      <PickUniqueCard
        title="Est-il nécessaire d’ajouter du gaz ?"
        cards={[0, 1]}
        formName={formName}
        questionName="necessiteAjoutGaz"
        userAnswer={PacAirEauFormMutableData?.necessiteAjoutGaz}
        visitId={visitId}
      />
      <InputNumber
        title="Si Oui, sur combien de mètres ?"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.siOuiAjoutGazCombienMetres}
        questionName="siOuiAjoutGazCombienMetres"
        placeholder="Ex : 50"
        visitId={visitId}
      />
      <div className="formPages__Category">
        <h4>Autres frais supplémentaires ?</h4>
        <div className="formPages__Trait" />
      </div>
      <InfoBoxForm
        textContent={
          <>
            Merci de ne pas reprendre les frais supplémentaires renseignés dans
            le formulaire ci-dessus.{" "}
            <strong>
              La zone ci-après concerne les frais supplémentaires non listés.
            </strong>{" "}
          </>
        }
      />
      <InputText
        title="Commentaire frais supplémentaires additionnels"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.commentaireFraisSupplementaires}
        questionName="commentaireFraisSupplementaires"
        visitId={visitId}
      />
      <InputNumber
        title="Montant estimé (€) :"
        formName={formName}
        userAnswer={PacAirEauFormMutableData?.montantEstimeFraisSupplementaires}
        questionName="montantEstimeFraisSupplementaires"
        placeholder="Ex : 50"
        visitId={visitId}
      />
    </div>
  );
};
