import { View, StyleSheet, Image, Text } from "@react-pdf/renderer";

//Images
import logoTuco from "../../../assets/pdf/images/logoTuco.png";

export const PdfHeader = () => {
  // Create styles
  const styles = StyleSheet?.create({
    containerHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 35,
      marginTop: 22,
    },
    logo: {
      width: 135,
      objectFit: "contain",
      paddingRight: 10,
    },
    title: {
      fontSize: 24,
      fontFamily: "DMSansBold",
    },
  });
  return (
    <View fixed style={styles.containerHeader}>
      <Image style={styles.logo} src={logoTuco} />
      <Text style={styles.title}>Visite technique</Text>
    </View>
  );
};
