// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Local interface declaration
export interface IGetVTFileInformationsResponse {
  fileName: string;
  url: string;
}

export const useGetVTFileInformations = () => {
  const BACKEND_URL = process.env.REACT_APP_VITEE_AZURE_API_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getVTFileInformations = async ({
    userToken,
    visiteTechniqueId,
    clientId,
  }: {
    userToken: string | null;
    visiteTechniqueId: string | null;
    clientId: string | null;
  }): Promise<IGetVTFileInformationsResponse[] | void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IGetVTFileInformationsResponse[]>(
      `${BACKEND_URL}/api/VT/${clientId}/${visiteTechniqueId}/GetVTFileInformations`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    return response;
  };

  return {
    getVTFileInformations,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
