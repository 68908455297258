// Interfaces import
import { ICRMWall } from "../../../interfaces/ITEFormInterface";

export const formatCRMWindowsBeforeSavingInRedux = (CRMWall: ICRMWall) => {
  return CRMWall.ouvrantItem
    .filter((ouvrant) => ouvrant.name.toLowerCase().includes("fenetre"))
    .map((window) => {
      return {
        murId: {
          value: String(CRMWall.murId),
        },
        ouvrantId: {
          value: String(window.ouvrantId),
        },
        ouvrantName: {
          value: String(window.name),
        },
        windowWidth: {
          value: String(window.largeur),
        },
        windowHeigh: {
          value: String(window.hauteur),
        },
        appuiFenetreExistant: {
          value: String(window.appuifenetreexistant),
        },
      };
    });
};
